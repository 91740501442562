import React, { useState  } from "react";
import { Axios } from "../../config/axiosConf";
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { DataGrid } from '@mui/x-data-grid';
import { ptBR } from '@mui/x-data-grid/locales';
import Grupo from "../../components/AutoCompletes/Grpds";
import Produto from "../../components/AutoCompletes/Produtos";
import CheckboxesEstBE from "../../components/Checkboxes/CheckboxesPlanilhaEst";
import styled from "@emotion/styled";
import { styled as Estilo, darken, lighten } from "@mui/material/styles";
import {
  Typography,
  Button,
  CircularProgress,
  FormControlLabel,
  Radio
} from "@mui/material";
import { AiOutlineSearch } from "react-icons/ai";
import Data from "../../components/DataPickers/DataUnica";
import "../../styles/ConsultaGeralVendas.css";
import logo from "../../assets/Logo-Grupo-Sumatex.png";
import logo_sumatex from "../../assets/logo.png";
import logo_ambiental from "../../assets/Logo-Sumatex-Ambiental.png";
import logo_agro from "../../assets/Logo-Sumatex-Agro.png";
import logo_lorenvel from "../../assets/Logo-Lorenvel.png";
import logo_cesbra from "../../assets/Logo-Cesbra.png";
import Modal from "react-modal";

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  width: 90%;
  gap: 16px;
  padding: 24px;
  background-color: #f5f5f5;
  border-radius: 8px;
  height: 100%;
  margin: 50px;

  @media (max-width: 550px) {
    width: 100%;
    margin: 0px;
  }
`;

const RadioWrapper = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

const RadioButton = styled(Radio)`
  color: #2196f3;
`;

const StyledTitle = styled(Typography)`
  font-weight: bold;
  font-size: 1.5em;
  margin: 0em 0;
  background-color: #2957a4;
  color: #fff;
  padding: 10px;
  text-align: center;
  @media (max-width: 550px) {
    font-size: 1.2em;
  }
`;

const StyledOrange = styled(Typography)`
    background-color: #D66E20;
    padding-top: 3px;
    padding-bottom: 3px;
  @media (max-width: 550px) {
    font-size: 1.2em;
  }
`;

const StyledGreen = styled(Typography)`
    background-color: #7CA217;
    padding-top: 3px;
    padding-bottom: 3px;
  @media (max-width: 550px) {
    font-size: 1.2em;
  }
`;


const ButtonContainer = styled.div`
  display: flex;
  gap: 16px;
  margin-left: 25px;
`;
const ResultadosTitle = styled.h2`
  margin: 0;
  font-size: 2em; /* Aumenta o tamanho da fonte */
  color: #1f437d; /* Define uma cor personalizada (laranja) */
  text-align: center; /* Centraliza o texto */
  text-transform: uppercase; /* Converte o texto em maiúsculas */
  padding: 10px; /* Adiciona espaçamento interno */
  border-radius: 10px; /* Adiciona bordas arredondadas */
  @media (max-width: 550px) {
    width: 80px;
    font-size: 1.5em;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-wrap: wrap; /* Permite que os botões quebrem para a próxima linha em telas estreitas */
  gap: 16px;
  justify-content: flex-end; /* Alinha os botões à direita */
  margin-top: 20px;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  @media (max-width: 550px) {
    justify-content: space-between;
  }
`;

const SaveButton = styled(Button)`
  height: 30px;
  width: 150px;
  color: #fff;
  background-color: #2957a4;
  border: 1px solid rgba(0, 0, 0, 0.2);
  &:hover {
    background-color: #1f437d;
  }
`;

const LimparButton = styled(Button)`
  height: 30px;
  width: 100px;
  color: #fff;
  background-color: #ff6347;
  border: 1px solid rgba(0, 0, 0, 0.2);
  &:hover {
    background-color: #ff0000;
  }
  @media (max-width: 550px) {
    width: 80px;
    font-size: 0.8em;
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const ImprimirButton = styled(Button)`
  height: 30px;
  padding-left: 50px;
  padding-right: 50px;
  width: 120px;
  color: #fff;
  background-color: #a0a0a0;
  border: 1px solid rgba(0, 0, 0, 0.2);
  &:hover {
    background-color: #808080;
  }

  @media (max-width: 550px) {
    width: 80px;
    font-size: 0.8em;
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const XlsButton = styled(Button)`
  height: 30px;
  width: 120px;
  padding-left: 50px;
  padding-right: 50px;
  color: #fff;
  background-color: #71bc78;
  border: 1px solid rgba(0, 0, 0, 0.2);
  &:hover {
    background-color: #569b5c;
  }
  @media (max-width: 550px) {
    width: 80px;
    font-size: 0.8em;
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const ResultadosContainer = styled.div`
  margin-top: 20px;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  @media (max-width: 550px) {
    // Estilo responsivo para largura de tela até 360px
    padding: 10px; // Ajuste o espaçamento conforme necessário
  }
`;

const getBackgroundColor = (color, mode) =>
  mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7);

const getHoverBackgroundColor = (color, mode) =>
  mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

const getSelectedBackgroundColor = (color, mode) =>
  mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

const getSelectedHoverBackgroundColor = (color, mode) =>
  mode === 'dark' ? darken(color, 0.4) : lighten(color, 0.4);


const StyledDataGrid = Estilo(DataGrid)(({ theme }) => ({
  '& .grid-azul': {
    backgroundColor: getBackgroundColor(theme.palette.info.main, theme.palette.mode),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.info.main,
        theme.palette.mode,
      ),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.info.main,
        theme.palette.mode,
      ),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.info.main,
          theme.palette.mode,
        ),
      },
    },
  },
  '& .grid-vermelho': {
    backgroundColor: getBackgroundColor(
      theme.palette.error.main,
      theme.palette.mode,
    ),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.error.main,
        theme.palette.mode,
      ),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.error.main,
        theme.palette.mode,
      ),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.error.main,
          theme.palette.mode,
        ),
      },
    },
  },
  '& .grid-amarelo': {
    backgroundColor: getBackgroundColor(
      theme.palette.warning.main,
      theme.palette.mode,
    ),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.warning.main,
        theme.palette.mode,
      ),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.warning.main,
        theme.palette.mode,
      ),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.warning.main,
          theme.palette.mode,
        ),
      },
    },
  },
}));

const EstoquePlanilhaGeral = () => {
  const [formattedDataFinal, setFormattedDataFinal] = useState("");
  const Filial = sessionStorage.getItem("empresa");
  const [grupoSelecionado, setGrupoSelecionado] = useState(null);
  const [produtoSelecionado, setProdutoSelecionado] = useState(null);
  const [prodDay, setProdDay] = useState(null);
  const [prodMovDay, setProdMovDay] = useState(null);
  const [saldoIniProdMovDay, setSaldoIniProdMovDay] = useState(null);
  const [saldoFinalProdMovDay, setSaldoFinalProdMovDay] = useState(null);
  const [entradaProdMovDay, setEntradaProdMovDay] = useState(null);
  const [saidaProdMovDay, setSaidaProdMovDay] = useState(null);
  const [tipoDeProduto, setTipoDeProdutoSelecionado] = useState("E");
  const [estoqueMinimo, setEstoqueMinimo] = useState(null);
  const [importados, setImportados] = useState(null);
  const [nacionais, setNacionais] = useState(null);
  const [controlePF, setControlePF] = useState(null);
  const [controlePC, setControlePC] = useState(null);
  const [controleEB, setControleEB] = useState(null);
  const [programado, setProgramado] = useState(null);
  const [trocaNF, setTrocaNF] = useState(null);
  const [consolidado, setConsolidado] = useState(null);
  const [resultados, setResultados] = useState([]);
  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(false);
    // eslint-disable-next-line
  const [consultaSelecionada, setConsultaSelecionada] = useState("relatorio");
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [rows2, setRows2] = useState([]);
  const [columns2, setColumns2] = useState([]);
  const [rows3, setRows3] = useState([]);
  const [columns3, setColumns3] = useState([]);
  const [rows4, setRows4] = useState([]);
  const [columns4, setColumns4] = useState([]);
  const [modalKey, setModalKey] = useState(0);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [blurBackground, setBlurBackground] = useState(false);

  const [columnVisibilityModel1, setColumnVisibilityModel1] = React.useState({
    id: false,
    linha: false,
    DATA_INICIAL: false,
  });

  const [filterModel, setFilterModel] = React.useState({
    items: [
    ],
  });

  const [columnVisibilityModel2, setColumnVisibilityModel2] = React.useState({
    id: false,
  });

  const [filterModel2, setFilterModel2] = React.useState({
    items: [
    ],
  });

  const [columnVisibilityModel4, setColumnVisibilityModel4] = React.useState({
    id: false,
  });

  const [filterModel4, setFilterModel4] = React.useState({
    items: [
    ],
  });

  const applyFilters = (rows, filterModel) => {
    return rows.filter((row) => {
      return filterModel.items.every((filter) => {
        const { field, operator, value } = filter;
        const rowValue = row[field];
  
        // Convertendo rowValue e value para string para comparações de texto
        const rowValueStr = String(rowValue).toLowerCase();
        const valueStr = String(value).toLowerCase();
  
        switch (operator) {
          case '>':
            return rowValue > value;
          case '>=':
            return rowValue >= value;
          case '<':
            return rowValue < value;
          case '<=':
            return rowValue <= value;
          case 'equals':
          case '=': // Igualdade numérica ou textual
            return rowValueStr === valueStr;
          case '!=': // Diferença numérica ou textual
            return rowValue !== value;
          case 'contains': // Contém
            return rowValueStr.includes(valueStr);
          case 'startsWith': // Começa com
            return rowValueStr.startsWith(valueStr);
          case 'endsWith': // Termina com
            return rowValueStr.endsWith(valueStr);
          default:
            return true; // Se o operador não for suportado, não filtra.
        }
      });
    });
  };

  const openModal = () => {
    if (!modalIsOpen) {
      setModalIsOpen(true);
      setBlurBackground(true);
      setModalKey((prevKey) => prevKey + 1);
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setBlurBackground(false);
  };

  const trocarDeTipoDeProduto = (event) => {
    setTipoDeProdutoSelecionado(event.target.value);
  };

  const customComparator = (v1, v2) => {
    const value1 = parseFloat(v1.replace(',', '.'));
    const value2 = parseFloat(v2.replace(',', '.'));
  
    if (value1 < value2) {
      return -1;
    }
    if (value1 > value2) {
      return 1;
    }
    return 0;
  };

  const customDateComparator = (dateStr1, dateStr2) => {

    const parts1 = dateStr1.split('/');
    const parts2 = dateStr2.split('/');
    const date1 = new Date(Number(parts1[2]), Number(parts1[1]) - 1, Number(parts1[0]));
    const date2 = new Date(Number(parts2[2]), Number(parts2[1]) - 1, Number(parts2[0]));
  
    if (date1 < date2) {
      return -1;
    }
    if (date1 > date2) {
      return 1;
    }
    return 0;
};

const handleRowDbClick = (params) => {

  function groupByDate(data) {
    return rows.reduce((groups, item) => {
        const date = item.data + '-' + item.Produto;
        if (!groups[date]) {
            groups[date] = [];
        }
        groups[date].push(item);
        return groups;
    }, {});
  }

  function resultDia(data, targetDate) {
    
      const groupedData = groupByDate(data);
      const result = [];

      for (let date in groupedData) {

        if (date === targetDate) {
            const items = groupedData[date];

            for (let i = 0; i < items.length; i++) {
              result.push(items[i])
            }
          
        }
    }

    return result;
  }

  const rows3 = resultDia(rows, params.Data + '-' + params.Produto);
  const columns3 = Object.keys(rows3[0]).map(key => ({
      field: key,
      ...(key === 'Tipo' ? {
        headerName: 'Tipo',
        width: 150
      } : {...(key === 'Produto' ? {
        headerName: 'Produto',
        width: 400 
      } : {...(key === 'unidade' ? {
        headerName: 'Un',
        width: 80
      } : {...(key === 'inicial' ? {
        headerName: 'Inicial (Kg)',
        width: 150 
      } : {...(key === 'entrada' ? {
        headerName: 'Entrada (Kg)',
        width: 150 
      } : {...(key === 'saida' ? {
        headerName: 'Saída (Kg)',
        width: 150
      } : {...(key === 'saldo_inicial' ? {
        headerName: 'Saldo Ini (Kg)',
        width: 150 
      } : {...(key === 'Produto_Origem' ? {
        headerName: 'P. Origem',
        width: 400 
      } : {...(key === 'pedido' ? {
        headerName: 'Pedido',
        width: 80
      } : {...(key === 'NOTA' ? {
        headerName: 'NF',
        width: 80
      } : {...(key === 'cfop' ? {
        headerName: 'CFOP',
        width: 80
      } : {...(key === 'nome' ? {
        headerName: 'Empresa',
        width: 400
      } : {...(key === 'qtd_total_entrada' ? {
        headerName: 'Qtd Entrada (Kg)',
        width: 120
      } : {...(key === 'qtd_total_saida' ? {
        headerName: 'Qtd Saída (Kg)',
        width: 120
      } : {...(key === 'saldo_final' ? {
        headerName: 'Saldo (Kg)',
        width: 120
      } : {...(key === 'vl_unitario_entrada' ? {
        headerName: 'Un Entrada (R$)',
        width: 120
      } : {...(key === 'vl_entrada_medio' ? {
        headerName: 'Pç Médio (R$)',
        width: 120
      } : {...(key === 'vl_total_entrada' ? {
        headerName: 'Entrada (R$)',
        width: 120
      } : {...(key === 'vl_total_geral_entrada' ? {
        headerName: 'Total Entrada (R$)',
        width: 120
      } : {...(key === 'vl_unitario_saida' ? {
        headerName: 'Un Saída (R$)',
        width: 120
      } : {...(key === 'vl_saida_medio' ? {
        headerName: 'Pç Médio Saida (R$)',
        width: 120
      } : {...(key === 'vl_total_saida' ? {
        headerName: 'Total Saída (R$)',
        width: 120
      } : {...(key === 'vl_total_geral_saida' ? {
        headerName: 'Total Saída (R$)',
        width: 120
      } : {...(key === 'vl_saldo' ? {
        headerName: 'Vl Saldo (R$)',
        width: 120
      } : {...(key === 'data' ? {
        headerName: 'Data',
        width: 120,
        sortComparator: customDateComparator
      } : {headerName: key, width: 200})})})})})})})})})})})})})})})})})})})})})})})})}),

      ...(key === 'inicial' || key === 'entrada' || key  === 'saida' || key  === 'saldo_inicial' || key  === 'qtd_total_entrada' 
      || key  === 'qtd_total_saida' || key  === 'saldo_final' || key  === 'vl_unitario_entrada'  || key  === 'vl_entrada_medio'  
      || key  === 'vl_total_entrada' || key  === 'vl_total_geral_entrada'  || key  === 'vl_unitario_saida'  || key  === 'vl_saida_medio'
      || key  === 'vl_total_saida'  || key  === 'vl_total_geral_saida' || key  === 'vl_saldo'  ? {
        valueFormatter: (params) => {
          if (params != null) {
            return params.toLocaleString('pt-BR', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            });
          }
        }
    } : {})
  }));

  setRows3(rows3);
  setColumns3(columns3);

  setProdDay(params.Data);
  setProdMovDay(params.Produto);
  setSaldoIniProdMovDay(params.SaldoInicial);
  setSaldoFinalProdMovDay(params.SaldoFinal);
  setEntradaProdMovDay(params.Entradas);
  setSaidaProdMovDay(params.Saidas);

  openModal();
};

const calculaQtdEntrada = () => {
  const entradaTotal = resultados.reduce((total, item) => total + (item.entrada || 0), 0);
  return entradaTotal == null ? 0 : formatarValor(entradaTotal);
};

const calculaQtdSaida = () => {
  const saidaTotal = resultados.reduce((total, item) => total + (item.saida || 0), 0);
  return saidaTotal == null ? 0 : formatarValor(saidaTotal);
};

const estoqueInicial = () => {
  const estoqueInicial = resultados.length > 0 ? resultados[0].saldo_inicial : 0;
  return estoqueInicial == null ? 0 : formatarValor(estoqueInicial);
};

const saldoFinal = () => {
  const ultimoIndex = resultados.length - 1;
  const saldoFinal = resultados.length > 0 ? resultados[ultimoIndex].saldo_final : 0;

  return saldoFinal == null ? 0 : formatarValor(saldoFinal);
};

const dataInicialEstoque = () => {
  const dataInicial = resultados.length > 0 ? resultados[0].DATA_INICIAL : 0;
  return dataInicial;
};

const handleLimparResultados = () => {
    setResultados([]);
    setColumns([]);
    setRows([]);
    setColumns2([]);
    setRows2([]);
    setColumns3([]);
    setRows3([]);
    setColumns4([]);
    setRows4([]);
    setProdMovDay(null);
    setProdDay(null);
    setSaldoIniProdMovDay(null);
    setSaldoFinalProdMovDay(null);
    setEntradaProdMovDay(null);
    setSaidaProdMovDay(null);
  };

  const realizarConsulta = async () => {
    let procedure = "";

    switch (consultaSelecionada) {
      case "relatorio":
        procedure = "estoque-planilha-geral";
        break;
      default:
        procedure = "relatorio";
        break;
    }

    try {
      const data = {
        Data: {
          formattedDataFinal,
        },

        Grpds: {
          grupoSelecionado:
            grupoSelecionado === null ? null : grupoSelecionado.substring(0, 6),
        },
        
        Produto: {
          produtoSelecionado:
            produtoSelecionado === null ? null : produtoSelecionado.substring(0, 6),
        },

        tipoDeProduto,

        CheckboxesEstBE: {
          estoqueMinimo,
          importados,
          nacionais,
          controlePF,
          programado,
          trocaNF,
          controlePC,
          controleEB
        },
        Filial,
      };

      const config = {
        timeout: 100000,
      };

      setIsLoading(true);

      const response = await Axios.post(
        `estoque/${procedure}`,
        data,
        config
      );

      if (response.data.length > 0){

        const rows = response.data.map((item, index) => {
          const rowData = {};
          Object.keys(item).forEach(key => {
              rowData[key] = item[key];
          });
          
          rowData['id'] = index + 1; 
          return rowData;
        });

        function groupByProd(data) {
          return response.data.reduce((groups, item) => {
              const prod = item.Produto;
              if (!groups[prod]) {
                  groups[prod] = [];
              }
              groups[prod].push(item);
              return groups;
          }, {});
        }


        function consolidadoEstoqueProdutos(data) {
          const groupedData = groupByProd(data);
          const result = [];


          for (let date in groupedData) {
              const items = groupedData[date];
              const saldoInicial = items[0].saldo_inicial;
              const saldoFinal = items[items.length - 1].saldo_final;
              const pcMedioEnt = items[items.length - 1].vl_entrada_medio;
              const pcMedioSai = items[items.length - 1].vl_saida_medio;
              const totVlEntrada = items[items.length - 1].vl_total_geral_entrada;
              const totVlSaida = items[items.length - 1].vl_total_geral_saida;
              const totalEntrada = items[items.length - 1].qtd_total_entrada;
              const totalSaida = items[items.length - 1].qtd_total_saida;
            
              result.push({  id: items[0].linha, Produto: items[0].Produto, Unidade: 'KG', SaldoInicial: saldoInicial, Entradas: totalEntrada, Saidas: totalSaida, 
                              SaldoFinal: saldoFinal, PcMedioEnt: pcMedioEnt, PcMedioSai: pcMedioSai, TotVlEntrada: totVlEntrada,
                              TotVlSaida: totVlSaida, Fechamento: items[0].DATA_INICIAL });
          }

          return result;
        }

        
        const rows4 = consolidadoEstoqueProdutos(response.data);

        const columns4 = Object.keys(rows4[0]).map(key => ({
            field: key,
            ...(key === 'Fechamento' ? {
              headerName: 'Fechamento',
              width: 150
            } : {...(key === 'Produto' ? {
              headerName: 'Produto',
              width: 400 
            } : {...(key === 'SaldoInicial' ? {
              headerName: 'Saldo Inicial (Kg)',
              width: 200
            } : {...(key === 'SaldoFinal' ? {
              headerName: 'Saldo Final (Kg)',
              width: 150 
            } : {...(key === 'Entradas' ? {
              headerName: 'Entradas (Kg)',
              width: 150 
            } : {...(key === 'Saidas' ? {
              headerName: 'Saídas (Kg)',
              width: 150 
            } : {...(key === 'PcMedioEnt' ? {
              headerName: 'Pç Médio Ent. (R$)',
              width: 150 
            } : {...(key === 'PcMedioSai' ? {
              headerName: 'Pç Médio Sai. (R$)',
              width: 150 
            } : {...(key === 'TotVlEntrada' ? {
              headerName: 'Total Entrada (R$)',
              width: 150 
            } : {...(key === 'TotVlSaida' ? {
              headerName: 'Total Saída (R$)',
              width: 150 
            } : {...(key === 'Unidade' ? {
              headerName: 'Un',
              width: 80 
            } : {headerName: key, width: 200})})})})})})})})})})}),

            ...(key === 'SaldoInicial' || key === 'SaldoFinal' || key === 'Entradas' || key === 'Saidas' || key === 'PcMedioEnt' 
                || key === 'PcMedioSai' || key === 'TotVlEntrada' || key === 'TotVlSaida' ? {
              valueFormatter: (params) => {
                if (params != null) {
                  return params.toLocaleString('pt-BR', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  });
                }
              }
          } : {})
        }));

        columns4.unshift({ field: 'id', headerName: 'ID', width: 10 });

        setRows4(rows4)
        setColumns4(columns4)

        function groupByDate(data) {
          return response.data.reduce((groups, item) => {
              const date = item.data + '-' + item.Produto;
              if (!groups[date]) {
                  groups[date] = [];
              }
              groups[date].push(item);
              return groups;
          }, {});
        }


        function findInitialAndFinalBalances(data) {
          const groupedData = groupByDate(data);
          const result = [];


          for (let date in groupedData) {
              const items = groupedData[date];
              const initialBalance = items[0].saldo_inicial;
              const finalBalance = items[items.length - 1].saldo_final;
              
              let totalEntrada = 0;
              for (let i = 0; i < items.length; i++) {
                  totalEntrada += items[i].entrada;
              }
              let totalSaida = 0;
              for (let i = 0; i < items.length; i++) {
                  totalSaida += items[i].saida;
              }
              result.push({ id: items[0].linha, Data: items[0].data, Produto: items[0].Produto, 
                             SaldoInicial: initialBalance, 
                             Entradas: totalEntrada, 
                             Saidas: totalSaida, 
                             SaldoFinal: finalBalance });
          }

          return result;
        }

        const rows2 = findInitialAndFinalBalances(response.data);

        const columns2 = Object.keys(rows2[0]).map(key => ({
            field: key,
            ...(key === 'Data' ? {
              headerName: 'Data',
              width: 150
            } : {...(key === 'Produto' ? {
              headerName: 'Produto',
              width: 400 
            } : {...(key === 'SaldoInicial' ? {
              headerName: 'Saldo Inicial (Kg)',
              width: 200
            } : {...(key === 'SaldoFinal' ? {
              headerName: 'Saldo Final (Kg)',
              width: 150 
            } : {...(key === 'Entradas' ? {
              headerName: 'Entradas (Kg)',
              width: 150 
            } : {...(key === 'Saidas' ? {
              headerName: 'Saídas (Kg)',
              width: 150 
            } : {headerName: key, width: 200})})})})})}),

            ...(key === 'SaldoInicial' || key === 'SaldoFinal' || key === 'Entradas' || key === 'Saidas' ? {
              valueFormatter: (params) => {
                if (params != null) {
                  return params.toLocaleString('pt-BR', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  });
                }
              }
          } : {})
        }));

        columns2.unshift({ field: 'id', headerName: 'ID', width: 10 });

        setRows2(rows2)
        setColumns2(columns2)

        const columns = Object.keys(response.data[0]).map(key => ({
            field: key,
            ...(key === 'Tipo' ? {
              headerName: 'Tipo',
              width: 200
            } : {...(key === 'Produto' ? {
              headerName: 'Produto',
              width: 400 
            } : {...(key === 'unidade' ? {
              headerName: 'Un',
              width: 80
            } : {...(key === 'inicial' ? {
              headerName: 'Inicial (Kg)',
              width: 150 
            } : {...(key === 'entrada' ? {
              headerName: 'Entrada (Kg)',
              width: 150 
            } : {...(key === 'saida' ? {
              headerName: 'Saída (Kg)',
              width: 150
            } : {...(key === 'saldo_inicial' ? {
              headerName: 'Saldo Ini (Kg)',
              width: 150 
            } : {...(key === 'Produto_Origem' ? {
              headerName: 'P. Origem',
              width: 400 
            } : {...(key === 'pedido' ? {
              headerName: 'Pedido',
              width: 80
            } : {...(key === 'NOTA' ? {
              headerName: 'NF',
              width: 80
            } : {...(key === 'cfop' ? {
              headerName: 'CFOP',
              width: 80
            } : {...(key === 'nome' ? {
              headerName: 'Empresa',
              width: 400
            } : {...(key === 'qtd_total_entrada' ? {
              headerName: 'Qtd Entrada (Kg)',
              width: 120
            } : {...(key === 'qtd_total_saida' ? {
              headerName: 'Qtd Saída (Kg)',
              width: 120
            } : {...(key === 'saldo_final' ? {
              headerName: 'Saldo (Kg)',
              width: 120
            } : {...(key === 'vl_unitario_entrada' ? {
              headerName: 'Un Entrada (R$)',
              width: 120
            } : {...(key === 'vl_entrada_medio' ? {
              headerName: 'Pç Médio (R$)',
              width: 120
            } : {...(key === 'vl_total_entrada' ? {
              headerName: 'Entrada (R$)',
              width: 120
            } : {...(key === 'vl_total_geral_entrada' ? {
              headerName: 'Total Entrada (R$)',
              width: 120
            } : {...(key === 'vl_unitario_saida' ? {
              headerName: 'Un Saída (R$)',
              width: 120
            } : {...(key === 'vl_saida_medio' ? {
              headerName: 'Pç Médio Saida (R$)',
              width: 120
            } : {...(key === 'vl_total_saida' ? {
              headerName: 'Saída (R$)',
              width: 120
            } : {...(key === 'vl_total_geral_saida' ? {
              headerName: 'Total Saída (R$)',
              width: 120
            } : {...(key === 'vl_saldo' ? {
              headerName: 'Vl Saldo (R$)',
              width: 120
            } : {...(key === 'data' ? {
              headerName: 'Data',
              width: 120,
              sortComparator: customDateComparator
            } : {headerName: key, width: 200})})})})})})})})})})})})})})})})})})})})})})})})}),
  
            ...(key === 'inicial' || key === 'entrada' || key  === 'saida' || key  === 'saldo_inicial' || key  === 'qtd_total_entrada' 
            || key  === 'qtd_total_saida' || key  === 'saldo_final' || key  === 'vl_unitario_entrada'  || key  === 'vl_entrada_medio'  
            || key  === 'vl_total_entrada' || key  === 'vl_total_geral_entrada'  || key  === 'vl_unitario_saida'  || key  === 'vl_saida_medio'
            || key  === 'vl_total_saida'  || key  === 'vl_total_geral_saida' || key  === 'vl_saldo'  ? {
              valueFormatter: (params) => {
                if (params != null) {
                  return params.toLocaleString('pt-BR', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  });
                }
              }
          } : {})
        }));
  
        columns.unshift({ field: 'id', headerName: 'ID', width: 10 });

        setRows(rows);
        setColumns(columns)

      }

      setResultados(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error("Erro ao realizar a consulta:", error);
      setIsLoading(false);
    }

  };

  function formatarValor(valor) {
    if (valor === null || valor === undefined) {
      return '';
    }
    
    if (typeof valor !== "number") {
      return valor;
    }

    return valor.toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }

  function desformatarData(data) {
    if (!data) return null;
  
    const ano = data.slice(0, 4);
    const mes = data.slice(4, 6);
    const dia = data.slice(6, 8);
  
    return `${dia}/${mes}/${ano}`;
  }

  const handlePrintResults = () => {
    const Filial = sessionStorage.getItem("empresa");
    const printContent = `
      <html>
        <head>
          <title>S.I.G.A - Grupo Sumatex</title>
          <style>
            /* Estilos para o relatório de impressão */
            body { font-family: Arial, sans-serif; margin: 0; padding: 0; }
            h1 {
              text-align: center;
              margin-bottom: 20px;
              font-size: 24px;
            }
            h2 img {
              height: 40px;
              margin-right: 10px;
            }
            table { 
              width: 100%; 
              border-collapse: 
              collapse; 
            }
            th, td { 
              border: 1px solid #000; 
              padding: 8px; 
              text-align: left; 
            }
          </style>
        </head>
        <body>
        <h2>
        ${
          Filial === "1 - MAT" ? `<img src="${logo_sumatex}" alt="Logo">` :
          Filial === "7 - CES" ? `<img src="${logo_cesbra}" alt="Logo">` :
          Filial === "2 - AMB" ? `<img src="${logo_ambiental}" alt="Logo">` :
          Filial === "9 - LOG" ? `<img src="${logo_lorenvel}" alt="Logo">` :
          Filial === "5 - FMT" ? `<img src="${logo_agro}" alt="Logo">` :
          `<img src="${logo}" alt="Logo">`
        } </h2>
       <h1>
          Planilha de Estoque Geral
       </h1>
       <div>
          <div class="filters">
           <p style="text-align: center;"><strong>Data: </strong>${desformatarData(formattedDataFinal)}</strong></p>
           
           ${grupoSelecionado ? `<div key="grupo"><p style="text-align: center;"><strong>Grupo: </strong> ${grupoSelecionado}</p></div>` : ''}
           ${produtoSelecionado ? `<div key="produto"><p style="text-align: center;"><strong>Produto: </strong> ${produtoSelecionado}</p></div>` : ''}
           </div>
        </div>
       <table>
       <thead>
         <tr>
           ${columns
            .filter((coluna) => columnVisibilityModel1[coluna.field] !== false)
            .map((coluna) => `<th>${coluna.headerName}</th>`)
             .join('')}
         </tr>
       </thead>
       <tbody>
         ${applyFilters(rows, filterModel)
           .map((item, index) => {
             return `
               <tr ${index % 2 === 0 ? 'style="background-color: #f9f9f9;"' : ''}>
                 ${columns
                  .filter((coluna) => columnVisibilityModel1[coluna.field] !== false)
                  .map((coluna) => {
                     return `<td>${formatarValor(item[coluna.field])}</td>`
                   })
                   .join("")}
               </tr>
             `;
           })
           .join("")}
       </tbody>
     </table>
     ${produtoSelecionado ?
     `<div style="margin-top: 20px; display: grid; grid-template-columns: 1fr 1fr;">
          <div>
            <h2>Dados</h2>
              <div class="consolidated-data">
                  <div><p><strong>Fechamento: </strong> ${dataInicialEstoque()}</p></div>
                  <div><p><strong>Saldo Inicial (Kg): </strong> ${estoqueInicial()}</p></div>
                  <div><p><strong>Entrada (Kg): </strong> ${calculaQtdEntrada()}</p></div>
                  <div><p><strong>Saída (Kg): </strong> ${calculaQtdSaida()}</p></div>
                  <div><p><strong>Saldo (Kg):</strong> ${saldoFinal()}</p></div>
              </div>
          </div>
      </div>` : null}
    </body>
  </html>
      `;

      const screenWidth = window.screen.width;
      const screenHeight = window.screen.height;
      const printWindow = window.open(
        "",
        "",
        `width=${screenWidth},height=${screenHeight}`
      );
  
      if (!printWindow) {
        alert(
          "Erro ao abrir a janela de impressão. Verifique as configurações do seu navegador."
        );
        return;
      }
  
      printWindow.document.open();
      printWindow.document.write(printContent);
      printWindow.document.close();
  
      printWindow.onload = () => {
        printWindow.print();
  
        setTimeout(() => {}, 1000);
      };
  };

  const handlePrintResultsDiario = () => {
    const Filial = sessionStorage.getItem("empresa");
    const printContent = `
      <html>
        <head>
          <title>S.I.G.A - Grupo Sumatex</title>
          <style>
            /* Estilos para o relatório de impressão */
            body { font-family: Arial, sans-serif; margin: 0; padding: 0; }
            h1 {
              text-align: center;
              margin-bottom: 20px;
              font-size: 24px;
            }
            h2 img {
              height: 40px;
              margin-right: 10px;
            }
            table { 
              width: 100%; 
              border-collapse: 
              collapse; 
            }
            th, td { 
              border: 1px solid #000; 
              padding: 8px; 
              text-align: left; 
            }
          </style>
        </head>
        <body>
        <h2>
        ${
          Filial === "1 - MAT" ? `<img src="${logo_sumatex}" alt="Logo">` :
          Filial === "7 - CES" ? `<img src="${logo_cesbra}" alt="Logo">` :
          Filial === "2 - AMB" ? `<img src="${logo_ambiental}" alt="Logo">` :
          Filial === "9 - LOG" ? `<img src="${logo_lorenvel}" alt="Logo">` :
          Filial === "5 - FMT" ? `<img src="${logo_agro}" alt="Logo">` :
          `<img src="${logo}" alt="Logo">`
        } </h2>
       <h1>
          Estoque Diário Por Produto
       </h1>
       <div>
          <div class="filters">
           <p style="text-align: center;"><strong>Data: </strong>${desformatarData(formattedDataFinal)}</strong></p>
           
           ${grupoSelecionado ? `<div key="grupo"><p style="text-align: center;"><strong>Grupo: </strong> ${grupoSelecionado}</p></div>` : ''}
           ${produtoSelecionado ? `<div key="produto"><p style="text-align: center;"><strong>Produto: </strong> ${produtoSelecionado}</p></div>` : ''}
           </div>
        </div>
       <table>
       <thead>
         <tr>
           ${columns2
            .filter((coluna) => columnVisibilityModel2[coluna.field] !== false)
            .map((coluna) => `<th>${coluna.headerName}</th>`)
             .join('')}
         </tr>
       </thead>
       <tbody>
         ${applyFilters(rows2, filterModel)
           .map((item, index) => {
             return `
               <tr ${index % 2 === 0 ? 'style="background-color: #f9f9f9;"' : ''}>
                 ${columns2
                  .filter((coluna) => columnVisibilityModel2[coluna.field] !== false)
                  .map((coluna) => {
                     return `<td>${formatarValor(item[coluna.field])}</td>`
                   })
                   .join("")}
               </tr>
             `;
           })
           .join("")}
       </tbody>
     </table>
      </body>
      </html>
      `;

      const screenWidth = window.screen.width;
      const screenHeight = window.screen.height;
      const printWindow = window.open(
        "",
        "",
        `width=${screenWidth},height=${screenHeight}`
      );
  
      if (!printWindow) {
        alert(
          "Erro ao abrir a janela de impressão. Verifique as configurações do seu navegador."
        );
        return;
      }
  
      printWindow.document.open();
      printWindow.document.write(printContent);
      printWindow.document.close();
  
      printWindow.onload = () => {
        printWindow.print();
  
        setTimeout(() => {}, 1000);
      };
  };

  const handlePrintResultsConsolidado = () => {
    const Filial = sessionStorage.getItem("empresa");
    const printContent = `
      <html>
        <head>
          <title>S.I.G.A - Grupo Sumatex</title>
          <style>
            /* Estilos para o relatório de impressão */
            body { font-family: Arial, sans-serif; margin: 0; padding: 0; }
            h1 {
              text-align: center;
              margin-bottom: 20px;
              font-size: 24px;
            }
            h2 img {
              height: 40px;
              margin-right: 10px;
            }
            table { 
              width: 100%; 
              border-collapse: 
              collapse; 
            }
            th, td { 
              border: 1px solid #000; 
              padding: 8px; 
              text-align: left; 
            }
          </style>
        </head>
        <body>
        <h2>
        ${
          Filial === "1 - MAT" ? `<img src="${logo_sumatex}" alt="Logo">` :
          Filial === "7 - CES" ? `<img src="${logo_cesbra}" alt="Logo">` :
          Filial === "2 - AMB" ? `<img src="${logo_ambiental}" alt="Logo">` :
          Filial === "9 - LOG" ? `<img src="${logo_lorenvel}" alt="Logo">` :
          Filial === "5 - FMT" ? `<img src="${logo_agro}" alt="Logo">` :
          `<img src="${logo}" alt="Logo">`
        } </h2>
       <h1>
          Estoque Consolidado
       </h1>
       <div>
          <div class="filters">
           <p style="text-align: center;"><strong>Data: </strong>${desformatarData(formattedDataFinal)}</strong></p>
           
           ${grupoSelecionado ? `<div key="grupo"><p style="text-align: center;"><strong>Grupo: </strong> ${grupoSelecionado}</p></div>` : ''}
           ${produtoSelecionado ? `<div key="produto"><p style="text-align: center;"><strong>Produto: </strong> ${produtoSelecionado}</p></div>` : ''}
           </div>
        </div>
       <table>
       <thead>
         <tr>
           ${columns4
            .filter((coluna) => columnVisibilityModel4[coluna.field] !== false)
            .map((coluna) => `<th>${coluna.headerName}</th>`)
             .join('')}
         </tr>
       </thead>
       <tbody>
         ${applyFilters(rows4, filterModel)
           .map((item, index) => {
             return `
               <tr ${index % 2 === 0 ? 'style="background-color: #f9f9f9;"' : ''}>
                 ${columns4
                  .filter((coluna) => columnVisibilityModel4[coluna.field] !== false)
                  .map((coluna) => {
                     return `<td>${formatarValor(item[coluna.field])}</td>`
                   })
                   .join("")}
               </tr>
             `;
           })
           .join("")}
       </tbody>
     </table>
      </body>
      </html>
      `;

      const screenWidth = window.screen.width;
      const screenHeight = window.screen.height;
      const printWindow = window.open(
        "",
        "",
        `width=${screenWidth},height=${screenHeight}`
      );
  
      if (!printWindow) {
        alert(
          "Erro ao abrir a janela de impressão. Verifique as configurações do seu navegador."
        );
        return;
      }
  
      printWindow.document.open();
      printWindow.document.write(printContent);
      printWindow.document.close();
  
      printWindow.onload = () => {
        printWindow.print();
  
        setTimeout(() => {}, 1000);
      };
  };

  const handleGenerateXLS = async () => {

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Resultados');
  
    const colunas = columns
    .filter((coluna) => columnVisibilityModel1[coluna.field] !== false)
      .map((coluna) => coluna.headerName);
  
    const headerRow = worksheet.addRow(colunas);
  
    headerRow.eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'D3D3D3' },
      };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
      cell.font = { bold: true };
    });
  
    applyFilters(rows, filterModel).forEach((item) => {
      const rowData = columns
        .filter((coluna) => columnVisibilityModel1[coluna.field] !== false)
        .map((coluna) => item[coluna.field]);
      worksheet.addRow(rowData);
    });
  
    const range = worksheet.getSheetValues();
    for (let R = 1; R <= range.length; ++R) {
      const row = worksheet.getRow(R);
      for (let C = 1; C <= colunas.length; ++C) {
        const cell = row.getCell(C);
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      }
    }
  
    const buffer = await workbook.xlsx.writeBuffer();
    const nomeDoArquivo = `resultados_planilha_estoque_geral_${formattedDataFinal}.xlsx`;
  
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, nomeDoArquivo);
  };
  
  const handleGenerateXLSDiario = async () => {

  
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Resultados');
  
    const colunas = columns2
      .filter((coluna) => columnVisibilityModel2[coluna.field] !== false)
      .map((coluna) => coluna.headerName);
  
    const headerRow = worksheet.addRow(colunas);
  
    headerRow.eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'D3D3D3' },
      };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
      cell.font = { bold: true };
    });
  
    applyFilters(rows2, filterModel).forEach((item) => {
      const rowData = columns2
        .filter((coluna) => columnVisibilityModel2[coluna.field] !== false)
        .map((coluna) => item[coluna.field]);
      worksheet.addRow(rowData);
    });
  
    const range = worksheet.getSheetValues();
    for (let R = 1; R <= range.length; ++R) {
      const row = worksheet.getRow(R);
      for (let C = 1; C <= colunas.length; ++C) {
        const cell = row.getCell(C);
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      }
    }
  
    const buffer = await workbook.xlsx.writeBuffer();
    const nomeDoArquivo = `resultados_planilha_estoque_geral_diario_${formattedDataFinal}.xlsx`;
  
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, nomeDoArquivo);
  };
  
  const handleGenerateXLSConsolidado = async () => {
  
  
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Resultados');
  
    const colunas = columns4
      .filter((coluna) => columnVisibilityModel4[coluna.field] !== false)
      .map((coluna) => coluna.headerName);
  
    const headerRow = worksheet.addRow(colunas);
  
    headerRow.eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'D3D3D3' },
      };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
      cell.font = { bold: true };
    });
  
    applyFilters(rows4, filterModel).forEach((item) => {
      const rowData = columns4
        .filter((coluna) => columnVisibilityModel4[coluna.field] !== false)
        .map((coluna) => item[coluna.field]);
      worksheet.addRow(rowData);
    });
  
    const range = worksheet.getSheetValues();
    for (let R = 1; R <= range.length; ++R) {
      const row = worksheet.getRow(R);
      for (let C = 1; C <= colunas.length; ++C) {
        const cell = row.getCell(C);
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      }
    }
  
    const buffer = await workbook.xlsx.writeBuffer();
    const nomeDoArquivo = `resultados_planilha_estoque_geral_consolidado_${formattedDataFinal}.xlsx`;
  
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, nomeDoArquivo);
  };

  return (
    <div className={`content ${blurBackground ? "blur-background" : ""}`}>
      <StyledOrange />
      <StyledTitle>Planilha de Estoque Geral</StyledTitle>
      <StyledGreen />

      <FormContainer>
        <div>
          {isLoading && (
            <div
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 9999,
              }}
            >
              <CircularProgress />
            </div>
          )}

          <Data
            formattedDataFinal={formattedDataFinal}
            setFormattedDataFinal={setFormattedDataFinal}
          />

          <Grupo
            grupoSelecionado={grupoSelecionado}
            setGrupoSelecionado={setGrupoSelecionado}
          />

          <Produto
            produtoSelecionado={produtoSelecionado}
            setProdutoSelecionado={setProdutoSelecionado}
          />

          <label></label>

          <RadioWrapper>
            <FormControlLabel
              value="E"
              control={<RadioButton aria-label="BaixadeEstoque" />}
              label="Baixa de Estoque"
              checked={tipoDeProduto === "E"}
              onChange={trocarDeTipoDeProduto}
            />
            <FormControlLabel
              value="C"
              control={<RadioButton aria-label="BaixaComercial" />}
              label="Baixa Comercial"
              checked={tipoDeProduto === "C"}
              onChange={trocarDeTipoDeProduto}
            />
         </RadioWrapper>

         <label></label>

          <CheckboxesEstBE
            estoqueMinimo={estoqueMinimo}
            importados={importados}
            nacionais={nacionais}
            controlePF={controlePF}
            programado={programado}
            trocaNF={trocaNF}
            consolidado={consolidado}
            controlePC={controlePC}
            controleEB={controleEB}
            setEstoqueMinimo={setEstoqueMinimo}
            setImportados={setImportados}
            setNacionais={setNacionais}
            setControlePF={setControlePF}
            setProgramado={setProgramado}
            setTrocaNF={setTrocaNF}
            setConsolidado={setConsolidado}
            setControlePC={setControlePC}
            setControleEB={setControleEB}
          />
          <br></br>
          <ButtonContainer>
            <SaveButton onClick={realizarConsulta}>
              <AiOutlineSearch />
               Consultar
            </SaveButton>
          </ButtonContainer>
        </div>
      </FormContainer>

      {resultados.length > 0 ? (
        <ResultadosContainer id="resultados-container">
          <ResultadosTitle>Resultados</ResultadosTitle>
          <ButtonWrapper>
            <LimparButton
              onClick={handleLimparResultados}
              variant="contained"
              color="secondary"
            >
              Limpar
            </LimparButton>
            <ImprimirButton
              onClick={handlePrintResults}
              variant="contained"
              color="primary"
            >
              Imprimir
            </ImprimirButton>
            <XlsButton
              onClick={handleGenerateXLS}
              variant="contained"
              color="primary"
            >
              Gerar⠀XLS
            </XlsButton>
          </ButtonWrapper>

        {produtoSelecionado ? (
          <div
            style={{
              border: "5px solid #D3D3D3",
              padding: "10px",
            }}
          >
            <Typography component="div">
              <div className="tb-result">
                  <table>
                      <tr>
                        <td style={{color:"#2957A4"}}>Fechamento</td>
                        <td>{dataInicialEstoque()}</td>
                      </tr>
                      <tr>
                        <td style={{color:"#2957A4"}}>Saldo Inicial (Kg)</td>
                        <td>{estoqueInicial()}</td>
                      </tr>
                      <tr>
                        <td style={{color:"#2957A4"}}>Entrada (Kg)</td>
                        <td>{calculaQtdEntrada()}</td>
                      </tr>
                      <tr>
                        <td style={{color:"#2957A4"}}>Saída (Kg)</td>
                        <td>{calculaQtdSaida()}</td>
                      </tr>
                      <tr>
                        <td style={{color:"#2957A4"}}>Saldo (Kg)</td>
                        <td>{saldoFinal()}</td>
                      </tr>
                  </table>
              </div>
          </Typography>
        </div>
        ) : null}  
          <br></br>
          <StyledDataGrid
                rows={rows}
                columns={columns}
                rowHeight={30}
                sortComparator={customComparator}
                sx={{
                  boxShadow: 2,
                  border: 2,
                  borderColor: 'primary.light',
                }}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 20 },
                  },
                }}
                columnVisibilityModel={columnVisibilityModel1}
                onColumnVisibilityModelChange={(newModel) =>
                  setColumnVisibilityModel1(newModel)
                }
                filterModel={filterModel}
                onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                pageSizeOptions={[5, 10, 15, 20]}
                disableSelectionOnClick
              />
              <br></br>
              <ResultadosTitle>Estoque Diário Por Produto</ResultadosTitle>
              <ButtonWrapper>
                <LimparButton
                  onClick={handleLimparResultados}
                  variant="contained"
                  color="secondary"
                >
                  Limpar
                </LimparButton>
                <ImprimirButton
                  onClick={handlePrintResultsDiario}
                  variant="contained"
                  color="primary"
                >
                  Imprimir
                </ImprimirButton>
                <XlsButton
                  onClick={handleGenerateXLSDiario}
                  variant="contained"
                  color="primary"
                >
                  Gerar⠀XLS
                </XlsButton>
              </ButtonWrapper>
              <br></br>
              <StyledDataGrid
                onRowDoubleClick={(params) =>
                                    handleRowDbClick(params.row)}
                rows={rows2}
                columns={columns2}
                rowHeight={30}
                sortComparator={customComparator}
                sx={{
                  boxShadow: 2,
                  border: 2,
                  borderColor: 'primary.light',
                }}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 20 },
                  },
                }}
                columnVisibilityModel={columnVisibilityModel2}
                onColumnVisibilityModelChange={(newModel) =>
                  setColumnVisibilityModel2(newModel)
                }
                filterModel={filterModel2}
                onFilterModelChange={(newFilterModel) => setFilterModel2(newFilterModel)}
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                pageSizeOptions={[5, 10, 15, 20]}
                disableSelectionOnClick
              />

              <br></br>
              
              {consolidado === "S" ? (
              <div>
                <ResultadosTitle>Estoque Consolidado</ResultadosTitle>
                <ButtonWrapper>
                  <LimparButton
                    onClick={handleLimparResultados}
                    variant="contained"
                    color="secondary"
                  >
                    Limpar
                  </LimparButton>
                  <ImprimirButton
                    onClick={handlePrintResultsConsolidado}
                    variant="contained"
                    color="primary"
                  >
                    Imprimir
                  </ImprimirButton>
                  <XlsButton
                    onClick={handleGenerateXLSConsolidado}
                    variant="contained"
                    color="primary"
                  >
                    Gerar⠀XLS
                  </XlsButton>
                </ButtonWrapper>
                <br></br>
                <StyledDataGrid
                  rows={rows4}
                  columns={columns4}
                  rowHeight={30}
                  sortComparator={customComparator}
                  sx={{
                    boxShadow: 2,
                    border: 2,
                    borderColor: 'primary.light',
                  }}
                  initialState={{
                    pagination: {
                      paginationModel: { page: 0, pageSize: 20 },
                    },
                  }}
                  columnVisibilityModel={columnVisibilityModel4}
                  onColumnVisibilityModelChange={(newModel) =>
                    setColumnVisibilityModel4(newModel)
                  }
                  filterModel={filterModel4}
                  onFilterModelChange={(newFilterModel) => setFilterModel4(newFilterModel)}
                  localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                  pageSizeOptions={[5, 10, 15, 20]}
                  disableSelectionOnClick
                />
              </div>
              ) : null}
            
            <Modal
              isOpen={modalIsOpen}
              key={modalKey}
              ariaHideApp={false}
              onRequestClose={closeModal}
              contentLabel="Detalhes do Pedido"
              className={`custom-modal`}
              overlayClassName="custom-overlay"
              style={{ content: { width: '80%' } }}
            >
                <div style={{ width: "100%"}}>
                  <strong><p style={{fontSize: "20px"}}>{prodMovDay}{" - "}{prodDay}</p></strong>

                  <StyledDataGrid
                    rows={rows3}
                    columns={columns3}
                    rowHeight={30}
                    sortComparator={customComparator}
                    sx={{
                      boxShadow: 2,
                      border: 2,
                      borderColor: 'primary.light',
                    }}
                    initialState={{
                      pagination: {
                        paginationModel: { page: 0, pageSize: 10 },
                      },
                      columns: {
                        columnVisibilityModel: {
                          id: false,
                          DATA_INICIAL: false,
                          data: false,
                          linha: false,
                          Produto: false,
                          Produto_Origem: false,
                          qtd_total_entrada: false,
                          qtd_total_saida: false,
                          saldo_final: false,
                          saldo_inicial: false,
                          vl_entrada_medio: false,
                          vl_saida_medio: false,
                          vl_saldo: false,
                          vl_total_entrada: false,
                          vl_total_geral_entrada: false,
                          vl_total_geral_saida: false,
                          vl_total_saida: false,
                          vl_unitario_entrada: false,
                          vl_unitario_saida: false,
                        },
                      },
                    }}
                    localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                    pageSizeOptions={[5, 10, 15, 20]}
                    disableSelectionOnClick
                  />
                  <br></br>
                  <Typography component="div">
                    <div className="tb-result">
                        <table>
                            <tr>
                              <td style={{color:"#2957A4"}}>Saldo Inicial (Kg)</td>
                              <td>{formatarValor(saldoIniProdMovDay)}</td>
                              <td>{" "}</td>
                              <td style={{color:"#2957A4"}}>Entrada (Kg)</td>
                              <td>{formatarValor(entradaProdMovDay)}</td>
                              <td>{" "}</td>
                              <td style={{color:"#2957A4"}}>Saída (Kg)</td>
                              <td>{formatarValor(saidaProdMovDay)}</td>
                              <td>{" "}</td>
                              <td style={{color:"#2957A4"}}>Saldo (Kg)</td>
                              <td>{formatarValor(saldoFinalProdMovDay)}</td>
                            </tr>
                        </table>
                    </div>
                  </Typography>
                    
                </div>
              <br></br>
              <LimparButton onClick={closeModal}>Fechar</LimparButton>
            </Modal>

        </ResultadosContainer>
      ) : null}        

    </div>
  );
};

export default EstoquePlanilhaGeral;