import React, { useState } from "react";
import { Axios } from "../../../config/axiosConf";
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { DataGrid } from '@mui/x-data-grid';
import { ptBR } from '@mui/x-data-grid/locales';
import Portador from "../../../components/AutoCompletes/Portador";
import PlanoCta from "../../../components/AutoCompletes/PlanoCta";
import Empresas from "../../../components/AutoCompletes/Empresas";
import RadioDataFinan from "../../../components/RadioButtons/RadioDataFinan";
import CheckboxTodas from "../../../components/Checkboxes/CheckboxTodas";
import styled from "@emotion/styled";
import { styled as Estilo, darken, lighten } from "@mui/material/styles";
import {
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";
import { AiOutlineSearch } from "react-icons/ai";
import Data from "../../../components/DataPickers/Data";
import "../../../styles/ConsultaGeralVendas.css"
import logo from "../../../assets/Logo-Grupo-Sumatex.png";
import logo_sumatex from "../../../assets/logo.png";
import logo_ambiental from "../../../assets/Logo-Sumatex-Ambiental.png";
import logo_agro from "../../../assets/Logo-Sumatex-Agro.png";
import logo_lorenvel from "../../../assets/Logo-Lorenvel.png";
import logo_cesbra from "../../../assets/Logo-Cesbra.png";


  const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  width: 90%;
  gap: 16px;
  padding: 24px;
  background-color: #f5f5f5;
  border-radius: 8px;
  height: 100%;
  margin: 50px;

  @media (max-width: 550px) {
    width: 100%;
    margin: 0px;
  }
`;

const StyledTitle = styled(Typography)`
  font-weight: bold;
  font-size: 1.5em;
  margin: 0em 0;
  background-color: #2957a4;
  color: #fff;
  padding: 10px;
  text-align: center;
  @media (max-width: 550px) {
    font-size: 1.2em;
  }
`;

const StyledOrange = styled(Typography)`
    background-color: #D66E20;
    padding-top: 3px;
    padding-bottom: 3px;
  @media (max-width: 550px) {
    font-size: 1.2em;
  }
`;

const StyledGreen = styled(Typography)`
    background-color: #7CA217;
    padding-top: 3px;
    padding-bottom: 3px;
  @media (max-width: 550px) {
    font-size: 1.2em;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 16px;
  margin-left: 25px;
`;
const ResultadosTitle = styled.h2`
  margin: 0;
  font-size: 2em;
  color: #1F437D;
  text-align: center;
  text-transform: uppercase;
  padding: 10px;
  border-radius: 10px;
  @media (max-width: 550px) {
    width: 80px;
    font-size: 1.5em;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: flex-end;
  margin-top: 20px;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;

`;

const SaveButton = styled(Button)`
  height: 30px;
  width: 150px;
  color: #fff;
  background-color: #2957a4;
  border: 1px solid rgba(0, 0, 0, 0.2);
  &:hover {
    background-color: #1f437d;
  }
`;

const LimparButton= styled(Button)`
  height: 30px;
  width: 100px;
  color: #fff;
  background-color: #FF6347;
  border: 1px solid rgba(0, 0, 0, 0.2);
  &:hover {
    background-color: #ff0000;
  }
  @media (max-width: 550px) {
    width: 80px;
    font-size: 0.8em;
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const ImprimirButton = styled(Button)`
  height: 30px;
  padding-left: 50px;
  padding-right: 50px;
  width: 120px;
  color: #fff;
  background-color: #a0a0a0;
  border: 1px solid rgba(0, 0, 0, 0.2);
  &:hover {
    background-color: #808080;
  }
  
  @media (max-width: 550px) {
    width: 80px;
    font-size: 0.8em;
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const XlsButton = styled(Button)`
  height: 30px;
  width: 120px;
  padding-left: 50px;
  padding-right: 50px;
  color: #fff;
  background-color: #71bc78;
  border: 1px solid rgba(0, 0, 0, 0.2);
  &:hover {
    background-color: #569b5c;
  }
  @media (max-width: 550px) {
    width: 80px;
    font-size: 0.8em;
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const ResultadosContainer = styled.div`
  margin-top: 20px;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  @media (max-width: 550px) {
    
    padding: 10px; 
  }
`;

const MobileButton = styled(Button)`
  height: 30px;
  width: 150px;
  color: #fff;
  background-color: #2957a4;
  border: 1px solid rgba(0, 0, 0, 0.2);
  display: none;
  &:hover {
    background-color: #1f437d;
  }

  @media (max-width: 360px) {
    display: block;
  }
`;

const getBackgroundColor = (color, mode) =>
  mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7);

const getHoverBackgroundColor = (color, mode) =>
  mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

const getSelectedBackgroundColor = (color, mode) =>
  mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

const getSelectedHoverBackgroundColor = (color, mode) =>
  mode === 'dark' ? darken(color, 0.4) : lighten(color, 0.4);


const StyledDataGrid = Estilo(DataGrid)(({ theme }) => ({
  '& .grid-azul': {
    backgroundColor: getBackgroundColor(theme.palette.info.main, theme.palette.mode),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.info.main,
        theme.palette.mode,
      ),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.info.main,
        theme.palette.mode,
      ),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.info.main,
          theme.palette.mode,
        ),
      },
    },
  },
  '& .grid-verde': {
    backgroundColor: getBackgroundColor(theme.palette.success.light, theme.palette.mode),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.success.light,
        theme.palette.mode,
      ),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.success.light,
        theme.palette.mode,
      ),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.success.light,
          theme.palette.mode,
        ),
      },
    },
  },
  '& .grid-roxo': {
    backgroundColor: getBackgroundColor(theme.palette.secondary.main, theme.palette.mode),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.secondary.main,
        theme.palette.mode,
      ),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.secondary.main,
        theme.palette.mode,
      ),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.secondary.main,
          theme.palette.mode,
        ),
      },
    },
  },
  '& .grid-vermelho': {
    backgroundColor: getBackgroundColor(
      theme.palette.error.main,
      theme.palette.mode,
    ),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.error.main,
        theme.palette.mode,
      ),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.error.main,
        theme.palette.mode,
      ),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.error.main,
          theme.palette.mode,
        ),
      },
    },
  },
}));

const RelatoriosContasaPagar = () => {
  const [formattedDataInicial, setFormattedDataInicial] = useState("");
  const [formattedDataFinal, setFormattedDataFinal] = useState("");
  const Filial = sessionStorage.getItem("empresa");
  const [todas, setTodas] = useState("");
  const [tipo, setTipoSelecionado] = useState("F");
  const [tipoData, setTipoDataSelecionada] = useState("E");
  const [empresaSelecionada, setEmpresaSelecionada] = useState(null);
  const [portadorSelecionado, setPortadorSelecionado] = useState(null);
  const [contaSelecionada, setContaSelecionada] = useState(null);
  const [resultados, setResultados] = useState([]);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
   // eslint-disable-next-line

  const [isLoading, setIsLoading] = useState(false);
    // eslint-disable-next-line
  const [consultaSelecionada, setConsultaSelecionada] = useState("relatorio");

  const grupo = sessionStorage.getItem("grupo");
  const grupoGerente = ["8f498e6aabb107de59d0ad2177f6d882"];

  const isUserInGroupGerente = grupoGerente.includes(grupo);

  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({
    id: false,
    hist_banco: false,
    ctrl_lcts: false,
    ctrl_lct1: false,
    Servico: false,
    Origem: false,
    ObsDesconto: false,
    vencto_original: false,
    obs_vencto_original: false,
    Operacao: false,
    destino: false,
    sacador_avalista: false,
    Nosso_Nro: false,
    Mes: false,
    Ano: false,
  });

  const [filterModel, setFilterModel] = React.useState({
    items: [
    ],
  });

  const applyFilters = (rows, filterModel) => {
    return rows.filter((row) => {
      return filterModel.items.every((filter) => {
        const { field, operator, value } = filter;
        const rowValue = row[field];
  
        // Convertendo rowValue e value para string para comparações de texto
        const rowValueStr = String(rowValue).toLowerCase();
        const valueStr = String(value).toLowerCase();
  
        switch (operator) {
          case '>':
            return rowValue > value;
          case '>=':
            return rowValue >= value;
          case '<':
            return rowValue < value;
          case '<=':
            return rowValue <= value;
          case 'equals':
          case '=': // Igualdade numérica ou textual
            return rowValueStr === valueStr;
          case '!=': // Diferença numérica ou textual
            return rowValue !== value;
          case 'contains': // Contém
            return rowValueStr.includes(valueStr);
          case 'startsWith': // Começa com
            return rowValueStr.startsWith(valueStr);
          case 'endsWith': // Termina com
            return rowValueStr.endsWith(valueStr);
          default:
            return true; // Se o operador não for suportado, não filtra.
        }
      });
    });
  };

  const handleLimparResultados = () => {
    setResultados([]);
    setRows([]);
    setColumns([]);
  };

  const customComparator = (v1, v2) => {
    const value1 = parseFloat(v1.replace(',', '.'));
    const value2 = parseFloat(v2.replace(',', '.'));
  
    if (value1 < value2) {
      return -1;
    }
    if (value1 > value2) {
      return 1;
    }
    return 0;
  };

  const calcularValorTotal = () => {
    const valorTotal = applyFilters(rows, filterModel).reduce((total, item) => total + (item.Valor || 0), 0);
    return formatarValor(valorTotal);
  };

  function getCurrentDateFormatted() {
    const date = new Date();
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Os meses são baseados em zero
    const year = date.getFullYear();
  
    return `${day}/${month}/${year}`;
  }

  
  const calcularEmAberto = () => {
    const emAberto = 
         applyFilters(rows, filterModel)
        .filter(item => item.Dt_Pgto === null)
        .reduce((total, item) => total + (item.Valor || 0), 0);
    return formatarValor(emAberto);
  };


  const customDateComparator = (dateStr1, dateStr2) => {
    if (dateStr1 === null || dateStr2 === null){
      return -1;
    } else {
      const parts1 = dateStr1.split('/');
      const parts2 = dateStr2.split('/');
      const date1 = new Date(Number(parts1[2]), Number(parts1[1]) - 1, Number(parts1[0]));
      const date2 = new Date(Number(parts2[2]), Number(parts2[1]) - 1, Number(parts2[0]));
    
      
      if (date1 < date2) {
        return -1;
      }
      if (date1 > date2) {
        return 1;
      }
      return 0;
    }
    
 };

  const realizarConsulta = async () => {
    let procedure = "";

    switch (consultaSelecionada) {
      case "relatorio":
        procedure = "relatorio-contas-a-pagar";
        break;
      default:
        break;
    }

    try {
      const data = {
        Data: {
          formattedDataInicial,
          formattedDataFinal,
        },
        Empresas: {
            empresaSelecionada:
            empresaSelecionada === null
                ? null
                : empresaSelecionada.substring(0, 6),
          },
        PlanoCta: {
          contaSelecionada:
            contaSelecionada === null
              ? null
              : contaSelecionada.substring(0, 6),
        },
        Portador: {
          portadorSelecionado: portadorSelecionado === null ? null : portadorSelecionado.substring(0, 6),
        },
        Tipo: {
          tipo,
        },
        TipoData: {
          tipoData,
        },
        Filial,
        CheckboxTodas: {
          todas
        },
      };

      const config = {
        timeout: 45000,
      };

      setIsLoading(true);

      const response = await Axios.post(
        `financeiro/${procedure}`,
        data,
        config
      );
      
      if (response.data.length > 0){
        
        const rows = response.data.map((item, index) => {
          const rowData = {};
          Object.keys(item).forEach(key => {
              rowData[key] = item[key];
          });
          
          rowData['id'] = index + 1; 
          return rowData;
        });
        
        const columns = Object.keys(response.data[0]).map(key => ({
            field: key,
            ...(key === 'Dt_Emissao' ? {
              headerName: 'Emissão',
              width: 120 ,
              sortComparator: customDateComparator
            } : {...(key === 'Dt_Pgto' ? {
              headerName: 'Dt. Pgto.',
              width: 120,
              sortComparator: customDateComparator
            } : {...(key === 'Nro_nota' ? {
              headerName: 'Nota',
              width: 100
            } : {...(key === 'Pedido' ? {
              headerName: 'Pedido',
              width: 100 
            } : {...(key === 'Empresa' ? {
              headerName: 'Empresa',
              width: 400
            } : {...(key === 'Conta' ? {
              headerName: 'Conta',
              width: 250 
            } : {...(key === 'Controle' ? {
              headerName: 'Controle',
              width: 100 
            } : {...(key === 'Valor' ? {
              headerName: 'Valor (R$)',
              width: 100 
            } : {...(key === 'Documento' ? {
              headerName: 'Documento',
              width: 100 
            } : {...(key === 'Parcela' ? {
              headerName: 'Parcela',
              width: 60 
            } : {...(key === 'Vl_Pgto' ? {
              headerName: 'Vl. Pgto. (R$)',
              width: 100 
            } : {...(key === 'Vl_Juros' ? {
              headerName: 'Juros (R$)',
              width: 100 
            } : {...(key === 'Vl_Desconto' ? {
              headerName: 'Desconto (R$)',
              width: 100 
            } : {...(key === 'Cheque' ? {
              headerName: 'Cheque',
              width: 100
            } : {...(key === 'Liberado' ? {
              headerName: 'L',
              width: 30
            } : {...(key === 'Obs' ? {
              headerName: 'Obs',
              width: 300
            } : {...(key === 'Obs2' ? {
              headerName: 'Obs2',
              width: 300
            } : {...(key === 'Filial' ? {
              headerName: 'Filial',
              width: 60
            } : {...(key === 'Cancelado' ? {
              headerName: 'C',
              width: 30
            } : {...(key === 'Vencimento' ? {
              headerName: 'Vencimento',
              width: 120
            } : {headerName: key, width: 200})})})})})})})})})})})})})})})})})})})}),
  
            ...(key === 'Vl_Desconto' || key === 'Vl_Juros' || key  === 'Vl_Pgto' || key  === 'Valor' ? {
              valueFormatter: (params) => {
                if (params === null) {
                  return 0
                } else {
                  return params.toLocaleString("pt-BR", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 5,
                  })
                }
              }
          } : {})
        }));
  
        columns.unshift({ field: 'id', headerName: 'ID', width: 10 });
  
        setRows(rows);
        setColumns(columns)
      
      }

      setResultados(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error("Erro ao realizar a consulta:", error);
      setIsLoading(false);
    }
  };

  function formatarValor(valor) {
    if (valor === null || valor === undefined) {
      return '';
    }

    if (typeof valor !== "number") {
      return valor;
    }

    return valor.toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }

  function desformatarData(data) {
    if (!data) return null;
  
    const ano = data.slice(0, 4);
    const mes = data.slice(4, 6);
    const dia = data.slice(6, 8);
  
    return `${dia}/${mes}/${ano}`;
  }

  const handlePrintResults = () => {
    const Filial = sessionStorage.getItem("empresa");
    const printContent = `
      <html>
        <head>
          <title>S.I.G.A - Grupo Sumatex</title>
          <style>
           
            body { font-family: Arial, sans-serif; margin: 0; padding: 0; }
            h1 {
              text-align: center;
              margin-bottom: 20px;
              font-size: 24px;
            }
            h2 img {
              height: 40px;
              margin-right: 10px;
            }
            table { 
              width: 100%; 
              border-collapse: 
              collapse; 
            }
            th, td { 
              border: 1px solid #000; 
              padding: 8px; 
              text-align: left; 
            }
          </style>
        </head>
        <body>
          <h2>
            ${
              Filial === "1 - MAT" ? `<img src="${logo_sumatex}" alt="Logo">` :
              Filial === "7 - CES" ? `<img src="${logo_cesbra}" alt="Logo">` :
              Filial === "2 - AMB" ? `<img src="${logo_ambiental}" alt="Logo">` :
              Filial === "9 - LOG" ? `<img src="${logo_lorenvel}" alt="Logo">` :
              Filial === "5 - FMT" ? `<img src="${logo_agro}" alt="Logo">` :
              `<img src="${logo}" alt="Logo">`
            } </h2>
           <h1>
            Relatório - Contas a Pagar
           </h1>
           <div>
           <div class="filters">
           <p style="text-align: center;"><strong>Data:</strong> ${desformatarData(formattedDataInicial)} a ${desformatarData(formattedDataFinal)}</p>
           <p style="text-align: center;"><strong>Filtro:</strong> ${tipoData === "E" ? "Emissão" : tipoData === "V" ? "Vencimento" : "Pagamento"}</p>
           
           ${portadorSelecionado ? `<div key="portador"><p><strong>Portador:</strong> ${portadorSelecionado}</p></div>` : ''}
           ${contaSelecionada ? `<div key="conta"><p><strong>Conta:</strong> ${contaSelecionada}</p></div>` : ''}
           ${empresaSelecionada ? `<div key="empresa"><p><strong>Transportadora:</strong> ${empresaSelecionada}</p></div>` : ''}
         </div>
        </div>
          <table>
            <thead>
              <tr>
                ${columns
                  .filter((coluna) => columnVisibilityModel[coluna.field] !== false)
                  .map((coluna) => `<th>${coluna.headerName}</th>`)
                  .join('')}
              </tr>
            </thead>
            <tbody>
              ${applyFilters(rows, filterModel)
                .map((item, index) => {
                  return `
                    <tr ${index % 2 === 0 ? 'style="background-color: #f9f9f9;"' : ''}>
                      ${columns
                        .filter((coluna) => columnVisibilityModel[coluna.field] !== false)
                        .map((coluna) => {
                          return `<td>${formatarValor(item[coluna.field])}</td>`
                        })
                        .join("")}
                    </tr>
                  `;
                })
                .join("")}
            </tbody>
          </table>
      <div style="margin-top: 20px; display: grid; grid-template-columns: 1fr 1fr;">
          <div>
            <h2>Dados</h2>
              <div class="consolidated-data">
                  <div><p><strong>Valor Total:</strong> R$ ${calcularValorTotal()}</p></div>
              </div>
          </div>
          <div> 
              <h2>⠀</h2>
              <div class="consolidated-data"> 
                  <div><p><strong>Em Aberto:</strong> R$ ${calcularEmAberto()}</p></div>
              </div>
          </div>
      </div>
      </div>
    </body>
  </html>
    `;

    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;
    const printWindow = window.open("", "", `width=${screenWidth},height=${screenHeight}`);

    if (!printWindow) {
      alert(
        "Erro ao abrir a janela de impressão. Verifique as configurações do seu navegador."
      );
      return;
    }

    printWindow.document.open();
    printWindow.document.write(printContent);
    printWindow.document.close();

    printWindow.onload = () => {
      printWindow.print();

      setTimeout(() => {
      }, 1000); 
    };
  };
  

  const handleGenerateXLS = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Resultados');
  
    const colunas = columns
      .filter((coluna) => columnVisibilityModel[coluna.field] !== false)
      .map((coluna) => coluna.headerName);
  
    const headerRow = worksheet.addRow(colunas);
  
    headerRow.eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'D3D3D3' },
      };
      applyThinBorder(cell); 
      cell.font = { bold: true };
    });
  
    applyFilters(rows, filterModel).forEach((item) => {
      const rowData = columns
        .filter((coluna) => columnVisibilityModel[coluna.field] !== false)
        .map((coluna) => item[coluna.field]);
      const row = worksheet.addRow(rowData);
      row.eachCell((cell) => {
        applyThinBorder(cell); 
      });
    });
  
    
    const range = worksheet.getSheetValues();
    for (let R = 1; R <= range.length; ++R) {
      const row = worksheet.getRow(R);
      for (let C = 1; C <= colunas.length; ++C) {
        const cell = row.getCell(C);
        applyThinBorder(cell); 
      }
    }
  
    const buffer = await workbook.xlsx.writeBuffer();
    const nomeDoArquivo = `resultados_relatorio_contasapagar_${formattedDataInicial}-${formattedDataFinal}.xlsx`;
  
    saveWorkbook(buffer, nomeDoArquivo);
  };

  
  const applyThinBorder = (cell) => {
    cell.border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };
  };
  
  
  const saveWorkbook = async (buffer, nomeDoArquivo) => {
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, nomeDoArquivo); 
  };

  return (
    <div>

      <StyledOrange />
      <StyledTitle>Relatórios - Contas a Pagar</StyledTitle>
      <StyledGreen />

      <FormContainer>
        <div>
          {isLoading && (
            <div
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 9999,
              }}
            >
              <CircularProgress />
            </div>
          )}

            <Data
              formattedDataInicial={formattedDataInicial}
              formattedDataFinal={formattedDataFinal}
              setFormattedDataInicial={setFormattedDataInicial}
              setFormattedDataFinal={setFormattedDataFinal}
            />
            <Empresas
              empresaSelecionada={empresaSelecionada}
              setEmpresaSelecionada={setEmpresaSelecionada}
              tipo={tipo}
              setTipoSelecionado={setTipoSelecionado}
            />
            <PlanoCta
              contaSelecionada={contaSelecionada}
              setContaSelecionada={setContaSelecionada}
            />
            <Portador
              portadorSelecionado={portadorSelecionado}
              setPortadorSelecionado={setPortadorSelecionado}
            />
            <br></br>
            <RadioDataFinan
              tipoData={tipoData}
              setTipoDataSelecionada={setTipoDataSelecionada}
            />
            <br></br>

            {(isUserInGroupGerente) && (
                    <CheckboxTodas
                      todas={todas}
                      setTodas={setTodas}
                    /> 
            )}

            <ButtonContainer>
              <SaveButton onClick={realizarConsulta}>
                <AiOutlineSearch />
                ⠀Calcular
              </SaveButton>
            </ButtonContainer>
          
        </div>
      </FormContainer>
      <MobileButton onClick={realizarConsulta}>
        <AiOutlineSearch />
        ⠀Calcular
      </MobileButton>
      
      {resultados.length > 0 && (
        <ResultadosContainer id="resultados-container">
            <ResultadosTitle>Resultados</ResultadosTitle>
            <ButtonWrapper>
            
            <LimparButton
              onClick={handleLimparResultados}
              variant="contained"
              color="secondary"
            >
              Limpar
            </LimparButton>
            <ImprimirButton
              onClick={handlePrintResults}
              variant="contained"
              color="primary"
            >
              Imprimir
            </ImprimirButton>
            <XlsButton
              onClick={handleGenerateXLS}
              variant="contained"
              color="primary"
            >
              Gerar⠀XLS
            </XlsButton>
            </ButtonWrapper>

            <div
            style={{
              border: "5px solid #D3D3D3", 
              padding: "10px", 
            }}
          >
            <Typography component="div">
                <div className="tb-result">
                    <table>
                        <tr>
                          <td style={{color:"#2957A4"}}>Valor Total (R$)</td>
                          <td>{calcularValorTotal()}</td>
                        </tr>
                        <tr>
                          <td style={{color:"#2957A4"}}>Em Aberto (R$)</td>
                          <td>{calcularEmAberto()}</td>
                        </tr>
                    </table>
                </div>                 
            </Typography>
          </div>
          <br></br>
        <StyledDataGrid
                rows={rows}
                columns={columns}
                rowHeight={30}
                sortComparator={customComparator}
                sx={{
                  boxShadow: 2,
                  border: 2,
                  borderColor: 'primary.light',
                  '& .MuiDataGrid-cell:hover': {
                    color: 'primary.main',
                  },
                }}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 20 },
                  },
                }}
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) =>
                  setColumnVisibilityModel(newModel)
                }
                filterModel={filterModel}
                onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                getRowClassName={(params) => ((customDateComparator(params.row.Vencimento, getCurrentDateFormatted()) === -1 && params.row.Dt_Pgto === null && params.row.Cancelado === null) ? `grid-vermelho` :
                                              params.row.Tipo.substring(0, 6) === '000025' ? `grid-roxo` : 
                                              params.row.Tipo.substring(0, 6) === '000021' ? `grid-roxo` : 
                                              (params.row.Vencimento === params.row.Dt_Emissao && params.row.Dt_Pgto === null) ? `grid-verde` : ``)}
                pageSizeOptions={[5, 10, 15, 20]}
                disableSelectionOnClick
              />
        </ResultadosContainer>
      )}
    </div>
  );
};

export default RelatoriosContasaPagar;