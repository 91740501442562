import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  perg1: 'S',
  perg2: 'S',
  perg3: 'S',
  perg4: 'S',
  perg5: 'S',
  perg6: 'S',
  perg7: 'S',
  perg8: 'S',
  perg9: 'S',
  perg10: 'S',
  perg11: 'S',
  perg12: 'S',
  perg13: 'S',
  perg14: 'S',
  perg15: 'S',
  perg16: 'S',
  perg17: 'S',
  perg18: 'S',
  perg19: 'S',
  perg21: 'S',
  perg22: 'S',
  perg23: 'S',
  perg24: 'S',
  perg25: 'S',
  perg26: 'S',
  perg27: 'S',
  perg28: 'S',
  perg29: 'S',
  perg30: 'S',
  perg31: 'S',
  perg32: 'S',
  perg33: 'S',
  perg34: 'S',
  perg35: 'S',
  perg36: 'S',
  perg37: 'S',
  perg38: 'S',
  perg39: 'S',
  perg41: 'S',
  perg42: 'S',
  perg43: 'S',
  perg44: 'S',
  perg45: 'S',
  perg46: 'S',
  perg47: 'S',
  perg48: 'S',
  perg49: 'S',
  perg50: 'S',
  perg51: 'S',
  perg52: 'S',
  perg53: 'S',
  perg54: 'S',
  perg55: 'S',
  perg56: 'S',
  perg57: 'S',
  perg58: ' ',
  perg59: ' ',
  perg60: ' ',
  perg61: ' ',
  perg62: ' ',
  perg63: ' ',
  perg64: ' ',
  perg65: ' ',
  perg66: ' ',
  perg67: ' ',
  perg68: ' ',
  perg69: ' ',
  perg70: ' ',
  perg71: ' ',
  perg72: ' ',
};

const pergsPortariaSlice = createSlice({
  name: 'pergsPortaria',
  initialState,
  reducers: {
    setPerg(state, action) {
      const { key, value } = action.payload;
      state[key] = value;
    },
  },
});

export const { setPerg } = pergsPortariaSlice.actions;
export default pergsPortariaSlice.reducer;
