import React, { useState, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Axios } from "../../config/axiosConf";
import styled from "@emotion/styled";
import { useMediaQuery } from "@mui/material";

const Portador = ({ portadorSelecionado, setPortadorSelecionado }) => {
  const [portador, setPortador] = useState([]);
  const isSmallScreen = useMediaQuery("(max-width: 550px)");

  const StyledAutocomplete = styled(Autocomplete)`
    width: ${isSmallScreen ? "270px" : "380px"};
    padding-left: 25px;
    .MuiButtonBase-root {
      width: 35px;
    }
    .MuiInputBase-root {
      padding-top: 4px;
      padding-bottom: 4px;
    }
    .MuiInputBase-input {
      font-size: 15px;
    }
  `;

  const StyledTextField = styled(TextField)`
    width: ${isSmallScreen ? "100%" : "380px"};
  `;

  useEffect(() => {
    const getPortador = async () => {
      try {
        const response = await Axios.get(
          "KBMtB7TvNj-cBUrw1-XBcw"
        );
        setPortador(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    getPortador();
  }, []);

  const enviarPortadorSelecionado = (event, newValue) => {
    if (!newValue) {
      setPortadorSelecionado(null);
    } else {
      setPortadorSelecionado(newValue);
    }
  };

  return (
    <div style={{ display: "flex" }}>
      <div style={{ paddingTop: 15, fontWeight: "bold" }}>
        <p>Portador:</p>
        <StyledAutocomplete
          freeSolo={false}
          options={portador}
          value={portadorSelecionado}
          onChange={enviarPortadorSelecionado}
          renderInput={(params) => <StyledTextField {...params} label="" />}
        />
      </div>
    </div>
  );
};

export default Portador;
