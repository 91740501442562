import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Axios } from "../../../config/axiosConf";
import { Typography, Box } from "@mui/material";
import { Link } from "react-router-dom";
import { RiEyeLine, RiEdit2Line, RiDeleteBinLine } from "react-icons/ri";
import { DataGrid } from "@mui/x-data-grid";
import { ptBR } from '@mui/x-data-grid/locales';

const StyledOrange = styled(Typography)`
    background-color: #D66E20;
    padding-top: 3px;
    padding-bottom: 3px;
  @media (max-width: 550px) {
    font-size: 1.2em;
  }
`;

const StyledGreen = styled(Typography)`
    background-color: #7CA217;
    padding-top: 3px;
    padding-bottom: 3px;
  @media (max-width: 550px) {
    font-size: 1.2em;
  }
`;

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  '& .MuiDataGrid-columnHeader': {
    backgroundColor: '#D3D3D3',
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    fontWeight: 'bold',
  },
  '& .MuiDataGrid-scrollbarFiller':{
    backgroundColor: '#D3D3D3',
  },
  '& .MuiDataGrid-filler':{
    backgroundColor: '#D3D3D3',
  }
}));

const StyledH1 = styled(Typography)`
  font-weight: bold;
  font-size: 1.5em;
  margin: 0em 0;
  background-color: #2957a4;
  color: #fff;
  padding: 10px;
  text-align: center;
`;

const StyledLink = styled(Link)`
  word-wrap: break-word;
  color: #2957a4;
  margin-right: 5px;
  &:hover {
    text-decoration: underline;
  }
`;

const Consulta = () => {
  const [dados, setDados] = useState([]);
  const [filtro, setFiltro] = useState("");
  const [dadosFiltrados, setDadosFiltrados] = useState([]);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);

  const customDateComparator = (dateStr1, dateStr2) => {
    const parts1 = dateStr1.split("/");
    const parts2 = dateStr2.split("/");
    const date1 = new Date(
      Number(parts1[2]),
      Number(parts1[1]) - 1,
      Number(parts1[0])
    );
    const date2 = new Date(
      Number(parts2[2]),
      Number(parts2[1]) - 1,
      Number(parts2[0])
    );

    // Comparando as datas
    if (date1 < date2) {
      return -1;
    }
    if (date1 > date2) {
      return 1;
    }
    return 0;
  };

  const textComparator = (value1, value2) => {
    if (value1 < value2) {
      return -1;
    }
    if (value1 > value2) {
      return 1;
    }
    return 0;
  };
  
  useEffect(() => {
    const getDados = async () => {
      try {
        const response = await Axios.get("portaria/analise-transp/cadastro/");
        setDados(response.data);
        setDadosFiltrados(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    getDados();
  }, []);

  useEffect(() => {
    const filteredData = dados.filter(
      (item) =>
        item.transportadora.toLowerCase().includes(filtro.toLowerCase()) ||
        item.empresa.toLowerCase().includes(filtro.toLowerCase())
    );
    setDadosFiltrados(filteredData);
  }, [filtro, dados]);

  useEffect(() => {
    if (dadosFiltrados.length > 0) {
      const rows = dadosFiltrados.map((item) => ({
        ...item,
      }));

      const filteredColumns = [
        "data",
        "documento",
        "pendente",
        "transportadora",
        "empresa",
        "responsavel",
        "condutor",
      ];

      const columns = Object.keys(dadosFiltrados[0])
        .filter((key) => filteredColumns.includes(key))
        .map((key) => {
          let columnWidth = 220; 

          if (key === "data") {
            columnWidth = 105;
          } else if (key === "documento") {
            columnWidth = 100;
          } else if (key === "pendente") {
            columnWidth = 100;
          } else if (key === "responsavel") {
            columnWidth = 150;
          } else if (key === "condutor") {
            columnWidth = 150;
          }
          return {
            field: key,
            headerName: key.charAt(0).toUpperCase() + key.slice(1),
            width: columnWidth,
            
            sortComparator: key === "data" ? customDateComparator : textComparator,
          };
        });

      columns.push(
        {
          field: "view",
          headerName: "",
          width: 45,
          renderCell: (params) => (
            <StyledLink to={`/portaria/analise-transp/visualizar/${params.row.id}`}>
              <RiEyeLine style={{ width: "18px", height: "18px" }} />
            </StyledLink>
          ),
        },
        {
          field: "edit",
          headerName: "",
          width: 45,
          renderCell: (params) => (
            <StyledLink to={`/portaria/analise-transp/editar/${params.row.id}/`}>
              <RiEdit2Line style={{ width: "18px", height: "18px" }} />
            </StyledLink>
          ),
        },
        {
          field: "delete",
          headerName: "",
          width: 45,
          renderCell: (params) => (
            <StyledLink to={`/portaria/analise-transp/excluir/${params.row.id}/`}>
              <RiDeleteBinLine style={{ width: "18px", height: "18px" }} />
            </StyledLink>
          ),
        }
      );

      setRows(rows);
      setColumns(columns);
    }
  }, [dadosFiltrados]);

  return (
    <>
    <StyledOrange/>
      <StyledH1>Consulta de Formulário</StyledH1>
      <StyledGreen/>
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
        
        <Box sx={{ height: 600, width: '95%', maxWidth: 1255, marginTop: "20px" }}>
          
          <StyledDataGrid
            rows={rows}
            columns={columns}
            pageSizeOptions={[5, 10, 20, 50]}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 20 },
              },
              columns: {
                columnVisibilityModel: {
                  id: false,
                },
              },
            }}
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            disableSelectionOnClick
          />
        </Box>
      </div>
    </>
  );
};

export default Consulta;
