import React, { useState, useEffect, useRef } from "react";
import { Axios } from "../../../config/axiosConf";
import "../../../styles/Cadastro.css";
import styled from "styled-components";
import { styled as Estilo} from '@mui/system';
import SignatureCanvas from "react-signature-canvas";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Box,
  CircularProgress,
  Typography,
  Button,
} from "@mui/material";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { useParams, useNavigate } from "react-router-dom";
import { MdPendingActions } from "react-icons/md";
import { AiOutlineCalendar } from "react-icons/ai";
import CheckboxesAnaliseTransp from "../../../components/Checkboxes/CheckboxesAnaliseTransp";
import PergsPortaria from "../../../components/Radios/PergsPortaria";

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  background-color: #f5f5f5;
  border-radius: 8px;
  height: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 16px;
`;

const ClearButton = styled(Button)`
  height: 30px;
  width: 60px;
  color: #fff;
  background-color: #f21f16;
  border: 1px solid rgba(0, 0, 0, 0.2);
  &:hover {
    background-color: #cf1810;
  }
`;

const ButtonContainerSubmit = styled.div`
  display: flex;
  gap: 16px;
  justify-content: center;
`;

const SubmitButton = styled(Button)`
  height: 50px;
  width: 150px;
  background-color: #2957a4;
  border: 1px solid rgba(0, 0, 0, 0.2);
  color: #fff;
  font-size: 1.105rem;
  justify-content: center;
  &:hover {
    background-color: #1f437d;
  }
`;

const PendenteLabel = styled(Typography)`
  font-weight: bold;
  font-size: 18px;
`;

const StyledTitle = styled(Typography)`
  font-weight: bold;
  font-size: 1.5em;
  margin: 0em 0;
  background-color: #2957a4;
  color: #fff;
  padding: 10px;
  text-align: center;
  @media (max-width: 550px) {
    font-size: 1.2em;
  }
`;

const StyledOrange = styled(Typography)`
    background-color: #D66E20;
    padding-top: 3px;
    padding-bottom: 3px;
  @media (max-width: 550px) {
    font-size: 1.2em;
  }
`;

const StyledGreen = styled(Typography)`
    background-color: #7CA217;
    padding-top: 3px;
    padding-bottom: 3px;
  @media (max-width: 550px) {
    font-size: 1.2em;
  }
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const RadioWrapper = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

const RadioButton = styled(Radio)`
  color: #2196f3;
`;

const DateIcon = styled(AiOutlineCalendar)`
  font-size: 24px;
  color: #2196f3;
`;

const PendingIcon = styled(MdPendingActions)`
  font-size: 24px;
  color: #2196f3;
`;

const StyledTextField = styled(TextField)`
  width: 380px;
`;

const StyledButton = Estilo(Button)(({ theme }) => ({
  position: 'fixed',
  bottom: '20px',
  textAlign: 'center',
  right: '0px',
  backgroundColor: 'transparent',
  color: 'white',
  fontSize: '10px',
  borderColor: 'white',
  transition: 'width 0.0s, height 0.0s',
  width: '40px',
  height: '40px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  '&:hover': {
    width: '220px',
    height: '40px',
    backgroundColor: '#5b5b5b',
    textAlign: 'center',
    '& .icon': {
      opacity: 0,
      fontSize: '0px',
    },
    '& .text': {
      opacity: 1,
    },
  },
}));

const Icon = Estilo(ArrowRightIcon)({
  fontSize: '80px',
  opacity: 1,
  color: '#5b5b5b',
});

const Text = Estilo('span')({
  opacity: 0,
  marginLeft: '0px',
  textAlign: 'center',
  display: 'inline-block',
  verticalAlign: 'middle',
  transition: 'opacity 0.3s',
});

const Editar = () => {
  const user = sessionStorage.getItem("user");
  const [date] = useState(
    new Date().toLocaleDateString("pt-BR", { timeZone: "UTC" })
  );
  const [pendente, setPendente] = useState(null);
  const [tipo, setTipoSelecionado] = useState(null);
  const [cod_forn, setFornecedorSelecionado] = useState(null);
  const [empresa, setEmpresa] = useState(null);
  const fornRef = useRef(null);
  const [cod_transp, setTransportadoraSelecionada] = useState(null);
  const [transportadora, setTransportadora] = useState(null);
  const transpRef = useRef(null);
  const [descricao, setDescricao] = useState(null);
  const descricaoRef = useRef(null);
  const [perg1, setPerg1] = useState(null);
  const [perg2, setPerg2] = useState(null);
  const [perg3, setPerg3] = useState(null);
  const [perg4, setPerg4] = useState(null);
  const [perg5, setPerg5] = useState(null);
  const [perg6, setPerg6] = useState(null);
  const [perg7, setPerg7] = useState(null);
  const [perg8, setPerg8] = useState(null);
  const [perg9, setPerg9] = useState(null);
  const [perg10, setPerg10] = useState(null);
  const [perg11, setPerg11] = useState(null);
  const [perg12, setPerg12] = useState(null);
  const [perg13, setPerg13] = useState(null);
  const [perg14, setPerg14] = useState(null);
  const [perg15, setPerg15] = useState(null);
  const [perg16, setPerg16] = useState(null);
  const [perg17, setPerg17] = useState(null);
  const [perg18, setPerg18] = useState(null);
  const [perg19, setPerg19] = useState(null);
  const [perg20, setPerg20] = useState(null);
  const [perg21, setPerg21] = useState(null);
  const [perg22, setPerg22] = useState(null);
  const [perg23, setPerg23] = useState(null);
  const [perg24, setPerg24] = useState(null);
  const [perg25, setPerg25] = useState(null);
  const [perg26, setPerg26] = useState(null);
  const [perg27, setPerg27] = useState(null);
  const [perg28, setPerg28] = useState(null);
  const [perg29, setPerg29] = useState(null);
  const [perg30, setPerg30] = useState(null);
  const [perg31, setPerg31] = useState(null);
  const [perg32, setPerg32] = useState(null);
  const [perg33, setPerg33] = useState(null);
  const [perg34, setPerg34] = useState(null);
  const [perg35, setPerg35] = useState(null);
  const [perg36, setPerg36] = useState(null);
  const [perg37, setPerg37] = useState(null);
  const [perg38, setPerg38] = useState(null);
  const [perg39, setPerg39] = useState(null);
  const [perg40, setPerg40] = useState(null);
  const [perg41, setPerg41] = useState(null);
  const [perg42, setPerg42] = useState(null);
  const [perg43, setPerg43] = useState(null);
  const [perg44, setPerg44] = useState(null);
  const [perg45, setPerg45] = useState(null);
  const [perg46, setPerg46] = useState(null);
  const [perg47, setPerg47] = useState(null);
  const [perg48, setPerg48] = useState(null);
  const [perg49, setPerg49] = useState(null);
  const [perg50, setPerg50] = useState(null);
  const [perg51, setPerg51] = useState(null);
  const [perg52, setPerg52] = useState(null);
  const [perg53, setPerg53] = useState(null);
  const [perg54, setPerg54] = useState(null);
  const [perg55, setPerg55] = useState(null);
  const [perg56, setPerg56] = useState(null);
  const [perg57, setPerg57] = useState(null);
  const [perg58, setPerg58] = useState(null);
  const [perg59, setPerg59] = useState(null);
  const [perg60, setPerg60] = useState(null);
  const [perg61, setPerg61] = useState(null);
  const [perg62, setPerg62] = useState(null);
  const [perg63, setPerg63] = useState(null);
  const [perg64, setPerg64] = useState(null);
  const [perg65, setPerg65] = useState(null);
  const [perg66, setPerg66] = useState(null);
  const [perg67, setPerg67] = useState(null);
  const [perg68, setPerg68] = useState(null);
  const [perg69, setPerg69] = useState(null);
  const [perg70, setPerg70] = useState(null);
  const [perg71, setPerg71] = useState(null);
  const [perg72, setPerg72] = useState(null);
  const [observacoes, setObservacoes] = useState(null);
  const [condutor, setCondutor] = useState(null);
  const [rgCondutor, setRGCondutor] = useState(null);
  const [signCondut, setSignCondut] = useState("");
  const [auxiliar, setAuxiliar] = useState(null);
  const [rgAuxiliar, setRGAuxiliar] = useState(null);
  const [signAux, setSignAux] = useState(null);
  const [assinatura_responsavel, setUrlResp] = useState(null);
  const [assinatura_condutor, setUrlCondut] = useState(null);
  const [assinatura_auxiliar, setUrlAux] = useState(null);
  const [tipoDeProduto, setTipoDeProdutoSelecionado] = useState(null);
  const [prodPerigoso, setProdutoPerigoso] = useState(null);
  const [resPerigoso, setResPerigoso] = useState(null);
  const [embalagemVazia, setEmbalagemVazia] = useState(null);
  const [produtoAMais, setProdutoAMais] = useState(null);
  const [documento, setDocumento] = useState(null);
  const [responsavel, setResponsavel] = useState(null);
  const [rgResponsavel, setRGResponsavel] = useState(null);
  const [responsavelTransp, setResponsavelTransp] = useState(null);
  const [rgResponsavelTransp, setRGResponsavelTransp] = useState(null);
  const [placaVeiculo, setPlacaVeiculo] = useState(null)
  const [numEixosVeiculo, setNumeroEixosVeiculo] = useState(null)
  const [placaVeiculoReboque, setPlacaVeiculoReboque] = useState(null)
  const [numEixosVeiculoReboque, setNumeroEixosVeiculoReboque] = useState(null)
  const [cod_prodA, setProdutoA] = useState(null);
  const [cod_prodB, setProdutoB] = useState(null);
  const [cod_prodC, setProdutoC] = useState(null);
  const [cod_prodD, setProdutoD] = useState(null);
  const [nome_prodA, setNomeA] = useState(null);
  const [nome_prodB, setNomeB] = useState(null);
  const [nome_prodC, setNomeC] = useState(null);
  const [nome_prodD, setNomeD] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [assinatura_condutor_antiga, setAssinaturaAntiga] = useState();
  const [assinatura_auxiliar_antiga, setAssinaturaAuxAntiga] = useState();

  useEffect(() => {
    const perguntas = [
      perg1, perg2, perg3, perg4, perg5, perg6, perg7, perg8, perg9, perg10,
      perg11, perg12, perg13, perg14, perg15, perg16, perg17, perg18, perg19, perg20,
      perg21, perg22, perg23, perg24, perg25, perg26, perg27, perg28, perg29, perg30,
      perg31, perg32, perg33, perg34, perg35, perg36, perg37, perg38, perg39, perg40,
      perg41, perg42, perg43, perg44, perg45, perg46, perg47, perg48, perg49, perg50,
      perg51, perg52, perg53, perg54, perg55, perg56, perg57, perg58, perg59, perg60,
      perg61, perg62, perg63, perg64, perg65, perg66, perg67, perg68, perg69, perg70,
      perg71, perg72
    ];

    const hasPending = perguntas.some(pergunta => pergunta === "N");
    if (hasPending) {
      setPendente("S");
    } else {
      setPendente("N");
    }
  }, [
    perg1, perg2, perg3, perg4, perg5, perg6, perg7, perg8, perg9, perg10,
    perg11, perg12, perg13, perg14, perg15, perg16, perg17, perg18, perg19, perg20,
    perg21, perg22, perg23, perg24, perg25, perg26, perg27, perg28, perg29, perg30,
    perg31, perg32, perg33, perg34, perg35, perg36, perg37, perg38, perg39, perg40,
    perg41, perg42, perg43, perg44, perg45, perg46, perg47, perg48, perg49, perg50,
    perg51, perg52, perg53, perg54, perg55, perg56, perg57, perg58, perg59, perg60,
    perg61, perg62, perg63, perg64, perg65, perg66, perg67, perg68, perg69, perg70,
    perg71, perg72
  ]);

  const { id } = useParams();
  const history = useNavigate();
  const [loading, setLoading] = useState(true);


  const StyledH1 = styled.h1`
    display: block;
    font-size: 1.1em;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
  `;

  const StyledH2 = styled.h2`
    display: block;
    font-size: 1em; /* Ajuste o tamanho conforme necessário */
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    padding-left: 15px;
  `;

  const limparAssinatura = (canvasRef, urlSetter) => {
    canvasRef.clear();
    urlSetter("");
  };
  const gerarAssinaturaCondut = () => {
    setUrlCondut(signCondut.getTrimmedCanvas().toDataURL("image/png"));
  };

  const gerarAssinaturaAux = () => {
    setUrlAux(signAux.getTrimmedCanvas().toDataURL("image/png"));
  };

  const pendenteChecked = (event) => {
    setPendente(event.target.value);
  };
  const trocarDeTipo = (event) => {
    setTipoSelecionado(event.target.value);
    setFornecedorSelecionado("");
  };

  const trocarDeTipoDeProduto = (event) => {
    setTipoDeProdutoSelecionado(event.target.value);
  };

  const enviarFornecedorEscolhido = (event) => {
    setFornecedorSelecionado(event.target.value);
  };
  const enviarTransportadoraEscolhida = (event) => {
    setTransportadoraSelecionada(event.target.value);
  };
  const enviarDescricao = (event) => {
    setDescricao(event.target.value);
  };
  const enviarObservacao = (event) => {
    setObservacoes(event.target.value);
  };
  const enviarCondutor = (event) => {
    setCondutor(event.target.value);
  };
  const enviarRGCondutor = (event) => {
    setRGCondutor(event.target.value);
  };
  const enviarAuxiliar = (event) => {
    setAuxiliar(event.target.value);
  };
  const enviarRGAuxiliar = (event) => {
    setRGAuxiliar(event.target.value);
  };
  const enviarResponsavel = (event) => {
    setResponsavel(event.target.value);
  };
  const enviarRGResponsavel = (event) => {
    setRGResponsavel(event.target.value);
  };


  const enviarResponsavelTransp = (event) => {
    setResponsavelTransp(event.target.value);
  };

  const enviarRGResponsavelTransp = (event) => {
    setRGResponsavelTransp(event.target.value);
  };

  const enviarPlacaVeiculo = (event) => {
    setPlacaVeiculo(event.target.value);
  };

  const enviarNumeroDeEixosVeiculo = (event) => {
    setNumeroEixosVeiculo(event.target.value);
  };

  const enviarPlacaVeiculoReboque = (event) => {
    setPlacaVeiculoReboque(event.target.value);
  };

  const enviarNumeroDeEixosVeiculoReboque = (event) => {
    setNumeroEixosVeiculoReboque(event.target.value);
  };

  const enviarProdutoA = (event) => {
    setProdutoA(event.target.value);
  };

  const enviarProdutoB = (event) => {
    setProdutoB(event.target.value);
  };

  const enviarProdutoC = (event) => {
    setProdutoC(event.target.value);
  };

  const enviarProdutoD = (event) => {
    setProdutoD(event.target.value);
  };
  const enviardocumento = (event) => {
    setDocumento(event.target.value);
  };

  useEffect(() => {
    const getViews = async () => {
      try {
        const response = await Axios.get(
          `portaria/analise-transp/cadastro/${id}`
        );
        const {
          pendente,
          cod_transp,
          transportadora,
          cod_prodA,
          cod_prodB,
          cod_prodC,
          cod_prodD,
          nome_prodA,
          nome_prodB,
          nome_prodC,
          nome_prodD,
          tipo,
          cod_forn,
          empresa,
          tipoDeProduto,
          prodPerigoso,
          resPerigoso,
          embalagemVazia,
          produtoAMais,
          descricao,
          documento,
          perg1,
          perg2,
          perg3,
          perg4,
          perg5,
          perg6,
          perg7,
          perg8,
          perg9,
          perg10,
          perg11,
          perg12,
          perg13,
          perg14,
          perg15,
          perg16,
          perg17,
          perg18,
          perg19,
          perg20,
          perg21,
          perg22,
          perg23,
          perg24,
          perg25,
          perg26,
          perg27,
          perg28,
          perg29,
          perg30,
          perg31,
          perg32,
          perg33,
          perg34,
          perg35,
          perg36,
          perg37,
          perg38,
          perg39,
          perg40,
          perg41,
          perg42,
          perg43,
          perg44,
          perg45,
          perg46,
          perg47,
          perg48,
          perg49,
          perg50,
          perg51,
          perg52,
          perg53,
          perg54,
          perg55,
          perg56,
          perg57,
          perg58,
          perg59,
          perg60,
          perg61,
          perg62,
          perg63,
          perg64,
          perg65,
          perg66,
          perg67,
          perg68,
          perg69,
          perg70,
          perg71,
          perg72,
          observacoes,
          condutor,
          rgCondutor,
          responsavel,
          rgResponsavel,
          responsavelTransp,
          rgResponsavelTransp,
          placaVeiculo,
          numEixosVeiculo,
          placaVeiculoReboque,
          numEixosVeiculoReboque,
          auxiliar,
          rgAuxiliar,
          assinatura_condutor,
          assinatura_responsavel,
          assinatura_auxiliar,
        } = response.data;

        setPendente(pendente);
        setTipoSelecionado(tipo);
        setTransportadoraSelecionada(cod_transp);
        setTransportadora(transportadora);
        setProdutoA(cod_prodA);
        setProdutoB(cod_prodB);
        setProdutoC(cod_prodC);
        setProdutoD(cod_prodD);
        setNomeA(nome_prodA);
        setNomeB(nome_prodB);
        setNomeC(nome_prodC);
        setNomeD(nome_prodD);
        setEmpresa(empresa);
        setFornecedorSelecionado(cod_forn);
        setDocumento(documento);
        setTipoDeProdutoSelecionado(tipoDeProduto);
        setProdutoPerigoso(prodPerigoso);
        setResPerigoso(resPerigoso);
        setEmbalagemVazia(embalagemVazia);
        setProdutoAMais(produtoAMais);
        setDescricao(descricao);
        setPerg1(perg1);
        setPerg2(perg2);
        setPerg3(perg3);
        setPerg4(perg4);
        setPerg5(perg5);
        setPerg6(perg6);
        setPerg7(perg7);
        setPerg8(perg8);
        setPerg9(perg9);
        setPerg10(perg10);
        setPerg11(perg11);
        setPerg12(perg12);
        setPerg13(perg13);
        setPerg14(perg14);
        setPerg15(perg15);
        setPerg16(perg16);
        setPerg17(perg17);
        setPerg18(perg18);
        setPerg19(perg19);
        setPerg20(perg20);
        setPerg21(perg21);
        setPerg22(perg22);
        setPerg23(perg23);
        setPerg24(perg24);
        setPerg25(perg25);
        setPerg26(perg26);
        setPerg27(perg27);
        setPerg28(perg28);
        setPerg29(perg29);
        setPerg30(perg30);
        setPerg31(perg31);
        setPerg32(perg32);
        setPerg33(perg33);
        setPerg34(perg34);
        setPerg35(perg35);
        setPerg36(perg36);
        setPerg37(perg37);
        setPerg38(perg38);
        setPerg39(perg39);
        setPerg40(perg40);
        setPerg41(perg41);
        setPerg42(perg42);
        setPerg43(perg43);
        setPerg44(perg44);
        setPerg45(perg45);
        setPerg46(perg46);
        setPerg47(perg47);
        setPerg48(perg48);
        setPerg49(perg49);
        setPerg50(perg50);
        setPerg51(perg51);
        setPerg52(perg52);
        setPerg53(perg53);
        setPerg54(perg54);
        setPerg55(perg55);
        setPerg56(perg56);
        setPerg57(perg57);
        setPerg58(perg58);
        setPerg59(perg59);
        setPerg60(perg60);
        setPerg61(perg61);
        setPerg62(perg62);
        setPerg63(perg63);
        setPerg64(perg64);
        setPerg65(perg65);
        setPerg66(perg66);
        setPerg67(perg67);
        setPerg68(perg68);
        setPerg69(perg69);
        setPerg70(perg70);
        setPerg71(perg71);
        setPerg72(perg72);
        setObservacoes(observacoes);
        setCondutor(condutor);
        setRGCondutor(rgCondutor);
        setResponsavel(responsavel);
        setRGResponsavel(rgResponsavel);
        setResponsavelTransp(responsavelTransp);
        setRGResponsavelTransp(rgResponsavelTransp);
        setPlacaVeiculo(placaVeiculo);
        setNumeroEixosVeiculo(numEixosVeiculo);
        setPlacaVeiculoReboque(placaVeiculoReboque);
        setNumeroEixosVeiculoReboque(numEixosVeiculoReboque);
        setAuxiliar(auxiliar);
        setRGAuxiliar(rgAuxiliar);
        setUrlCondut(assinatura_condutor);
        setUrlResp(assinatura_responsavel);
        setUrlAux(assinatura_auxiliar);
        setAssinaturaAntiga(assinatura_condutor);
        setAssinaturaAuxAntiga(assinatura_auxiliar);
      } catch (error) {
        console.log(error);
      }
    };
    getViews();
  }, [id]);

  useEffect(() => {
    const fetchData = () => {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, 2000);
      });
    };

    fetchData().then(() => {
      setLoading(false);
    });
  }, []);

  const editForm = async (event) => {
    event.preventDefault();
    gerarAssinaturaCondut();
    gerarAssinaturaAux();


    try {
      await Axios.put(
        `portaria/analise-transp/cadastro/${id}`,
        {
          user,
          pendente,
          cod_transp: cod_transp.substring(0, 6),
          tipo,
          cod_forn: cod_forn.substring(0, 6),
          tipoDeProduto,
          prodPerigoso,
          resPerigoso,
          embalagemVazia,
          produtoAMais,
          descricao,
          documento,
          perg1: perg1 === " " ? null : perg1,
          perg2: perg2 === " " ? null : perg2,
          perg3: perg3 === " " ? null : perg3,
          perg4: perg4 === " " ? null : perg4,
          perg5: perg5 === " " ? null : perg5,
          perg6: perg6 === " " ? null : perg6,
          perg7: perg7 === " " ? null : perg7,
          perg8: perg8 === " " ? null : perg8,
          perg9: perg9 === " " ? null : perg9,
          perg10: perg10 === " " ? null : perg10,
          perg11: perg11 === " " ? null : perg11,
          perg12: perg12 === " " ? null : perg12,
          perg13: perg13 === " " ? null : perg13,
          perg14: perg14 === " " ? null : perg14,
          perg15: perg15 === " " ? null : perg15,
          perg16: perg16 === " " ? null : perg16,
          perg17: perg17 === " " ? null : perg17,
          perg18: perg18 === " " ? null : perg18,
          perg19: perg19 === " " ? null : perg19,
          perg20: perg20 === " " ? null : perg20,
          perg21: perg21 === " " ? null : perg21,
          perg22: perg22 === " " ? null : perg22,
          perg23: perg23 === " " ? null : perg23,
          perg24: perg24 === " " ? null : perg24,
          perg25: perg25 === " " ? null : perg25,
          perg26: perg26 === " " ? null : perg26,
          perg27: perg27 === " " ? null : perg27,
          perg28: perg28 === " " ? null : perg28,
          perg29: perg29 === " " ? null : perg29,
          perg30: perg30 === " " ? null : perg30,
          perg31: perg31 === " " ? null : perg31,
          perg32: perg32 === " " ? null : perg32,
          perg33: perg33 === " " ? null : perg33,
          perg34: perg34 === " " ? null : perg34,
          perg35: perg35 === " " ? null : perg35,
          perg36: perg36 === " " ? null : perg36,
          perg37: perg37 === " " ? null : perg37,
          perg38: perg38 === " " ? null : perg38,
          perg39: perg39 === " " ? null : perg39,
          perg40: perg40 === " " ? null : perg40,
          perg41: perg41 === " " ? null : perg41,
          perg42: perg42 === " " ? null : perg42,
          perg43: perg43 === " " ? null : perg43,
          perg44: perg44 === " " ? null : perg44,
          perg45: perg45 === " " ? null : perg45,
          perg46: perg46 === " " ? null : perg46,
          perg47: perg47 === " " ? null : perg47,
          perg48: perg48 === " " ? null : perg48,
          perg49: perg49 === " " ? null : perg49,
          perg50: perg50 === " " ? null : perg50,
          perg51: perg51 === " " ? null : perg51,
          perg52: perg52 === " " ? null : perg52,
          perg53: perg53 === " " ? null : perg53,
          perg54: perg54 === " " ? null : perg54,
          perg55: perg55 === " " ? null : perg55,
          perg56: perg56 === " " ? null : perg56,
          perg57: perg57 === " " ? null : perg57,
          perg58: perg58 === " " ? null : perg58,
          perg59: perg59 === " " ? null : perg59,
          perg60: perg60 === " " ? null : perg60,
          perg61: perg61 === " " ? null : perg61,
          perg62: perg62 === " " ? null : perg62,
          perg63: perg63 === " " ? null : perg63,
          perg64: perg64 === " " ? null : perg64,
          perg65: perg65 === " " ? null : perg65,
          perg66: perg66 === " " ? null : perg66,
          perg67: perg67 === " " ? null : perg67,
          perg68: perg68 === " " ? null : perg68,
          perg69: perg69 === " " ? null : perg69,
          perg70: perg70 === " " ? null : perg70,
          perg71: perg71 === " " ? null : perg71,
          perg72: perg72 === " " ? null : perg72,
          observacoes,
          condutor,
          rgCondutor,
          responsavel,
          rgResponsavel,
          responsavelTransp,
          rgResponsavelTransp,
          placaVeiculo,
          numEixosVeiculo,
          placaVeiculoReboque,
          numEixosVeiculoReboque,
          cod_prodA: cod_prodA === null ? "" : cod_prodA.substring(0, 6),
          cod_prodB: cod_prodB === null ? "" : cod_prodB.substring(0, 6),
          cod_prodC: cod_prodC === null ? "" : cod_prodC.substring(0, 6),
          cod_prodD: cod_prodD === null ? "" : cod_prodD.substring(0, 6),
          auxiliar,
          rgAuxiliar,
          assinatura_auxiliar,
          assinatura_condutor,
        }
      );
      showSnackbar("Cadastro atualizado com sucesso!", "success");
      setTimeout(() => {
        history("/portaria/analise-transp/consulta");
      }, 2000);
    } catch (error) {
      showSnackbar("Erro ao atualizar cadastro!", "error");
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };
  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setOpenSnackbar(true);
  };

  return (
    <div>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress size={80} />
        </Box>
      ) : (
        <>
          <div>
            <StyledOrange />
            <StyledTitle>Editar Formulário</StyledTitle>
            <StyledGreen />
          </div>

          <FormContainer id="meu-formulario" onSubmit={editForm}>
            <StyledH1 style={{ textAlign: "center" }}>
              Lista de verificação (checklist) para a expedição e o transporte
              rodoviário de produto perigoso
            </StyledH1>
            <StyledButton
          variant="outlined"
          onClick={() => history('/portaria/analise-transp/consulta')}
        >
              <Icon className="icon" />
              <Text className="text">Voltar para a tela de consulta</Text>
            </StyledButton>
            <br></br>
            <Label>
              <DateIcon />
              <TextField
                label="Data"
                value={date}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Label>
            <br></br>
            <PendenteLabel>
              {" "}
              <PendingIcon />
              ⠀Tipo de Produto
            </PendenteLabel>
            <RadioWrapper>
              <FormControlLabel
                value="Fracionado"
                control={<RadioButton required aria-label="Fracionado" />}
                label="Produto Fracionado"
                checked={tipoDeProduto === "Fracionado"}
                onChange={trocarDeTipoDeProduto}
              />
              <FormControlLabel
                value="Granel"
                control={<RadioButton required aria-label="Granel" />}
                label="Produto à Granel"
                checked={tipoDeProduto === "Granel"}
                onChange={trocarDeTipoDeProduto}
              />
            </RadioWrapper>
            <br></br>
            <CheckboxesAnaliseTransp
              prodPerigoso={prodPerigoso}
              resPerigoso={resPerigoso}
              embalagemVazia={embalagemVazia}
              produtoAMais={produtoAMais}
              setProdPerigoso={setProdutoPerigoso}
              setResPerigoso={setResPerigoso}
              setEmbalagemVazia={setEmbalagemVazia}
              setProdutoAMais={setProdutoAMais}
            />
            <StyledH1>1 - INFORMAÇÕES GERAIS</StyledH1>
            <br></br>
            <PendenteLabel>
              {" "}
              <PendingIcon />
              ⠀Pendente
            </PendenteLabel>
            <RadioWrapper>
              <FormControlLabel
                value="S"
                control={<RadioButton required aria-label="pendente" />}
                label="Sim"
                checked={pendente === "S"}
                onChange={pendenteChecked}
              />
              <FormControlLabel
                value="N"
                control={<RadioButton required aria-label="pendente" />}
                label="Não"
                checked={pendente === "N"}
                onChange={pendenteChecked}
              />
            </RadioWrapper>
            <br></br>
            <StyledH2>
              Número de documento de coleta/Ordem de carregamento ou similar:
            </StyledH2>
            <Label>
              <TextField
                id="outlined-textarea"
                label="Num. de Doc. de coleta"
                placeholder="Insira núm. de doc. de coleta..."
                required
                value={documento}
                sx={{
                  width: 300,
                }}
                onChange={enviardocumento}
                multiline
                inputRef={descricaoRef}
              />
            </Label>
            <br></br>
            <StyledH2>1.1 - Cliente/Fornecedor:</StyledH2>
            <Label>
              {tipo === "F" ? (
                <StyledTextField
                  id="F"
                  value={cod_forn + " - " + empresa}
                  label="Fornecedor:"
                  placeholder="Fornecedor:"
                  onChange={enviarFornecedorEscolhido}
                  sx={{
                    width: 380,
                  }}
                  InputProps={{
                    readOnly: true,
                  }}
                  inputRef={fornRef}
                />
              ) : (
                <StyledTextField
                  id="C"
                  value={cod_forn + " - " + empresa}
                  label="Cliente:"
                  placeholder="Cliente:"
                  onChange={enviarFornecedorEscolhido}
                  sx={{
                    width: 380,
                  }}
                  InputProps={{
                    readOnly: true,
                  }}
                  inputRef={fornRef}
                />
              )}
              <label>
                <RadioGroup
                  required
                  aria-label="userType"
                  name="userType"
                  value={tipo}
                  onChange={trocarDeTipo}
                >
                  <FormControlLabel
                    value="C"
                    control={<Radio />}
                    label="Cliente"
                    disabled
                  />
                  <FormControlLabel
                    value="F"
                    control={<Radio />}
                    label="Fornecedor"
                    disabled
                  />
                </RadioGroup>
              </label>
            </Label>
            <StyledH2>1.2 - Responsável pela vistoria:</StyledH2>
            <Label>
              <TextField
                id="outlined-textarea"
                label="Responsável pela vistoria"
                placeholder="Digite o nome do responsável pela vistoria..."
                required
                value={responsavel}
                sx={{
                  width: 500,
                }}
                onChange={enviarResponsavel}
                multiline
                inputRef={descricaoRef}
              />
            </Label>
            <Label>
              <TextField
                id="outlined-textarea"
                label="RG do Responsável pela vistoria"
                placeholder="Digite o RG do responsável pela vistoria..."
                required
                value={rgResponsavel}
                sx={{
                  width: 350,
                }}
                onChange={enviarRGResponsavel}
                multiline
                inputRef={descricaoRef}
              />
            </Label>
            <StyledH2>1.3 - Transportador:</StyledH2>
            <Label>
              <StyledTextField
                id="outlined-textarea"
                label="Transportadora:"
                placeholder="Transportadora:"
                required
                value={cod_transp + " - " + transportadora}
                sx={{
                  width: 380,
                }}
                InputProps={{
                  readOnly: true,
                }}
                onChange={enviarTransportadoraEscolhida}
                inputRef={transpRef}
              />
            </Label>
            <StyledH2>
              1.4 - Responsavel pela verificação (Transportador):
            </StyledH2>
            <Label>
              <TextField
                id="outlined-textarea"
                label="Responsável pela vistoria (Transportadora)"
                placeholder="Digite o nome do responsável pela vistoria (Transportadora)..."
                required
                value={responsavelTransp}
                sx={{
                  width: 500,
                }}
                onChange={enviarResponsavelTransp}
                multiline
                inputRef={descricaoRef}
              />
            </Label>
            <Label>
              <TextField
                id="outlined-textarea"
                label="RG do Responsável pela vistoria (Transportadora)"
                placeholder="Digite o RG do resp. pela vistoria (Transportadora)..."
                required
                value={rgResponsavelTransp}
                sx={{
                  width: 450,
                }}
                onChange={enviarRGResponsavelTransp}
                multiline
                inputRef={descricaoRef}
              />
            </Label>
            <StyledH2>1.5 - Veículo (tração):</StyledH2>
            <Label>
              <TextField
                id="outlined-textarea"
                label="Placa do Veículo"
                placeholder="Digite a placa do veículo..."
                required
                value={placaVeiculo}
                sx={{
                  width: 300,
                }}
                onChange={enviarPlacaVeiculo}
                multiline
                inputRef={descricaoRef}
              />
            </Label>

            <Label id="labelEixosVeiculo">
              <TextField
                id="eixosVeiculo"
                name="eixosVeiculo"
                placeholder="Digite a quantidade de eixos do veículo..."
                required
                value={numEixosVeiculo}
                sx={{
                  width: 300,
                }}
                onChange={enviarNumeroDeEixosVeiculo}
                multiline
              />
            </Label>

            <StyledH2>1.6 - Equipamento/Reboque/Semirreboque:</StyledH2>

            <Label>
              <TextField
                id="outlined-textarea"
                label="Placa do Veículo de Reboque"
                placeholder="Digite a placa do veículo de reboque..."
                required
                value={placaVeiculoReboque}
                sx={{
                  width: 320,
                }}
                onChange={enviarPlacaVeiculoReboque}
                multiline
                inputRef={descricaoRef}
              />
            </Label>

            <Label id="labelEixosVeiculoReboque">
              <TextField
                id="eixosVeiculoReboque"
                name="eixosVeiculoReboque"
                placeholder="Digite a quantidade de eixos do veículo de reboque..."
                required
                value={numEixosVeiculoReboque}
                sx={{
                  width: 300,
                }}
                onChange={enviarNumeroDeEixosVeiculoReboque}
                multiline
              />
            </Label>

            <StyledH2>1.7 - Condutor:</StyledH2>
            <Label>
              <TextField
                id="outlined-textarea"
                label="Condutor"
                placeholder="Digite o nome do condutor..."
                required
                value={condutor}
                sx={{
                  width: 375,
                }}
                onChange={enviarCondutor}
                multiline
                inputRef={descricaoRef}
              />
            </Label>

            <Label id="labelRGcondutor">
              <TextField
                id="rgCondutor"
                name="rgCondutor"
                placeholder="Digite o RG do condutor..."
                required
                value={rgCondutor}
                sx={{
                  width: 250,
                }}
                onChange={enviarRGCondutor}
                multiline
              />
            </Label>
            <StyledH2>1.8 - Auxiliar:</StyledH2>
            <Label>
              <TextField
                id="outlined-textarea"
                label="Auxiliar"
                placeholder="Digite o nome do auxiliar..."
                required
                value={auxiliar}
                sx={{
                  width: 375,
                }}
                onChange={enviarAuxiliar}
                multiline
                inputRef={descricaoRef}
              />
            </Label>

            <Label id="labelRGauxiliar">
              <TextField
                id="rgAuxiliar"
                name="rgAuxiliar"
                placeholder="Digite o RG do auxiliar do condutor..."
                required
                value={rgAuxiliar}
                sx={{
                  width: 300,
                }}
                onChange={enviarRGAuxiliar}
                multiline
              />
            </Label>
            <StyledH2>1.9 - Produto(s) perigoso(s) transportado(s):</StyledH2>
            <Label>
              a)
              <StyledTextField
                id="combo-box-demo"
                label="Produto A"
                value={nome_prodA === null ? "" : cod_prodA + ' - ' + nome_prodA}

                sx={{
                  width: 375,
                }}
                onChange={enviarProdutoA}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Label>

            <Label>
              b)
              <StyledTextField
                id="combo-box-demo"
                label="Produto B"
                value={nome_prodB === null ? "" : cod_prodB + ' - ' + nome_prodB}
                sx={{
                  width: 375,
                }}
                onChange={enviarProdutoB}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Label>

            <Label>
              c)
              <StyledTextField
                id="combo-box-demo"
                label="Produto C"
                value={nome_prodC === null ? "" : cod_prodC + ' - ' + nome_prodC}
                sx={{
                  width: 375,
                }}
                onChange={enviarProdutoC}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Label>

            <Label>
              a)
              <StyledTextField
                id="combo-box-demo"
                label="Produto D"
                value={nome_prodD === null ? "" : cod_prodD + ' - ' + nome_prodD}
                sx={{
                  width: 375,
                }}
                onChange={enviarProdutoD}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Label>

            <StyledH2>Descrição:</StyledH2>
            <Label>
              <TextField
                id="outlined-textarea"
                label="Descrição"
                placeholder="Descrição"
                required
                value={descricao}
                sx={{
                  width: 500,
                }}
                onChange={enviarDescricao}
                multiline
                inputRef={descricaoRef}
              />
            </Label>
            <br></br>
            <PergsPortaria
              perg1={perg1}
              setPerg1={setPerg1}
              perg2={perg2}
              setPerg2={setPerg2}
              perg3={perg3}
              setPerg3={setPerg3}
              perg4={perg4}
              setPerg4={setPerg4}
              perg5={perg5}
              setPerg5={setPerg5}
              perg6={perg6}
              setPerg6={setPerg6}
              perg7={perg7}
              setPerg7={setPerg7}
              perg8={perg8}
              setPerg8={setPerg8}
              perg9={perg9}
              setPerg9={setPerg9}
              perg10={perg10}
              setPerg10={setPerg10}
              perg11={perg11}
              setPerg11={setPerg11}
              perg12={perg12}
              setPerg12={setPerg12}
              perg13={perg13}
              setPerg13={setPerg13}
              perg14={perg14}
              setPerg14={setPerg14}
              perg15={perg15}
              setPerg15={setPerg15}
              perg16={perg16}
              setPerg16={setPerg16}
              perg17={perg17}
              setPerg17={setPerg17}
              perg18={perg18}
              setPerg18={setPerg18}
              perg19={perg19}
              setPerg19={setPerg19}
              perg20={perg20}
              setPerg20={setPerg20}
              perg21={perg21}
              setPerg21={setPerg21}
              perg22={perg22}
              setPerg22={setPerg22}
              perg23={perg23}
              setPerg23={setPerg23}
              perg24={perg24}
              setPerg24={setPerg24}
              perg25={perg25}
              setPerg25={setPerg25}
              perg26={perg26}
              setPerg26={setPerg26}
              perg27={perg27}
              setPerg27={setPerg27}
              perg28={perg28}
              setPerg28={setPerg28}
              perg29={perg29}
              setPerg29={setPerg29}
              perg30={perg30}
              setPerg30={setPerg30}
              perg31={perg31}
              setPerg31={setPerg31}
              perg32={perg32}
              setPerg32={setPerg32}
              perg33={perg33}
              setPerg33={setPerg33}
              perg34={perg34}
              setPerg34={setPerg34}
              perg35={perg35}
              setPerg35={setPerg35}
              perg36={perg36}
              setPerg36={setPerg36}
              perg37={perg37}
              setPerg37={setPerg37}
              perg38={perg38}
              setPerg38={setPerg38}
              perg39={perg39}
              setPerg39={setPerg39}
              perg40={perg40}
              setPerg40={setPerg40}
              perg41={perg41}
              setPerg41={setPerg41}
              perg42={perg42}
              setPerg42={setPerg42}
              perg43={perg43}
              setPerg43={setPerg43}
              perg44={perg44}
              setPerg44={setPerg44}
              perg45={perg45}
              setPerg45={setPerg45}
              perg46={perg46}
              setPerg46={setPerg46}
              perg47={perg47}
              setPerg47={setPerg47}
              perg48={perg48}
              setPerg48={setPerg48}
              perg49={perg49}
              setPerg49={setPerg49}
              perg50={perg50}
              setPerg50={setPerg50}
              perg51={perg51}
              setPerg51={setPerg51}
              perg52={perg52}
              setPerg52={setPerg52}
              perg53={perg53}
              setPerg53={setPerg53}
              perg54={perg54}
              setPerg54={setPerg54}
              perg55={perg55}
              setPerg55={setPerg55}
              perg56={perg56}
              setPerg56={setPerg56}
              perg57={perg57}
              setPerg57={setPerg57}
              perg58={perg58}
              setPerg58={setPerg58}
              perg59={perg59}
              setPerg59={setPerg59}
              perg60={perg60}
              setPerg60={setPerg60}
              perg61={perg61}
              setPerg61={setPerg61}
              perg62={perg62}
              setPerg62={setPerg62}
              perg63={perg63}
              setPerg63={setPerg63}
              perg64={perg64}
              setPerg64={setPerg64}
              perg65={perg65}
              setPerg65={setPerg65}
              perg66={perg66}
              setPerg66={setPerg66}
              perg67={perg67}
              setPerg67={setPerg67}
              perg68={perg68}
              setPerg68={setPerg68}
              perg69={perg69}
              setPerg69={setPerg69}
              perg70={perg70}
              setPerg70={setPerg70}
              perg71={perg71}
              setPerg71={setPerg71}
              perg72={perg72}
              setPerg72={setPerg72}
            />
            <br></br>

            <StyledH1>OBS:</StyledH1>
            <TextField
              id="outlined-textarea"
              label="Observações"
              placeholder="Digite..."
              value={observacoes}
              sx={{
                width: 500,
              }}
              onChange={enviarObservacao}
              multiline
              inputRef={descricaoRef}
            />
            <br></br>
            <br></br>
            <div style={{ border: "1px solid #BCBCBC", borderRadius: "10px",  width: "600px", height: "600px"}}>
              <StyledH1 style={{ margin: "10px" }}> Nova Assinatura do Condutor:</StyledH1>
              <div style={{ border: "2px solid #BCBCBC", width: 500, height: 200, margin: "10px", borderRadius: "10px" }}>
                <SignatureCanvas
                  ref={(ref) => setSignCondut(ref)}
                  canvasProps={{ width: 500, height: 200, className: "sigCanvas" }}
                  onEnd={() => {
                    setUrlCondut(
                      signCondut.getTrimmedCanvas().toDataURL("image/png")
                    );
                  }}
                />
                <ButtonContainer>

                  <ClearButton
                    onClick={() => limparAssinatura(signCondut, setUrlCondut)}
                  >
                    Limpar
                  </ClearButton>
                </ButtonContainer>
              </div>
              <br></br>

              
              <br></br>

              <br></br>
              <StyledH1 style={{ margin: "10px" }}> Assinatura Atual do Condutor:</StyledH1>
              <div
                style={{
                  border: "2px solid #BCBCBC",
                  width: 500,
                  height: 200,
                  marginTop: "10px",
                  marginRight: "10px",
                  marginBottom: "10px",
                  marginLeft: "10px",
                  borderRadius: "10px",
                  
                }}
              >


                <img
                  key={id}
                  src={assinatura_condutor_antiga}
                  alt="Assinatura Condutor"

                />
              </div>
            </div>
            <br></br>
            <br></br>
            <div style={{ border: "1px solid #BCBCBC", borderRadius: "10px", width: "600px", height: "600px"}}>
              <StyledH1 style={{ margin: "10px" }}> Nova Assinatura do Auxiliar:</StyledH1>
              <div style={{ border: "2px solid #BCBCBC", width: 500, height: 200, margin: "10px", borderRadius: "10px" }}>
                <SignatureCanvas
                  ref={(ref) => setSignAux(ref)}
                  canvasProps={{ width: 500, height: 200, className: "sigCanvas" }}
                  onEnd={() => {
                    setUrlAux(
                      signAux.getTrimmedCanvas().toDataURL("image/png")
                    );
                  }}
                />
                <ButtonContainer>

                  <ClearButton
                    onClick={() => limparAssinatura(signAux, setUrlAux)}
                  >
                    Limpar
                  </ClearButton>
                </ButtonContainer>
              </div>
              <br></br>

              
              <br></br>

              <br></br>
              <StyledH1 style={{ margin: "10px" }}> Assinatura Atual do Auxiliar:</StyledH1>
              <div
                style={{
                  border: "2px solid #BCBCBC",
                  width: 500,
                  height: 200,
                  marginTop: "10px",
                  marginRight: "10px",
                  marginBottom: "10px",
                  marginLeft: "10px",
                  borderRadius: "10px",
                }}
              >


                <img
                  key={id}
                  src={assinatura_auxiliar_antiga}
                  alt="Assinatura Auxiliar"

                />
              </div>
            </div>
            <br></br>
            <br></br>

            <div
              style={{
                border: "2px solid black",
                width: 500,
                height: 200,
                marginLeft: "0px",
              }}
            >
              <Typography variant="body1" fontWeight="bold" color>
                Assinatura Atual do Responsável
              </Typography>
              <img
                key={id}
                src={assinatura_responsavel}
                alt="Assinatura Responsável"
              />
            </div>
            <br />
            <br />
            <br />
            <br />
            <ButtonContainerSubmit>
              <SubmitButton type="submit">Editar</SubmitButton>
            </ButtonContainerSubmit>
            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
              <MuiAlert elevation={6} variant="filled" onClose={handleCloseSnackbar} severity={snackbarSeverity}>
                {snackbarMessage}
              </MuiAlert>
            </Snackbar>
            <br></br>
            <br></br>
          </FormContainer>
        </>
      )}
    </div>
  );
};

export default Editar;
