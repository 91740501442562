import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Axios } from '../config/axiosConf';

export const fetchCarros = createAsyncThunk('veiculos/fetchCarros', async () => {
  const response = await Axios.get('a60h7H-yK3gww2uXs3chAg');
  return response.data;
});

const veiculosReboqueSlice = createSlice({
  name: 'placaVeiculoReboque',
  initialState: {
    placaVeiculoReboque: '',
    numeroEixosVeiculoReboque: '',
    carros: [],
  },
  reducers: {
    setPlacaVeiculoReboque: (state, action) => {
      state.placaVeiculoReboque = action.payload;
    },
    setNumeroEixosVeiculoReboque: (state, action) => {
      state.numeroEixosVeiculoReboque = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCarros.fulfilled, (state, action) => {
      state.pessoas = action.payload;
    });
  },
});

export const { setPlacaVeiculoReboque, setNumeroEixosVeiculoReboque } = veiculosReboqueSlice.actions;

export default veiculosReboqueSlice.reducer;
