import React, { useState, useEffect, useCallback } from "react";
import { RadioGroup, FormControlLabel, Radio } from "@mui/material/";

const RadioProduto = ({ produto, setProdutoSelecionado }) => {
    const [isSmallScreen, setIsSmallScreen] = useState(window.matchMedia("(max-width: 750px)").matches);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.matchMedia("(max-width: 750px)").matches);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const trocarDeTipo = useCallback((event) => {
        setProdutoSelecionado(event.target.value);
    }, [setProdutoSelecionado]);

    return (
        <>
            <RadioGroup
                id="produto"
                required
                aria-label="userType"
                name="userType"
                value={produto}
                onChange={trocarDeTipo}
                row={!isSmallScreen} // Example: display as a row on larger screens
            >
                <FormControlLabel value="S" control={<Radio />} label="Com Produto" />
                <FormControlLabel value="N" control={<Radio />} label="Sem Produto" />
            </RadioGroup>
        </>
    );

};

export default RadioProduto;
