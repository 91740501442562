import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Axios } from '../config/axiosConf';

export const fetchPessoas = createAsyncThunk('responsavelTransp/fetchPessoas', async () => {
  const response = await Axios.get('sKC25mWjGC_VS3VeFBSJWqaxJnmfXbFJQd-B61htMsI');
  return response.data;
});

const responsavelTranspSlice = createSlice({
  name: 'responsavelTransp',
  initialState: {
    responsavelTransp: '',
    rgResponsavelTransp: '',
    pessoas: [],
  },
  reducers: {
    setResponsavelTransp: (state, action) => {
      state.responsavelTransp = action.payload;
    },
    setRGResponsavelTransp: (state, action) => {
      state.rgResponsavelTransp = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPessoas.fulfilled, (state, action) => {
      state.pessoas = action.payload;
    });
  },
});

export const { setResponsavelTransp, setRGResponsavelTransp } = responsavelTranspSlice.actions;

export default responsavelTranspSlice.reducer;
