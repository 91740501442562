import { configureStore } from '@reduxjs/toolkit';
import responsavelReducer from './slices/responsavelSlice';
import auxiliarReducer from './slices/auxiliarSlice';
import pergsPortariaReducer from './slices/pergsPortariaSlice';
import condutorReducer from './slices/condutorSlice';
import responsavelTranspReducer from './slices/responsavelTranspSlice';
import veiculosReboqueReducer from './slices/veiculosReboqueSlice';
import veiculosReducer from './slices/veiculosSlice';


const store = configureStore({
  reducer: {
    responsavel: responsavelReducer,
    auxiliar: auxiliarReducer,
    condutor: condutorReducer,
    responsavelTransp: responsavelTranspReducer,
    veiculos: veiculosReducer,
    veiculosReboque: veiculosReboqueReducer,
    pergsPortaria: pergsPortariaReducer,
  },
});

export default store;
