import React, { useState } from "react";
import styled from "@emotion/styled";
import {
    RadioGroup,
    FormControlLabel,
    Radio,
    Tab,
    Tabs,
    Box
} from "@mui/material";
import PropTypes from 'prop-types';
import { styled as Estilo} from '@mui/system';
import {
    DetalhesPerg1,
    DetalhesPerg2,
    DetalhesPerg3,
    DetalhesPerg4,
    DetalhesPerg5,
    DetalhesPerg6,
    DetalhesPerg7,
    DetalhesPerg8,
    DetalhesPerg9a,
    DetalhesPerg9b,
    DetalhesPerg10,
    DetalhesPerg11,
    DetalhesPerg12,
    DetalhesPerg13,
    DetalhesPerg14a,
    DetalhesPerg14b,
    DetalhesPerg14c,
    DetalhesPerg14d,
    DetalhesPerg15a,
    DetalhesPerg15b,
    DetalhesPerg16,
    DetalhesPerg17,
    DetalhesPerg18,
    DetalhesPerg19,
    DetalhesPerg20,
    DetalhesPerg21,
    DetalhesPerg22,
    DetalhesPerg23,
    DetalhesPerg24,
    DetalhesPerg25,
    DetalhesPerg26,
    DetalhesPerg27,
    DetalhesPerg28,
    DetalhesPerg29,
    DetalhesPerg30,
    DetalhesPerg31,
    DetalhesPerg32,
    DetalhesPerg33,
    DetalhesPerg34,
    DetalhesPerg35,
    DetalhesPerg36,
    DetalhesPerg37,
    DetalhesPerg38,
    DetalhesPerg39,
    DetalhesPerg40,
    DetalhesPerg41,
    DetalhesPerg42,
    DetalhesPerg43,
    DetalhesPerg44,
    DetalhesPerg45,
    DetalhesPerg46,
    DetalhesPerg47,
    DetalhesPerg48,
    DetalhesPerg49,
    DetalhesPerg50,
    DetalhesPerg51,
    DetalhesPerg52,
    DetalhesPerg53,
    DetalhesPerg54,
    DetalhesPerg55,
    DetalhesPerg56,
    DetalhesPerg57,
} from "../DetalhesChecklist";
import { FaChevronDown, FaChevronRight } from "react-icons/fa";

const StyledH2 = styled.h2`
    display: block;
    font-size: 1em;
    font-weight: bold;
    padding-left: 15px;
`;

const StyledH1 = styled.h1`
    display: block;
    font-size: 1.1em;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
  `;

  const StyledRadioGroup = Estilo(RadioGroup)(({ theme }) => ({
    flexDirection: 'row',
    '& .MuiFormControlLabel-root': {
      marginRight: '16px',
    },
  }));

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
};

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const PergsPortariaNaoEditavel = ({ perg1, setPerg1, perg2, setPerg2, perg3, setPerg3, perg4, setPerg4,
    perg5, setPerg5, perg6, setPerg6, perg7, setPerg7, perg8, setPerg8,
    perg9, setPerg9, perg10, setPerg10, perg11, setPerg11, perg12, setPerg12,
    perg13, setPerg13, perg14, setPerg14,
    perg15, setPerg15, perg16, setPerg16, perg17, setPerg17, perg18, setPerg18,
    perg19, setPerg19, perg20, setPerg20, perg21, setPerg21, perg22, setPerg22,
    perg23, setPerg23, perg24, setPerg24,
    perg25, setPerg25, perg26, setPerg26, perg27, setPerg27, perg28, setPerg28,
    perg29, setPerg29, perg30, setPerg30, perg31, setPerg31, perg32, setPerg32,
    perg33, setPerg33, perg34, setPerg34,
    perg35, setPerg35, perg36, setPerg36, perg37, setPerg37, perg38, setPerg38,
    perg39, setPerg39, perg40, setPerg40, perg41, setPerg41, perg42, setPerg42,
    perg43, setPerg43, perg44, setPerg44,
    perg45, setPerg45, perg46, setPerg46, perg47, setPerg47, perg48, setPerg48,
    perg49, setPerg49, perg50, setPerg50, perg51, setPerg51, perg52, setPerg52,
    perg53, setPerg53, perg54, setPerg54,
    perg55, setPerg55, perg56, setPerg56, perg57, setPerg57, perg58, setPerg58,
    perg59, setPerg59, perg60, setPerg60, perg61, setPerg61, perg62, setPerg62,
    perg63, setPerg63, perg64, setPerg64,
    perg65, setPerg65, perg66, setPerg66, perg67, setPerg67, perg68, setPerg68,
    perg69, setPerg69, perg70, setPerg70, perg71, setPerg71, perg72, setPerg72, }) => {

    const [secaoVisivel, setSecaoVisivel] = useState(false);
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const toggleSecaoVisivel = () => {
        setSecaoVisivel(!secaoVisivel);
    };

    return (
        <>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" variant="scrollable" scrollButtons="auto">
                <Tab label="2 - CONDUTOR E AUXILIAR" />
                <Tab label="3 - VEÍCULO E TRANSPORTE" />
                <Tab label="4 - CONJUNTO DE EQUIPAMENTOS PARA EMERGÊNCIA" />
                <Tab label="5 - SINALIZAÇÃO DO VEÍCULO E EQUIPAMENTO" />
                <Tab label="6 - EMBALAGENS (MARCAÇÃO E ROTULAGEM) E CARGA" />
                <Tab label="7 - CARACTERÍSTICAS TÉCNICAS" />
                <Tab label="8 - CARREGAMENTO" />

            </Tabs>
            <TabPanel value={value} index={0}>
                <StyledH1>2 - CONDUTOR E AUXILIAR</StyledH1>
                <StyledH2><br></br>2.1 - Documentação do condutor</StyledH2>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <p style={{ marginRight: "8px", marginBottom: "0" }}><br></br>a) Curso de movimentação de produtos perigosos MOPP? <DetalhesPerg1 /></p>

                </div>

                <label>
                    <StyledRadioGroup
                        id="perg1"
                        required
                        aria-label="perg1"
                        name="perg1"
                        value={perg1}
                        onChange={(event) => setPerg1(event.target.value)}
                         
                    >
                        <FormControlLabel value="S" control={<Radio />} label="SIM" disabled />
                        <FormControlLabel value="N" control={<Radio />} label="NÃO" disabled />
                        <FormControlLabel value=" " control={<Radio />} label="N/A" disabled />
                    </StyledRadioGroup>
                </label>
                <br></br>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <p style={{ marginRight: "8px", marginBottom: "0" }}><br></br>
                        <br></br>b) CNH: Carteira Nacional de Habilitação compatível com o veículo?{" "}
                        <DetalhesPerg2 /></p>

                </div>
                <label>
                    <StyledRadioGroup
                        id="perg2"
                        required
                        aria-label="perg2"
                        name="perg2"
                        value={perg2}
                        onChange={(event) => setPerg2(event.target.value)}
                         
                    >
                        <FormControlLabel value="S" control={<Radio />} label="SIM" disabled />
                        <FormControlLabel value="N" control={<Radio />} label="NÃO" disabled />
                        <FormControlLabel value=" " control={<Radio />} label="N/A" disabled />
                    </StyledRadioGroup>
                </label>
                <br></br>
                <StyledH2><br></br>2.2 - Auxiliar</StyledH2>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <p style={{ marginRight: "8px", marginBottom: "0" }}>
                        <br></br>c) Documento de identificação? <DetalhesPerg3 />
                    </p>
                </div>
                <label>
                    <StyledRadioGroup
                        id="perg3"
                        required
                        aria-label="perg3"
                        name="perg3"
                        value={perg3}
                        onChange={(event) => setPerg3(event.target.value)}
                         
                    >
                        <FormControlLabel value="S" control={<Radio />} label="SIM" disabled />
                        <FormControlLabel value="N" control={<Radio />} label="NÃO" disabled />
                        <FormControlLabel value=" " control={<Radio />} label="N/A" disabled />
                    </StyledRadioGroup>
                </label>
                <br></br>
                <StyledH2><br></br>2.3 - Apresentação: condutor e auxiliar</StyledH2>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <p style={{ marginRight: "8px", marginBottom: "0" }}><br></br>
                        d) Traje mínimo obrigatório (calça comprida, camisa ou camiseta com
                        mangas curtas ou compridas e calçado fechado)? <DetalhesPerg4 />
                    </p>
                </div>
                <label>
                    <StyledRadioGroup
                        id="perg4"
                        required
                        aria-label="perg4"
                        name="perg4"
                        value={perg4}
                        onChange={(event) => setPerg4(event.target.value)}
                         
                    >
                        <FormControlLabel value="S" control={<Radio />} label="SIM" disabled />
                        <FormControlLabel value="N" control={<Radio />} label="NÃO" disabled />
                        <FormControlLabel value=" " control={<Radio />} label="N/A" disabled />
                    </StyledRadioGroup>
                </label>
                <br></br>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <p style={{ marginRight: "8px", marginBottom: "0" }}><br></br>
                        e) Condições físicas aparentes (isento de sinais de uso de drogas e
                        álcool, e de incapacidade física e mental temporária){" "}
                        <DetalhesPerg5 />
                    </p>
                </div>
                <label>
                    <StyledRadioGroup
                        id="perg5"
                        required
                        aria-label="perg5"
                        name="perg5"
                        value={perg5}
                        onChange={(event) => setPerg5(event.target.value)}
                         
                    >
                        <FormControlLabel value="S" control={<Radio />} label="SIM" disabled />
                        <FormControlLabel value="N" control={<Radio />} label="NÃO" disabled />
                        <FormControlLabel value=" " control={<Radio />} label="N/A" disabled />
                    </StyledRadioGroup>
                </label>
                <br></br>
                <StyledH2><br></br>2.4 - Orientação do auxiliar, quando aplicável</StyledH2>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <p style={{ marginRight: "8px", marginBottom: "0" }}><br></br>
                        f) Orientação para a operação? <DetalhesPerg6 />
                    </p>
                </div>
                <label>
                    <StyledRadioGroup
                        id="perg6"
                        required
                        aria-label="perg6"
                        name="perg6"
                        value={perg6}
                        onChange={(event) => setPerg6(event.target.value)}
                         
                    >
                        <FormControlLabel value="S" control={<Radio />} label="SIM" disabled />
                        <FormControlLabel value="N" control={<Radio />} label="NÃO" disabled />
                        <FormControlLabel value=" " control={<Radio />} label="N/A" disabled />
                    </StyledRadioGroup>
                </label>
            </TabPanel>

            <TabPanel value={value} index={1}>
                <StyledH1>3 - VEÍCULO E TRANSPORTE</StyledH1>
                <StyledH2><br></br>3.1 - CRLV</StyledH2>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <p style={{ marginRight: "8px", marginBottom: "0" }}><br></br>
                        a) CRLV: Certificado de Registro e Licenciamento do Veículo; CRLV
                        distintos quando houver cavalo-mecânico e carreta <DetalhesPerg7 />
                    </p>
                </div>
                <label>
                    <StyledRadioGroup
                        id="perg7"
                        required
                        aria-label="perg7"
                        name="perg7"
                        value={perg7}
                        onChange={(event) => setPerg7(event.target.value)}
                         
                    >
                        <FormControlLabel value="S" control={<Radio />} label="SIM" disabled />
                        <FormControlLabel value="N" control={<Radio />} label="NÃO" disabled />
                        <FormControlLabel value=" " control={<Radio />} label="N/A" disabled />
                    </StyledRadioGroup>
                </label>
                <StyledH2><br></br>
                    3.2 - CTPP/CIPP/CIV(originais)Item específico para carga a granel.
                </StyledH2>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <p style={{ marginRight: "8px", marginBottom: "0" }}><br></br>
                        b) CTPP: Certificado de Transporte Para Produtos Perigosos{" "}
                        <DetalhesPerg8 />
                    </p>
                </div>
                <label>
                    <StyledRadioGroup
                        id="perg8"
                        required
                        aria-label="perg8"
                        name="perg8"
                        value={perg8}
                        onChange={(event) => setPerg8(event.target.value)}
                         
                    >
                        <FormControlLabel value="S" control={<Radio />} label="SIM" disabled />
                        <FormControlLabel value="N" control={<Radio />} label="NÃO" disabled />
                        <FormControlLabel value=" " control={<Radio />} label="N/A" disabled />
                    </StyledRadioGroup>
                </label>

                <div style={{ display: "flex", alignItems: "center" }}>
                    <p style={{ marginRight: "8px", marginBottom: "0" }}><br></br>
                        c) CIPP ou Certificado de Inspeção Internacional: Certificado de
                        Inspeção para o Transporte de Produtos Perigosos a granel, emitido por
                        organismos acreditados pelo Inmetro (compatível com a carga do
                        equipamento e atualizado) <DetalhesPerg9a /> <DetalhesPerg9b />
                    </p>
                </div>
                <label>
                    <StyledRadioGroup
                        id="perg9"
                        required
                        aria-label="perg9"
                        name="perg9"
                        value={perg9}
                        onChange={(event) => setPerg9(event.target.value)}
                         
                    >
                        <FormControlLabel value="S" control={<Radio />} label="SIM" disabled />
                        <FormControlLabel value="N" control={<Radio />} label="NÃO" disabled />
                        <FormControlLabel value=" " control={<Radio />} label="N/A" disabled />
                    </StyledRadioGroup>
                </label>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <p style={{ marginRight: "8px", marginBottom: "0" }}><br></br>
                        d) CIV: Certificado de Inspeção Veicular emitido por organismos
                        acreditados pelo Inmetro <DetalhesPerg10 />
                    </p>
                </div>
                <label>
                    <StyledRadioGroup
                        id="perg10"
                        required
                        aria-label="perg10"
                        name="perg10"
                        value={perg10}
                        onChange={(event) => setPerg10(event.target.value)}
                         
                    >
                        <FormControlLabel value="S" control={<Radio />} label="SIM" disabled />
                        <FormControlLabel value="N" control={<Radio />} label="NÃO" disabled />
                        <FormControlLabel value=" " control={<Radio />} label="N/A" disabled />
                    </StyledRadioGroup>
                </label>
                <br></br>
                <StyledH2><br></br>3.3 - Documento para transporte</StyledH2>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <p style={{ marginRight: "8px", marginBottom: "0" }}><br></br>
                        e) Documento para transporte com dados obrigatórios e declaração{" "}
                        <DetalhesPerg11 />
                    </p>
                </div>
                <label>
                    <StyledRadioGroup
                        id="perg11"
                        required
                        aria-label="perg11"
                        name="perg11"
                        value={perg11}
                        onChange={(event) => setPerg11(event.target.value)}
                         
                    >
                        <FormControlLabel value="S" control={<Radio />} label="SIM" disabled />
                        <FormControlLabel value="N" control={<Radio />} label="NÃO" disabled />
                        <FormControlLabel value=" " control={<Radio />} label="N/A" disabled />
                    </StyledRadioGroup>
                </label>
                <br></br>
                <StyledH2><br></br>3.4 - Ficha de emergência (se for entregue)</StyledH2>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <p style={{ marginRight: "8px", marginBottom: "0" }}><br></br>
                        f) Ficha de Emergência do produto transportado de acordo com a ABNT
                        NBR 7503. <DetalhesPerg12 />
                    </p>
                </div>
                <label>
                    <StyledRadioGroup
                        id="perg12"
                        required
                        aria-label="perg12"
                        name="perg12"
                        value={perg12}
                        onChange={(event) => setPerg12(event.target.value)}
                         
                    >
                        <FormControlLabel value="S" control={<Radio />} label="SIM" disabled />
                        <FormControlLabel value="N" control={<Radio />} label="NÃO" disabled />
                        <FormControlLabel value=" " control={<Radio />} label="N/A" disabled />
                    </StyledRadioGroup>
                </label>
                <br></br>
                <StyledH2><br></br>
                    3.5 - Autorização e licença ambiental para transporte de produtos
                    perigosos
                </StyledH2>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <p style={{ marginRight: "8px", marginBottom: "0" }}><br></br>
                        g) AATIPP - Autorização Ambiental de Transporte Interestadual de
                        Produtos Perigosos <DetalhesPerg13 />
                    </p>
                </div>
                <label>
                    <StyledRadioGroup
                        id="perg13"
                        required
                        aria-label="perg13"
                        name="perg13"
                        value={perg13}
                        onChange={(event) => setPerg13(event.target.value)}
                         
                    >
                        <FormControlLabel value="S" control={<Radio />} label="SIM" disabled />
                        <FormControlLabel value="N" control={<Radio />} label="NÃO" disabled />
                        <FormControlLabel value=" " control={<Radio />} label="N/A" disabled />
                    </StyledRadioGroup>
                </label>
                <br></br>
                <StyledH2><br></br>3.6 - Exigências específicas estaduais</StyledH2>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <p style={{ marginRight: "8px", marginBottom: "0" }}><br></br>
                        h) Autorização ou Licença Ambiental Estadual para Transporte de
                        Produtos Perigosos <DetalhesPerg14a />
                    </p>
                </div>
                <p style={{ fontStyle: "italic" }}>
                    h.1) Exigência em Minas Gerais:
                    <span style={{ display: "block", marginLeft: "20px" }}>
                        <br />— ⠀PAE – Plano de Ação de Emergência; <DetalhesPerg14b />
                        <br />— ⠀Especificação do tanque; <DetalhesPerg14c />
                        <br />— ⠀Número do telefone do plantão de atendimento a emergência.{" "}
                        <DetalhesPerg14d />
                    </span>
                </p>

                <label>
                    <StyledRadioGroup
                        id="perg14"
                        required
                        aria-label="perg14"
                        name="perg14"
                        value={perg14}
                        onChange={(event) => setPerg14(event.target.value)}
                         
                    >
                        <FormControlLabel value="S" control={<Radio />} label="SIM" disabled />
                        <FormControlLabel value="N" control={<Radio />} label="NÃO" disabled />
                        <FormControlLabel value=" " control={<Radio />} label="N/A" disabled />
                    </StyledRadioGroup>
                </label>
                <br></br>

                <StyledH2><br></br>3.7 - Exigências específicas municipais</StyledH2>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <p style={{ marginRight: "8px", marginBottom: "0" }}><br></br>
                        i) Autorização ou Licença Ambiental Municipal para Transporte de
                        Produtos Perigosos: De acordo com a exigência de cada município.{" "}
                        <DetalhesPerg15a />
                    </p>
                </div>
                <p style={{ fontStyle: "italic" }}>
                    i.1) Exigência no município de São Paulo:
                    <span style={{ display: "block", marginLeft: "20px" }}>
                        <br />— LETPP – Licença Especial de Trânsito de Produtos Perigosos,
                        expedida pelo DSV <DetalhesPerg15b />
                    </span>
                </p>
                <sup style={{ fontStyle: "italic", top: "0px" }}>
                    * NOTA: Não há exigência para o veículo trator.
                </sup>
                <label>
                    <StyledRadioGroup
                        id="perg15"
                        required
                        aria-label="perg15"
                        name="perg15"
                        value={perg15}
                        onChange={(event) => setPerg15(event.target.value)}
                         
                    >
                        <FormControlLabel value="S" control={<Radio />} label="SIM" disabled />
                        <FormControlLabel value="N" control={<Radio />} label="NÃO" disabled />
                        <FormControlLabel value=" " control={<Radio />} label="N/A" disabled />
                    </StyledRadioGroup>
                </label>
                <br></br>
                <StyledH2><br></br>
                    3.8 - Autorização específica da União, estados ou municípios
                </StyledH2>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <p style={{ marginRight: "8px", marginBottom: "0" }}><br></br>
                        j) Autorização especial de trânsito (AET) para veículos ou Autorização
                        específica (AE) <DetalhesPerg16 />
                    </p>
                </div>
                <label>
                    <StyledRadioGroup
                        id="perg16"
                        required
                        aria-label="perg16"
                        name="perg16"
                        value={perg16}
                        onChange={(event) => setPerg16(event.target.value)}
                         
                    >
                        <FormControlLabel value="S" control={<Radio />} label="SIM" disabled />
                        <FormControlLabel value="N" control={<Radio />} label="NÃO" disabled />
                        <FormControlLabel value=" " control={<Radio />} label="N/A" disabled />
                    </StyledRadioGroup>
                </label>
                <br></br>
                <StyledH2><br></br>3.9 - Documento comprobatório</StyledH2>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <p style={{ marginRight: "8px", marginBottom: "0" }}><br></br>
                        k) Documento de comprovação do horário de chegada <DetalhesPerg17 />
                    </p>
                </div>
                <label>
                    <StyledRadioGroup
                        id="perg17"
                        required
                        aria-label="perg17"
                        name="perg17"
                        value={perg17}
                        onChange={(event) => setPerg17(event.target.value)}
                         
                    >
                        <FormControlLabel value="S" control={<Radio />} label="SIM" disabled />
                        <FormControlLabel value="N" control={<Radio />} label="NÃO" disabled />
                        <FormControlLabel value=" " control={<Radio />} label="N/A" disabled />
                    </StyledRadioGroup>
                </label>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <StyledH1>4 - CONJUNTO DE EQUIPAMENTOS PARA EMERGÊNCIA</StyledH1>
                <StyledH2><br></br>
                    4.1 - Equipamentos para sinalização (quando aplicável)
                </StyledH2>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <p style={{ marginRight: "8px", marginBottom: "0" }}><br></br>
                        a) Dois, quatro ou seis calços com dimensões mínimas de 150 mm × 200
                        mm × 150 mm, de acordo com o tipo do veículo <DetalhesPerg18 />
                    </p>
                </div>
                <label>
                    <StyledRadioGroup
                        id="perg18"
                        required
                        aria-label="perg18"
                        name="perg18"
                        value={perg18}
                        onChange={(event) => setPerg18(event.target.value)}
                         
                    >
                        <FormControlLabel value="S" control={<Radio />} label="SIM" disabled />
                        <FormControlLabel value="N" control={<Radio />} label="NÃO" disabled />
                        <FormControlLabel value=" " control={<Radio />} label="N/A" disabled />
                    </StyledRadioGroup>
                </label>
                <br></br>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <p style={{ marginRight: "8px", marginBottom: "0" }}><br></br>
                        b) Jogo de ferramentas (mínimo): um alicate universal e uma chave
                        apropriada para desconexão do cabo da bateria. <DetalhesPerg19 />
                    </p>
                </div>
                <label>
                    <StyledRadioGroup
                        id="perg19"
                        required
                        aria-label="perg19"
                        name="perg19"
                        value={perg19}
                        onChange={(event) => setPerg19(event.target.value)}
                         
                    >
                        <FormControlLabel value="S" control={<Radio />} label="SIM" disabled />
                        <FormControlLabel value="N" control={<Radio />} label="NÃO" disabled />
                        <FormControlLabel value=" " control={<Radio />} label="N/A" disabled />
                    </StyledRadioGroup>
                </label>
                <br></br>

                <div style={{ display: "flex", alignItems: "center" }}>
                    <p style={{ marginRight: "8px", marginBottom: "0" }}><br></br>
                        c) Quatro cones de cor laranja, com duas faixas retrorrefletivas na
                        cor branca, para sinalização da via, para uso nas situações de
                        emergências ou avarias. <DetalhesPerg20 />
                    </p>
                </div>
                <label>
                    <StyledRadioGroup
                        id="perg20"
                        required
                        aria-label="perg20"
                        name="perg20"
                        value={perg20}
                        onChange={(event) => setPerg20(event.target.value)}
                         
                    >
                        <FormControlLabel value="S" control={<Radio />} label="SIM" disabled />
                        <FormControlLabel value="N" control={<Radio />} label="NÃO" disabled />
                        <FormControlLabel value=" " control={<Radio />} label="N/A" disabled />
                    </StyledRadioGroup>
                </label>
                <br></br>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <p style={{ marginRight: "8px", marginBottom: "0" }}><br></br>
                        d) Conjunto de emergência antifaiscante para produtosBaixaComercial
                        cujo risco principal ou subsidiário seja inflamável (exceto o jogo de
                        ferramentas e o extintor de incêndio) <DetalhesPerg21 />
                    </p>
                </div>
                <label>
                    <StyledRadioGroup
                        id="perg21"
                        required
                        aria-label="perg21"
                        name="perg21"
                        value={perg21}
                        onChange={(event) => setPerg21(event.target.value)}
                         
                    >
                        <FormControlLabel value="S" control={<Radio />} label="SIM" disabled />
                        <FormControlLabel value="N" control={<Radio />} label="NÃO" disabled />
                        <FormControlLabel value=" " control={<Radio />} label="N/A" disabled />
                    </StyledRadioGroup>
                </label>
                <br></br>
                <StyledH2><br></br>4.2 - Extintor de incêndio do veículo</StyledH2>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <p style={{ marginRight: "8px", marginBottom: "0" }}><br></br>
                        e) Extintor de incêndio do veículo automotor: Verificar: data de
                        validade, certificação do Inmetro, devendo a identificação estar
                        legível. <DetalhesPerg22 />
                    </p>
                </div>
                <label>
                    <StyledRadioGroup
                        id="perg22"
                        required
                        aria-label="perg22"
                        name="perg22"
                        value={perg22}
                        onChange={(event) => setPerg22(event.target.value)}
                         
                    >
                        <FormControlLabel value="S" control={<Radio />} label="SIM" disabled />
                        <FormControlLabel value="N" control={<Radio />} label="NÃO" disabled />
                        <FormControlLabel value=" " control={<Radio />} label="N/A" disabled />
                    </StyledRadioGroup>
                </label>
                <br></br>
                <StyledH2><br></br>4.3 - Extintor de incêndio da carga</StyledH2>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <p style={{ marginRight: "8px", marginBottom: "0" }}><br></br>
                        f) Extintor(es) de incêndio da carga: O extintor de incêndio não pode
                        ser instalado dentro do compartimento de carga, exceto para os
                        veículos com capacidade de carga de até 3 t. <DetalhesPerg23 />
                    </p>
                </div>
                <label>
                    <StyledRadioGroup
                        id="perg23"
                        required
                        aria-label="perg23"
                        name="perg23"
                        value={perg23}
                        onChange={(event) => setPerg23(event.target.value)}
                         
                    >
                        <FormControlLabel value="S" control={<Radio />} label="SIM" disabled />
                        <FormControlLabel value="N" control={<Radio />} label="NÃO" disabled />
                        <FormControlLabel value=" " control={<Radio />} label="N/A" disabled />
                    </StyledRadioGroup>
                </label>
                <br></br>
                <StyledH2><br></br>4.4 - EPI para cada ocupante do veículo</StyledH2>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <p style={{ marginRight: "8px", marginBottom: "0" }}><br></br>
                        g) Luvas de material compatível com o(s) produto(s) transportado(s){" "}
                        <DetalhesPerg24 />
                    </p>
                </div>
                <label>
                    <StyledRadioGroup
                        id="perg24"
                        required
                        aria-label="perg24"
                        name="perg24"
                        value={perg24}
                        onChange={(event) => setPerg24(event.target.value)}
                         
                    >
                        <FormControlLabel value="S" control={<Radio />} label="SIM" disabled />
                        <FormControlLabel value="N" control={<Radio />} label="NÃO" disabled />
                        <FormControlLabel value=" " control={<Radio />} label="N/A" disabled />
                    </StyledRadioGroup>
                </label>
                <br></br>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <p style={{ marginRight: "8px", marginBottom: "0" }}><br></br>
                        h) Capacete de segurança <DetalhesPerg25 />
                    </p>
                </div>
                <label>
                    <StyledRadioGroup
                        id="perg25"
                        required
                        aria-label="perg25"
                        name="perg25"
                        value={perg25}
                        onChange={(event) => setPerg25(event.target.value)}
                         
                    >
                        <FormControlLabel value="S" control={<Radio />} label="SIM" disabled />
                        <FormControlLabel value="N" control={<Radio />} label="NÃO" disabled />
                        <FormControlLabel value=" " control={<Radio />} label="N/A" disabled />
                    </StyledRadioGroup>
                </label>
                <br></br>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <p style={{ marginRight: "8px", marginBottom: "0" }}><br></br>
                        i) Óculos de segurança tipo ampla visão * <DetalhesPerg26 />
                        <br></br>
                        <sup style={{ fontStyle: "italic", top: "0px" }}>
                            * NOTA: No caso de o produto transportado exigir peça facial
                            inteira, substituir os óculos de segurança.
                        </sup>
                    </p>
                </div>
                <label>
                    <StyledRadioGroup
                        id="perg26"
                        required
                        aria-label="perg26"
                        name="perg26"
                        value={perg26}
                        onChange={(event) => setPerg26(event.target.value)}
                         
                    >
                        <FormControlLabel value="S" control={<Radio />} label="SIM" disabled />
                        <FormControlLabel value="N" control={<Radio />} label="NÃO" disabled />
                        <FormControlLabel value=" " control={<Radio />} label="N/A" disabled />
                    </StyledRadioGroup>
                </label>
                <br></br>

                <div style={{ display: "flex", alignItems: "center" }}>
                    <p style={{ marginRight: "8px", marginBottom: "0" }}><br></br>
                        j) Peça facial inteira ou semifacial com filtro, quando apropriado ao
                        produto. <DetalhesPerg27 />
                    </p>
                </div>
                <label>
                    <StyledRadioGroup
                        id="perg27"
                        required
                        aria-label="perg27"
                        name="perg27"
                        value={perg27}
                        onChange={(event) => setPerg27(event.target.value)}
                         
                    >
                        <FormControlLabel value="S" control={<Radio />} label="SIM" disabled />
                        <FormControlLabel value="N" control={<Radio />} label="NÃO" disabled />
                        <FormControlLabel value=" " control={<Radio />} label="N/A" disabled />
                    </StyledRadioGroup>
                </label>
                <br></br>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <p style={{ marginRight: "8px", marginBottom: "0" }}><br></br>
                        k) Filtros *, quando aplicável: Verificar tipo e validade do
                        fabricante. <DetalhesPerg28 /> <br></br>
                        <sup style={{ fontStyle: "italic", top: "0px" }}>
                            * NOTA: A função do EPI é para avaliação e fuga e não para
                            atendimento emergencial.
                        </sup>
                    </p>
                </div>
                <label>
                    <StyledRadioGroup
                        id="perg28"
                        required
                        aria-label="perg28"
                        name="perg28"
                        value={perg28}
                        onChange={(event) => setPerg28(event.target.value)}
                         
                    >
                        <FormControlLabel value="S" control={<Radio />} label="SIM" disabled />
                        <FormControlLabel value="N" control={<Radio />} label="NÃO" disabled />
                        <FormControlLabel value=" " control={<Radio />} label="N/A" disabled />
                    </StyledRadioGroup>
                </label>
            </TabPanel>

            <TabPanel value={value} index={3}>
                <StyledH1>5 - SINALIZAÇÃO DO VEÍCULO E EQUIPAMENTO</StyledH1>
                <StyledH2><br></br>5.1 - Sinalização do veículo e equipamento</StyledH2>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <p style={{ marginRight: "8px", marginBottom: "0" }}><br></br>
                        a) Painéis de segurança (quando aplicável) de acordo com o(s)
                        produto(s) <DetalhesPerg29 />
                    </p>
                </div>
                <label>
                    <StyledRadioGroup
                        id="perg29"
                        required
                        aria-label="perg29"
                        name="perg29"
                        value={perg29}
                        onChange={(event) => setPerg29(event.target.value)}
                         
                    >
                        <FormControlLabel value="S" control={<Radio />} label="SIM" disabled />
                        <FormControlLabel value="N" control={<Radio />} label="NÃO" disabled />
                        <FormControlLabel value=" " control={<Radio />} label="N/A" disabled />
                    </StyledRadioGroup>
                </label>
                <br></br>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <p style={{ marginRight: "8px", marginBottom: "0" }}><br></br>
                        b) Rótulos de risco (quando aplicável) de acordo com o(s) produto(s){" "}
                        <DetalhesPerg30 />
                    </p>
                </div>
                <label>
                    <StyledRadioGroup
                        id="perg30"
                        required
                        aria-label="perg30"
                        name="perg30"
                        value={perg30}
                        onChange={(event) => setPerg30(event.target.value)}
                         
                    >
                        <FormControlLabel value="S" control={<Radio />} label="SIM" disabled />
                        <FormControlLabel value="N" control={<Radio />} label="NÃO" disabled />
                        <FormControlLabel value=" " control={<Radio />} label="N/A" disabled />
                    </StyledRadioGroup>
                </label>
                <br></br>

                <div style={{ display: "flex", alignItems: "center" }}>
                    <p style={{ marginRight: "8px", marginBottom: "0" }}><br></br>
                        c) Veículos e equipamentos carregados com substâncias que apresentam
                        risco para o meio ambiente (ONU 3077 e ONU 3082), quando aplicável{" "}
                        <DetalhesPerg31 />
                    </p>
                </div>
                <label>
                    <StyledRadioGroup
                        id="perg31"
                        required
                        aria-label="perg31"
                        name="perg31"
                        value={perg31}
                        onChange={(event) => setPerg31(event.target.value)}
                         
                    >
                        <FormControlLabel value="S" control={<Radio />} label="SIM" disabled />
                        <FormControlLabel value="N" control={<Radio />} label="NÃO" disabled />
                        <FormControlLabel value=" " control={<Radio />} label="N/A" disabled />
                    </StyledRadioGroup>
                </label>
                <br></br>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <p style={{ marginRight: "8px", marginBottom: "0" }}><br></br>
                        d) Veículos e equipamentos carregados com substância à temperatura
                        elevada <DetalhesPerg32 />
                    </p>
                </div>
                <label>
                    <StyledRadioGroup
                        required
                        aria-label="perg32"
                        name="perg32"
                        value={perg32}
                        onChange={(event) => setPerg32(event.target.value)}
                         
                    >
                        <FormControlLabel value="S" control={<Radio />} label="SIM" disabled />
                        <FormControlLabel value="N" control={<Radio />} label="NÃO" disabled />
                        <FormControlLabel value=" " control={<Radio />} label="N/A" disabled />
                    </StyledRadioGroup>
                </label>
                <br></br>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <p style={{ marginRight: "8px", marginBottom: "0" }}><br></br>
                        e) Veículos e equipamentos sem sinalização imprópria:{" "}
                        <DetalhesPerg33 />
                    </p>
                </div>
                <label>
                    <StyledRadioGroup
                        required
                        aria-label="perg33"
                        name="perg33"
                        value={perg33}
                        onChange={(event) => setPerg33(event.target.value)}
                         
                    >
                        <FormControlLabel value="S" control={<Radio />} label="SIM" disabled />
                        <FormControlLabel value="N" control={<Radio />} label="NÃO" disabled />
                        <FormControlLabel value=" " control={<Radio />} label="N/A" disabled />
                    </StyledRadioGroup>
                </label>
            </TabPanel>
            <TabPanel value={value} index={4}>
                <StyledH1>6 - EMBALAGENS (MARCAÇÃO E ROTULAGEM) E CARGA</StyledH1>
                <StyledH2><br></br>
                    6.1 - Nº ONU, nome apropriado para embarque, rótulo de risco, setas de
                    orientação e demais símbolos
                </StyledH2>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <p style={{ marginRight: "8px", marginBottom: "0" }}><br></br>
                        a) Nº ONU, nome apropriado para embarque (quando aplicável), rótulo de
                        risco (quando aplicável), setas de orientação (quando aplicável),
                        símbolos (quando aplicáveis) <DetalhesPerg34 />
                    </p>
                </div>
                <label>
                    <StyledRadioGroup
                        required
                        aria-label="perg34"
                        name="perg34"
                        value={perg34}
                        onChange={(event) => setPerg34(event.target.value)}
                         
                    >
                        <FormControlLabel value="S" control={<Radio />} label="SIM" disabled />
                        <FormControlLabel value="N" control={<Radio />} label="NÃO" disabled />
                        <FormControlLabel value=" " control={<Radio />} label="N/A" disabled />
                    </StyledRadioGroup>
                </label>
                <br></br>
                <StyledH2><br></br>6.2 - Homologação de embalagem</StyledH2>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <p style={{ marginRight: "8px", marginBottom: "0" }}><br></br>
                        b) Identificação da homologação de embalagem (quando aplicável){" "}
                        <DetalhesPerg35 />
                    </p>
                </div>
                <label>
                    <StyledRadioGroup
                        required
                        aria-label="perg35"
                        name="perg35"
                        value={perg35}
                        onChange={(event) => setPerg35(event.target.value)}
                         
                    >
                        <FormControlLabel value="S" control={<Radio />} label="SIM" disabled />
                        <FormControlLabel value="N" control={<Radio />} label="NÃO" disabled />
                        <FormControlLabel value=" " control={<Radio />} label="N/A" disabled />
                    </StyledRadioGroup>
                </label>
                <br></br>
                <StyledH2><br></br>6.3 - Organização de carga</StyledH2>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <p style={{ marginRight: "8px", marginBottom: "0" }}><br></br>
                        c) Organização da carga <DetalhesPerg36 />
                    </p>
                </div>
                <label>
                    <StyledRadioGroup
                        required
                        aria-label="perg36"
                        name="perg36"
                        value={perg36}
                        onChange={(event) => setPerg36(event.target.value)}
                         
                    >
                        <FormControlLabel value="S" control={<Radio />} label="SIM" disabled />
                        <FormControlLabel value="N" control={<Radio />} label="NÃO" disabled />
                        <FormControlLabel value=" " control={<Radio />} label="N/A" disabled />
                    </StyledRadioGroup>
                </label>
            </TabPanel>
            <TabPanel value={value} index={5}>
                <StyledH1>
                    7 - CARACTERÍSTICAS TÉCNICAS E OPERACIONAIS DO VEÍCULO E EQUIPAMENTOS
                    OBRIGATÓRIOS
                </StyledH1>
                <StyledH2><br></br>
                    7.1 - Características técnicas e operacionais do veículo e
                    equipamentos obrigatórios
                </StyledH2>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <p style={{ marginRight: "8px", marginBottom: "0" }}><br></br>
                        a) Cronotacógrafo, registrador inalterável de velocidade e tempo{" "}
                        <DetalhesPerg37 />
                    </p>
                </div>
                <label>
                    <StyledRadioGroup
                        required
                        aria-label="perg37"
                        name="perg37"
                        value={perg37}
                        onChange={(event) => setPerg37(event.target.value)}
                         
                    >
                        <FormControlLabel value="S" control={<Radio />} label="SIM" disabled />
                        <FormControlLabel value="N" control={<Radio />} label="NÃO" disabled />
                        <FormControlLabel value=" " control={<Radio />} label="N/A" disabled />
                    </StyledRadioGroup>
                </label>
                <br></br>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <p style={{ marginRight: "8px", marginBottom: "0" }}><br></br>
                        b) Pneus e rodas em bom estado. A profundidade mínima do sulco dos
                        pneus deve ser de 1,6 mm <DetalhesPerg38 />
                    </p>
                </div>
                <label>
                    <StyledRadioGroup
                        required
                        aria-label="perg38"
                        name="perg38"
                        value={perg38}
                        onChange={(event) => setPerg38(event.target.value)}
                         
                    >
                        <FormControlLabel value="S" control={<Radio />} label="SIM" disabled />
                        <FormControlLabel value="N" control={<Radio />} label="NÃO" disabled />
                        <FormControlLabel value=" " control={<Radio />} label="N/A" disabled />
                    </StyledRadioGroup>
                </label>
                <br></br>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <p style={{ marginRight: "8px", marginBottom: "0" }}><br></br>
                        c) Pneu(s) sobressalente(s): Verificar a compatibilidade com os demais
                        pneus. <DetalhesPerg39 />
                    </p>
                </div>
                <label>
                    <StyledRadioGroup
                        required
                        aria-label="perg39"
                        name="perg39"
                        value={perg39}
                        onChange={(event) => setPerg39(event.target.value)}
                         
                    >
                        <FormControlLabel value="S" control={<Radio />} label="SIM" disabled />
                        <FormControlLabel value="N" control={<Radio />} label="NÃO" disabled />
                        <FormControlLabel value=" " control={<Radio />} label="N/A" disabled />
                    </StyledRadioGroup>
                </label>
                <br></br>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <p style={{ marginRight: "8px", marginBottom: "0" }}><br></br>
                        d) Equipamentos obrigatórios: chave de rodas, macaco, triângulo de
                        segurança. <DetalhesPerg40 />
                    </p>
                </div>
                <label>
                    <StyledRadioGroup
                        required
                        aria-label="perg40"
                        name="perg40"
                        value={perg40}
                        onChange={(event) => setPerg40(event.target.value)}
                         
                    >
                        <FormControlLabel value="S" control={<Radio />} label="SIM" disabled />
                        <FormControlLabel value="N" control={<Radio />} label="NÃO" disabled />
                        <FormControlLabel value=" " control={<Radio />} label="N/A" disabled />
                    </StyledRadioGroup>
                </label>
                <br></br>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <p style={{ marginRight: "8px", marginBottom: "0" }}><br></br>
                        e) Dispositivos de controle: faróis alto e baixo, buzina, velocímetro,
                        lanternas de posição, indicador de mudança de direção (seta/pisca),
                        luzes de freio, iluminação de placa traseira e, quando aplicável, luz
                        de ré; verificar funcionamento. <DetalhesPerg41 />
                    </p>
                </div>
                <label>
                    <StyledRadioGroup
                        required
                        aria-label="perg41"
                        name="perg41"
                        value={perg41}
                        onChange={(event) => setPerg41(event.target.value)}
                         
                    >
                        <FormControlLabel value="S" control={<Radio />} label="SIM" disabled />
                        <FormControlLabel value="N" control={<Radio />} label="NÃO" disabled />
                        <FormControlLabel value=" " control={<Radio />} label="N/A" disabled />
                    </StyledRadioGroup>
                </label>
                <br></br>

                <div style={{ display: "flex", alignItems: "center" }}>
                    <p style={{ marginRight: "8px", marginBottom: "0" }}><br></br>
                        f) Películas retrorrefletivas nas laterais, traseira e para-choque.{" "}
                        <DetalhesPerg42 />
                    </p>
                </div>
                <label>
                    <StyledRadioGroup
                        required
                        aria-label="perg42"
                        name="perg42"
                        value={perg42}
                        onChange={(event) => setPerg42(event.target.value)}
                         
                    >
                        <FormControlLabel value="S" control={<Radio />} label="SIM" disabled />
                        <FormControlLabel value="N" control={<Radio />} label="NÃO" disabled />
                        <FormControlLabel value=" " control={<Radio />} label="N/A" disabled />
                    </StyledRadioGroup>
                </label>
                <br></br>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <p style={{ marginRight: "8px", marginBottom: "0" }}><br></br>
                        g) Equipamento/carroçaria em boas condições. <DetalhesPerg43 />
                    </p>
                </div>
                <label>
                    <StyledRadioGroup
                        required
                        aria-label="perg43"
                        name="perg43"
                        value={perg43}
                        onChange={(event) => setPerg43(event.target.value)}
                         
                    >
                        <FormControlLabel value="S" control={<Radio />} label="SIM" disabled />
                        <FormControlLabel value="N" control={<Radio />} label="NÃO" disabled />
                        <FormControlLabel value=" " control={<Radio />} label="N/A" disabled />
                    </StyledRadioGroup>
                </label>
                <br></br>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <p style={{ marginRight: "8px", marginBottom: "0" }}><br></br>
                        h) Tanque de combustível: fechado e sem vazamento. <DetalhesPerg44 />
                    </p>
                </div>
                <label>
                    <StyledRadioGroup
                        required
                        aria-label="perg44"
                        name="perg44"
                        value={perg44}
                        onChange={(event) => setPerg44(event.target.value)}
                         
                    >
                        <FormControlLabel value="S" control={<Radio />} label="SIM" disabled />
                        <FormControlLabel value="N" control={<Radio />} label="NÃO" disabled />
                        <FormControlLabel value=" " control={<Radio />} label="N/A" disabled />
                    </StyledRadioGroup>
                </label>
                <br></br>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <p style={{ marginRight: "8px", marginBottom: "0" }}><br></br>
                        i) 5ª (quinta) roda e conjunto de engate: deve estar íntegra.{" "}
                        <DetalhesPerg45 />
                    </p>
                </div>
                <label>
                    <StyledRadioGroup
                        required
                        aria-label="perg45"
                        name="perg45"
                        value={perg45}
                        onChange={(event) => setPerg45(event.target.value)}
                         
                    >
                        <FormControlLabel value="S" control={<Radio />} label="SIM" disabled />
                        <FormControlLabel value="N" control={<Radio />} label="NÃO" disabled />
                        <FormControlLabel value=" " control={<Radio />} label="N/A" disabled />
                    </StyledRadioGroup>
                </label>
                <br></br>

                <div style={{ display: "flex", alignItems: "center" }}>
                    <p style={{ marginRight: "8px", marginBottom: "0" }}><br></br>
                        j) Para-choque dianteiro/traseiro em boas condições <DetalhesPerg46 />
                    </p>
                </div>
                <label>
                    <StyledRadioGroup
                        required
                        aria-label="perg46"
                        name="perg46"
                        value={perg46}
                        onChange={(event) => setPerg46(event.target.value)}
                         
                    >
                        <FormControlLabel value="S" control={<Radio />} label="SIM" disabled />
                        <FormControlLabel value="N" control={<Radio />} label="NÃO" disabled />
                        <FormControlLabel value=" " control={<Radio />} label="N/A" disabled />
                    </StyledRadioGroup>
                </label>
                <br></br>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <p style={{ marginRight: "8px", marginBottom: "0" }}><br></br>
                        k) Cinta protetora do eixo cardan <DetalhesPerg47 />{" "}
                    </p>
                </div>
                <label>
                    <StyledRadioGroup
                        required
                        aria-label="perg47"
                        name="perg47"
                        value={perg47}
                        onChange={(event) => setPerg47(event.target.value)}
                         
                    >
                        <FormControlLabel value="S" control={<Radio />} label="SIM" disabled />
                        <FormControlLabel value="N" control={<Radio />} label="NÃO" disabled />
                        <FormControlLabel value=" " control={<Radio />} label="N/A" disabled />
                    </StyledRadioGroup>
                </label>
                <br></br>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <p style={{ marginRight: "8px", marginBottom: "0" }}><br></br>
                        l) Fiação elétrica devidamente isolada e fixada <DetalhesPerg48 />
                    </p>
                </div>
                <label>
                    <StyledRadioGroup
                        required
                        aria-label="perg48"
                        name="perg48"
                        value={perg48}
                        onChange={(event) => setPerg48(event.target.value)}
                         
                    >
                        <FormControlLabel value="S" control={<Radio />} label="SIM" disabled />
                        <FormControlLabel value="N" control={<Radio />} label="NÃO" disabled />
                        <FormControlLabel value=" " control={<Radio />} label="N/A" disabled />
                    </StyledRadioGroup>
                </label>
                <br></br>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <p style={{ marginRight: "8px", marginBottom: "0" }}><br></br>
                        m) Para-lamas (dianteiros e traseiros) em boas condições de
                        funcionamento <DetalhesPerg49 />
                    </p>
                </div>
                <label>
                    <StyledRadioGroup
                        required
                        aria-label="perg49"
                        name="perg49"
                        value={perg49}
                        onChange={(event) => setPerg49(event.target.value)}
                         
                    >
                        <FormControlLabel value="S" control={<Radio />} label="SIM" disabled />
                        <FormControlLabel value="N" control={<Radio />} label="NÃO" disabled />
                        <FormControlLabel value=" " control={<Radio />} label="N/A" disabled />
                    </StyledRadioGroup>
                </label>
                <br></br>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <p style={{ marginRight: "8px", marginBottom: "0" }}><br></br>
                        <br></br>
                        n) Espelho retrovisor externo, limpador de para-brisa em boas
                        condições de funcionamento <DetalhesPerg50 />
                    </p>
                </div>
                <label>
                    <StyledRadioGroup
                        required
                        aria-label="perg50"
                        name="perg50"
                        value={perg50}
                        onChange={(event) => setPerg50(event.target.value)}
                         
                    >
                        <FormControlLabel value="S" control={<Radio />} label="SIM" disabled />
                        <FormControlLabel value="N" control={<Radio />} label="NÃO" disabled />
                        <FormControlLabel value=" " control={<Radio />} label="N/A" disabled />
                    </StyledRadioGroup>
                </label>
                <br></br>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <p style={{ marginRight: "8px", marginBottom: "0" }}><br></br>
                        o) Freios de serviço e estacionamento em boas condições de
                        funcionamento (item específico do transportador) <DetalhesPerg51 />
                    </p>
                </div>
                <label>
                    <StyledRadioGroup
                        required
                        aria-label="perg51"
                        name="perg51"
                        value={perg51}
                        onChange={(event) => setPerg51(event.target.value)}
                         
                    >
                        <FormControlLabel value="S" control={<Radio />} label="SIM" disabled />
                        <FormControlLabel value="N" control={<Radio />} label="NÃO" disabled />
                        <FormControlLabel value=" " control={<Radio />} label="N/A" disabled />
                    </StyledRadioGroup>
                </label>
                <br></br>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <p style={{ marginRight: "8px", marginBottom: "0" }}><br></br>
                        p) Quebra-sol: pala interna de proteção contra o sol, para o condutor
                        em boas condições de funcionamento <DetalhesPerg52 />
                    </p>
                </div>
                <label>
                    <StyledRadioGroup
                        required
                        aria-label="perg52"
                        name="perg52"
                        value={perg52}
                        onChange={(event) => setPerg52(event.target.value)}
                         
                    >
                        <FormControlLabel value="S" control={<Radio />} label="SIM" disabled />
                        <FormControlLabel value="N" control={<Radio />} label="NÃO" disabled />
                        <FormControlLabel value=" " control={<Radio />} label="N/A" disabled />
                    </StyledRadioGroup>
                </label>
                <br></br>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <p style={{ marginRight: "8px", marginBottom: "0" }}><br></br>
                        q) Para-barro, quando exigido: protetores das rodas traseiras em boas
                        condições de funcionamento <DetalhesPerg53 />
                    </p>
                </div>
                <label>
                    <StyledRadioGroup
                        required
                        aria-label="perg53"
                        name="perg53"
                        value={perg53}
                        onChange={(event) => setPerg53(event.target.value)}
                         
                    >
                        <FormControlLabel value="S" control={<Radio />} label="SIM" disabled />
                        <FormControlLabel value="N" control={<Radio />} label="NÃO" disabled />
                        <FormControlLabel value=" " control={<Radio />} label="N/A" disabled />
                    </StyledRadioGroup>
                </label>
                <br></br>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <p style={{ marginRight: "8px", marginBottom: "0" }}><br></br>
                        r) Cintos de segurança: para todos os ocupantes, em boas condições de
                        funcionamento <DetalhesPerg54 />
                    </p>
                </div>
                <label>
                    <StyledRadioGroup
                        required
                        aria-label="perg54"
                        name="perg54"
                        value={perg54}
                        onChange={(event) => setPerg54(event.target.value)}
                         
                    >
                        <FormControlLabel value="S" control={<Radio />} label="SIM" disabled />
                        <FormControlLabel value="N" control={<Radio />} label="NÃO" disabled />
                        <FormControlLabel value=" " control={<Radio />} label="N/A" disabled />
                    </StyledRadioGroup>
                </label>
                <br></br>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <p style={{ marginRight: "8px", marginBottom: "0" }}><br></br>
                        <br></br>
                        s) Limpeza/Descontaminação de resíduos <DetalhesPerg55 />
                    </p>
                </div>
                <label>
                    <StyledRadioGroup
                        required
                        aria-label="perg55"
                        name="perg55"
                        value={perg55}
                        onChange={(event) => setPerg55(event.target.value)}
                         
                    >
                        <FormControlLabel value="S" control={<Radio />} label="SIM" disabled />
                        <FormControlLabel value="N" control={<Radio />} label="NÃO" disabled />
                        <FormControlLabel value=" " control={<Radio />} label="N/A" disabled />
                    </StyledRadioGroup>
                </label>
                <br></br>
                <StyledH2><br></br>
                    7.2 - Características técnicas e operacionais do veículo-tanque
                </StyledH2>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <p style={{ marginRight: "8px", marginBottom: "0" }}><br></br>
                        t) Tanque: <DetalhesPerg56 />
                    </p>
                    <p style={{ fontStyle: "italic", marginLeft: "20px" }}>
                        <br />— ⠀A plaqueta de identificação/inspeção fixada no equipamento
                        (Inmetro) deve corresponder ao Certificado de Inspeção (CIPP);
                        <br />— ⠀flange cego, tampão, CAP na tubulação de saída;
                        <br />— ⠀escada de acesso e piso antiderrapante;
                        <br />— ⠀ponto de aterramento adequado (líquidos inflamáveis a
                        granel);
                        <br />— ⠀lacre (após o carregamento) – Válvula de descarga;
                        <br />— ⠀lacre (após o carregamento) – Boca de visita;
                        <br />— ⠀estanqueidade;
                        <br />— ⠀tanques, válvulas e conexões sem vazamentos.
                    </p>
                </div>

                <label>
                    <StyledRadioGroup
                        required
                        aria-label="perg56"
                        name="perg56"
                        value={perg56}
                        onChange={(event) => setPerg56(event.target.value)}
                         
                    >
                        <FormControlLabel value="S" control={<Radio />} label="SIM" disabled />
                        <FormControlLabel value="N" control={<Radio />} label="NÃO" disabled />
                        <FormControlLabel value=" " control={<Radio />} label="N/A" disabled />
                    </StyledRadioGroup>
                </label>
                <br></br>
                <StyledH2><br></br>7.3 - Equipamentos proibidos</StyledH2>

                <div style={{ display: "flex", alignItems: "center" }}>
                    <p style={{ marginRight: "8px", marginBottom: "0" }}><br></br>
                        u) Equipamento de autoaquecimento: Verificar se o veículo não possui
                        aparelho ou equipamento de aquecimento sujeito à combustão, a gás ou
                        elétrico (fogão, fogareiro ou semelhantes). <DetalhesPerg57 />
                    </p>
                </div>
                <label>
                    <StyledRadioGroup
                        required
                        aria-label="perg57"
                        name="perg57"
                        value={perg57}
                        onChange={(event) => setPerg57(event.target.value)}
                         
                    >
                        <FormControlLabel value="S" control={<Radio />} label="SIM" disabled />
                        <FormControlLabel value="N" control={<Radio />} label="NÃO" disabled />
                        <FormControlLabel value=" " control={<Radio />} label="N/A" disabled />
                    </StyledRadioGroup>
                </label>
            </TabPanel>
            <TabPanel value={value} index={6}>
                <StyledH1 onClick={toggleSecaoVisivel} style={{ cursor: "pointer" }}>
                    {secaoVisivel ? <FaChevronDown /> : <FaChevronRight />} 8 -
                    CARREGAMENTO (RESPONSABILIDADE DO EXPEDIDOR) (ESTE ITEM NÃO FAZ PARTE
                    DA LISTA DE VERIFICAÇÃO PARA A LIBERAÇÃO DO CARREGAMENTO) (
                    <i>Clique para expandir</i>)
                </StyledH1>
                {secaoVisivel && (
                    <>
                        <StyledH2><br></br>8.1 - Antes do processo de carregamento</StyledH2>
                        <p>a) Requerimentos legais:</p>
                        <label>
                            <StyledRadioGroup
                                required
                                aria-label="perg58"
                                name="perg58"
                                value={perg58}
                                onChange={(event) => setPerg58(event.target.value)}
                                 
                            >
                                <FormControlLabel value="S" control={<Radio />} label="SIM" disabled />
                                <FormControlLabel value="N" control={<Radio />} label="NÃO" disabled />
                                <FormControlLabel value=" " control={<Radio />} label="N/A" disabled />
                            </StyledRadioGroup>
                        </label>
                        <br></br>
                        <p>b) Quantidade:</p>
                        <label>
                            <StyledRadioGroup
                                required
                                aria-label="perg59"
                                name="perg59"
                                value={perg59}
                                onChange={(event) => setPerg59(event.target.value)}
                                 
                            >
                                <FormControlLabel value="S" control={<Radio />} label="SIM" disabled />
                                <FormControlLabel value="N" control={<Radio />} label="NÃO" disabled />
                                <FormControlLabel value=" " control={<Radio />} label="N/A" disabled />
                            </StyledRadioGroup>
                        </label>
                        <br></br>
                        <p>c) Posicionamento:</p>
                        <label>
                            <StyledRadioGroup
                                required
                                aria-label="perg60"
                                name="perg60"
                                value={perg60}
                                onChange={(event) => setPerg60(event.target.value)}
                                 
                            >
                                <FormControlLabel value="S" control={<Radio />} label="SIM" disabled />
                                <FormControlLabel value="N" control={<Radio />} label="NÃO" disabled />
                                <FormControlLabel value=" " control={<Radio />} label="N/A" disabled />
                            </StyledRadioGroup>
                        </label>
                        <br></br>
                        <p>d) Colocação dos calços:</p>
                        <label>
                            <StyledRadioGroup
                                required
                                aria-label="perg61"
                                name="perg61"
                                value={perg61}
                                onChange={(event) => setPerg61(event.target.value)}
                                 
                            >
                                <FormControlLabel value="S" control={<Radio />} label="SIM" disabled />
                                <FormControlLabel value="N" control={<Radio />} label="NÃO" disabled />
                                <FormControlLabel value=" " control={<Radio />} label="N/A" disabled />
                            </StyledRadioGroup>
                        </label>
                        <br></br>
                        <p>e) Conexões e material:</p>
                        <label>
                            <StyledRadioGroup
                                required
                                aria-label="perg62"
                                name="perg62"
                                value={perg62}
                                onChange={(event) => setPerg62(event.target.value)}
                                 
                            >
                                <FormControlLabel value="S" control={<Radio />} label="SIM" disabled />
                                <FormControlLabel value="N" control={<Radio />} label="NÃO" disabled />
                                <FormControlLabel value=" " control={<Radio />} label="N/A" disabled />
                            </StyledRadioGroup>
                        </label>
                        <br></br>

                        <p>f) Veículo:</p>
                        <label>
                            <StyledRadioGroup
                                required
                                aria-label="perg63"
                                name="perg63"
                                value={perg63}
                                onChange={(event) => setPerg63(event.target.value)}
                                 
                            >
                                <FormControlLabel value="S" control={<Radio />} label="SIM" disabled />
                                <FormControlLabel value="N" control={<Radio />} label="NÃO" disabled />
                                <FormControlLabel value=" " control={<Radio />} label="N/A" disabled />
                            </StyledRadioGroup>
                        </label>
                        <br></br>
                        <p>g) Ligações e aterramento:</p>
                        <label>
                            <StyledRadioGroup
                                required
                                aria-label="perg64"
                                name="perg64"
                                value={perg64}
                                onChange={(event) => setPerg64(event.target.value)}
                                 
                            >
                                <FormControlLabel value="S" control={<Radio />} label="SIM" disabled />
                                <FormControlLabel value="N" control={<Radio />} label="NÃO" disabled />
                                <FormControlLabel value=" " control={<Radio />} label="N/A" disabled />
                            </StyledRadioGroup>
                        </label>
                        <StyledH2><br></br>
                            8.2 - Durante o processo de carregamento (fracionado)
                        </StyledH2>
                        <br></br>
                        <p>h) Transferência:</p>
                        <label>
                            <StyledRadioGroup
                                required
                                aria-label="perg65"
                                name="perg65"
                                value={perg65}
                                onChange={(event) => setPerg65(event.target.value)}
                                 
                            >
                                <FormControlLabel value="S" control={<Radio />} label="SIM" disabled />
                                <FormControlLabel value="N" control={<Radio />} label="NÃO" disabled />
                                <FormControlLabel value=" " control={<Radio />} label="N/A" disabled />
                            </StyledRadioGroup>
                        </label>
                        <br></br>
                        <p>i) Incompatibilidade química:</p>
                        <label>
                            <StyledRadioGroup
                                required
                                aria-label="perg66"
                                name="perg66"
                                value={perg66}
                                onChange={(event) => setPerg66(event.target.value)}
                                 
                            >
                                <FormControlLabel value="S" control={<Radio />} label="SIM" disabled />
                                <FormControlLabel value="N" control={<Radio />} label="NÃO" disabled />
                                <FormControlLabel value=" " control={<Radio />} label="N/A" disabled />
                            </StyledRadioGroup>
                        </label>
                        <br></br>
                        <StyledH2><br></br>
                            8.3 - Após o processo de carregamento no veículo ou equipamento de
                            transporte
                        </StyledH2>
                        <p>j) Tubo coletor baixo:</p>
                        <label>
                            <StyledRadioGroup
                                required
                                aria-label="perg67"
                                name="perg67"
                                value={perg67}
                                onChange={(event) => setPerg67(event.target.value)}
                                 
                            >
                                <FormControlLabel value="S" control={<Radio />} label="SIM" disabled />
                                <FormControlLabel value="N" control={<Radio />} label="NÃO" disabled />
                                <FormControlLabel value=" " control={<Radio />} label="N/A" disabled />
                            </StyledRadioGroup>
                        </label>
                        <br></br>
                        <p>k) Mangueiras:</p>
                        <label>
                            <StyledRadioGroup
                                required
                                aria-label="perg68"
                                name="perg68"
                                value={perg68}
                                onChange={(event) => setPerg68(event.target.value)}
                                 
                            >
                                <FormControlLabel value="S" control={<Radio />} label="SIM" disabled />
                                <FormControlLabel value="N" control={<Radio />} label="NÃO" disabled />
                                <FormControlLabel value=" " control={<Radio />} label="N/A" disabled />
                            </StyledRadioGroup>
                        </label>
                        <br></br>
                        <p>l) Limpeza:</p>
                        <label>
                            <StyledRadioGroup
                                required
                                aria-label="perg69"
                                name="perg69"
                                value={perg69}
                                onChange={(event) => setPerg69(event.target.value)}
                                 
                            >
                                <FormControlLabel value="S" control={<Radio />} label="SIM" disabled />
                                <FormControlLabel value="N" control={<Radio />} label="NÃO" disabled />
                                <FormControlLabel value=" " control={<Radio />} label="N/A" disabled />
                            </StyledRadioGroup>
                        </label>
                        <br></br>
                        <p>m) Tampas e válvulas:</p>
                        <label>
                            <StyledRadioGroup
                                required
                                aria-label="perg70"
                                name="perg70"
                                value={perg70}
                                onChange={(event) => setPerg70(event.target.value)}
                                 
                            >
                                <FormControlLabel value="S" control={<Radio />} label="SIM" disabled />
                                <FormControlLabel value="N" control={<Radio />} label="NÃO" disabled />
                                <FormControlLabel value=" " control={<Radio />} label="N/A" disabled />
                            </StyledRadioGroup>
                        </label>
                        <br></br>
                        <p>
                            <br></br>
                            n) Calços, sinalização de advertência e proteção contra derrames:
                        </p>
                        <label>
                            <StyledRadioGroup
                                required
                                aria-label="perg71"
                                name="perg71"
                                value={perg71}
                                onChange={(event) => setPerg71(event.target.value)}
                                 
                            >
                                <FormControlLabel value="S" control={<Radio />} label="SIM" disabled />
                                <FormControlLabel value="N" control={<Radio />} label="NÃO" disabled />
                                <FormControlLabel value=" " control={<Radio />} label="N/A" disabled />
                            </StyledRadioGroup>
                        </label>
                        <br></br>
                        <p>
                            o) Equipamento de transporte e veículo para transporte rodoviário:
                        </p>
                        <label>
                            <StyledRadioGroup
                                required
                                aria-label="perg72"
                                name="perg72"
                                value={perg72}
                                onChange={(event) => setPerg72(event.target.value)}
                                 
                            >
                                <FormControlLabel value="S" control={<Radio />} label="SIM" disabled />
                                <FormControlLabel value="N" control={<Radio />} label="NÃO" disabled />
                                <FormControlLabel value=" " control={<Radio />} label="N/A" disabled />
                            </StyledRadioGroup>
                        </label>
                    </>
                )}
            </TabPanel>
            <br></br>
        </>
    );
};

export default PergsPortariaNaoEditavel;