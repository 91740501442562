import React, { useState, useEffect } from 'react';
import { Box, Container, LinearProgress } from '@mui/material';
import "../styles/Header.css";
import Sidebar from './Sidebar';

const Header = ({ user }) => {
  const emp = sessionStorage.getItem("empresa");
  const [tempoRestante, setTempoRestante] = useState(0);

  useEffect(() => {
    const atualizarTempoRestante = () => {
      const sessionExpiration = sessionStorage.getItem("sessionExpiration");
      if (sessionExpiration) {
        const tempoDeExpiracao = parseInt(sessionExpiration, 10);
        const horaAtual = new Date().getTime();
        const tempoRestante = tempoDeExpiracao - horaAtual;
        setTempoRestante(tempoRestante > 0 ? tempoRestante : 0);
      }
    };

    atualizarTempoRestante(); // Atualiza imediatamente ao montar o componente

    const intervalId = setInterval(() => {
      atualizarTempoRestante();
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const formatTempoRestante = (milissegundos) => {
    const totalSegundos = Math.floor(milissegundos / 1000);
    const horas = Math.floor(totalSegundos / 3600);
    const minutos = Math.floor((totalSegundos % 3600) / 60);

    return `0${horas}h ${minutos}m`;
  };

  const calcularProgressoTotal = (milissegundos) => {
    const tempoEmMilissegundos = 6 * 60 * 60 * 1000; // 6 horas
    return ((tempoEmMilissegundos - milissegundos) / tempoEmMilissegundos) * 100;
  };

  return (
    <div className="box-usuario">
      <Box sx={{ backgroundColor: '#D3D3D3', padding: '6px', display: 'flex' }}>
        <Box sx={{ width: "0px", marginLeft: '5px', marginRight: '10px' }}>
          <Sidebar />
        </Box>
        <Container sx={{ flexGrow: 0.95, display: 'flex', justifyContent: 'center' }}>
          {user && (
            <span > 
              <table>
                <thead>
                  <tr>
                    <th>Usuário</th>
                    <th>Empresa</th>
                    <th>Tempo Restante de Sessão</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{user}</td>
                    <td>{emp}</td>
                    <td>
                      {formatTempoRestante(tempoRestante)}
                      <LinearProgress variant="determinate" value={100 - calcularProgressoTotal(tempoRestante)} />
                    </td>
                  </tr>
                </tbody>
              </table>
            </span>
          )}
        </Container>
      </Box>
    </div>
  );
};

export default Header;
