import React, { useState, useEffect } from "react";
import { TextField, Autocomplete } from "@mui/material/";
import { useSelector, useDispatch } from 'react-redux';
import styled from "@emotion/styled";
import { fetchPessoas, setRGAuxiliar, setAuxiliar } from "../../slices/auxiliarSlice";

const StyledH2 = styled.h2`
    display: block;
    font-size: 1em;
    font-weight: bold;
    padding-left: 15px;
`;

const StyledTextField = styled(TextField)`
  width: 380px;
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 500px;
`;

const StyledAutocomplete = styled(Autocomplete)`
    width: ${({ isSmallScreen }) => (isSmallScreen ? "100%" : "380px")};

    .MuiButtonBase-root {
        width: 40px;
    }
`;

const AuxiliaresPortaria = () => {
    const dispatch = useDispatch();
    const { auxiliar, rgAuxiliar, pessoas } = useSelector((state) => state.auxiliar);
    const [isSmallScreen, setIsSmallScreen] = useState(window.matchMedia("(max-width: 750px)").matches);


    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.matchMedia("(max-width: 750px)").matches);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        dispatch(fetchPessoas());
    }, [dispatch]);

    useEffect(() => {
        if (rgAuxiliar.length >= 8) {
        const selectedAuxiliar = pessoas.find((person) => person.rg === rgAuxiliar);
        if (selectedAuxiliar) {
            dispatch(setAuxiliar(selectedAuxiliar.nome));
        }
    }
    }, [rgAuxiliar, dispatch, pessoas]);

    useEffect(() => {
        const selectedAuxiliar = pessoas.find((person) => person.nome === auxiliar);
        if (selectedAuxiliar) {
            dispatch(setRGAuxiliar(selectedAuxiliar.rg));
        }
    
    }, [auxiliar, dispatch, pessoas]);

    const enviarAuxiliar = (event, newValue) => {
        dispatch(setAuxiliar(newValue));
    };

    const handleRGChange = (event) => {
        const value = event.target.value.replace(/\D/g, ''); // Remove non-digit characters
        if (value.length <= 11) {
            dispatch(setRGAuxiliar(value));
        }
    };



    return (
        <>
            <StyledH2>1.8 - Auxiliar:</StyledH2>
           
            <Label id="labelRGauxiliar">
                <TextField
                    id="rgAuxiliar"
                    name="rgAuxiliar"
                    placeholder="Digite o RG do auxiliar do condutor..."
                    required
                    value={rgAuxiliar}
                    sx={{
                        width: 300,
                    }}
                    onChange={handleRGChange}
                    multiline
                />
            </Label>

            <Label id="labelAuxiliar">
                <StyledAutocomplete
                    id="auxiliar"
                    freeSolo
                    options={pessoas.map((person) => person.nome)}
                    value={auxiliar}
                    onChange={(event, newValue) => enviarAuxiliar(event, newValue)}
                    onBlur={(event) => dispatch(setAuxiliar(event.target.value))}
                    renderInput={(params) => (
                        <StyledTextField
                            id="text-auxiliar"
                            name="text-auxiliar"
                            {...params}
                            label="Digite o nome do auxiliar"
                            required
                        />
                    )}
                    isSmallScreen={isSmallScreen}
                    isOptionEqualToValue={(option, value) => option === value}
                />
            </Label>
        </>
    );
};

export default AuxiliaresPortaria;