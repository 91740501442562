import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Axios } from '../config/axiosConf';

export const fetchCarros = createAsyncThunk('veiculos/fetchCarros', async () => {
  const response = await Axios.get('a60h7H-yK3gww2uXs3chAg');
  return response.data;
});

const veiculosSlice = createSlice({
  name: 'placaVeiculo',
  initialState: {
    placaVeiculo: null,
    numeroEixosVeiculo: null,
    carros: [],
  },
  reducers: {
    setPlacaVeiculo: (state, action) => {
      state.placaVeiculo = action.payload;
    },
    setNumeroEixosVeiculo: (state, action) => {
      state.numeroEixosVeiculo = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCarros.fulfilled, (state, action) => {
      state.pessoas = action.payload;
    });
  },
});

export const { setPlacaVeiculo, setNumeroEixosVeiculo } = veiculosSlice.actions;

export default veiculosSlice.reducer;
