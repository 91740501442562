import React, { useState, useEffect, useCallback } from "react";
import { Axios } from '../../config/axiosConf';
import {
  Button,
  Typography,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { ptBR } from '@mui/x-data-grid/locales';
import styled from "@emotion/styled";
import { styled as estilo } from "@mui/material/styles";
import CheckIcon from '@mui/icons-material/Check'; 

const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    border-radius: 16px;
    padding: 20px;
    max-width: 550px;
    background-color: #eeeeee;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    border: 3px solid #fff;
  }
`;

const StyledDialogTitle = styled(DialogTitle)`
  font-weight: bold;
  font-size: 1.25em;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  width: 60%;
  color: #2957A4;
`;

const StyledDialogContent = styled(DialogContent)`
  padding: 0px;
  margin-bottom: 20px;
  color: #2957A4;
`;

const StyledDialogActions = styled(DialogActions)`
  padding: 0px;
  justify-content: center;
  .MuiButton-root {
    margin: 0px 8px 0px 8px;
    color: #fff;
  }
`;

const StyledTitle = styled(Typography)`
  font-weight: bold;
  font-size: 1.5em;
  margin: 0em 0;
  background-color: #2957a4;
  color: #fff;
  padding: 10px;
  text-align: center;
  @media (max-width: 550px) {
    font-size: 1.2em;
  }
`;

const StyledOrange = styled(Typography)`
  background-color: #d66e20;
  padding-top: 3px;
  padding-bottom: 3px;
  @media (max-width: 550px) {
    font-size: 1.2em;
  }
`;

const StyledGreen = styled(Typography)`
  background-color: #7ca217;
  padding-top: 3px;
  padding-bottom: 3px;
  @media (max-width: 550px) {
    font-size: 1.2em;
  }
`;

const StyledDataGrid = estilo(DataGrid)(({ theme }) => ({
  '& .MuiDataGrid-columnHeader': {
    backgroundColor: '#D3D3D3',
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    fontWeight: 'bold',
  },
  '& .MuiDataGrid-scrollbarFiller': {
    backgroundColor: '#D3D3D3',
  },
  '& .MuiDataGrid-filler': {
    backgroundColor: '#D3D3D3',
  },
  '& .readonly-row': {
    backgroundColor: '#4caf50', 
  },

  '& .readonly-row:hover': {
    backgroundColor: '#388e3c', 
  }
}));

const ConfirmButton = styled(Button)`
  background-color: #4caf50;
  &:hover {
    background-color: #388e3c;
  }
`;

const CancelButton = styled(Button)`
  background-color: #f44336;
  &:hover {
    background-color: #c62828;
  }
`;

const TrocaEmpresa = () => {
  const [empresas, setEmpresas] = useState([]);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [selectedEmpresa, setSelectedEmpresa] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    const getEmpresas = async () => {
      try {
        const response = await Axios.get("/obter-empresas-detalhadas");
        setEmpresas(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    getEmpresas();
  }, []);

  const handleEmpresaSelect = useCallback((empresa) => {
    setSelectedEmpresa(empresa);
    setOpenDialog(true); 
  }, []);

  const handleConfirm = async () => {
    if (!selectedEmpresa) return;

    const codFuncionario = sessionStorage.getItem("codFuncionarios");

    try {
      const response = await Axios.post("/verificar-acesso-empresa", {
        codFuncionario: codFuncionario,
        codEmpresa: selectedEmpresa.codigo,
      });

      if (response.data.hasAccess) {
        sessionStorage.setItem("empresa", `${selectedEmpresa.codigo} - ${selectedEmpresa.sigla}`);
        window.location.reload();
      } else {
        alert("Você não tem acesso a esta empresa.");
      }
    } catch (error) {
      console.log(error);
      alert("Erro ao verificar acesso à empresa.");
    }

    setOpenDialog(false);
    setSelectedEmpresa(null); 
  };

  const handleCancel = () => {
    setOpenDialog(false);
    setSelectedEmpresa(null); 
  };

  useEffect(() => {
    if (empresas.length > 0) {
      const rows = empresas.map((empresa, index) => {
        const empresaLogada = sessionStorage.getItem("empresa") === `${empresa.codigo} - ${empresa.sigla}`;
        return {
          ...empresa,
          id: index,
          readonly: empresaLogada
        };
      });

      const columns = [
        { field: "codigo", headerName: "Código", width: 60 },
        { field: "cnpj", headerName: "CNPJ", width: 150 },
        { field: "razao_social", headerName: "Razão Social", width: 250 },
        { field: "sigla", headerName: "Sigla", width: 100 },
        {
          field: "acao",
          headerName: "",
          width: 100,
          renderCell: (params) => (
            params.row.readonly ? (
              <IconButton sx={{ color: "#D3D3D3" }} disabled>
                <CheckIcon />
              </IconButton>
            ) : (
              <IconButton
                sx={{ color: "#2957A4" }}
                onClick={() => handleEmpresaSelect(params.row)}
              >
                <CheckIcon />
              </IconButton>
            )
          ),
        },
      ];

      setRows(rows);
      setColumns(columns);
    }
  }, [empresas, handleEmpresaSelect]);

  return (
    <div>
      <StyledOrange />
      <StyledTitle id="title">Alterar Empresa</StyledTitle>
      <StyledGreen />
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
        <Box sx={{ height: 400, width: '95%', maxWidth: 700, marginTop: "20px", justifyContent: "center" }}>
          <StyledDataGrid
            rows={rows}
            columns={columns}
            rowHeight={40}
            pageSizeOptions={[5, 10, 20, 50]}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 20 },
              },
            }}
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            disableSelectionOnClick
            getRowClassName={(params) =>
              params.row.readonly ? 'readonly-row' : ''
            }
          />
        </Box>
      </div>

      <StyledDialog
        open={openDialog}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <StyledDialogTitle id="alert-dialog-title">
          Confirmar Alteração
        </StyledDialogTitle>
        <StyledDialogContent>
          <Typography id="alert-dialog-description">
            Deseja realmente alterar a empresa conectada para: <span style={{fontWeight: "bold"}}>{selectedEmpresa?.sigla}?</span>
          </Typography>
        </StyledDialogContent>
        <StyledDialogActions>
          <CancelButton onClick={handleCancel} color="primary" className="cancel">
            Cancelar
          </CancelButton>
          <ConfirmButton onClick={handleConfirm} color="primary" className="confirm" autoFocus>
            Confirmar
          </ConfirmButton>
        </StyledDialogActions>
      </StyledDialog>
    </div>
  );
};

export default TrocaEmpresa;
