import React, { useState, useEffect } from "react";
import Checkboxes from "../../../components/Checkboxes/Checkboxes";
import CheckboxTodas from "../../../components/Checkboxes/CheckboxTodas";
import styled from "@emotion/styled";
import { Chart, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import {
  Typography,
  Button,
  Paper,
  CircularProgress,
  Tab,
  Tabs,
} from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { AiOutlineSearch } from "react-icons/ai";
import Data from "../../../components/DataPickers/Data";
import { Axios } from "../../../config/axiosConf";
import CheckboxAmb from "../../../components/Checkboxes/CheckboxAmb";
import logo from "../../../assets/Logo-Grupo-Sumatex.png";
import logo_sumatex from "../../../assets/logo.png";
import logo_ambiental from "../../../assets/Logo-Sumatex-Ambiental.png";
import logo_agro from "../../../assets/Logo-Sumatex-Agro.png";
import logo_lorenvel from "../../../assets/Logo-Lorenvel.png";
import logo_cesbra from "../../../assets/Logo-Cesbra.png";

import "../../../styles/AnaliseGerencial.css";

Chart.register(ArcElement, Tooltip, Legend);

const SaveButton = styled(Button)`
  height: 30px;
  width: 150px;
  color: #fff;
  background-color: #2957a4;
  border: 1px solid rgba(0, 0, 0, 0.2);
  &:hover {
    background-color: #1f437d;
  }
`;

const ImprimirButton = styled(Button)`
  height: 30px;
  padding-left: 50px;
  padding-right: 50px;
  width: 120px;
  color: #fff;
  background-color: #a0a0a0;
  border: 1px solid rgba(0, 0, 0, 0.2);
  &:hover {
    background-color: #808080;
  }

  @media (max-width: 550px) {
    width: 80px;
    font-size: 0.8em;
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  width: 90%;
  gap: 16px;
  padding: 24px;
  background-color: #f5f5f5;
  border-radius: 8px;
  height: 100%;
  margin: 50px;

  @media (max-width: 550px) {
    width: 100%;
    margin: 0px;
    padding: 16px;
  }
`;

const StyledTitle = styled(Typography)`
  font-weight: bold;
  font-size: 1.5em;
  margin: 0em 0;
  background-color: #2957a4;
  color: #fff;
  padding: 10px;
  text-align: center;
  @media (max-width: 550px) {
    font-size: 1.2em;
  }
`;

const StyledOrange = styled(Typography)`
  background-color: #d66e20;
  padding-top: 3px;
  padding-bottom: 3px;
  @media (max-width: 550px) {
    font-size: 1.2em;
  }
`;

const StyledGreen = styled(Typography)`
  background-color: #7ca217;
  padding-top: 3px;
  padding-bottom: 3px;
  @media (max-width: 550px) {
    font-size: 1.2em;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 16px;
  margin-left: 25px;

  @media (max-width: 550px) {
    flex-direction: column;
    align-items: center;
    margin-left: 0;
  }
`;

const ResultadosTitle = styled.h2`
  margin: 0;
  font-size: 2em;
  color: #1f437d;
  text-align: center;
  text-transform: uppercase;
  padding: 10px;
  border-radius: 10px;
  @media (max-width: 550px) {
    font-size: 1.5em;
  }
`;

const ResultadosContainer = styled(Paper)`
  margin-top: 20px;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  @media (max-width: 550px) {
    padding: 10px;
  }
`;

const DataGridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;

  @media (max-width: 550px) {
    grid-template-columns: 1fr;
    gap: 10px;
  }
`;

const DataGridItem = styled.div`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;

  @media (max-width: 550px) {
    padding: 5px;
  }
`;

const LimparButton = styled(Button)`
  height: 30px;
  width: 100px;
  color: #fff;
  background-color: #ff6347;
  border: 1px solid rgba(0, 0, 0, 0.2);
  &:hover {
    background-color: #ff0000;
  }
  @media (max-width: 550px) {
    width: 80px;
    font-size: 0.8em;
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: flex-end;
  margin-top: 20px;
  padding: 20px;
  background-color: #EEEEEE;
  border-radius: 8px;
  @media (max-width: 550px) {
    justify-content: space-between;
    padding: 10px;
  }
`;

const AnaliseGerencial = () => {
  const [formattedDataInicial, setFormattedDataInicial] = useState(null);
  const [formattedDataFinal, setFormattedDataFinal] = useState(null);
  const Filial = sessionStorage.getItem("empresa");
  const [todas, setTodas] = useState("");
  const [ambiental, setAmbiental] = useState("S");
  const [FMT, setFMT] = useState("S");
  const [Cesbra, setCesbra] = useState("S");
  const [Farelo, setFarelo] = useState("S");
  const [Soja, setSoja] = useState("S");
  const [Milho, setMilho] = useState("S");
  const [Oleo, setOleo] = useState("S");
  const [prod_Cesbra, setProdCesbra] = useState("S");
  const [baixa_Comercial, setBaixaComercial] = useState("S");
  const [Sumatex, setSumatex] = useState("S");
  const [Rep, setRep] = useState("S");
  const [Prog, setProg] = useState(null);
  const [Cor, setCor] = useState("S");
  const [mostrarResultados, setMostrarResultados] = useState(false);
  const [Fat, setFat] = useState("S");
  const [isLoading, setIsLoading] = useState(false);
  const [Ent, setEnt] = useState("S");
  useState(null);
  const [resultados, setResultados] = useState([]);
  const [consultaSelecionada, setConsultaSelecionada] = useState("relatorio");

  function formatarValor(valor) {
    if (valor === null || valor === undefined) {
      return "";
    }

    if (typeof valor !== "number") {
      return valor;
    }

    return valor.toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }

  const grupo = sessionStorage.getItem("grupo");
  const grupoGerente = ["8f498e6aabb107de59d0ad2177f6d882"];
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const isUserInGroupGerente = grupoGerente.includes(grupo);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setOpenSnackbar(true);
  };

  const realizarConsulta = async () => {
    if (
      !formattedDataInicial ||
      !formattedDataFinal ||
      formattedDataInicial === "NaNNaNNaN" ||
      formattedDataFinal === "NaNNaNNaN"
    ) {
      showSnackbar("Insira uma data válida para continuar!", "error");
      setResultados([]);
      setMostrarResultados(false);
      return;
    }

    let procedure = "";

    switch (consultaSelecionada) {
      case "relatorio":
        procedure = "analise-gerencial";
        break;
      default:
        procedure = "analise-gerencial";
        break;
    }
    try {
      const data = {
        Data: {
          formattedDataInicial,
          formattedDataFinal,
        },
        Checkboxes: {
          FMT,
          Cesbra,
          Farelo,
          Soja,
          Oleo,
          Milho,
          prod_Cesbra,
          baixa_Comercial,
          Sumatex,
          Rep,
          Prog,
          Cor,
          Fat,
          Ent,
        },
        Filial,
        CheckboxTodas: {
          todas,
        },
        CheckboxAmb: {
          ambiental,
        },
      };

      const config = {
        timeout: 45000,
      };

      setIsLoading(true);

      const resultados = await Axios.post(
        `financeiro/${procedure}`,
        data,
        config
      );
      console.log("Response Data:", resultados.data);
      setResultados(resultados.data);
      console.log(resultados);
      setMostrarResultados(true);
      setIsLoading(false);
    } catch (error) {
      console.error("Erro ao realizar a consulta:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (resultados.length > 0) {
      setMostrarResultados(true);
    }
  }, [resultados]);

  const handleLimparResultados = () => {
    setResultados([]);
    setMostrarResultados(false);
  };

  const handleConsultaChange = (event, newValue) => {
    setConsultaSelecionada(newValue);
  };

  const dataForPieChart = (labels, values) => ({
    labels,
    datasets: [
      {
        data: values,
        backgroundColor: [
          "#D66E20",
          "#7CA217",
          "#FFCE56",
          "#2957A4",
          "#9966FF",
          "#E00090",
        ],
        hoverBackgroundColor: [
          "#D66E20",
          "#7CA217",
          "#FFCE56",
          "#2957A4",
          "#9966FF",
          "#E00090",
        ],
        borderWidth: 1,
      },
    ],
  });

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return context.label + ': ' + context.raw;
          },
        },
      },
    },
    elements: {
      arc: {
        borderWidth: 2, 
        offset: 10, 
      },
    },
    layout: {
      padding: 20,
    },
  };

  function desformatarData(data) {
    if (!data) return null;

    const ano = data.slice(0, 4);
    const mes = data.slice(4, 6);
    const dia = data.slice(6, 8);

    return `${dia}/${mes}/${ano}`;
  }

  const handlePrintResults = () => {
    const Filial = sessionStorage.getItem("empresa");

    const printContent = `
      <html>
        <link href='https://fonts.googleapis.com/css?family=Poppins' rel='stylesheet'>
        <link href='https://fonts.googleapis.com/css?family=Inter' rel='stylesheet'>
        <link href='https://fonts.googleapis.com/css?family=Istok Web' rel='stylesheet'>
        <head>
          <title>S.I.G.A - Grupo Sumatex</title>
          <style>
            body { font-family: 'Istok Web', Inter; margin: 0; padding: 20px; }
            h1 { text-align: center; font-size: 24px; }
            h2 img { height: 40px; margin-right: 10px; }
            table { width: 100%; border-collapse: collapse; margin-bottom: 10px; }
            th { border: 1px solid #000; padding: 8px; text-align: left; width: 75%; }
            td { border: 1px solid #000; padding: 8px; text-align: left; width: 25%; }
            .chart-page { page-break-before: always; }
            .section-title { font-size: 18px; margin: 20px 0 10px; text-align: center; }
            .options-container {
            display: flex;
            flex-wrap: wrap;
            }
            .option-item {
            flex: 1 1 33.3333333333%; /* Ocupa cerca de 30% do espaço disponível */
            box-sizing: border-box;
            padding: 10px;
            }
            .option-item p {
            margin: 0;
            font-size: 0.8rem;
            }
          </style>
        </head>
        <body>
          <h2>
            ${Filial === "1 - MAT"
        ? `<img src="${logo_sumatex}" alt="Logo">`
        : Filial === "7 - CES"
          ? `<img src="${logo_cesbra}" alt="Logo">`
          : Filial === "2 - AMB"
            ? `<img src="${logo_ambiental}" alt="Logo">`
            : Filial === "9 - LOG"
              ? `<img src="${logo_lorenvel}" alt="Logo">`
              : Filial === "5 - FMT"
                ? `<img src="${logo_agro}" alt="Logo">`
                : `<img src="${logo}" alt="Logo">`
      }
          </h2>
          <h1>Análise Gerencial</h1>
          <div>
            <p style="text-align: center;">
              <strong>Data:</strong> ${desformatarData(
        formattedDataInicial
      )} a ${desformatarData(formattedDataFinal)}
            </p>
            ${resultados
        .map(
          (resultado, index) => `

              <table>
                <tr><th style = "color: blue;">Total Geral</th><td>R$ ${formatarValor(
            resultado.FATURAMENTO_TOTAL_SUMATEX
          )}</td></tr>
                <tr><th style = "color: green;">Total Comissionado</th><td>R$ ${formatarValor(
            resultado.FATURAMENTO_COM_COMISSAO_SUMATEX
          )}</td></tr>
                <tr><th style = "color: red;">Total Sem Comissão</th><td>R$ ${formatarValor(
            resultado.FATURAMENTO_SEM_COMISSAO_SUMATEX
          )}</td></tr>
              </table>

              <table>
                <tr><th style = "color: blue;">Total Privado</th><td>R$ ${formatarValor(
            resultado.VALOR_PRIVADO_TOTAL
          )}</td></tr>
                <tr><th style = "color: green;">Privado Comissionado</th><td>R$ ${formatarValor(
            resultado.VALOR_PRIVADO_COM_COMISSAO
          )}</td></tr>
                <tr><th style = "color: red;">Privado Sem Comissão</th><td>R$ ${formatarValor(
            resultado.VALOR_PRIVADO_SEM_COMISSAO
          )}</td></tr>
              </table>

              <table>
                <tr><th style = "color: blue;">Total Concorrência</th><td>R$ ${formatarValor(
            resultado.VALOR_CONCORRENCIA_TOTAL
          )}</td></tr>
                <tr><th style = "color: green;">Concorrência Comissionado</th><td>R$ ${formatarValor(
            resultado.VALOR_CONCORRENCIA_COM_COMISSAO
          )}</td></tr>
                <tr><th style = "color: red;">Concorrência Sem Comissão</th><td>R$ ${formatarValor(
            resultado.VALOR_CONCORRENCIA_SEM_COMISSAO
          )}</td></tr>
              </table>

              <table>
                <tr><th style = "color: blue;">Total Reservado</th><td>R$ ${formatarValor(
            resultado.VALOR_RESERVADO_TOTAL
          )}</td></tr>
                <tr><th style = "color: green;">Reservado Comissionado</th><td>R$ ${formatarValor(
            resultado.VALOR_RESERVADO_COM_COMISSAO
          )}</td></tr>
                <tr><th style = "color: red;">Reservado Sem Comissão</th><td>R$ ${formatarValor(
            resultado.VALOR_RESERVADO_SEM_COMISSAO
          )}</td></tr>
              </table> 

              <table> 
                <tr><th style = "color: blue;">Total Ambiental</th><td>R$ ${formatarValor(
            resultado.VALOR_AMBIENTAL_TOTAL
          )}</td></tr>
                <tr><th style = "color: green;">Ambiental Comissionado</th><td>R$ ${formatarValor(
            resultado.VALOR_AMBIENTAL_COM_COMISSAO
          )}</td></tr>
                <tr><th style = "color: red;">Ambiental Sem Comissão</th><td>R$ ${formatarValor(
            resultado.VALOR_AMBIENTAL_SEM_COMISSAO
          )}</td></tr>
              </table>
            `
        )
        .join("")} 
      
  <div class="options-container">
    ${FMT === "S"
        ? `<div class="option-item"><p><strong>Retirar FMT:</strong> ${FMT}im</p></div>`
        : ""
      }
    ${Cesbra === "S"
        ? `<div class="option-item"><p><strong>Retirar Cesbra:</strong> ${Cesbra}im</p></div>`
        : ""
      }
    ${Farelo === "S"
        ? `<div class="option-item"><p><strong>Retirar Farelo:</strong> ${Farelo}im</p></div>`
        : ""
      }
    ${Soja === "S"
        ? `<div class="option-item"><p><strong>Retirar Soja:</strong> ${Soja}im</p></div>`
        : ""
      }
    ${Oleo === "S"
        ? `<div class="option-item"><p><strong>Retirar Oleo:</strong> ${Oleo}im</p></div>`
        : ""
      }
    ${Milho === "S"
        ? `<div class="option-item"><p><strong>Retirar Milho:</strong> ${Milho}im</p></div>`
        : ""
      }
    ${prod_Cesbra === "S"
        ? `<div class="option-item"><p><strong>Retirar Produtos Cesbra:</strong> ${prod_Cesbra}im</p></div>`
        : ""
      }
    ${baixa_Comercial === "S"
        ? `<div class="option-item"><p><strong>Considerar Baixa Comercial:</strong> ${baixa_Comercial}im</p></div>`
        : ""
      }
    ${Sumatex === "S"
        ? `<div class="option-item"><p><strong>Retirar Sumatex:</strong> ${Sumatex}im</p></div>`
        : ""
      }
    ${Rep === "S"
        ? `<div class="option-item"><p><strong>Retirar Rep:</strong> ${Rep}im</p></div>`
        : ""
      }
    ${Prog === "S"
        ? `<div class="option-item"><p><strong>Considerar Programados:</strong> ${Prog}im</p></div>`
        : ""
      }
    ${Cor === "S"
        ? `<div class="option-item"><p><strong>Retirar Corantes:</strong> ${Cor}im</p></div>`
        : ""
      }
    ${Fat === "S"
        ? `<div class="option-item"><p><strong>Geram Faturamento:</strong> ${Fat}im</p></div>`
        : ""
      }
    ${Ent === "S"
        ? `<div class="option-item"><p><strong>Desconsiderar Entradas:</strong> ${Ent}im</p></div>`
        : ""
      }
    ${ambiental === "S"
        ? `<div class="option-item"><p><strong>Remover Ambiental:</strong> ${ambiental}im</p></div>`
        : ""
      }
    ${todas === "S"
        ? `<div class="option-item"><p><strong>Todas as empresas:</strong> ${todas}im</p></div>`
        : ""
      }
    
</div>
          </div>
        </body>
      </html>
    `;

    const printWindow = window.open("", "", "width=800,height=600");
    if (printWindow) {
      printWindow.document.open();
      printWindow.document.write(printContent);
      printWindow.document.close();
     
      printWindow.onload = () => {
        printWindow.print();
  
        setTimeout(() => {}, 1000);
      };
    } else {
      alert(
        "Erro ao abrir a janela de impressão. Verifique as configurações do seu navegador."
      );
    }
  };

  return (
    <div>
      <StyledOrange />
      <StyledTitle>Análise Gerencial</StyledTitle>
      <StyledGreen />
      <div>
        {isLoading && (
          <div
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 9999,
            }}
          >
            <CircularProgress />
          </div>
        )}
        <FormContainer>
          <Data
            formattedDataInicial={formattedDataInicial}
            formattedDataFinal={formattedDataFinal}
            setFormattedDataInicial={setFormattedDataInicial}
            setFormattedDataFinal={setFormattedDataFinal}
          />

          <Checkboxes
            FMT={FMT}
            Cesbra={Cesbra}
            Farelo={Farelo}
            Soja={Soja}
            Oleo={Oleo}
            Milho={Milho}
            prod_Cesbra={prod_Cesbra}
            baixa_Comercial={baixa_Comercial}
            Sumatex={Sumatex}
            Rep={Rep}
            Prog={Prog}
            Cor={Cor}
            Fat={Fat}
            Ent={Ent}
            setFMT={setFMT}
            setCesbra={setCesbra}
            setFarelo={setFarelo}
            setSoja={setSoja}
            setOleo={setOleo}
            setMilho={setMilho}
            setProdCesbra={setProdCesbra}
            setBaixaComercial={setBaixaComercial}
            setSumatex={setSumatex}
            setRep={setRep}
            setProg={setProg}
            setCor={setCor}
            setFat={setFat}
            setEnt={setEnt}
          />

          {isUserInGroupGerente && (
            <div>
              <CheckboxTodas todas={todas} setTodas={setTodas} />
              <CheckboxAmb ambiental={ambiental} setAmbiental={setAmbiental} />
            </div>
          )}

          <ButtonContainer>
            <SaveButton onClick={realizarConsulta}>
              <AiOutlineSearch />
              ⠀Calcular
            </SaveButton>
          </ButtonContainer>
        </FormContainer>
        <Tabs
          value={consultaSelecionada}
          onChange={handleConsultaChange}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          aria-label="scrollable force tabs example"
        >
          <Tab label="Geral" value="relatorio" />
          <Tab label="Gráficos" value="graficos" />
        </Tabs>

        {mostrarResultados && consultaSelecionada === "relatorio"
          ? resultados.map((resultado, index) => (
            <ResultadosContainer key={index}>
              <ResultadosTitle>Resultados</ResultadosTitle>
              <ButtonWrapper>
                <LimparButton
                  onClick={handleLimparResultados}
                  variant="contained"
                  color="secondary"
                >
                  Limpar
                </LimparButton>

                <ImprimirButton
                  onClick={handlePrintResults}
                  variant="contained"
                  color="primary"
                >
                  Imprimir
                </ImprimirButton>
              </ButtonWrapper>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <DataGridItem>
                  <DataGridItem>
                    <Typography variant="h6" style={{ color: "blue" }}>
                      Total Geral: R${" "}
                      {formatarValor(resultado.FATURAMENTO_TOTAL_SUMATEX)}
                    </Typography>
                  </DataGridItem>
                  <DataGridItem>
                    <Typography variant="h6" style={{ color: "green" }}>
                      Total Comissionado: R${" "}
                      {formatarValor(
                        resultado.FATURAMENTO_COM_COMISSAO_SUMATEX
                      )}
                    </Typography>
                  </DataGridItem>
                  <DataGridItem>
                    <Typography variant="h6" style={{ color: "red" }}>
                      Total Sem Comissão: R${" "}
                      {formatarValor(
                        resultado.FATURAMENTO_SEM_COMISSAO_SUMATEX
                      )}
                    </Typography>
                  </DataGridItem>
                </DataGridItem>
              </div>
              <br />
              <DataGridContainer>
                <DataGridItem>
                  <DataGridItem>
                    <Typography style={{ color: "blue" }}>
                      Total Privado: R${" "}
                      {formatarValor(resultado.VALOR_PRIVADO_TOTAL)}
                    </Typography>
                  </DataGridItem>
                  <DataGridItem>
                    <Typography style={{ color: "green" }}>
                      Privado Comissionado: R${" "}
                      {formatarValor(resultado.VALOR_PRIVADO_COM_COMISSAO)}
                    </Typography>
                  </DataGridItem>
                  <DataGridItem>
                    <Typography style={{ color: "red" }}>
                      Privado Sem Comissão: R${" "}
                      {formatarValor(resultado.VALOR_PRIVADO_SEM_COMISSAO)}
                    </Typography>
                  </DataGridItem>
                </DataGridItem>
                <DataGridItem>
                  <DataGridItem>
                    <Typography style={{ color: "blue" }}>
                      Total Óleo: R$ 0,00
                    </Typography>
                  </DataGridItem>
                  <DataGridItem>
                    <Typography style={{ color: "green" }}>
                      Óleo Comissionado: R$ 0,00
                    </Typography>
                  </DataGridItem>
                  <DataGridItem>
                    <Typography style={{ color: "red" }}>
                      Óleo Sem Comissão: R$ 0,00
                    </Typography>
                  </DataGridItem>
                </DataGridItem>
                <DataGridItem>
                  <DataGridItem>
                    <Typography style={{ color: "blue" }}>
                      Total Concorrência: R${" "}
                      {formatarValor(resultado.VALOR_CONCORRENCIA_TOTAL)}
                    </Typography>
                  </DataGridItem>
                  <DataGridItem>
                    <Typography style={{ color: "green" }}>
                      Concorrência Comissionado: R${" "}
                      {formatarValor(
                        resultado.VALOR_CONCORRENCIA_COM_COMISSAO
                      )}
                    </Typography>
                  </DataGridItem>
                  <DataGridItem>
                    <Typography style={{ color: "red" }}>
                      Concorrência Sem Comissão: R${" "}
                      {formatarValor(
                        resultado.VALOR_CONCORRENCIA_SEM_COMISSAO
                      )}
                    </Typography>
                  </DataGridItem>
                </DataGridItem>

                <DataGridItem>
                  <DataGridItem>
                    <Typography style={{ color: "blue" }}>
                      Total Farelo: R$ 0,00
                    </Typography>
                  </DataGridItem>
                  <DataGridItem>
                    <Typography style={{ color: "green" }}>
                      Farelo Comissionado: R$ 0,00
                    </Typography>
                  </DataGridItem>
                  <DataGridItem>
                    <Typography style={{ color: "red" }}>
                      Farelo Sem Comissão: R$ 0,00
                    </Typography>
                  </DataGridItem>
                </DataGridItem>

                <DataGridItem>
                  <DataGridItem>
                    <Typography style={{ color: "blue" }}>
                      Total Reservado: R${" "}
                      {formatarValor(resultado.VALOR_RESERVADO_TOTAL)}
                    </Typography>
                  </DataGridItem>
                  <DataGridItem>
                    <Typography style={{ color: "green" }}>
                      Reservado Comissionado: R${" "}
                      {formatarValor(resultado.VALOR_RESERVADO_COM_COMISSAO)}
                    </Typography>
                  </DataGridItem>
                  <DataGridItem>
                    <Typography style={{ color: "red" }}>
                      Reservado Sem Comissão: R${" "}
                      {formatarValor(resultado.VALOR_RESERVADO_SEM_COMISSAO)}
                    </Typography>
                  </DataGridItem>
                </DataGridItem>

                <DataGridItem>
                  <DataGridItem>
                    <Typography style={{ color: "blue" }}>
                      Total Ambiental: R${" "}
                      {formatarValor(resultado.VALOR_AMBIENTAL_TOTAL)}
                    </Typography>
                  </DataGridItem>
                  <DataGridItem>
                    <Typography style={{ color: "green" }}>
                      Ambiental Comissionado: R${" "}
                      {formatarValor(resultado.VALOR_AMBIENTAL_COM_COMISSAO)}
                    </Typography>
                  </DataGridItem>
                  <DataGridItem>
                    <Typography style={{ color: "red" }}>
                      Ambiental Sem Comissão: R${" "}
                      {formatarValor(resultado.VALOR_AMBIENTAL_SEM_COMISSAO)}
                    </Typography>
                  </DataGridItem>
                </DataGridItem>
              </DataGridContainer>
            </ResultadosContainer>
          ))
          : resultados.length > 0 && consultaSelecionada === "graficos"
            ? resultados.map((resultado, index) => (
              <div key={index}>
                <br></br>
                <br></br>
                <div className="charts-wrapper">
                  <div className="containerBox">
                    <Typography className="typographyChart" variant="h6">
                      Faturado
                    </Typography>
                    <div className="chart-container">
                      <Pie
                        data={dataForPieChart(
                          [
                            "Total Privado",
                            "Total Concorrência",
                            "Total Reservado",
                            "Total Ambiental",
                            "Total Óleo",
                            "Total Farelo",
                          ],
                          [
                            resultado.VALOR_PRIVADO_TOTAL,
                            resultado.VALOR_CONCORRENCIA_TOTAL,
                            resultado.VALOR_RESERVADO_TOTAL,
                            resultado.VALOR_AMBIENTAL_TOTAL,
                            0, // Total Óleo
                            0, // Total Farelo
                          ]
                        )}
                        options={options}
                      />
                    </div>
                  </div>

                  <div className="containerBox">
                    <Typography className="typographyChart" variant="h6">
                      Comissionado e Sem Comissão
                    </Typography>
                    <div className="chart-container">
                      <Pie
                        data={dataForPieChart(
                          ["Total Comissionado", "Total Sem Comissão"],
                          [
                            resultado.FATURAMENTO_COM_COMISSAO_SUMATEX,
                            resultado.FATURAMENTO_SEM_COMISSAO_SUMATEX,
                          ]
                        )}
                        options={options}
                      />
                    </div>
                  </div>

                  <div className="containerBox">
                    <Typography className="typographyChart" variant="h6">
                      Privado
                    </Typography>
                    <div className="chart-container">
                      <Pie
                        data={dataForPieChart(
                          ["Privado Comissionado", "Privado Sem Comissão"],
                          [
                            resultado.VALOR_PRIVADO_COM_COMISSAO,
                            resultado.VALOR_PRIVADO_SEM_COMISSAO,
                          ]
                        )}
                        options={options}
                      />
                    </div>
                  </div>

                  <div className="containerBox">
                    <Typography className="typographyChart" variant="h6">
                      Concorrência
                    </Typography>
                    <div className="chart-container">
                      <Pie
                        data={dataForPieChart(
                          [
                            "Concorrência Comissionado",
                            "Concorrência Sem Comissão",
                          ],
                          [
                            resultado.VALOR_CONCORRENCIA_COM_COMISSAO,
                            resultado.VALOR_CONCORRENCIA_SEM_COMISSAO,
                          ]
                        )}
                        options={options}
                      />
                    </div>
                  </div>

                  <div className="containerBox">
                    <Typography className="typographyChart" variant="h6">
                      Reservado
                    </Typography>
                    <div className="chart-container">
                      <Pie
                        data={dataForPieChart(
                          ["Reservado Comissionado", "Reservado Sem Comissão"],
                          [
                            resultado.VALOR_RESERVADO_COM_COMISSAO,
                            resultado.VALOR_RESERVADO_SEM_COMISSAO,
                          ]
                        )}
                        options={options}
                      />
                    </div>
                  </div>

                  <div className="containerBox">
                    <Typography className="typographyChart" variant="h6">
                      Ambiental
                    </Typography>
                    <div className="chart-container">
                      <Pie
                        data={dataForPieChart(
                          ["Ambiental Comissionado", "Ambiental Sem Comissão"],
                          [
                            resultado.VALOR_AMBIENTAL_COM_COMISSAO,
                            resultado.VALOR_AMBIENTAL_SEM_COMISSAO,
                          ]
                        )}
                        options={options}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))
            : null}
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={handleCloseSnackbar}
            severity={snackbarSeverity}
          >
            {snackbarMessage}
          </MuiAlert>
        </Snackbar>
      </div>
    </div>
  );
};

export default AnaliseGerencial;
