import React, { useState, useEffect, useRef } from "react";
import { Axios } from "../../../config/axiosConf";
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { DataGrid } from "@mui/x-data-grid";
import { ptBR } from '@mui/x-data-grid/locales';
import MesAno from "../../../components/AutoCompletes/MesAnoComplete";
import styled from "@emotion/styled";
import { styled as Estilo, darken, lighten } from "@mui/material/styles";
import { Typography, Button, CircularProgress, Box, useMediaQuery, TextField, IconButton} from "@mui/material";
import Snackbar from '@mui/material/Snackbar';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import MuiAlert from '@mui/material/Alert';
import { AiOutlineSearch } from "react-icons/ai";
import logo from "../../../assets/Logo-Grupo-Sumatex.png";
import logo_sumatex from "../../../assets/logo.png";
import logo_ambiental from "../../../assets/Logo-Sumatex-Ambiental.png";
import logo_agro from "../../../assets/Logo-Sumatex-Agro.png";
import logo_lorenvel from "../../../assets/Logo-Lorenvel.png";
import logo_cesbra from "../../../assets/Logo-Cesbra.png";
import { CiEdit } from "react-icons/ci";
import { Link, useNavigate } from "react-router-dom";
import Modal from "react-modal";
import "./Orcamento.css";

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  width: 90%;
  gap: 16px;
  padding: 24px;
  background-color: #f5f5f5;
  border-radius: 8px;
  height: 100%;
  margin: 50px;

  @media (max-width: 550px) {
    width: 100%;
    margin: 0px;
  }
`;

const StyledTitle = styled(Typography)`
  font-weight: bold;
  font-size: 1.5em;
  margin: 0em 0;
  background-color: #2957a4;
  color: #fff;
  padding: 10px;
  text-align: center;
  @media (max-width: 550px) {
    font-size: 1.2em;
  }
`;

const StyledOrange = styled(Typography)`
    background-color: #D66E20;
    padding-top: 3px;
    padding-bottom: 3px;
  @media (max-width: 550px) {
    font-size: 1.2em;
  }
`;

const StyledGreen = styled(Typography)`
    background-color: #7CA217;
    padding-top: 3px;
    padding-bottom: 3px;
  @media (max-width: 550px) {
    font-size: 1.2em;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 16px;
  margin-left: 25px;
`;
const ResultadosTitle = styled.h2`
  margin: 0;
  font-size: 2em; /* Aumenta o tamanho da fonte */
  color: #1f437d; /* Define uma cor personalizada (laranja) */
  text-align: center; /* Centraliza o texto */
  text-transform: uppercase; /* Converte o texto em maiúsculas */
  padding: 10px; /* Adiciona espaçamento interno */
  border-radius: 10px; /* Adiciona bordas arredondadas */
  @media (max-width: 550px) {
    width: 80px;
    font-size: 1.5em;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-wrap: wrap; /* Permite que os botões quebrem para a próxima linha em telas estreitas */
  gap: 16px;
  justify-content: flex-end; /* Alinha os botões à direita */
  margin-top: 20px;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
`;

const SaveButton = styled(Button)`
  height: 30px;
  width: 150px;
  color: #fff;
  background-color: #2957a4;
  border: 1px solid rgba(0, 0, 0, 0.2);
  &:hover {
    background-color: #1f437d;
  }
`;

const LimparButton = styled(Button)`
  height: 30px;
  width: 100px;
  color: #fff;
  background-color: #ff6347;
  border: 1px solid rgba(0, 0, 0, 0.2);
  &:hover {
    background-color: #ff0000;
  }
  @media (max-width: 550px) {
    width: 80px;
    font-size: 0.8em;
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const ImprimirButton = styled(Button)`
  height: 30px;
  padding-left: 50px;
  padding-right: 50px;
  width: 120px;
  color: #fff;
  background-color: #a0a0a0;
  border: 1px solid rgba(0, 0, 0, 0.2);
  &:hover {
    background-color: #808080;
  }

  @media (max-width: 550px) {
    width: 80px;
    font-size: 0.8em;
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const GravaButton = styled(Button)`
  height: 30px;
  padding-left: 50px;
  padding-right: 50px;
  width: 120px;
  color: #fff;
  background-color: #2957A4;
  border: 1px solid rgba(0, 0, 0, 0.2);
  &:hover {
    background-color: #808080;
  }

  @media (max-width: 550px) {
    width: 80px;
    font-size: 0.8em;
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const XlsButton = styled(Button)`
  height: 30px;
  width: 120px;
  padding-left: 50px;
  padding-right: 50px;
  color: #fff;
  background-color: #71bc78;
  border: 1px solid rgba(0, 0, 0, 0.2);
  &:hover {
    background-color: #569b5c;
  }
  @media (max-width: 550px) {
    width: 80px;
    font-size: 0.8em;
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const ResultadosContainer = styled.div`
  margin-top: 20px;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  @media (max-width: 550px) {
    // Estilo responsivo para largura de tela até 360px
    padding: 10px; // Ajuste o espaçamento conforme necessário
  }
`;

const StyledTextField = styled(TextField)`
  margin-top: 20px;
  margin-left: 10px;
  float: left;

`;

const StyledLink = styled(Link)`
  word-wrap: break-word;
  color: #2957a4;
  margin-right: 5px;
  &:hover {
    text-decoration: underline;
  }
`;

const getBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.7) : lighten(color, 0.7);

const getHoverBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

const getSelectedBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.5) : lighten(color, 0.5);

const getSelectedHoverBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.4) : lighten(color, 0.4);

const StyledDataGrid = Estilo(DataGrid)(({ theme }) => ({
  "& .grid-azul": {
    backgroundColor: getBackgroundColor(
      theme.palette.info.main,
      theme.palette.mode
    ),
    "&:hover": {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.info.main,
        theme.palette.mode
      ),
    },
    "&.Mui-selected": {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.info.main,
        theme.palette.mode
      ),
      "&:hover": {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.info.main,
          theme.palette.mode
        ),
      },
    },
  },
  "& .grid-vermelho": {
    backgroundColor: getBackgroundColor(
      theme.palette.error.main,
      theme.palette.mode
    ),
    "&:hover": {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.error.main,
        theme.palette.mode
      ),
    },
    "&.Mui-selected": {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.error.main,
        theme.palette.mode
      ),
      "&:hover": {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.error.main,
          theme.palette.mode
        ),
      },
    },
  },
}));

const Orcamento = () => {
  const gridRef = useRef(null);
  const [mesSelecionado, setMesSelecionado] = useState(null);
  const [anoSelecionado, setAnoSelecionado] = useState(null);
  const [mesSelecionado2, setMesSelecionado2] = useState(null);
  const [anoSelecionado2, setAnoSelecionado2] = useState(null);
  const [blurBackground, setBlurBackground] = useState(false);
  const isSmallScreen = useMediaQuery("(max-width: 850px)");
  const [modalKey, setModalKey] = useState(0);
  const [dados, setDados] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [resultados, setResultados] = useState([]);
  const [pm, setPM] = useState(null);
  const [qtd, setQtd] = useState(null);
  const [obs, setObs] = useState([]);
  const [codProduto, setCodProduto] = useState([]);
  const [margem, setMargem] = useState(null);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(false);
  const [codClick, setCodClick] = useState([]);
  const [nomeClick, setNomeClick] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  // eslint-disable-next-line no-unused-vars
  const [consultaSelecionada, setConsultaSelecionada] = useState("relatorio");

  const navigate = useNavigate();

  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({
    id: false,
  });

  const [filterModel, setFilterModel] = React.useState({
    items: [
    ],
  });

  const applyFilters = (rows, filterModel) => {
    return rows.filter((row) => {
      return filterModel.items.every((filter) => {
        const { field, operator, value } = filter;
        const rowValue = row[field];
  
        // Convertendo rowValue e value para string para comparações de texto
        const rowValueStr = String(rowValue).toLowerCase();
        const valueStr = String(value).toLowerCase();
  
        switch (operator) {
          case '>':
            return rowValue > value;
          case '>=':
            return rowValue >= value;
          case '<':
            return rowValue < value;
          case '<=':
            return rowValue <= value;
          case 'equals':
          case '=': // Igualdade numérica ou textual
            return rowValueStr === valueStr;
          case '!=': // Diferença numérica ou textual
            return rowValue !== value;
          case 'contains': // Contém
            return rowValueStr.includes(valueStr);
          case 'startsWith': // Começa com
            return rowValueStr.startsWith(valueStr);
          case 'endsWith': // Termina com
            return rowValueStr.endsWith(valueStr);
          default:
            return true; // Se o operador não for suportado, não filtra.
        }
      });
    });
  };


  const handleLimparResultados = () => {
    setResultados([]);
    setRows([]);
    setColumns([]);
    setDados([]);
  };

  const handleClick = async (params) => {

    setIsLoading(true);
    setDados([]);
    setCodClick(params.COD_PRODUTO);
    setNomeClick(params.NOME);

    try {
        const response = await Axios.get(
          `consultas/orcamento-comercial/${
          params.COD_PRODUTO}/${anoSelecionado.id}/${mesSelecionado.id}`
        );

        setDados(response.data);

        if (response.data.quantidade !== null) {
          setQtd(response.data.quantidade);
        } else {
          setQtd(0);
        }
  
        if (response.data.margem !== null) {
          setMargem(response.data.margem);
        } else {
          setMargem(0);
        }
  
        if (response.data.preco_medio !== null) {
          setPM(response.data.preco_medio);
        } else {
          setPM(0);
        }

        if (response.data.obs !== null) {
          setObs(response.data.obs);
        } else {
          setObs([]);
        }
  
        if (response.data.cod_produto !== null) {
          setCodProduto(response.data.cod_produto);
        } else {
          setCodProduto([]);
        }
    } catch (error) {
      console.log(error);
    }

    setIsLoading(false);
  };

  const handleLinkClick = (params) => {
    openModal(params);
    handleClick(params);
  };

  const openModal = () => {
    if (!modalIsOpen) {
      // Verifique se o modal não está aberto
      setModalIsOpen(true);
      setBlurBackground(true);
      setModalKey((prevKey) => prevKey + 1);
    }
    
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setBlurBackground(false);
  };

  useEffect(() => {
    if (!modalIsOpen && resultados.length > 0) {
        handleCalcularClick();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalIsOpen]);

  const customComparator = (v1, v2) => {
    const value1 = parseFloat(v1.replace(",", "."));
    const value2 = parseFloat(v2.replace(",", "."));

    if (value1 < value2) {
      return -1;
    }
    if (value1 > value2) {
      return 1;
    }
    return 0;
  };

  const calcularValorTotal = () => {
    const valorTotal = applyFilters(rows, filterModel).reduce(
      (total, item) => total + (item.FATURAMENTO || 0),
      0
    );
    return formatarValor(valorTotal);
  };

  const calcularValorTotalPrev = () => {
    const valorTotal = applyFilters(rows, filterModel).reduce(
      (total, item) => total + (item.PREV_FATURAMENTO || 0),
      0
    );
    return formatarValor(valorTotal);
  };

  // Função para calcular a Quantidade Total
  const calcularQuantidadeTotal = () => {
    const quantidadeTotal = applyFilters(rows, filterModel).reduce(
      (total, item) => total + (item.QTD || 0),
      0
    );
    return formatarValor(quantidadeTotal);
  };

  const calcularQuantidadeTotalPrev = () => {
    const quantidadeTotal = applyFilters(rows, filterModel).reduce(
      (total, item) => total + (item.PREV_QTD || 0),
      0
    );
    return formatarValor(quantidadeTotal);
  };

  const realizarConsulta = async () => {
    let procedure = "";

    switch (consultaSelecionada) {
      case "relatorio":
        procedure = "orcamento-comercial";
        break;
      default :
        procedure = "orcamento-comercial";
        break
    }

    try {
      const data = {
        Previsao: {
          mesSelecionado,
          anoSelecionado,
        },
        Realizado: {
          mesSelecionado2,
          anoSelecionado2,
        },
      };

      const config = {
        timeout: 45000,
      };

      setIsLoading(true);

      const response = await Axios.post(
        `consultas/${procedure}`,
        data,
        config
      );

      if (response.data.length > 0) {
        const rows = response.data.map((item, index) => {
          const rowData = {};
          Object.keys(item).forEach((key) => {
            rowData[key] = item[key];
          });
          // Adicionando manualmente o campo "ID" a cada linha
          rowData["id"] = index + 1;
          return rowData;
        });

        const columns = Object.keys(response.data[0]).map((key) => ({
          field: key,
          ...(key === "COD_PRODUTO"
            ? {
                headerName: "Código",
                width: 100,
              }
            : {
                ...(key === "NOME"
                  ? {
                      headerName: "Produto",
                      width: 400,
                    }
                  : {
                      ...(key === "PREV_QTD"
                        ? {
                            headerName: "Prev. Qtd (Kg)",
                            width: 150,
                          }
                        : {
                            ...(key === "PREV_FATURAMENTO"
                              ? {
                                  headerName: "Prev. Fat. (R$)",
                                  width: 150,
                                }
                              : {
                                  ...(key === "PREV_PM"
                                    ? {
                                        headerName: "Prev. PM (R$)",
                                        width: 100,
                                      }
                                    : {
                                        ...(key === "PREV_MARGEM"
                                          ? {
                                              headerName: "Margem Min.",
                                              width: 100,
                                            }
                                          : {
                                              ...(key === "QTD"
                                                ? {
                                                    headerName:
                                                      "Quantidade (Kg)",
                                                    width: 150,
                                                  }
                                                : {
                                                    ...(key === "FATURAMENTO"
                                                      ? {
                                                          headerName: "Faturamento (R$)",
                                                          width: 150,
                                                        }
                                                      : {
                                                          ...(key ===
                                                          "PM"
                                                            ? {
                                                                headerName:
                                                                  "PM (R$)",
                                                                width: 100,
                                                              }
                                                            : {
                                                                ...(key === "REALIZADO_ORCADO"
                                                                  ? {
                                                                      headerName:
                                                                        "Dif (%)",
                                                                      width: 100,
                                                                    }
                                                                  : {
                                                                      ...(key === "VL_REL"
                                                                      ? {
                                                                          headerName:
                                                                            "(%)",
                                                                          width: 100,
                                                                        }
                                                                      : {
    
                                                                        }),
                                                                    }),
                                                              }),
                                                              
                                                        }),
                                                  }),
                                            }),
                                      }),
                                }),
                          }),
                    }),
              }),

          ...(key === "PREV_QTD" ||
          key === "PREV_FATURAMENTO" ||
          key === "PREV_PM" ||
          key === "PREV_MARGEM" ||
          key === "QTD" ||
          key === "FATURAMENTO" ||
          key === "PM" ||
          key === "VL_REL" ||
          key === "REALIZADO_ORCADO"
            ? {
              valueFormatter: (params) => {
                if (params === null) {
                  return 0
                } else {
                  return params.toLocaleString("pt-BR", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 3,
                  })
                }
                //return params.value.toFixed(2);
              },
            }
            : {}),
        }));

        columns.unshift({ field: "id", headerName: "ID", width: 10 });

        columns.unshift({
          field: "actions",
          headerName: "Ações",
          width: 90,
          renderCell: (params) => (
            <StyledLink
              onClick={() => handleLinkClick(params.row)} // Passando os dados da linha para a função openModal
              to={`/consultas/orcamento-comercial/${
                params.row.COD_PRODUTO}/${anoSelecionado.id}/${mesSelecionado.id}`}
            >
              <CiEdit style={{ width: "15px", height: "15px" }} />
            </StyledLink>
          ),
        });

        setRows(rows);
        setColumns(columns);
      }

      setTimeout(() => {
        gridRef.current.scrollIntoView({ behavior: 'smooth' });
        gridRef.current.focus();
      }, 100); 
    

      setResultados(response.data);
      setIsLoading(false);
      navigate("/consultas/orcamento-comercial/");

      //sortResults(response.data, colunaOrdenada);
    } catch (error) {
      console.error("Erro ao realizar a consulta:", error);
      setIsLoading(false);
    }
  };

  const realizarAtt = async () => {

    try {
      const data = {
        codProduto,
        mesSelecionado,
        anoSelecionado,
        pm,
        qtd,
        margem,
        obs
      };

      const config = {
        timeout: 45000,
      };

      setIsLoading(true);
// eslint-disable-next-line no-unused-vars
      const response = await Axios.post(
        `consultas/orcamento-comercial/atualiza`,
        data,
        config
      );
      

      setIsLoading(false);

      closeModal();

    } catch (error) {
      console.error("Erro ao realizar o cadastro:", error);
      setIsLoading(false);
    }

  };


  const handleCalcularClick = () => {
    realizarConsulta();
  };

  const handleAttClick = () => {
    realizarAtt().then(response => {
      setSnackbarSeverity("success");
      setSnackbarMessage('Atualizado com Sucesso!!');
      setOpenSnackbar(true);
    })
    .catch(error => {
      setSnackbarSeverity("error");
      setSnackbarMessage('Erro ao processar sua solicitação.');
      setOpenSnackbar(true);
    });
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  function formatarValor(valor) {
    if (valor === null || valor === undefined) {
      return '';
    }
    
    if (typeof valor !== "number") {
      return valor;
    }

    return valor.toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }

  const handlePrintResults = () => {
    const Filial = sessionStorage.getItem("empresa");
    const printContent = `
      <html>
        <head>
          <title>S.I.G.A - Grupo Sumatex</title>
          <style>
            /* Estilos para o relatório de impressão */
            body { font-family: Arial, sans-serif; margin: 0; padding: 0; }
            h1 {
              text-align: center;
              margin-bottom: 20px;
              font-size: 24px;
            }
            h2 img {
              height: 40px;
              margin-right: 10px;
            }
            table { 
              width: 100%; 
              border-collapse: 
              collapse; 
            }
            th, td { 
              border: 1px solid #000; 
              padding: 8px; 
              text-align: left; 
            }
          </style>
        </head>
        <body>
          <h2>
            ${
              Filial === "1 - MAT"
                ? `<img src="${logo_sumatex}" alt="Logo">`
                : Filial === "7 - CES"
                ? `<img src="${logo_cesbra}" alt="Logo">`
                : Filial === "2 - AMB"
                ? `<img src="${logo_ambiental}" alt="Logo">`
                : Filial === "9 - LOG"
                ? `<img src="${logo_lorenvel}" alt="Logo">`
                : Filial === "5 - FMT"
                ? `<img src="${logo_agro}" alt="Logo">`
                : `<img src="${logo}" alt="Logo">`
            }
            
            </h2>
           <h1>
              Orçamento Comercial
           </h1>
           <div class="filters">
              <p style="text-align: center;"><strong>Previsão: </strong> ${mesSelecionado.label + "/" + anoSelecionado.label}</strong></p>
              <p style="text-align: center;"><strong>Realizado: </strong> ${mesSelecionado2.label + "/" + anoSelecionado2.label}</strong></p>
           </div>
          <table>
            <thead>
              <tr>
                ${columns
                  .filter((coluna) => columnVisibilityModel[coluna.field] !== false)
                  .map((coluna) => `<th>${coluna.headerName}</th>`)
                  .join("")}
              </tr>
            </thead>
            <tbody>
              ${applyFilters(rows, filterModel)
                .map((item, index) => {
                  return `
                    <tr ${
                      index % 2 === 0
                        ? 'style="background-color: #f9f9f9;"'
                        : ""
                    }>
                      ${columns
                        .filter((coluna) => columnVisibilityModel[coluna.field] !== false)
                        .map((coluna) => {
                          return `<td>${formatarValor(
                            item[coluna.field]
                          )}</td>`;
                        })
                        .join("")}
                    </tr>
                  `;
                })
                .join("")}
            </tbody>
          </table>
           ${isExpanded ? `
          <div style="margin-top: 20px; display: grid; grid-template-columns: 1fr 1fr;">
            <div>
              <h2>Dados</h2>
                <div class="consolidated-data">
                    <div><p><strong>Valor Total Prev.: </strong> ${calcularValorTotalPrev()}</p></div>
                </div>
            </div>
            <div> 
                <div class="consolidated-data"> 
                    <div><p><strong>Valor Total: </strong> ${calcularValorTotal()}</p></div>
                </div>
            </div>
            <div> 
              <h2>⠀</h2>
                <div class="consolidated-data"> 
                    <div><p><strong>Quantidade Total Prev. (Kg): </strong> ${calcularQuantidadeTotalPrev()}</p></div>
                </div>
            </div>
            <div> 
              <div class="consolidated-data"> 
                  <div><p><strong>Quantidade Total (Kg): </strong> ${calcularQuantidadeTotal()}</p></div>
              </div>
            </div>
        </div> ` : ''}
      </body>
  </html>
    `;
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;
    const printWindow = window.open(
      "",
      "",
      `width=${screenWidth},height=${screenHeight}`
    );

    if (!printWindow) {
      alert(
        "Erro ao abrir a janela de impressão. Verifique as configurações do seu navegador."
      );
      return;
    }

    printWindow.document.open();
    printWindow.document.write(printContent);
    printWindow.document.close();

    printWindow.onload = () => {
      printWindow.print();

      setTimeout(() => {}, 1000);
    };
  };

  const handleGenerateXLS = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Resultados');
  
    const colunas = columns
      .filter((coluna) => columnVisibilityModel[coluna.field] !== false)
      .map((coluna) => coluna.headerName);
  
    const headerRow = worksheet.addRow(colunas);
  
    headerRow.eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'D3D3D3' },
      };
      applyThinBorder(cell); 
      cell.font = { bold: true };
    });
  
    applyFilters(rows, filterModel).forEach((item) => {
      const rowData = columns
        .filter((coluna) => columnVisibilityModel[coluna.field] !== false)
        .map((coluna) => item[coluna.field]);
      const row = worksheet.addRow(rowData);
      row.eachCell((cell) => {
        applyThinBorder(cell); 
      });
    });
  
    
    const range = worksheet.getSheetValues();
    for (let R = 1; R <= range.length; ++R) {
      const row = worksheet.getRow(R);
      for (let C = 1; C <= colunas.length; ++C) {
        const cell = row.getCell(C);
        applyThinBorder(cell); 
      }
    }
  
    const buffer = await workbook.xlsx.writeBuffer();
    const nomeDoArquivo = `resultados_orcamentovendas_P_${mesSelecionado.id}_${anoSelecionado.id}_R_${mesSelecionado2.id}_${anoSelecionado2.id}.xlsx`;
  
    saveWorkbook(buffer, nomeDoArquivo);
  };

  const applyThinBorder = (cell) => {
    cell.border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };
  };
  
  
  const saveWorkbook = async (buffer, nomeDoArquivo) => {
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, nomeDoArquivo); 
  };

  const [isExpanded, setIsExpanded] = useState(true);

  const toggleCollapse = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className={`content ${blurBackground ? "blur-background" : ""}`}>
      <StyledOrange />
      <StyledTitle>Orçamento Comercial</StyledTitle>
      <StyledGreen />

      <FormContainer>
        <div>
          {isLoading && (
            <div
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 9999,
              }}
            >
              <CircularProgress />
            </div>
          )}
          
          <div className="my-box">
            <Box sx={{border:'1px solid #2957A4', 
                      borderRadius:'8px', 
                      padding:'7px'}}>
              <legend style={{fontSize: "15px", marginLeft: "22px", 
                              fontWeight: "bold", textDecoration: "underline",
                              letterSpacing: "1px"}}>• Previsão</legend>
              <MesAno
                mesSelecionado={mesSelecionado}
                anoSelecionado={anoSelecionado}
                setMesSelecionado={setMesSelecionado}
                setAnoSelecionado={setAnoSelecionado}
              />
            </Box>
          </div>
          <br></br>
          <div className="my-box">
             <Box sx={{border:'1px solid #2957A4', 
                      borderRadius:'8px', 
                      padding:'7px'}}>
              <legend style={{fontSize: "15px", marginLeft: "22px", 
                              fontWeight: "bold", textDecoration: "underline",
                              letterSpacing: "1px"}}>• Realizado</legend>
              <MesAno
                mesSelecionado={mesSelecionado2}
                anoSelecionado={anoSelecionado2}
                setMesSelecionado={setMesSelecionado2}
                setAnoSelecionado={setAnoSelecionado2}
              />
            </Box>
          </div>

          <br></br>

          <ButtonContainer>
            <SaveButton onClick={handleCalcularClick}>
              <AiOutlineSearch />
              ⠀Calcular
            </SaveButton>
          </ButtonContainer>
        </div>
      </FormContainer>

      {resultados.length > 0 && (
        <ResultadosContainer ref={gridRef} id="resultados-container">
          <ResultadosTitle>Resultados</ResultadosTitle>
          <ButtonWrapper>
            <LimparButton
              onClick={handleLimparResultados}
              variant="contained"
              color="secondary"
            >
              Limpar
            </LimparButton>
            <ImprimirButton
              onClick={handlePrintResults}
              variant="contained"
              color="primary"
            >
              Imprimir
            </ImprimirButton>
            <XlsButton
              onClick={handleGenerateXLS}
              variant="contained"
              color="primary"
            >
              Gerar⠀XLS
            </XlsButton>
          </ButtonWrapper>
          <IconButton onClick={toggleCollapse}>
            {isExpanded ? <ExpandLess /> : <>
            <ExpandMore />
            <Typography
              variant="caption" 
              style={{
                marginLeft: 4, 
                color: "#6c757d", 
                fontSize: '0.9rem', 
              }}
            >
              Exibir Totalizadores
            </Typography>
          </>}
          </IconButton>
          {isExpanded && (

          <div
            style={{
              border: "5px solid #D3D3D3", // Cor e largura da borda
              padding: "10px", // Espaçamento interno
            }}
          >
            <Typography component="div">

                <div className="tb-result">
                  <table>
                      <tr>
                        <td style={{color:"#2957A4"}}>Valor Total Prev (R$)</td>
                        <td>{calcularValorTotalPrev()}</td>
                      </tr>
                      <tr>
                        <td style={{color:"#2957A4"}}>Valor Total (R$)</td>
                        <td>{calcularValorTotal()}</td>
                      </tr>
                      <tr>
                        <td style={{color:"#2957A4"}}>Quantidade Total Prev (Kg)</td>
                        <td>{calcularQuantidadeTotalPrev()}</td>
                      </tr>
                      <tr>
                        <td style={{color:"#2957A4"}}>Quantidade Total (Kg)</td>
                        <td>{calcularQuantidadeTotal()}</td>
                      </tr>
                  </table>
                </div>
            </Typography>
          </div>
          )}
          <br></br>
          <StyledDataGrid
            rows={rows}
            columns={columns}
            sortComparator={customComparator}
            sx={{
              boxShadow: 2,
              border: 2,
              borderColor: "primary.light",
              "& .MuiDataGrid-cell:hover": {
                color: "primary.main",
              },
            }}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 20 },
              },
            }}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) =>
              setColumnVisibilityModel(newModel)
            }
            filterModel={filterModel}
            onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            getRowClassName={(params) =>
              params.row.VL_REL >= 15 ? `grid-azul` : ``
            }
            pageSizeOptions={[5, 10, 15, 20]}
            disableSelectionOnClick
          />

          <Modal
            isOpen={modalIsOpen}
            key={modalKey}
            ariaHideApp={false}
            onRequestClose={closeModal}
            contentLabel="Detalhes do Pedido"
            className={`custom-modal ${isSmallScreen ? "isSmallScreen" : ""}`}
            overlayClassName="custom-overlay"
          >
            {dados ? (
              <div style={{ display: "flex"}}>
                <div
                  style={{
                    width: "100%"
                  }}
                >
                  <StyledTextField sx={{width: "40%"}}
                    label="Código"
                    size="small"
                    disabled={true}
                    value={codClick}
                  />

                  <StyledTextField sx={{width: "80%"}}
                    label="Nome"
                    size="small"
                    disabled={true}
                    value={nomeClick}
                  />

                  <StyledTextField sx={{width: "40%"}}
                    label="Ano"
                    size="small"
                    disabled={true}
                    value={anoSelecionado.id}
                  />

                  <StyledTextField sx={{width: "40%"}}
                    label="Mês"
                    size="small"
                    disabled={true}
                    value={mesSelecionado.id}
                  />

                  <StyledTextField sx={{width: "40%"}}
                    label="PM (R$)"
                    size="small"
                    value={pm}
                    variant="outlined"
                    inputProps={{
                      maxLength: 13,
                      type: "number",
                      pattern:"([0-9]{1,3}).([0-9]{1,3})"
                    }}
                    onChange={(e) => setPM(e.target.value)}
                  />

                  <StyledTextField sx={{width: "40%"}}
                    label="Quantidade (Kg)"
                    size="small"
                    value={qtd}
                    variant="outlined"
                    inputProps={{
                      maxLength: 13,
                      type: "number",
                      pattern:"([0-9]{1,3}).([0-9]{1,3})"
                    }}
                    onChange={(e) => setQtd(e.target.value)}
                  />

                  <StyledTextField sx={{width: "40%", marginBottom: "10px"}}
                    label="Margem Min. (%)"
                    size="small"
                    value={margem}
                    variant="outlined"
                    inputProps={{
                      maxLength: 13,
                      type: "number",
                      pattern:"([0-9]{1,3}).([0-9]{1,3})"
                    }}
                    onChange={(e) => setMargem(e.target.value)}
                  />   

                  <StyledTextField
                        id="outlined-textarea"
                        label="Obs"
                        value={obs}
                        fullWidth
                        multiline
                        rows={3}
                        onChange={(e) => setObs(e.target.value)}
                  />     
                </div>
              </div>
              
            ) : (
              <div
                style={{
                  marginLeft: "50%",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  zIndex: 9999,
                }}
              >
                <CircularProgress />
              </div>
            )}
            <br></br>
            <GravaButton sx={{marginRight:"5px"}} onClick={handleAttClick}>Gravar</GravaButton>
            <LimparButton onClick={closeModal}>Fechar</LimparButton>
          </Modal>

          <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose}>
            <MuiAlert elevation={6} variant="filled" onClose={handleSnackbarClose} severity={snackbarSeverity}>
              {snackbarMessage}
            </MuiAlert>
          </Snackbar>
        </ResultadosContainer>
      )}
    </div>
  );
};

export default Orcamento;

