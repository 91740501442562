import React, { useState, useEffect, useCallback } from "react";
import { RadioGroup, FormControlLabel, Radio } from "@mui/material/";

const RadioDataFinan = ({ tipoData, setTipoDataSelecionada }) => {
    const [isSmallScreen, setIsSmallScreen] = useState(window.matchMedia("(max-width: 750px)").matches);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.matchMedia("(max-width: 750px)").matches);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const trocarDeTipo = useCallback((event) => {
        setTipoDataSelecionada(event.target.value);
    }, [setTipoDataSelecionada]);

    return (
        <>
            <RadioGroup
                id="tipoData"
                required
                aria-label="userType"
                name="userType"
                value={tipoData}
                onChange={trocarDeTipo}
                row={!isSmallScreen} // Example: display as a row on larger screens
            >
                <FormControlLabel value="V" control={<Radio />} label="Vencimento" />
                <FormControlLabel value="E" control={<Radio />} label="Emissão" />
                <FormControlLabel value="P" control={<Radio />} label="Pagamento" />
            </RadioGroup>
        </>
    );

};

export default RadioDataFinan;
