import React, { useState, useEffect } from "react";
import { TextField, Autocomplete } from "@mui/material/";
import { useSelector, useDispatch } from "react-redux";
import styled from "@emotion/styled";
import {
  fetchPessoas,
  setRGCondutor,
  setCondutor,
} from "../../slices/condutorSlice";

const StyledH2 = styled.h2`
  display: block;
  font-size: 1em;
  font-weight: bold;
  padding-left: 15px;
`;

const StyledTextField = styled(TextField)`
  width: 380px;
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 500px;
`;

const StyledAutocomplete = styled(Autocomplete)`
  width: ${({ isSmallScreen }) => (isSmallScreen ? "100%" : "380px")};

  .MuiButtonBase-root {
    width: 40px;
  }
`;

const CondutoresPortaria = () => {
  const dispatch = useDispatch();
  const { condutor, rgCondutor, pessoas } = useSelector(
    (state) => state.condutor
  );
  const [isSmallScreen, setIsSmallScreen] = useState(
    window.matchMedia("(max-width: 750px)").matches
  );

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.matchMedia("(max-width: 750px)").matches);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    dispatch(fetchPessoas());
  }, [dispatch]);

  useEffect(() => {
    if (rgCondutor.length >= 8) {
      const selectedCondutor = pessoas.find((person) => person.rg === rgCondutor);
      if (selectedCondutor) {
        dispatch(setCondutor(selectedCondutor.nome));
      }
    }
  }, [rgCondutor, pessoas, dispatch]);
  
  useEffect(() => {

      const selectedCondutor = pessoas.find((person) => person.nome === condutor);
      if (selectedCondutor) {
        dispatch(setRGCondutor(selectedCondutor.rg));
      }
    
  }, [condutor, pessoas, dispatch]);

  const enviarCondutor = (event, newValue) => {
    dispatch(setCondutor(newValue));
  };

  const handleRGChange = (event) => {
    const value = event.target.value.replace(/\D/g, ''); // Remove non-digit characters
    if (value.length <= 11) {
        dispatch(setRGCondutor(value));
    }
};

  return (
    <>
      <StyledH2>1.7 - Condutor:</StyledH2>
      

      <Label id="labelRGcondutor">
        <TextField
          id="rgCondutor"
          name="rgCondutor"
          placeholder="Digite o RG do condutor..."
          required
          value={rgCondutor}
          sx={{
            width: 250,
          }}
          onChange={handleRGChange}
          multiline
        />
      </Label>

      <Label id="labelCondutor">
        <StyledAutocomplete
          id="condutor"
          freeSolo
          options={pessoas.map((person) => person.nome)}
          value={condutor}
          onChange={(event, newValue) => enviarCondutor(event, newValue)}
          onBlur={(event) => dispatch(setCondutor(event.target.value))}
          renderInput={(params) => (
            <StyledTextField
              {...params}
              id="text-condutor"
              name="text-condutor"
              label="Digite o nome do condutor"
              required
            />
          )}
          isSmallScreen={isSmallScreen}
          isOptionEqualToValue={(option, value) => option === value}
        />
      </Label>
    </>
  );
};

export default CondutoresPortaria;