// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sticky-row {
    position: sticky;
    top: 0; /* Fixar no topo da tabela */
    background-color: #f2f2f2; /* Cor de fundo desejada para o cabeçalho fixo */
    z-index: 1; /* Certifique-se de que o cabeçalho fixo apareça acima do conteúdo da tabela */
  }

  .sticky-table-head {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: #fff; /* Cor de fundo desejada para o cabeçalho fixo */
    box-shadow: 0px 5px 5px -5px rgba(0, 0, 0, 0.2); /* Opcional: adicione uma sombra para destacar o cabeçalho fixo */
  }`, "",{"version":3,"sources":["webpack://./src/styles/ConsultaGeralCompras.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,MAAM,EAAE,4BAA4B;IACpC,yBAAyB,EAAE,gDAAgD;IAC3E,UAAU,EAAE,8EAA8E;EAC5F;;EAEA;IACE,gBAAgB;IAChB,MAAM;IACN,UAAU;IACV,sBAAsB,EAAE,gDAAgD;IACxE,+CAA+C,EAAE,iEAAiE;EACpH","sourcesContent":[".sticky-row {\r\n    position: sticky;\r\n    top: 0; /* Fixar no topo da tabela */\r\n    background-color: #f2f2f2; /* Cor de fundo desejada para o cabeçalho fixo */\r\n    z-index: 1; /* Certifique-se de que o cabeçalho fixo apareça acima do conteúdo da tabela */\r\n  }\r\n\r\n  .sticky-table-head {\r\n    position: sticky;\r\n    top: 0;\r\n    z-index: 1;\r\n    background-color: #fff; /* Cor de fundo desejada para o cabeçalho fixo */\r\n    box-shadow: 0px 5px 5px -5px rgba(0, 0, 0, 0.2); /* Opcional: adicione uma sombra para destacar o cabeçalho fixo */\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
