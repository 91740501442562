// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1em;
}

.pagination button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin: 0.2em;
    padding: 0.3em 0.6em;
    font-size: 1em;
    color: #333;
    border-radius: 4px;
    transition: background-color 0.3s ease-in-out;
}

.pagination button:hover {
    background-color: #f0f0f0;
}

.pagination button.active {
    background-color: #007bff;
    color: #fff;
    font-weight: bold;
}

.pagination .arrow-button {
    font-size: 1.2em;
    padding: 0.2em 0.5em;
}

.iconCustomColor {
    color: #000000; /* Altere para a cor desejada */
}`, "",{"version":3,"sources":["webpack://./src/styles/Consulta.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,6BAA6B;IAC7B,YAAY;IACZ,eAAe;IACf,aAAa;IACb,oBAAoB;IACpB,cAAc;IACd,WAAW;IACX,kBAAkB;IAClB,6CAA6C;AACjD;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;IACzB,WAAW;IACX,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;IAChB,oBAAoB;AACxB;;AAEA;IACI,cAAc,EAAE,+BAA+B;AACnD","sourcesContent":[".pagination {\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    margin-top: 1em;\r\n}\r\n\r\n.pagination button {\r\n    background-color: transparent;\r\n    border: none;\r\n    cursor: pointer;\r\n    margin: 0.2em;\r\n    padding: 0.3em 0.6em;\r\n    font-size: 1em;\r\n    color: #333;\r\n    border-radius: 4px;\r\n    transition: background-color 0.3s ease-in-out;\r\n}\r\n\r\n.pagination button:hover {\r\n    background-color: #f0f0f0;\r\n}\r\n\r\n.pagination button.active {\r\n    background-color: #007bff;\r\n    color: #fff;\r\n    font-weight: bold;\r\n}\r\n\r\n.pagination .arrow-button {\r\n    font-size: 1.2em;\r\n    padding: 0.2em 0.5em;\r\n}\r\n\r\n.iconCustomColor {\r\n    color: #000000; /* Altere para a cor desejada */\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
