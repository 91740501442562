import React from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useMediaQuery } from "@mui/material";
import styled from "@emotion/styled";

const Unidade = ({ unidadeSelecionada, setUnidadeSelecionada }) => {
  const isSmallScreen = useMediaQuery("(max-width: 550px)");
  const StyledSelect = styled(Select)`
    width: ${isSmallScreen ? "90px" : "90px"};
    margin-left: 25px;
    height: 40px;
    padding-right: 0px;
    padding-left: 5px;
  `;
  const handleChange = (event) => {
    setUnidadeSelecionada(event.target.value);
  };

  return (
    <div style={{ display: "flex" }}>
      <div style={{ paddingTop: 15, fontWeight: "bold" }}>
        <p>Unidade:</p>
        <StyledSelect
          label="Unidade"
          value={unidadeSelecionada}
          onChange={handleChange}
        >
          <MenuItem value="KG">KG</MenuItem>
          <MenuItem value="LT">LT</MenuItem>
        </StyledSelect>
      </div>
    </div>
  );
};

export default Unidade;
