import React, { useState, useRef } from "react";
import { Axios } from "../../../config/axiosConf";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { DataGrid } from "@mui/x-data-grid";
import { ptBR } from "@mui/x-data-grid/locales";
import Clientes from "../../../components/AutoCompletes/Clientes";
import Produtos from "../../../components/AutoCompletes/Produtos";
import Atividades from "../../../components/AutoCompletes/Atividades";
import Vendedores from "../../../components/AutoCompletes/Vendedores";
import Areas from "../../../components/AutoCompletes/Areas";
import Cidades from "../../../components/AutoCompletes/Cidades";
import UFs from "../../../components/AutoCompletes/UFs";
import Grpds from "../../../components/AutoCompletes/Grpds";
import Transportadoras from "../../../components/AutoCompletes/Transportadoras";
import Checkboxes from "../../../components/Checkboxes/Checkboxes";
import CheckboxTodas from "../../../components/Checkboxes/CheckboxTodas";
import styled from "@emotion/styled";
import { styled as Estilo, darken, lighten } from "@mui/material/styles";
import { Typography, Button, CircularProgress, IconButton } from "@mui/material";
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import { AiOutlineSearch } from "react-icons/ai";
import Data from "../../../components/DataPickers/Data";
import "../../../styles/ConsultaGeralVendas.css";
import logo from "../../../assets/Logo-Grupo-Sumatex.png";
import logo_sumatex from "../../../assets/logo.png";
import logo_ambiental from "../../../assets/Logo-Sumatex-Ambiental.png";
import logo_agro from "../../../assets/Logo-Sumatex-Agro.png";
import logo_lorenvel from "../../../assets/Logo-Lorenvel.png";
import logo_cesbra from "../../../assets/Logo-Cesbra.png";

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  width: 90%;
  gap: 16px;
  padding: 24px;
  background-color: #f5f5f5;
  border-radius: 8px;
  height: 100%;
  margin: 50px;

  @media (max-width: 550px) {
    width: 100%;
    margin: 0px;
  }
`;

const StyledTitle = styled(Typography)`
  font-weight: bold;
  font-size: 1.5em;
  margin: 0em 0;
  background-color: #2957a4;
  color: #fff;
  padding: 10px;
  text-align: center;
  @media (max-width: 550px) {
    font-size: 1.2em;
  }
`;

const StyledOrange = styled(Typography)`
  background-color: #d66e20;
  padding-top: 3px;
  padding-bottom: 3px;
  @media (max-width: 550px) {
    font-size: 1.2em;
  }
`;

const StyledGreen = styled(Typography)`
  background-color: #7ca217;
  padding-top: 3px;
  padding-bottom: 3px;
  @media (max-width: 550px) {
    font-size: 1.2em;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 16px;
  margin-left: 25px;
`;
const ResultadosTitle = styled.h2`
  margin: 0;
  font-size: 2em;
  color: #1f437d;
  text-align: center;
  text-transform: uppercase;
  padding: 10px;
  border-radius: 10px;
  @media (max-width: 550px) {
    width: 80px;
    font-size: 1.5em;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: flex-end;
  margin-top: 20px;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
`;

const SaveButton = styled(Button)`
  height: 30px;
  width: 150px;
  color: #fff;
  background-color: #2957a4;
  border: 1px solid rgba(0, 0, 0, 0.2);
  &:hover {
    background-color: #1f437d;
  }
`;

const LimparButton = styled(Button)`
  height: 30px;
  width: 100px;
  color: #fff;
  background-color: #ff6347;
  border: 1px solid rgba(0, 0, 0, 0.2);
  &:hover {
    background-color: #ff0000;
  }
  @media (max-width: 550px) {
    width: 80px;
    font-size: 0.8em;
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const ImprimirButton = styled(Button)`
  height: 30px;
  padding-left: 50px;
  padding-right: 50px;
  width: 120px;
  color: #fff;
  background-color: #a0a0a0;
  border: 1px solid rgba(0, 0, 0, 0.2);
  &:hover {
    background-color: #808080;
  }

  @media (max-width: 550px) {
    width: 80px;
    font-size: 0.8em;
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const XlsButton = styled(Button)`
  height: 30px;
  width: 120px;
  padding-left: 50px;
  padding-right: 50px;
  color: #fff;
  background-color: #71bc78;
  border: 1px solid rgba(0, 0, 0, 0.2);
  &:hover {
    background-color: #569b5c;
  }
  @media (max-width: 550px) {
    width: 80px;
    font-size: 0.8em;
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const ResultadosContainer = styled.div`
  margin-top: 20px;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  @media (max-width: 550px) {
    padding: 10px;
  }
`;

const MobileButton = styled(Button)`
  height: 30px;
  width: 150px;
  color: #fff;
  background-color: #2957a4;
  border: 1px solid rgba(0, 0, 0, 0.2);
  display: none;
  &:hover {
    background-color: #1f437d;
  }

  @media (max-width: 360px) {
    display: block;
  }
`;

const getBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.7) : lighten(color, 0.7);

const getHoverBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

const getSelectedBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.5) : lighten(color, 0.5);

const getSelectedHoverBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.4) : lighten(color, 0.4);

const StyledDataGrid = Estilo(DataGrid)(({ theme }) => ({
  "& .grid-azul": {
    backgroundColor: getBackgroundColor(
      theme.palette.info.main,
      theme.palette.mode
    ),
    "&:hover": {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.info.main,
        theme.palette.mode
      ),
    },
    "&.Mui-selected": {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.info.main,
        theme.palette.mode
      ),
      "&:hover": {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.info.main,
          theme.palette.mode
        ),
      },
    },
  },
  "& .grid-vermelho": {
    backgroundColor: getBackgroundColor(
      theme.palette.error.main,
      theme.palette.mode
    ),
    "&:hover": {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.error.main,
        theme.palette.mode
      ),
    },
    "&.Mui-selected": {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.error.main,
        theme.palette.mode
      ),
      "&:hover": {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.error.main,
          theme.palette.mode
        ),
      },
    },
  },
}));

const ConsultaGeralVendasDetail = () => {
  const [formattedDataInicial, setFormattedDataInicial] = useState("");
  const [formattedDataFinal, setFormattedDataFinal] = useState("");
  const gridRef = useRef(null);
  const Filial = sessionStorage.getItem("empresa");
  const [todas, setTodas] = useState("");
  const [FMT, setFMT] = useState("S");
  const [Cesbra, setCesbra] = useState("S");
  const [Farelo, setFarelo] = useState("S");
  const [Soja, setSoja] = useState("S");
  const [Milho, setMilho] = useState("S");
  const [Oleo, setOleo] = useState("S");
  const [prod_Cesbra, setProdCesbra] = useState("S");
  const [baixa_Comercial, setBaixaComercial] = useState("S");
  const [Sumatex, setSumatex] = useState("S");
  const [Rep, setRep] = useState("S");
  const [Prog, setProg] = useState(null);
  const [Cor, setCor] = useState("S");
  const [Fat, setFat] = useState("S");
  const [Ent, setEnt] = useState("S");
  const [clienteSelecionado, setClienteSelecionado] = useState(null);
  const [areaSelecionada, setAreaSelecionada] = useState(null);
  const [atividadeSelecionada, setAtividadeSelecionada] = useState(null);
  const [cidadeSelecionada, setCidadeSelecionada] = useState(null);
  const [grupoSelecionado, setGrupoSelecionado] = useState(null);
  const [produtoSelecionado, setProdutoSelecionado] = useState(null);
  const [ufSelecionada, setUFSelecionada] = useState(null);
  const [vendedorSelecionado, setVendedorSelecionado] = useState(null);
  const [transportadoraSelecionada, setTransportadoraSelecionada] =
    useState(null);
  const [resultados, setResultados] = useState([]);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  // eslint-disable-next-line

  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line
  const [consultaSelecionada, setConsultaSelecionada] = useState("relatorio");

  const grupo = sessionStorage.getItem("grupo");
  const grupoGerente = ["8f498e6aabb107de59d0ad2177f6d882"];

  const isUserInGroupGerente = grupoGerente.includes(grupo);

  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({
    id: false,
  });

  const [filterModel, setFilterModel] = React.useState({
    items: [],
  });

  const applyFilters = (rows, filterModel) => {
    return rows.filter((row) => {
      return filterModel.items.every((filter) => {
        const { field, operator, value } = filter;
        const rowValue = row[field];

        // Convertendo rowValue e value para string para comparações de texto
        const rowValueStr = String(rowValue).toLowerCase();
        const valueStr = String(value).toLowerCase();

        switch (operator) {
          case ">":
            return rowValue > value;
          case ">=":
            return rowValue >= value;
          case "<":
            return rowValue < value;
          case "<=":
            return rowValue <= value;
          case "equals":
          case "=": // Igualdade numérica ou textual
            return rowValueStr === valueStr;
          case "!=": // Diferença numérica ou textual
            return rowValue !== value;
          case "contains": // Contém
            return rowValueStr.includes(valueStr);
          case "startsWith": // Começa com
            return rowValueStr.startsWith(valueStr);
          case "endsWith": // Termina com
            return rowValueStr.endsWith(valueStr);
          default:
            return true; // Se o operador não for suportado, não filtra.
        }
      });
    });
  };

  const handleLimparResultados = () => {
    setResultados([]);
    setRows([]);
    setColumns([]);
  };

  const customComparator = (v1, v2) => {
    const value1 = parseFloat(v1.replace(",", "."));
    const value2 = parseFloat(v2.replace(",", "."));

    if (value1 < value2) {
      return -1;
    }
    if (value1 > value2) {
      return 1;
    }
    return 0;
  };

  const calcularValorTotal = () => {
    const valorTotal = applyFilters(rows, filterModel).reduce(
      (total, item) => total + (item.Vl_Total || 0),
      0
    );
    return formatarValor(valorTotal);
  };

  const calcularQuantidadeTotal = () => {
    const quantidadeTotal = applyFilters(rows, filterModel).reduce(
      (total, item) => total + (item.Quantidade || 0),
      0
    );
    return formatarValor(quantidadeTotal);
  };

  const customDateComparator = (dateStr1, dateStr2) => {
    const parts1 = dateStr1.split("/");
    const parts2 = dateStr2.split("/");
    const date1 = new Date(
      Number(parts1[2]),
      Number(parts1[1]) - 1,
      Number(parts1[0])
    );
    const date2 = new Date(
      Number(parts2[2]),
      Number(parts2[1]) - 1,
      Number(parts2[0])
    );

    if (date1 < date2) {
      return -1;
    }
    if (date1 > date2) {
      return 1;
    }
    return 0;
  };

  const realizarConsulta = async () => {
    let procedure = "";

    switch (consultaSelecionada) {
      case "relatorio":
        procedure = "consulta-venda-detail";
        break;
      default:
        break;
    }

    try {
      const data = {
        Data: {
          formattedDataInicial,
          formattedDataFinal,
        },
        Clientes: {
          clienteSelecionado:
            clienteSelecionado === null
              ? null
              : clienteSelecionado.substring(0, 6),
        },
        Produtos: {
          produtoSelecionado:
            produtoSelecionado === null
              ? null
              : produtoSelecionado.substring(0, 6),
        },
        Atividades: {
          atividadeSelecionada:
            atividadeSelecionada === null
              ? null
              : atividadeSelecionada.substring(0, 6),
        },
        Areas: {
          areaSelecionada:
            areaSelecionada === null ? null : areaSelecionada.substring(0, 6),
        },
        Vendedores: {
          vendedorSelecionado:
            vendedorSelecionado === null
              ? null
              : vendedorSelecionado.substring(0, 6),
        },
        Cidades: {
          cidadeSelecionada:
            cidadeSelecionada === null
              ? null
              : cidadeSelecionada.substring(0, 6),
        },
        UFs: {
          ufSelecionada:
            ufSelecionada === null ? null : ufSelecionada.substring(0, 6),
        },
        Grpds: {
          grupoSelecionado:
            grupoSelecionado === null ? null : grupoSelecionado.substring(0, 6),
        },
        Transportadoras: {
          transportadoraSelecionada:
            transportadoraSelecionada === null
              ? null
              : transportadoraSelecionada.substring(0, 6),
        },
        Checkboxes: {
          FMT,
          Cesbra,
          Farelo,
          Soja,
          Oleo,
          Milho,
          prod_Cesbra,
          baixa_Comercial,
          Sumatex,
          Rep,
          Prog,
          Cor,
          Fat,
          Ent,
        },
        Filial,
        CheckboxTodas: {
          todas,
        },
      };

      const config = {
        timeout: 45000,
      };

      setIsLoading(true);

      const response = await Axios.post(`consultas/${procedure}`, data, config);

      if (response.data.length > 0) {
        const rows = response.data.map((item, index) => {
          const rowData = {};
          Object.keys(item).forEach((key) => {
            rowData[key] = item[key];
          });

          rowData["id"] = index + 1;
          return rowData;
        });

        const columns = Object.keys(response.data[0]).map((key) => ({
          field: key,
          ...(key === "Emissao"
            ? {
              headerName: "Emissão",
              width: 120,
              sortComparator: customDateComparator,
            }
            : {
              ...(key === "Dt_Pedido"
                ? {
                  headerName: "Dt_Pedido",
                  width: 120,
                  sortComparator: customDateComparator,
                }
                : {
                  ...(key === "Nro_Nota"
                    ? {
                      headerName: "Nota",
                      width: 100,
                    }
                    : {
                      ...(key === "Pedido"
                        ? {
                          headerName: "Pedido",
                          width: 100,
                        }
                        : {
                          ...(key === "Produto"
                            ? {
                              headerName: "Produto",
                              width: 400,
                            }
                            : {
                              ...(key === "Cliente"
                                ? {
                                  headerName: "Cliente",
                                  width: 400,
                                }
                                : {
                                  ...(key === "Uf"
                                    ? {
                                      headerName: "UF",
                                      width: 40,
                                    }
                                    : {
                                      ...(key === "Vl_Unitario"
                                        ? {
                                          headerName:
                                            "Vl_Un (R$)",
                                          width: 100,
                                        }
                                        : {
                                          ...(key ===
                                            "Quantidade"
                                            ? {
                                              headerName:
                                                "Qtd (Kg)",
                                              width: 100,
                                            }
                                            : {
                                              ...(key ===
                                                "Unidade"
                                                ? {
                                                  headerName:
                                                    "Un",
                                                  width: 40,
                                                }
                                                : {
                                                  ...(key ===
                                                    "ICMS"
                                                    ? {
                                                      headerName:
                                                        "ICMS",
                                                      width: 80,
                                                    }
                                                    : {
                                                      ...(key ===
                                                        "Vl_Total"
                                                        ? {
                                                          headerName:
                                                            "Total (R$)",
                                                          width: 120,
                                                        }
                                                        : {
                                                          ...(key ===
                                                            "Tipo"
                                                            ? {
                                                              headerName:
                                                                "Tipo",
                                                              width: 180,
                                                            }
                                                            : {
                                                              ...(key ===
                                                                "Prazo"
                                                                ? {
                                                                  headerName:
                                                                    "Prazo",
                                                                  width: 140,
                                                                  sortComparator:
                                                                    customDateComparator,
                                                                }
                                                                : {
                                                                  ...(key ===
                                                                    "CFOP"
                                                                    ? {
                                                                      headerName:
                                                                        "CFOP",
                                                                      width: 100,
                                                                    }
                                                                    : {
                                                                      headerName:
                                                                        key,
                                                                      width: 200,
                                                                    }),
                                                                }),
                                                            }),
                                                        }),
                                                    }),
                                                }),
                                            }),
                                        }),
                                    }),
                                }),
                            }),
                        }),
                    }),
                }),
            }),

          ...(key === "Vl_Unitario" ||
            key === "Quantidade" ||
            key === "ICMS" ||
            key === "Vl_Total"
            ? {
              valueFormatter: (params) => {
                return params.toLocaleString("pt-BR", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 5,
                });
              },
            }
            : {}),
        }));

        columns.unshift({ field: "id", headerName: "ID", width: 10 });

        setRows(rows);
        setColumns(columns);
      }

      setTimeout(() => {
        gridRef.current.scrollIntoView({ behavior: 'smooth' });
        gridRef.current.focus();
      }, 100); 
    

      setResultados(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error("Erro ao realizar a consulta:", error);
      setIsLoading(false);
    }
  };

  function formatarValor(valor) {
    if (valor === null || valor === undefined) {
      return "";
    }

    if (typeof valor !== "number") {
      return valor;
    }

    return valor.toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }

  function desformatarData(data) {
    if (!data) return null;

    const ano = data.slice(0, 4);
    const mes = data.slice(4, 6);
    const dia = data.slice(6, 8);

    return `${dia}/${mes}/${ano}`;
  }

  const handlePrintResults = () => {
    const Filial = sessionStorage.getItem("empresa");
    const printContent = `
      <html>
        <head>
          <title>S.I.G.A - Grupo Sumatex</title>
          <style>
           
            body { font-family: Arial, sans-serif; margin: 0; padding: 0; }
            h1 {
              text-align: center;
              margin-bottom: 20px;
              font-size: 24px;
            }
            h2 img {
              height: 40px;
              margin-right: 10px;
            }
            table { 
              width: 100%; 
              border-collapse: 
              collapse; 
            }
            th, td { 
              border: 1px solid #000; 
              padding: 8px; 
              text-align: left; 
            }
          </style>
        </head>
        <body>
          <h2>
            ${Filial === "1 - MAT"
        ? `<img src="${logo_sumatex}" alt="Logo">`
        : Filial === "7 - CES"
          ? `<img src="${logo_cesbra}" alt="Logo">`
          : Filial === "2 - AMB"
            ? `<img src="${logo_ambiental}" alt="Logo">`
            : Filial === "9 - LOG"
              ? `<img src="${logo_lorenvel}" alt="Logo">`
              : Filial === "5 - FMT"
                ? `<img src="${logo_agro}" alt="Logo">`
                : `<img src="${logo}" alt="Logo">`
      } </h2>
           <h1>
           Consulta Geral de Vendas - Detalhado
           </h1>
           <div>
           <div class="filters">
           <p style="text-align: center;"><strong>Data:</strong> ${desformatarData(
        formattedDataInicial
      )} a ${desformatarData(formattedDataFinal)}</strong></p>
           
           ${clienteSelecionado
        ? `<div key="cliente"><p><strong>Cliente:</strong> ${clienteSelecionado}</p></div>`
        : ""
      }
           ${produtoSelecionado
        ? `<div key="produto"><p><strong>Produto:</strong> ${produtoSelecionado}</p></div>`
        : ""
      }
           ${atividadeSelecionada
        ? `<div key="atividade"><p><strong>Atividade:</strong> ${atividadeSelecionada}</p></div>`
        : ""
      }
           ${areaSelecionada
        ? `<div key="area"><p><strong>Área:</strong> ${areaSelecionada}</p></div>`
        : ""
      }
           ${vendedorSelecionado
        ? `<div key="vendedor"><p><strong>Vendedor:</strong> ${vendedorSelecionado}</p></div>`
        : ""
      }
           ${cidadeSelecionada
        ? `<div key="cidade"><p><strong>Cidade:</strong> ${cidadeSelecionada}</p></div>`
        : ""
      }
           ${ufSelecionada
        ? `<div key="uf"><p><strong>UF:</strong> ${ufSelecionada}</p></div>`
        : ""
      }
           ${grupoSelecionado
        ? `<div key="grupo"><p><strong>Grupo de Prod.:</strong> ${grupoSelecionado}</p></div>`
        : ""
      }
           ${transportadoraSelecionada
        ? `<div key="transportadora"><p><strong>Transportadora:</strong> ${transportadoraSelecionada}</p></div>`
        : ""
      }
         </div>
        </div>
          <table>
            <thead>
              <tr>
                ${columns
        .filter(
          (coluna) => columnVisibilityModel[coluna.field] !== false
        )
        .map((coluna) => `<th>${coluna.headerName}</th>`)
        .join("")}
              </tr>
            </thead>
            <tbody>
              ${applyFilters(rows, filterModel)
        .map((item, index) => {
          return `
                    <tr ${index % 2 === 0
              ? 'style="background-color: #f9f9f9;"'
              : ""
            }>
                      ${columns
              .filter(
                (coluna) =>
                  columnVisibilityModel[coluna.field] !== false
              )
              .map((coluna) => {
                return `<td>${formatarValor(
                  item[coluna.field]
                )}</td>`;
              })
              .join("")}
                    </tr>
                  `;
        })
        .join("")}
            </tbody>
          </table>
          ${isExpanded
        ? `
      <div style="margin-top: 20px; display: grid; grid-template-columns: 1fr 1fr;">
          <div>
            <h2>Dados</h2>
              <div class="consolidated-data">
                  <div><p><strong>Valor Total:</strong> R$ ${calcularValorTotal()}</p></div>
              </div>
          </div>
          <div> 
              <h2>⠀</h2>
              <div class="consolidated-data"> 
                  <div><p><strong>Quantidade Total (Kg):</strong> ${calcularQuantidadeTotal()}</p>
                  <p><strong>Preço Médio:</strong> ${formatarValor(
          parseFloat(
            calcularValorTotal().replace(/\./g, "").replace(",", ".")
          ) /
          parseFloat(
            calcularQuantidadeTotal()
              .replace(/\./g, "")
              .replace(",", ".")
          )
        )}</p></div>
              </div>
          </div>
      </div>
      </div>` : ''}
    </body>
  </html>
    `;

    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;
    const printWindow = window.open(
      "",
      "",
      `width=${screenWidth},height=${screenHeight}`
    );

    if (!printWindow) {
      alert(
        "Erro ao abrir a janela de impressão. Verifique as configurações do seu navegador."
      );
      return;
    }

    printWindow.document.open();
    printWindow.document.write(printContent);
    printWindow.document.close();

    printWindow.onload = () => {
      printWindow.print();

      setTimeout(() => { }, 1000);
    };
  };

  const handleGenerateXLS = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Resultados");

    const colunas = columns
      .filter((coluna) => columnVisibilityModel[coluna.field] !== false)
      .map((coluna) => coluna.headerName);

    const headerRow = worksheet.addRow(colunas);

    headerRow.eachCell((cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "D3D3D3" },
      };
      applyThinBorder(cell);
      cell.font = { bold: true };
    });

    applyFilters(rows, filterModel).forEach((item) => {
      const rowData = columns
        .filter((coluna) => columnVisibilityModel[coluna.field] !== false)
        .map((coluna) => item[coluna.field]);
      const row = worksheet.addRow(rowData);
      row.eachCell((cell) => {
        applyThinBorder(cell);
      });
    });

    const range = worksheet.getSheetValues();
    for (let R = 1; R <= range.length; ++R) {
      const row = worksheet.getRow(R);
      for (let C = 1; C <= colunas.length; ++C) {
        const cell = row.getCell(C);
        applyThinBorder(cell);
      }
    }

    const buffer = await workbook.xlsx.writeBuffer();
    const nomeDoArquivo = `resultados_consultageraldevendas_detalhes_${formattedDataInicial}-${formattedDataFinal}.xlsx`;

    saveWorkbook(buffer, nomeDoArquivo);
  };

  const applyThinBorder = (cell) => {
    cell.border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
  };

  const saveWorkbook = async (buffer, nomeDoArquivo) => {
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, nomeDoArquivo);
  };

  const [isExpanded, setIsExpanded] = useState(true);

  const toggleCollapse = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div>
      <StyledOrange />
      <StyledTitle>Consulta Geral de Vendas - Detalhado</StyledTitle>
      <StyledGreen />

      <FormContainer>
        <div>
          {isLoading && (
            <div
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 9999,
              }}
            >
              <CircularProgress />
            </div>
          )}

          <Data
            formattedDataInicial={formattedDataInicial}
            formattedDataFinal={formattedDataFinal}
            setFormattedDataInicial={setFormattedDataInicial}
            setFormattedDataFinal={setFormattedDataFinal}
          />
          <Clientes
            clienteSelecionado={clienteSelecionado}
            setClienteSelecionado={setClienteSelecionado}
          />
          <Produtos
            produtoSelecionado={produtoSelecionado}
            setProdutoSelecionado={setProdutoSelecionado}
          />
          <Atividades
            atividadeSelecionada={atividadeSelecionada}
            setAtividadeSelecionada={setAtividadeSelecionada}
          />
          <Areas
            areaSelecionada={areaSelecionada}
            setAreaSelecionada={setAreaSelecionada}
          />
          <Vendedores
            vendedorSelecionado={vendedorSelecionado}
            setVendedorSelecionado={setVendedorSelecionado}
          />
          <Cidades
            cidadeSelecionada={cidadeSelecionada}
            setCidadeSelecionada={setCidadeSelecionada}
          />
          <UFs
            ufSelecionada={ufSelecionada}
            setUFSelecionada={setUFSelecionada}
          />
          <Grpds
            grupoSelecionado={grupoSelecionado}
            setGrupoSelecionado={setGrupoSelecionado}
          />
          <Transportadoras
            transportadoraSelecionada={transportadoraSelecionada}
            setTransportadoraSelecionada={setTransportadoraSelecionada}
          />
          <br></br>
          <Checkboxes
            FMT={FMT}
            Cesbra={Cesbra}
            Farelo={Farelo}
            Soja={Soja}
            Oleo={Oleo}
            Milho={Milho}
            prod_Cesbra={prod_Cesbra}
            baixa_Comercial={baixa_Comercial}
            Sumatex={Sumatex}
            Rep={Rep}
            Prog={Prog}
            Cor={Cor}
            Fat={Fat}
            Ent={Ent}
            setFMT={setFMT}
            setCesbra={setCesbra}
            setFarelo={setFarelo}
            setSoja={setSoja}
            setOleo={setOleo}
            setMilho={setMilho}
            setProdCesbra={setProdCesbra}
            setBaixaComercial={setBaixaComercial}
            setSumatex={setSumatex}
            setRep={setRep}
            setProg={setProg}
            setCor={setCor}
            setFat={setFat}
            setEnt={setEnt}
          />

          <br></br>

          {isUserInGroupGerente && (
            <CheckboxTodas todas={todas} setTodas={setTodas} />
          )}

          <ButtonContainer>
            <SaveButton onClick={realizarConsulta}>
              <AiOutlineSearch />
              ⠀Calcular
            </SaveButton>
          </ButtonContainer>
        </div>
      </FormContainer>
      <MobileButton onClick={realizarConsulta}>
        <AiOutlineSearch />
        ⠀Calcular
      </MobileButton>

      {resultados.length > 0 && (
        <ResultadosContainer ref={gridRef} id="resultados-container">
          <ResultadosTitle>Resultados</ResultadosTitle>
          <ButtonWrapper>
            <LimparButton
              onClick={handleLimparResultados}
              variant="contained"
              color="secondary"
            >
              Limpar
            </LimparButton>
            <ImprimirButton
              onClick={handlePrintResults}
              variant="contained"
              color="primary"
            >
              Imprimir
            </ImprimirButton>
            <XlsButton
              onClick={handleGenerateXLS}
              variant="contained"
              color="primary"
            >
              Gerar⠀XLS
            </XlsButton>
          </ButtonWrapper>
          <IconButton onClick={toggleCollapse}>
            {isExpanded ? <ExpandLess /> : <>
              <ExpandMore />
              <Typography
                variant="caption"
                style={{
                  marginLeft: 4,
                  color: "#6c757d",
                  fontSize: '0.9rem',
                }}
              >
                Exibir Totalizadores
              </Typography>
            </>}
          </IconButton>
          {isExpanded && (

            <div
              style={{
                border: "5px solid #D3D3D3",
                padding: "10px",
              }}
            >
              <Typography component="div">
                <div className="tb-result">
                  <table>
                    <tr>
                      <td style={{ color: "#2957A4" }}>Valor Total (R$)</td>
                      <td>{calcularValorTotal()}</td>
                    </tr>
                    <tr>
                      <td style={{ color: "#2957A4" }}>Quantidade Total (Kg)</td>
                      <td>{calcularQuantidadeTotal()}</td>
                    </tr>
                    <tr>
                      <td style={{ color: "#2957A4" }}>Preço Médio (R$)</td>
                      <td>
                        {formatarValor(
                          parseFloat(
                            calcularValorTotal()
                              .replace(/\./g, "")
                              .replace(",", ".")
                          ) /
                          parseFloat(
                            calcularQuantidadeTotal()
                              .replace(/\./g, "")
                              .replace(",", ".")
                          )
                        )}
                      </td>
                    </tr>
                  </table>
                </div>
              </Typography>
            </div>
          )}
          <br></br>
          <StyledDataGrid
            rows={rows}
            columns={columns}
            sortComparator={customComparator}
            sx={{
              boxShadow: 2,
              border: 2,
              borderColor: "primary.light",
              "& .MuiDataGrid-cell:hover": {
                color: "primary.main",
              },
            }}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 20 },
              },
            }}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) =>
              setColumnVisibilityModel(newModel)
            }
            filterModel={filterModel}
            onFilterModelChange={(newFilterModel) =>
              setFilterModel(newFilterModel)
            }
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            getRowClassName={(params) =>
              params.row.VL_REL >= 15 ? `grid-azul` : ``
            }
            pageSizeOptions={[5, 10, 15, 20]}
            disableSelectionOnClick
          />
        </ResultadosContainer>
      )}
    </div>
  );
};

export default ConsultaGeralVendasDetail;
