import React from "react";
import { FormGroup, FormControlLabel, Checkbox, Paper, Grid } from "@mui/material";
import { styled } from "@mui/material";

const Container = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: "#F5F5F5",
  boxShadow: "0px 0px 0px 0px rgba(0,0,0,0.2),0px 0px 0px 0px rgba(0,0,0,0.14),0px 0px 0px 0px rgba(0,0,0,0.12)",
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
}));

const StyledFormGroup = styled(FormGroup)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  width: '200px',
}));

const Checkboxes = ({
  FMT,
  Cesbra,
  Farelo,
  Soja,
  Oleo,
  Milho,
  prod_Cesbra,
  baixa_Comercial,
  Sumatex,
  Rep,
  Prog,
  Cor,
  Fat,
  Ent,
  setFMT,
  setCesbra,
  setFarelo,
  setSoja,
  setOleo,
  setMilho,
  setProdCesbra,
  setBaixaComercial,
  setSumatex,
  setRep,
  setProg,
  setCor,
  setFat,
  setEnt
}) => {
  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <StyledFormGroup>
            <StyledFormControlLabel
              control={
                <Checkbox
                  checked={FMT === "S"}
                  onChange={() => setFMT(FMT === "S" ? null : "S")}
                />
              }
              label="Retirar FMT"
            />
          </StyledFormGroup>
          <StyledFormGroup>
            <StyledFormControlLabel
              control={
                <Checkbox
                  checked={Cesbra === "S"}
                  onChange={() => setCesbra(Cesbra === "S" ? null : "S")}
                />
              }
              label="Retirar Cesbra"
            />
          </StyledFormGroup>
          <StyledFormGroup>
            <StyledFormControlLabel
              control={
                <Checkbox
                  checked={Farelo === "S"}
                  onChange={() => setFarelo(Farelo === "S" ? null : "S")}
                />
              }
              label="Retirar Farelo"
            />
          </StyledFormGroup>
          <StyledFormGroup>
            <StyledFormControlLabel
              control={
                <Checkbox
                  checked={Soja === "S"}
                  onChange={() => setSoja(Soja === "S" ? null : "S")}
                />
              }
              label="Retirar Soja"
            />
          </StyledFormGroup>
          <StyledFormGroup>
            <StyledFormControlLabel
              control={
                <Checkbox
                  checked={Oleo === "S"}
                  onChange={() => setOleo(Oleo === "S" ? null : "S")}
                />
              }
              label="Retirar Óleo"
            />
          </StyledFormGroup>
        </Grid>
        <Grid item xs={12} sm={4}>

          <StyledFormGroup>
            <StyledFormControlLabel
              control={
                <Checkbox
                  checked={Milho === "S"}
                  onChange={() => setMilho(Milho === "S" ? null : "S")}
                />
              }
              label="Retirar Milho"
            />
          </StyledFormGroup>
          <StyledFormGroup>
            <StyledFormControlLabel
              control={
                <Checkbox
                  checked={prod_Cesbra === "S"}
                  onChange={() => setProdCesbra(prod_Cesbra === "S" ? null : "S")}
                />
              }
              label="Retirar Produtos Cesbra"
            />
          </StyledFormGroup>
          <StyledFormGroup>
            <StyledFormControlLabel
              control={
                <Checkbox
                  checked={baixa_Comercial === "S"}
                  onChange={() =>
                    setBaixaComercial(baixa_Comercial === "S" ? null : "S")
                  }
                />
              }
              label="Considerar Baixa Comercial"
            />
          </StyledFormGroup>
          <StyledFormGroup>
            <StyledFormControlLabel
              control={
                <Checkbox
                  checked={Sumatex === "S"}
                  onChange={() => setSumatex(Sumatex === "S" ? null : "S")}
                />
              }
              label="Retirar Sumatex"
            />
          </StyledFormGroup>
          <StyledFormGroup>
            <StyledFormControlLabel
              control={
                <Checkbox
                  checked={Rep === "S"}
                  onChange={() => setRep(Rep === "S" ? null : "S")}
                />
              }
              label="Retirar Rep"
            />
          </StyledFormGroup>
        </Grid>
        <Grid item xs={12} sm={4}>

          <StyledFormGroup>
            <StyledFormControlLabel
              control={
                <Checkbox
                  checked={Cor === "S"}
                  onChange={() => setCor(Cor === "S" ? null : "S")}
                />
              }
              label="Retirar Corantes"
            />
          </StyledFormGroup>
          <StyledFormGroup>
            <StyledFormControlLabel
              control={
                <Checkbox
                  checked={Fat === "S"}
                  onChange={() => setFat(Fat === "S" ? null : "S")}
                />
              }
              label="Geram Faturamento"
            />
          </StyledFormGroup>
          <StyledFormGroup>
            <StyledFormControlLabel
              control={
                <Checkbox
                  checked={Ent === "S"}
                  onChange={() => setEnt(Ent === "S" ? null : "S")}
                />
              }
              label="Desconsiderar Entradas"
            />
          </StyledFormGroup>
          <StyledFormGroup>
            <StyledFormControlLabel
              control={
                <Checkbox
                  checked={Prog === "S"}
                  onChange={() => setProg(Prog === "S" ? null : "S")}
                />
              }
              label="Considerar Programados"
            />
          </StyledFormGroup>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Checkboxes;
