export { default as Cadastro } from "./pages/Portaria/AnaliseTransp/Cadastro";
export { default as Visualizar } from "./pages/Portaria/AnaliseTransp/Visualizar";
export { default as Consulta } from "./pages/Portaria/AnaliseTransp/Consulta";
export { default as Editar } from "./pages/Portaria/AnaliseTransp/Editar";
export { default as Excluir } from "./pages/Portaria/AnaliseTransp/Excluir";
export { default as ConsultaGeral } from "./pages/Consultas/ConsultaGeral/ConsultaGeralVendas";
export { default as ConsultaGeralCompra } from "./pages/Consultas/ConsultaGeral/ConsultaGeralCompras";
export { default as ConsultaGeralDetail } from "./pages/Consultas/ConsultaGeral/ConsultaGeralDetail";
export { default as ConsultaGeralVendasDetail } from "./pages/Consultas/ConsultaGeral/ConsultaGeralVendasDetail";
export { default as Cascata } from "./pages/Consultas/Cascata/Cascata";
export { default as Orcamento } from "./pages/Consultas/Orcamento/Orcamento";
export { default as ProgramacaoVendas } from "./pages/Consultas/Programacao/ProgramacaoVendas";
export { default as PerdasGanhosProdutos } from "./pages/Consultas/PerdasGanhos/PerdasGanhosProdutos";
export { default as PerdasGanhosFatClientes } from "./pages/Consultas/PerdasGanhos/PerdasGanhosFatClientes";
export { default as PerdasGanhosClientes } from "./pages/Consultas/PerdasGanhos/PerdasGanhosClientes";
export { default as ProgramacaoCompras } from "./pages/Consultas/Programacao/ProgramacaoCompras";
export { default as RelatoriosComercial } from "./pages/Relatorios/RelatoriosComercial";
export { default as RelatoriosVendas } from "./pages/Relatorios/RelatoriosVendas";
export { default as RelatoriosRH } from "./pages/Relatorios/RelatoriosRH";
export { default as EstoqueBaixaEstoque } from "./pages/Estoque/EstoqueBaixaEstoque";
export { default as PoliciaFederal } from "./pages/Estoque/PoliciaFederal";
export { default as PoliciaCivil } from "./pages/Estoque/PoliciaCivil";
export { default as Exercito } from "./pages/Estoque/Exercito";
export { default as EstoquePlanilhaGeral } from "./pages/Estoque/EstoquePlanilhaGeral";
export { default as Erro } from "./pages/Erro";
export { default as Home } from "./pages/Home";
export { default as Indicadores } from "./pages/Consultas/Indicadores/Indicadores";
export { default as RelatoriosContabilidade } from "./pages/Relatorios/RelatoriosContabilidade";
export { default as RelatoriosFinanceiro } from "./pages/Relatorios/RelatoriosFinanceiro";
export { default as RelatoriosLogistica } from "./pages/Relatorios/RelatoriosLogistica";
export { default as AnaliseGerencial } from "./pages/Financeiro/Modulo Gerencial/AnaliseGerencial";
export { default as RelatoriosContasaPagar } from "./pages/Financeiro/Contas a Pagar/RelatoriosContasaPagar";
export { default as RelatoriosContasaReceber } from "./pages/Financeiro/Contas a Receber/RelatoriosContasaReceber";
export { default as RelatoriosContEntradasSaidas } from "./pages/Contabilidade/Consultas/RelatoriosContEntradasSaidas";
export { default as User } from "./pages/Usuario/User";
export { default as TrocaEmpresa } from "./pages/Usuario/TrocaEmpresa";
