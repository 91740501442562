import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import React from "react";
import { styled, Paper } from "@mui/material";

const Container = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: "#F5F5F5",
  boxShadow: "0px 0px 0px 0px rgba(0,0,0,0.2),0px 0px 0px 0px rgba(0,0,0,0.14),0px 0px 0px 0px rgba(0,0,0,0.12)",
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
}));

const CheckboxesProgCompra = ({
  cobranca,
  setCobranca
}) => {
  return (
    <Container>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <div style={{ width: "40%" }}>
        <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={cobranca === "S"}
                  onChange={() => setCobranca(cobranca === "S" ? null : "S")}
                />
              }
              label="Geram Cobrança"
            />
          </FormGroup>
          <label></label>
        </div>
      </div>
    </Container>
  );
};

export default CheckboxesProgCompra;
