import React, { useState, useEffect } from 'react';
import {
    TextField, Button, Select, MenuItem, Checkbox, FormControlLabel, Autocomplete,
    Grid, Typography, Paper, Box, InputAdornment, CircularProgress
} from '@mui/material';
import { Save as SaveIcon, Phone as PhoneIcon, Email as EmailIcon, Computer as ComputerIcon, BugReport as BugIcon, Help as HelpIcon, Build as BuildIcon, DeviceHub as InfraIcon, Code as CodeIcon } from '@mui/icons-material';

import { useNavigate } from 'react-router-dom';
import { Axios } from '../../config/axiosConf';
import styled from "@emotion/styled";
import { VscRequestChanges } from "react-icons/vsc";

const StyledTitle = styled(Typography)`
  font-weight: bold;
  font-size: 1.5em;
  margin: 0em 0;
  background-color: #2957a4;
  color: #fff;
  padding: 10px;
  text-align: center;
  @media (max-width: 550px) {
    font-size: 1.2em;
  }
`;

const StyledOrange = styled(Typography)`
  background-color: #d66e20;
  padding-top: 3px;
  padding-bottom: 3px;
  @media (max-width: 550px) {
    font-size: 1.2em;
  }
`;

const StyledGreen = styled(Typography)`
  background-color: #7ca217;
  padding-top: 3px;
  padding-bottom: 3px;
  @media (max-width: 550px) {
    font-size: 1.2em;
  }
`;

const FormWrapper = styled(Paper)`
  margin-top: 20px;
  padding: 20px;
  background-color: #f5f5f5;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  @media (max-width: 500px) {
    padding: 10px;
  }
`;

const validateEmail = (email) => /\S+@\S+\.\S+/.test(email);
const validatePhone = (phone) => /^[0-9]{10,11}$/.test(phone);

const NovoChamado = () => {
    const navigate = useNavigate();
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const user = sessionStorage.getItem("user");
    const [possuiResponsavel, setPossuiResponsavel] = useState("N");
    const emp = sessionStorage.getItem("empresa");
    const [formData, setFormData] = useState({
        tipoatendimento: '',
        motivo: '',
        descricao: '',
        contato: '',
        tipocontato: '',
        usuariosafetados: '',
        respprojeto: null,
        usuario: user,
        empresa: emp.substring(4, 7),
        image: null,
        ativo: ''
    });
    const [funcionarios, setFuncionarios] = useState([]);
    const [errors, setErrors] = useState({ contato: '' });
    const [loading, setLoading] = useState(false);

    const handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") return;
        setOpenSnackbar(false);
    };

    const showSnackbar = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setOpenSnackbar(true);
    };


    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };


    const handleAutocompleteChange = (event, newValue) => {
        setFormData({ ...formData, respprojeto: newValue });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (formData.tipocontato === 'Email' && !validateEmail(formData.contato)) {
            setErrors({ ...errors, contato: 'Email inválido' });
            return;
        } else if (formData.tipocontato === 'Telefone' && !validatePhone(formData.contato)) {
            setErrors({ ...errors, contato: 'Telefone inválido' });
            return;
        } else {
            setErrors({ ...errors, contato: '' });
        }

        setLoading(true);

        try {
            await Axios.post('/usuario/novo-chamado', formData);
            showSnackbar("Chamado criado com sucesso!", "success");
            setFormData({
                tipoatendimento: '',
                motivo: '',
                descricao: '',
                contato: '',
                tipocontato: '',
                usuario: '',
                empresa: emp.substring(4, 7),
                usuariosafetados: '',
                image: null,
                ativo: '',
                respprojeto: null
            });
            navigate('/usuario/meus-chamados');
        } catch (error) {
            showSnackbar("Erro ao criar chamado", "error");
        } finally {
            setLoading(false); // Desativa o loading
        }
    };

    useEffect(() => {
        const fetchFuncionarios = async () => {
            try {
                const response = await Axios.get('/funcionarios');
                setFuncionarios(response.data);
            } catch (error) {
                console.error("Erro ao carregar funcionários", error);
            }
        };

        fetchFuncionarios();
    }, []);


    return (
        <>
            <StyledOrange />
            <StyledTitle id="title">Service Desk - Novo chamado</StyledTitle>
            <StyledGreen />
            <Box p={{ xs: 2, sm: 4 }} sx={{ height: "100%" }}>
                <FormWrapper elevation={3}>
                    <Box p={{ xs: 2, sm: 4 }} sx={{ height: "100%" }}>
                        <form style={{ height: "100%" }} onSubmit={handleSubmit}>
                            <Grid container spacing={4}>

                                <Grid item xs={12} md={6}>
                                    <Typography>Tipo de Atendimento</Typography>
                                    <Select
                                        fullWidth
                                        name="tipoatendimento"
                                        value={formData.tipoatendimento}
                                        onChange={handleInputChange}
                                        required
                                        sx={{ '& .MuiSelect-select': { display: 'flex', alignItems: 'center' } }}
                                    >
                                        <MenuItem value="Requisicao">
                                            <InputAdornment position="start">
                                                <VscRequestChanges />
                                            </InputAdornment>
                                            Requisição
                                        </MenuItem>
                                        <MenuItem value="Duvida">
                                            <InputAdornment position="start">
                                                <HelpIcon />
                                            </InputAdornment>
                                            Dúvida
                                        </MenuItem>
                                        <MenuItem value="Incidente">
                                            <InputAdornment position="start">
                                                <BugIcon />
                                            </InputAdornment>
                                            Incidente
                                        </MenuItem>
                                    </Select>
                                </Grid>

                                {formData.tipoatendimento === "Incidente" && (
                                    <Grid item xs={12} md={6}>
                                        <Typography>Ativo</Typography>
                                        <TextField
                                            fullWidth
                                            name="ativo"
                                            value={formData.ativo}
                                            onChange={handleInputChange}
                                        />
                                    </Grid>
                                )}

                                <Grid item xs={12} md={6}>
                                    <Typography>Categoria</Typography>
                                    <Select
                                        fullWidth
                                        name="motivo"
                                        value={formData.motivo}
                                        onChange={handleInputChange}
                                        required
                                        sx={{ '& .MuiSelect-select': { display: 'flex', alignItems: 'center' } }}
                                    >
                                        <MenuItem value="Hardware">
                                            <InputAdornment position="start">
                                                <BuildIcon />
                                            </InputAdornment>
                                            Hardware
                                        </MenuItem>
                                        <MenuItem value="Software">
                                            <InputAdornment position="start">
                                                <ComputerIcon />
                                            </InputAdornment>
                                            Software
                                        </MenuItem>
                                        <MenuItem value="Infraestrutura">
                                            <InputAdornment position="start">
                                                <InfraIcon />
                                            </InputAdornment>
                                            Infraestrutura
                                        </MenuItem>
                                        <MenuItem value="Desenvolvimento">
                                            <InputAdornment position="start">
                                                <CodeIcon />
                                            </InputAdornment>
                                            Desenvolvimento
                                        </MenuItem>
                                    </Select>
                                </Grid>

                                <Grid item xs={12}>
                                    <Typography>Descrição</Typography>
                                    <TextField
                                        fullWidth
                                        multiline
                                        rows={5}
                                        name="descricao"
                                        value={formData.descricao}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <Typography>Usuários Afetados</Typography>
                                    <Select
                                        fullWidth
                                        name="usuariosafetados"
                                        value={formData.usuariosafetados}
                                        onChange={handleInputChange}
                                        required
                                    >
                                        <MenuItem value="Não">Não</MenuItem>
                                        <MenuItem value="Sim, até 5 usuários">Sim, até 5 usuários</MenuItem>
                                        <MenuItem value="Sim, até 10 usuários">Sim, até 10 usuários</MenuItem>
                                        <MenuItem value="Sim, até 20 usuários">Sim, até 20 usuários</MenuItem>
                                    </Select>
                                </Grid>
                                <Grid item xs={12} md={6}>

                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <Typography>Tipo de Contato</Typography>
                                    <Select
                                        fullWidth
                                        name="tipocontato"
                                        value={formData.tipocontato}
                                        onChange={handleInputChange}
                                        required
                                    >
                                        <MenuItem value="Telefone">Telefone</MenuItem>
                                        <MenuItem value="Teams">Teams</MenuItem>
                                        <MenuItem value="Email">E-mail</MenuItem>
                                    </Select>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <Typography>Contato</Typography>
                                    <TextField
                                        fullWidth
                                        name="contato"
                                        value={formData.contato}
                                        onChange={handleInputChange}
                                        error={!!errors.contato}
                                        helperText={errors.contato}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    {formData.tipocontato === "Telefone" ? <PhoneIcon /> : <EmailIcon />}
                                                </InputAdornment>
                                            ),
                                        }}
                                        required
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={possuiResponsavel === "S"}
                                                onChange={() => setPossuiResponsavel(possuiResponsavel === "S" ? "N" : "S")}
                                            />
                                        }
                                        label="Responsável pelo Projeto"
                                    />
                                </Grid>

                                {possuiResponsavel === "S" && (
                                    <Grid item xs={12} md={6}>
                                        <Autocomplete
                                            options={funcionarios}
                                            value={formData.respprojeto}
                                            onChange={handleAutocompleteChange}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Responsável"
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            )}
                                        />
                                    </Grid>
                                )}

                                <Grid item xs={12}>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        startIcon={loading ? <CircularProgress size={20} /> : <SaveIcon />}
                                        disabled={loading} 
                                    >
                                        {loading ? 'Criando...' : 'Criar chamado'}
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Box>
                    
                </FormWrapper>
            </Box>


        </>
    );
};

export default NovoChamado;
