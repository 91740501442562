import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import React from "react";

const CheckboxTodas = ({
  todas,
  setTodas
}) => {
  return (
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      <div style={{ width: "40%" }}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={todas === "S"}
                onChange={() => setTodas(todas === "S" ? null : "S")}
              />
            }
            label="Todas as Empresas"
          />
        </FormGroup>
        <label></label>
      </div>
    </div>
  );
};

export default CheckboxTodas;
