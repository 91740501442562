import React, { useState } from "react";
import ModalComp from "./ModalComp";

const DivInfo = ({ onClick }) => {
  return (
    <div
      style={{
        display: "inline-block",
        borderRadius: "50%",
        width: "25px",
        height: "25px",
        textAlign: "center",
        lineHeight: "25px",
        cursor: "pointer",
        backgroundColor: "#b0e0e6",
        border: "1px solid rgba(0, 0, 0, 0.2)",
        "&:hover": {
          backgroundColor: "#87ceeb",
        },
      }}
      onClick={onClick}
    >
      <CustomI />
    </div>
  );
};

const DivInfo2 = ({ onClick }) => {
    return (
      <div
        style={{
          display: "inline-block",
          borderRadius: "50%",
          width: "25px",
          height: "25px",
          textAlign: "center",
          lineHeight: "25px",
          cursor: "pointer",
          backgroundColor: "#b0e0e6",
          border: "1px solid rgba(0, 0, 0, 0.2)",
          "&:hover": {
            backgroundColor: "#87ceeb",
          },
        }}
        onClick={onClick}
      >
        <CustomI2 />
      </div>
    );
};

const DivInfo3 = ({ onClick }) => {
    return (
      <div
        style={{
          display: "inline-block",
          borderRadius: "50%",
          width: "25px",
          height: "25px",
          textAlign: "center",
          lineHeight: "25px",
          cursor: "pointer",
          backgroundColor: "#b0e0e6",
          border: "1px solid rgba(0, 0, 0, 0.2)",
          "&:hover": {
            backgroundColor: "#87ceeb",
          },
        }}
        onClick={onClick}
      >
        <CustomI3 />
      </div>
    );
};

const DivInfo4 = ({ onClick }) => {
      return (
        <div
          style={{
            display: "inline-block",
            borderRadius: "50%",
            width: "25px",
            height: "25px",
            textAlign: "center",
            lineHeight: "25px",
            cursor: "pointer",
            backgroundColor: "#b0e0e6",
            border: "1px solid rgba(0, 0, 0, 0.2)",
            "&:hover": {
              backgroundColor: "#87ceeb",
            },
          }}
          onClick={onClick}
        >
          <CustomI4 />
        </div>
      );
};

const CustomI = () => {
  return <i style={{ fontSize: "15px" }}>ℹ️</i>;
};


const CustomI2 = () => {
    return <i style={{ fontSize: "15px" }}>ℹ️²</i>;
};

const CustomI3 = () => {
    return <i style={{ fontSize: "15px" }}>ℹ️³</i>;
};

const CustomI4 = () => {
      return <i style={{ fontSize: "15px" }}>ℹ️⁴</i>;
};



const DetalhesPerg1 = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <>
      <DivInfo onClick={toggleModal} />
      {modalOpen && (
        <ModalComp
          open={modalOpen}
          handleClose={toggleModal}
          content="a) MOPP: Possui treinamento específico para transporte de produtos perigosos (quando aplicável). Quando não houver a inscrição na CNH, deve portar o certificado original de realização do curso."
        />
      )}
    </>
  );
};

const DetalhesPerg2 = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <>
      <DivInfo onClick={toggleModal} />
      {modalOpen && (
        <ModalComp
          open={modalOpen}
          handleClose={toggleModal}
          content="b) CNH: Carteira Nacional de Habilitação compatível com o veículo. Verificar o prazo de validade do exame de saúde e para qual categoria de veículo o condutor é habilitado. Verificar a citação das informações se o condutor exercer atividade remunerada (EAR), exceto carga própria. Categoria A: condutor de veículo motorizado de duas ou três rodas, com ou sem carro lateral; categoria B: condutor de veículo motorizado, não abrangido pela categoria A, cujo peso bruto total não exceda 3 500 kg; categoria C: condutor de veículo motorizado utilizado em transporte de carga, cujo peso bruto total exceda 3 500 kg; categoria E: condutor de combinação de veículos em que a unidade tratora se enquadre na categoria B ou C, e cuja unidade acoplada, reboque, semirreboque ou articulada tenha 6 000 kg ou mais de peso bruto total. CNH Digital: Aceita com as mesmas informações."
        />
      )}
    </>
  );
};

const DetalhesPerg3 = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <>
      <DivInfo onClick={toggleModal} />
      {modalOpen && (
        <ModalComp
          open={modalOpen}
          handleClose={toggleModal}
          content="c) Documento de identificação: Verificar se o auxiliar comprova vínculo empregatício
            com o transportador. Não é permitida a presença de passageiro (carona)."
        />
      )}
    </>
  );
};

const DetalhesPerg4 = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <>
      <DivInfo onClick={toggleModal} />
      {modalOpen && (
        <ModalComp
          open={modalOpen}
          handleClose={toggleModal}
          content="d) Traje mínimo obrigatório (calça comprida, camisa ou camiseta com mangas curtas
                ou compridas e calçado fechado): Verificar se atende aos requisitos mínimos."
        />
      )}
    </>
  );
};

const DetalhesPerg5 = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <>
      <DivInfo onClick={toggleModal} />
      {modalOpen && (
        <ModalComp
          open={modalOpen}
          handleClose={toggleModal}
          content="e) Condições físicas aparentes: Verificar se o condutor e o auxiliar estão isentos
            de sinais de uso de drogas e álcool, e de incapacidade física e mental temporária."
        />
      )}
    </>
  );
};

const DetalhesPerg6 = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <>
      <DivInfo onClick={toggleModal} />
      {modalOpen && (
        <ModalComp
          open={modalOpen}
          handleClose={toggleModal}
          content="f) Orientação para a operação: Verificar se o auxiliar comprova respectiva
            orientação para uso de EPI (curso MOPP não é válido para o auxiliar)."
        />
      )}
    </>
  );
};

const DetalhesPerg7 = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <>
      <DivInfo onClick={toggleModal} />
      {modalOpen && (
        <ModalComp
          open={modalOpen}
          handleClose={toggleModal}
          content="a) CRLV *: Certificado de Registro e Licenciamento do Veículo; CRLV distintos
            quando houver cavalo-mecânico e carreta. Verificar se atende às exigências:
            Verificar o ano de exercício e as características do veículo, como adaptações
            de 3°eixo e tipo de carroçaria; Verificar o comprovante de recolhimento
            do seguro obrigatório. Consultar as legislações federal e estadual de licenciamento,
            de acordo com a origem, destino e trânsito.

            * CRLV Digital: Aceito com as mesmas informações."
        />
      )}
    </>
  );
};

const DetalhesPerg8 = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <>
      <DivInfo onClick={toggleModal} />
      {modalOpen && (
        <ModalComp
          open={modalOpen}
          handleClose={toggleModal}
          content="b) CTPP: Certificado de Transporte para Produtos Perigosos. Verificar se atende
                        às exigências.

                     Verificar se os tanques de carga rodoviários destinados ao transporte de produtos
                     perigosos certificado pelo Inmetro atendem ao Programa de Avaliação da Conformidade,
                     com foco na segurança, por meio do mecanismo de certificação, atendendo aos
                     requisitos estabelecidos nos Regulamentos Técnicos da Qualidade."
        />
      )}
    </>
  );
};

const DetalhesPerg9a = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <>
      <DivInfo onClick={toggleModal} />
      {modalOpen && (
        <ModalComp
          open={modalOpen}
          handleClose={toggleModal}
          content="c) CIPP ou Certificado de Inspeção Internacional: Certificado de Inspeção para
                    o Transporte de Produtos Perigosos a granel emitido por organismos acreditados
                    pelo INMETRO. Verificar se atende às exigências nacionais ou internacionais.
                    Verificar o porte da primeira via original do CIPP na validade, sem sinais de
                    rasuras ou adulteração. 
                    Verificar o número do chassi, placa e o número do equipamento, comparando com a plaqueta afixada na lateral esquerda do tanque,
                    na parte anterior. Verificar se o equipamento está apto para transportar o produto
                    constante no documento de transporte (o grupo de produtos constantes no CTPP
                    ou CIPP deve ser compatível com o produto a ser transportado), consultando a
                    tabela constante no verso do registro de não conformidade (RNC) CIPP."
        />
      )}
    </>
  );
};
const DetalhesPerg9b = () => {
    const [modalOpen, setModalOpen] = useState(false);
  
    const toggleModal = () => {
      setModalOpen(!modalOpen);
    };
  
    return (
      <>
        <DivInfo2 onClick={toggleModal} />
        {modalOpen && (
          <ModalComp
            open={modalOpen}
            handleClose={toggleModal}
            content="Definição conforme a ABNT NBR 7501 – Carga a granel: Produto que é transportado
            sem qualquer embalagem, sendo contido apenas pelo equipamento de transporte
            (tanque, vaso, caçamba ou contêiner-tanque). Exemplo: um tambor de 200 L
            e um contentor intermediário para granéis (IBC) de 1 000 L e o tanque portátil
            são considerados carga fracionada. É obrigatório um CTPP ou CIPP para cada
            veículo, no caso de combinações. No transporte rodoviário de produtos perigosos
            a granel, é admitido o uso de equipamentos (contêiner-tanque) que possuam
            certificado de inspeção internacionalmente aceito e dentro do prazo de validade,
            de acordo com a Convenção Internacional para Segurança de Contêineres,
            permitindo-se seu porte em cópia impressa simples."
          />
        )}
      </>
    );
  };


const DetalhesPerg10 = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <>
      <DivInfo onClick={toggleModal} />
      {modalOpen && (
        <ModalComp
          open={modalOpen}
          handleClose={toggleModal}
          content="d) CIV: Certificado de Inspeção Veicular emitido por organismos acreditados pelo
          Inmetro. Verificar: o porte da primeira via original do CIV na validade, sem sinais
          de rasuras ou adulteração."
        />
      )}
    </>
  );
};

const DetalhesPerg11 = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <>
      <DivInfo onClick={toggleModal} />
      {modalOpen && (
        <ModalComp
          open={modalOpen}
          handleClose={toggleModal}
          content="e) Documento para transporte com dados obrigatórios: Verificar se atende às
          exigências.
        <br/>
          Nº ONU precedido das letras UN ou ONU, nome apropriado para embarque,
          classe ou subclasse de risco principal e risco subsidiário, entre parênteses,
          depois da classe ou subclasse de risco principal, grupo de embalagem (quando
          aplicável) e quantidade total por produto perigoso abrangido pela descrição.
          Verificar a quantidade limitada (carga fracionada) e se há provisão especial que
          isente de cumprir parte ou todo o RTPP.

          ** Documento para transporte digital: Aceito com as mesmas informações."
        />
      )}
    </>
  );
};

const DetalhesPerg12 = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <>
      <DivInfo onClick={toggleModal} />
      {modalOpen && (
        <ModalComp
          open={modalOpen}
          handleClose={toggleModal}
          content="f) Ficha de Emergência do produto transportado de acordo com a ABNT NBR 7503.
          O porte deste documento não é obrigatório."
        />
      )}
    </>
  );
};

const DetalhesPerg13 = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <>
      <DivInfo onClick={toggleModal} />
      {modalOpen && (
        <ModalComp
          open={modalOpen}
          handleClose={toggleModal}
          content="g) AATIPP – Autorização Ambiental de Transporte Interestadual de Produtos
          Perigosos: Verificar se está portando.

          SOMENTE PARA DESTINAÇÃO OU TRÂNSITO INTERESTADUAL – cada
          veículo deve ser detentor da AATIPP (no momento do transporte interestadual,
          a empresa transportadora, seja ela Matriz ou Filial, constante no documento para
          transporte, deve dispor para cada veículo, ou composição veicular, de cópia da
          Autorização ambiental para transporte de produtos perigosos) – validade de
          três meses."
        />
      )}
    </>
  );
};

const DetalhesPerg14a = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <>
      <DivInfo onClick={toggleModal} />
      {modalOpen && (
        <ModalComp
          open={modalOpen}
          handleClose={toggleModal}
          content="h) Autorização ou Licença Ambiental Estadual para Transporte de Produtos
Perigosos: De acordo com a exigência de cada estado. Verificar se está portando,
caso no trajeto o estado exija."
        />
      )}
    </>
  );
};

const DetalhesPerg14b = () => {
    const [modalOpen, setModalOpen] = useState(false);
  
    const toggleModal = () => {
      setModalOpen(!modalOpen);
    };
  
    return (
      <>
        <DivInfo2 onClick={toggleModal} />
        {modalOpen && (
          <ModalComp
            open={modalOpen}
            handleClose={toggleModal}
            content="
      — PAE – Plano de Ação de Emergência. Verificar o porte (físico ou digital)
  do PAE;"
          />
        )}
      </>
    );
  };

  const DetalhesPerg14c = () => {
    const [modalOpen, setModalOpen] = useState(false);
  
    const toggleModal = () => {
      setModalOpen(!modalOpen);
    };
  
    return (
      <>
        <DivInfo3 onClick={toggleModal} />
        {modalOpen && (
          <ModalComp
            open={modalOpen}
            handleClose={toggleModal}
            content="
      — Especificação do tanque: Verificar se as informações do tanque estão
  disponíveis, no caso de transporte a granel, como características de
  construção, localização e quantidade de válvulas, bem como a pressão de
  abertura da válvula de alívio."
          />
        )}
      </>
    );
  };

  const DetalhesPerg14d = () => {
    const [modalOpen, setModalOpen] = useState(false);
  
    const toggleModal = () => {
      setModalOpen(!modalOpen);
    };
  
    return (
      <>
        <DivInfo4 onClick={toggleModal} />
        {modalOpen && (
          <ModalComp
            open={modalOpen}
            handleClose={toggleModal}
            content="
            — Número do telefone do plantão de atendimento a emergência: Verificar
  se os veículos possuem avisos com o número do plantão de atendimento
  a emergências do transportador afixados nas superfícies externas das
  unidades e dos equipamentos em local visível, podendo para tanto utilizar
  placas, adesivos ou plotagem. Os avisos devem ser resistentes ao risco
  do transporte e afixados pelo menos em três lados das unidades ou
  equipamentos de transporte de produtos e resíduos perigosos."
          />
        )}
      </>
    );
  };

const DetalhesPerg15a = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <>
      <DivInfo onClick={toggleModal} />
      {modalOpen && (
        <ModalComp
          open={modalOpen}
          handleClose={toggleModal}
          content="i) Verificar se está portando, caso no trajeto o município exija."
        />
      )}
    </>
  );
};

const DetalhesPerg15b = () => {
    const [modalOpen, setModalOpen] = useState(false);
  
    const toggleModal = () => {
      setModalOpen(!modalOpen);
    };
  
    return (
      <>
        <DivInfo onClick={toggleModal} />
        {modalOpen && (
          <ModalComp
            open={modalOpen}
            handleClose={toggleModal}
            content="LETPP – Licença Especial de Trânsito de Produtos Perigosos, expedida pelo DSV: Verificar se está portando.
                    SOMENTE PARA DESTINAÇÃO OU TRÂNSITO PELO MUNICÍPIO DE SÃO PAULO/SP – cada veículo de carga deve ser detentor da LETPP (a licença é por placa do veículo e nº ONU e não para o conjunto transportador) – dentro da validade."
          />
        )}
      </>
    );
  };

const DetalhesPerg16 = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <>
      <DivInfo onClick={toggleModal} />
      {modalOpen && (
        <ModalComp
          open={modalOpen}
          handleClose={toggleModal}
          content="j) Autorização especial de trânsito (AET) para veículos ou Autorização
          específica (AE), quando aplicável. Verificar se está portando."
        />
      )}
    </>
  );
};

const DetalhesPerg17 = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <>
      <DivInfo onClick={toggleModal} />
      {modalOpen && (
        <ModalComp
          open={modalOpen}
          handleClose={toggleModal}
          content="k) Documento de comprovação do horário de chegada, fornecido pelo
          Embarcador, e horário fornecido pelo Destinatário em que o transportador
          chegou e saiu. O documento deve ser entregue ao transportador imediatamente
          após o apontamento dos horários. Verificar se está portando."
        />
      )}
    </>
  );
};

const DetalhesPerg18 = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <>
      <DivInfo onClick={toggleModal} />
      {modalOpen && (
        <ModalComp
          open={modalOpen}
          handleClose={toggleModal}
          content="a) Dois, quatro ou seis calços com dimensões mínimas de 150 mm × 200 mm × 150 mm,
          de acordo com o tipo do veículo. Verificar se atende às dimensões e quantidades.
          Os calços devem ser construídos em material compatível com o produto perigoso
          transportado."
        />
      )}
    </>
  );
};

const DetalhesPerg19 = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <>
      <DivInfo onClick={toggleModal} />
      {modalOpen && (
        <ModalComp
          open={modalOpen}
          handleClose={toggleModal}
          content="b) Jogo de ferramentas (mínimo): um alicate universal e uma chave apropriada para
          desconexão do cabo da bateria. Verificar se as ferramentas estão no veículo."
        />
      )}
    </>
  );
};

const DetalhesPerg20 = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <>
      <DivInfo onClick={toggleModal} />
      {modalOpen && (
        <ModalComp
          open={modalOpen}
          handleClose={toggleModal}
          content="c) Quatro cones de cor laranja, com duas faixas retrorrefletivas na cor branca,
          para sinalização da via, para uso nas situações de emergências ou avarias.
          Verificar se os cones atendem à altura de 700 mm a 760 mm e base de 380 mm
          a 420 mm, forma, cores, limpeza e integridade.

          O cone deve ser fabricado em material de características flexíveis, ser resistente
          às intempéries e ter estabilidade quando exposto ao calor, sem sofrer deformações
          significativas (inclusive a base) e descoloramento intenso. O cone deve ter acabamento
          isento de defeitos superficiais, rebarbas ou bordas cortantes. O cone deve ser uma
          peça única, composto por peça cônica, base de sustentação e sapatas (pés de apoio
          ou outro sistema similar), de forma que não se separem. A peça cônica deve ser,
          externamente, na cor laranja, com duas faixas retrorrefletivas brancas, flexíveis,
          autoadesivas, aplicadas horizontalmente em toda a sua circunferência. A base de
          sustentação deve ser na cor laranja (mesma cor da peça cônica) ou preta."
        />
      )}
    </>
  );
};

const DetalhesPerg21 = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <>
      <DivInfo onClick={toggleModal} />
      {modalOpen && (
        <ModalComp
          open={modalOpen}
          handleClose={toggleModal}
          content="d) Produtos cujo risco principal ou subsidiário seja inflamável: conjunto de emergência (exceto o jogo de ferramentas e o extintor de incêndio). Verificar se atende e se é de material antifaiscante, no caso de transporte de produto inflamável."
        />
      )}
    </>
  );
};

const DetalhesPerg22 = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <>
      <DivInfo onClick={toggleModal} />
      {modalOpen && (
        <ModalComp
          open={modalOpen}
          handleClose={toggleModal}
          content="e) Extintor de incêndio do veículo automotor: Verificar data de validade e certificação do Inmetro, devendo a identificação estar legível."
        />
      )}
    </>
  );
};

const DetalhesPerg23 = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <>
      <DivInfo onClick={toggleModal} />
      {modalOpen && (
        <ModalComp
          open={modalOpen}
          handleClose={toggleModal}
          content="f) Extintor(es) de incêndio da carga: Verificar o tipo e a quantidade adequada à carga (produto transportado), data de validade e certificação do Inmetro, devendo a identificação estar legível.

          O extintor de incêndio não pode ser instalado dentro do compartimento de carga,
          exceto para o veículo com capacidade de carga de até 3t, que pode ser colocado
          no compartimento de carga, próximo a uma das portas ou tampa, não podendo
          ser obstruído pela carga."
        />
      )}
    </>
  );
};

const DetalhesPerg24 = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <>
      <DivInfo onClick={toggleModal} />
      {modalOpen && (
        <ModalComp
          open={modalOpen}
          handleClose={toggleModal}
          content="g) Luvas de material compatível com o(s) produto(s) transportado(s) com certificado
          de aprovação (CA) atendendo à legislação."
        />
      )}
    </>
  );
};

const DetalhesPerg25 = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <>
      <DivInfo onClick={toggleModal} />
      {modalOpen && (
        <ModalComp
          open={modalOpen}
          handleClose={toggleModal}
          content="h) Capacete de segurança com CA atendendo à legislação."
        />
      )}
    </>
  );
};

const DetalhesPerg26 = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <>
      <DivInfo onClick={toggleModal} />
      {modalOpen && (
        <ModalComp
          open={modalOpen}
          handleClose={toggleModal}
          content="i) Óculos de segurança tipo ampla visão com CA atendendo à legislação."
        />
      )}
    </>
  );
};

const DetalhesPerg27 = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <>
      <DivInfo onClick={toggleModal} />
      {modalOpen && (
        <ModalComp
          open={modalOpen}
          handleClose={toggleModal}
          content="j) Peça facial inteira ou semifacial com filtro, quando apropriado ao produto,
          com CA atendendo à legislação."
        />
      )}
    </>
  );
};

const DetalhesPerg28 = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <>
      <DivInfo onClick={toggleModal} />
      {modalOpen && (
        <ModalComp
          open={modalOpen}
          handleClose={toggleModal}
          content="k) Filtros, quando aplicável: verificar o tipo e a validade do fabricante, com CA
          atendendo à legislação."
        />
      )}
    </>
  );
};

const DetalhesPerg29 = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <>
      <DivInfo onClick={toggleModal} />
      {modalOpen && (
        <ModalComp
          open={modalOpen}
          handleClose={toggleModal}
          content="a) Painéis de segurança (quando aplicável) de acordo com o(s) produto(s):
          Verificar retângulo de cor laranja, medindo 300 mm × 400 mm, com uma borda
          de 10 mm na cor preta, o tamanho pode ser reduzido para 250 mm × 350 mm
          quando for utilizado em veículos com capacidade de até 3 500 kg. Verificar o
          verso, que não pode apresentar outro produto diferente do transportado; Verificar:
          os algarismos podem ser adesivos, porém não podem ser removíveis. Verificar
          se o painel está colocado um na frente e um na traseira (ambos do lado do
          condutor) e um em cada lateral.
          
          No transporte de apenas um produto, os painéis de segurança devem apresentar
          número de risco (quando aplicável) e nº ONU; no transporte de mais de um
          produto de mesmo risco ou de riscos diferentes, os painéis de segurança não
          podem apresentar números."
        />
      )}
    </>
  );
};

const DetalhesPerg30 = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <>
      <DivInfo onClick={toggleModal} />
      {modalOpen && (
        <ModalComp
          open={modalOpen}
          handleClose={toggleModal}
          content="b) Rótulos de risco (quando aplicável) de acordo com o(s) produto(s): Verificar
          o quadrado apoiado sobre um de seus ângulos de 45°, com medida de
          300 mm × 300 mm para os veículos, com uma linha a 12,5 mm da borda, em todo
          o perímetro, da mesma cor do símbolo, devendo a linha interna da borda ter no
          mínimo 2 mm; o tamanho pode ser reduzido para 250 mm × 250 mm quando for
          utilizado em veículos com capacidade de até 3 500 kg; para embalagens, mínimo
          de 100 mm × 100 mm, com uma linha a 5 mm da borda, podendo estar contido
          no rótulo de segurança. Pode ser utilizado tamanho inferior quando o volume
          não permitir. Verificar o verso que não pode apresentar classe de risco diferente.
          Verificar se a cor varia em função da classe de risco, podendo o símbolo e
          o texto ser de cor branca ou preta, quando o fundo for de cor verde, vermelha e
          azul. Verificar os rótulos no transporte de um produto ou de mais de um produto
          de mesmo risco, devendo ser colocado nas laterais e traseira do veículo.

          Para radioativos, os rótulos de risco devem ser afixados nos quatro lados da
          parte externa de um contêiner ou tanque."
        />
      )}
    </>
  );
};

const DetalhesPerg31 = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <>
      <DivInfo onClick={toggleModal} />
      {modalOpen && (
        <ModalComp
          open={modalOpen}
          handleClose={toggleModal}
          content="c) Veículos e equipamentos carregados com substâncias que apresentam
                    risco para o meio ambiente (ONU 3077 e ONU 3082), quando aplicável,
                    devem portar, nas laterais, na frente e na traseira, o símbolo indicado na
                    ABNT NBR 7500 (símbolo para o transporte de substâncias perigosas para
                    o meio ambiente). Verificar se o símbolo atende à ABNT NBR 7500."
        />
      )}
    </>
  );
};

const DetalhesPerg32 = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <>
      <DivInfo onClick={toggleModal} />
      {modalOpen && (
        <ModalComp
          open={modalOpen}
          handleClose={toggleModal}
          content="d) Veículos e equipamentos carregados com substância à temperatura
          elevada (líquida igual ou superior a 100 °C ou sólida igual ou superior a 240 °C),
          independentemente da quantidade. Deve portar nas laterais, na frente e na
          traseira o símbolo indicado na ABNT NBR 7500 (símbolo para o transporte à
          temperatura elevada). Verificar se o símbolo atende à ABNT NBR 7500."
        />
      )}
    </>
  );
};

const DetalhesPerg33 = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <>
      <DivInfo onClick={toggleModal} />
      {modalOpen && (
        <ModalComp
          open={modalOpen}
          handleClose={toggleModal}
          content="e) Veículos e equipamentos sem sinalização imprópria: Verificar se o veículo
          não utiliza rótulos de risco, painéis de segurança e demais símbolos como
          forma de publicidade, decoração e propaganda. Verificar se o veículo não tem
          publicidade, propaganda, marca, decorações, imagens, informações, bem como
          inscrição de produtos para uso e consumo humano ou animal, para não induzir
          ao erro quando da operação de emergência."
        />
      )}
    </>
  );
};

const DetalhesPerg34 = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <>
      <DivInfo onClick={toggleModal} />
      {modalOpen && (
        <ModalComp
          open={modalOpen}
          handleClose={toggleModal}
          content="a) Nº ONU, nome apropriado para embarque (quando aplicável), rótulo de
          risco (quando aplicável), setas de orientação (quando aplicável), símbolos
          (quando aplicáveis): Verificar se atendem à ABNT NBR 7500.
          
          Para os IBC, essas informações devem ser colocadas em dois lados opostos e para
          os radioativos da classe 7, essas informações devem constar nos quatro lados.
          
          As embalagens singelas ou combinadas contendo ONU 3077 e ONU 3082 com
          capacidade superior a 5 L (líquidos) e 5 kg (sólidos) devem ser marcadas com o
          símbolo para o transporte de substâncias perigosas para o meio ambiente."
        />
      )}
    </>
  );
};

const DetalhesPerg35 = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <>
      <DivInfo onClick={toggleModal} />
      {modalOpen && (
        <ModalComp
          open={modalOpen}
          handleClose={toggleModal}
          content="b) Identificação da homologação de embalagem (quando aplicável) para produtos
          perigosos, exceto as isenções previstas em legislação. Verificar a marcação de
          homologação (Inmetro, Marinha ou Aérea) e demais marcações na embalagem
          para produtos nacionais e importados. Verificar a marcação de homologação do
          país de origem."
        />
      )}
    </>
  );
};

const DetalhesPerg36 = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <>
      <DivInfo onClick={toggleModal} />
      {modalOpen && (
        <ModalComp
          open={modalOpen}
          handleClose={toggleModal}
          content="c) Organização da carga: Verificar se toda a carga transportada está devidamente
          amarrada, ancorada e acondicionada no compartimento de carga ou superfície de
          carregamento do veículo ou equipamento de transporte. Verificar se os dispositivos
          de amarração atendem à legislação.

          Devem ser utilizados dispositivos de amarração, como cintas têxteis, correntes
          ou cabos de aço, com resistência total à ruptura por tração de, no mínimo, duas
          vezes o peso da carga, bem como dispositivos adicionais, como barras de
          contenção, trilhos, malhas, redes, calços, mantas de atrito, separadores, bloqueadores,
          protetores, etc., além de pontos de amarração adequados e em número suficiente.

          É proibida a utilização de cordas como dispositivo de amarração de carga. Nos
          veículos do tipo carroçaria aberta, com guardas laterais rebatíveis, no caso de
          haver espaço entre a carga e as guardas laterais, os dispositivos de amarração
          devem ser tensionados pelo lado interno das guardas laterais. É proibida a
          passagem dos dispositivos pelo lado externo das guardas laterais. Os pontos de
          amarração não podem estar fixados exclusivamente no piso de madeira, e sim
          fixados na parte metálica da carroçaria ou no próprio chassi."
        />
      )}
    </>
  );
};

const DetalhesPerg37 = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <>
      <DivInfo onClick={toggleModal} />
      {modalOpen && (
        <ModalComp
          open={modalOpen}
          handleClose={toggleModal}
          content="a) Cronotacógrafo, registrador inalterável de velocidade e tempo: Verificar se
                    existem no veículo. Verificar se estão em funcionamento. Verifricar se há lacre
                    do Inmetro. Verificar se porta o Certificado de Aferição e se está na validade e,
                    quando aplicável, se há disco-diagrama reserva.

                    O cronotacógrafo é obrigatório no transporte a granel e fracionado (CTB) para
                    os veículos automotores de transporte de carga com peso bruto total (PBT)
                    superior a 4 536 kg."
        />
      )}
    </>
  );
};

const DetalhesPerg38 = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <>
      <DivInfo onClick={toggleModal} />
      {modalOpen && (
        <ModalComp
          open={modalOpen}
          handleClose={toggleModal}
          content="b) Pneus e rodas: Verificar se está em bom estado e a profundidade mínima do sulco dos pneus que deve ser de 1,6 mm."
        />
      )}
    </>
  );
};

const DetalhesPerg39 = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <>
      <DivInfo onClick={toggleModal} />
      {modalOpen && (
        <ModalComp
          open={modalOpen}
          handleClose={toggleModal}
          content="c) Pneu(s) sobressalente(s): Verificar compatibilidade com os demais pneus."
        />
      )}
    </>
  );
};

const DetalhesPerg40 = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <>
      <DivInfo onClick={toggleModal} />
      {modalOpen && (
        <ModalComp
          open={modalOpen}
          handleClose={toggleModal}
          content="d) Equipamentos obrigatórios: Verificar se o veículo possui chave de rodas, macaco
          e triângulo de segurança, e se estão em condições de uso."
        />
      )}
    </>
  );
};

const DetalhesPerg41 = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <>
      <DivInfo onClick={toggleModal} />
      {modalOpen && (
        <ModalComp
          open={modalOpen}
          handleClose={toggleModal}
          content="e) Dispositivos de controle: faróis alto e baixo, buzina, velocímetro, lanternas de
          posição, indicador de mudança de direção (seta/pisca), luzes de freio, iluminação
          de placa traseira e, quando aplicável, luz de ré; verificar se estão funcionando."
        />
      )}
    </>
  );
};

const DetalhesPerg42 = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <>
      <DivInfo onClick={toggleModal} />
      {modalOpen && (
        <ModalComp
          open={modalOpen}
          handleClose={toggleModal}
          content="f) Películas retrorrefletivas nas laterais, na traseira e no para-choque. Verificar se
          o veículo possui, se estão afixadas, se estão na quantidade correta e de acordo
          com a proporção exigida."
        />
      )}
    </>
  );
};

const DetalhesPerg43 = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <>
      <DivInfo onClick={toggleModal} />
      {modalOpen && (
        <ModalComp
          open={modalOpen}
          handleClose={toggleModal}
          content="g) Equipamento/carroçaria. Verificar se estão em boas condições."
        />
      )}
    </>
  );
};

const DetalhesPerg44 = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <>
      <DivInfo onClick={toggleModal} />
      {modalOpen && (
        <ModalComp
          open={modalOpen}
          handleClose={toggleModal}
          content="h) Tanque de combustível: Verifiar se está fechado e sem vazamento."
        />
      )}
    </>
  );
};

const DetalhesPerg45 = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <>
      <DivInfo onClick={toggleModal} />
      {modalOpen && (
        <ModalComp
          open={modalOpen}
          handleClose={toggleModal}
          content="i) 5ª (quinta) roda e conjunto de engate: Verificar se estão íntegros."
        />
      )}
    </>
  );
};

const DetalhesPerg46 = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <>
      <DivInfo onClick={toggleModal} />
      {modalOpen && (
        <ModalComp
          open={modalOpen}
          handleClose={toggleModal}
          content="j) Para-choque dianteiro/traseiro Verificar se estão em boas condições. Verificar
          no parachoque traseiro a afixação das plaquetas do Inmetro e das faixas refletivas."
        />
      )}
    </>
  );
};

const DetalhesPerg47 = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <>
      <DivInfo onClick={toggleModal} />
      {modalOpen && (
        <ModalComp
          open={modalOpen}
          handleClose={toggleModal}
          content="k) Cinta protetora do eixo cardan: Verificar se está íntegra, em bom estado e fixada
          adequadamente."
        />
      )}
    </>
  );
};

const DetalhesPerg48 = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <>
      <DivInfo onClick={toggleModal} />
      {modalOpen && (
        <ModalComp
          open={modalOpen}
          handleClose={toggleModal}
          content="l) Fiação elétrica Verificar se está devidamente isolada e fixada."
        />
      )}
    </>
  );
};

const DetalhesPerg49 = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <>
      <DivInfo onClick={toggleModal} />
      {modalOpen && (
        <ModalComp
          open={modalOpen}
          handleClose={toggleModal}
          content="m) Para-lamas (dianteiros e traseiros) Verificar se estão em condições de funcionamento."
        />
      )}
    </>
  );
};

const DetalhesPerg50 = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <>
      <DivInfo onClick={toggleModal} />
      {modalOpen && (
        <ModalComp
          open={modalOpen}
          handleClose={toggleModal}
          content="n) Espelho retrovisor externo, limpador de para-brisa: Verificar se estão em
          condições de funcionamento."
        />
      )}
    </>
  );
};

const DetalhesPerg51 = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <>
      <DivInfo onClick={toggleModal} />
      {modalOpen && (
        <ModalComp
          open={modalOpen}
          handleClose={toggleModal}
          content="o) Freios de serviço e estacionamento Verificar se estão em condições de
          funcionamento (item específico do transportador)."
        />
      )}
    </>
  );
};

const DetalhesPerg52 = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <>
      <DivInfo onClick={toggleModal} />
      {modalOpen && (
        <ModalComp
          open={modalOpen}
          handleClose={toggleModal}
          content="p) Quebra-sol: pala interna de proteção contra o sol, para o condutor. Verificar se
          está em condições de funcionamento"
        />
      )}
    </>
  );
};

const DetalhesPerg53 = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <>
      <DivInfo onClick={toggleModal} />
      {modalOpen && (
        <ModalComp
          open={modalOpen}
          handleClose={toggleModal}
          content="q) Para-barro, quando exigido: protetores das rodas traseiras. Verificar se está
          em condições de funcionamento."
        />
      )}
    </>
  );
};

const DetalhesPerg54 = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <>
      <DivInfo onClick={toggleModal} />
      {modalOpen && (
        <ModalComp
          open={modalOpen}
          handleClose={toggleModal}
          content="r) Cintos de segurança: para todos os ocupantes. Verificar se estão em condições
          de funcionamento."
        />
      )}
    </>
  );
};

const DetalhesPerg55 = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <>
      <DivInfo onClick={toggleModal} />
      {modalOpen && (
        <ModalComp
          open={modalOpen}
          handleClose={toggleModal}
          content="s) Limpeza e descontaminação de resíduos: Verificar: se houve a limpeza ou
          descontaminação de resíduos de carregamentos anteriores, antes da operação
          de carregamento."
        />
      )}
    </>
  );
};

const DetalhesPerg56 = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <>
      <DivInfo onClick={toggleModal} />
      {modalOpen && (
        <ModalComp
          open={modalOpen}
          handleClose={toggleModal}
          content="Verificar:
          
            — estanqueidade. Verificar: 1) se não há vazamento em locais de solda, boca
          de visita e válvulas de descarga; 2) se nos equipamentos de transporte
          de corrosivos não há perfurações na estrutura do equipamento devido
          ao comprometimento do revestimento interno e à ação do produto;"
        />
      )}
    </>
  );
};

const DetalhesPerg57 = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <>
      <DivInfo onClick={toggleModal} />
      {modalOpen && (
        <ModalComp
          open={modalOpen}
          handleClose={toggleModal}
          content="u) Equipamento de autoaquecimento: Verificar se o veículo não possui aparelho
          ou equipamento de aquecimento sujeito à combustão, a gás ou elétrico (fogão,
          fogareiro ou semelhantes), assim como os produtos combustíveis necessários ao
          seu funcionamento, ou quaisquer recipientes ou dispositivos capazes de produzir
          ignição dos produtos, seus gases ou vapores, bem como reservatório extra de
          combustível, exceto se permitido pela legislação de trânsito."
        />
      )}
    </>
  );
};

export {
  DetalhesPerg1,
  DetalhesPerg2,
  DetalhesPerg3,
  DetalhesPerg4,
  DetalhesPerg5,
  DetalhesPerg6,
  DetalhesPerg7,
  DetalhesPerg8,
  DetalhesPerg9a,
  DetalhesPerg9b,
  DetalhesPerg10,
  DetalhesPerg11,
  DetalhesPerg12,
  DetalhesPerg13,
  DetalhesPerg14a,
  DetalhesPerg14b,
  DetalhesPerg14c,
  DetalhesPerg14d,
  DetalhesPerg15a,
  DetalhesPerg15b,
  DetalhesPerg16,
  DetalhesPerg17,
  DetalhesPerg18,
  DetalhesPerg19,
  DetalhesPerg20,
  DetalhesPerg21,
  DetalhesPerg22,
  DetalhesPerg23,
  DetalhesPerg24,
  DetalhesPerg25,
  DetalhesPerg26,
  DetalhesPerg27,
  DetalhesPerg28,
  DetalhesPerg29,
  DetalhesPerg30,
  DetalhesPerg31,
  DetalhesPerg32,
  DetalhesPerg33,
  DetalhesPerg34,
  DetalhesPerg35,
  DetalhesPerg36,
  DetalhesPerg37,
  DetalhesPerg38,
  DetalhesPerg39,
  DetalhesPerg40,
  DetalhesPerg41,
  DetalhesPerg42,
  DetalhesPerg43,
  DetalhesPerg44,
  DetalhesPerg45,
  DetalhesPerg46,
  DetalhesPerg47,
  DetalhesPerg48,
  DetalhesPerg49,
  DetalhesPerg50,
  DetalhesPerg51,
  DetalhesPerg52,
  DetalhesPerg53,
  DetalhesPerg54,
  DetalhesPerg55,
  DetalhesPerg56,
  DetalhesPerg57,
};
