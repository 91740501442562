import React, { useState } from 'react';
import { Button, Menu, MenuItem, Typography, useMediaQuery, useTheme } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import LockIcon from '@mui/icons-material/Lock';
import BusinessIcon from '@mui/icons-material/Business';
import { useNavigate } from 'react-router-dom';

const ConfigUser = ({ user, handleLogout }) => {
  const history = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangePassword = () => {
    handleClose();
    history("/usuario/alterar-senha");
  };

  const handleSwitchBranch = () => {
    handleClose();
    history("/usuario/alterar-empresa");
  };

  return (
    <>
      <Button
        color="inherit"
        onClick={handleClick}
        sx={{
          minWidth: isSmallScreen ? '31px' : '42px',
          width: isSmallScreen ? '31px' : '42px',
          height: isSmallScreen ? '31px' : '42px',
          fontSize: isSmallScreen ? '0.7540em' : '1.0em',
          padding: '6px',
          borderRadius: 3800,
          marginRight: '0px',
          fontWeight: 'bold',
          color: 'white',
          backgroundColor: '#2957A4',
          '&:hover': {
            backgroundColor: '#173f69',
          },
        }}
      >
        <Typography variant="button" sx={{ fontSize: isSmallScreen ? '0.8rem' : '0.9rem', fontWeight: 'bold', marginTop: '2px' }}>
          {user.substring(0, 2)}
        </Typography>
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{ mt: '5px' }}
      >
        <MenuItem onClick={handleChangePassword}>
          <LockIcon sx={{ marginRight: '0px' }} />
          <Typography>Alterar Senha</Typography>
        </MenuItem>
        <MenuItem onClick={handleSwitchBranch}>
          <BusinessIcon sx={{ marginRight: '0px' }} />
          <Typography>Alterar Empresa</Typography>
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <LogoutIcon sx={{ marginRight: '0px' }} />
          <Typography>Sair</Typography>
          
        </MenuItem>
      </Menu>
    </>
  );
};

export default ConfigUser;
