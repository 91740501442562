import React from "react";
import { AppBar, Toolbar, Grid, useMediaQuery } from "@mui/material";
import logo from "../assets/Logo-Grupo-Sumatex.png";
import logo_sumatex from "../assets/logo.png";
import logo_ambiental from "../assets/Logo-Sumatex-Ambiental.png";
import logo_agro from "../assets/Logo-Sumatex-Agro.png";
import logo_siga from "../assets/logo_SIGA.png";
import logo_lorenvel from "../assets/Logo-Lorenvel.png";
import logo_cesbra from "../assets/Logo-Cesbra.png";
import "../styles/Consulta.css";
import ConfigUser from "./ConfigUser";
import ServiceDesk from "./ServiceDesk";
// import Notifications from "./Notifications";

const Navbar = ({ user, onLogout }) => {   //, notifications
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const Filial = sessionStorage.getItem("empresa");

  const handleLogout = () => {
    sessionStorage.removeItem("user");
    sessionStorage.removeItem("empresa");
    sessionStorage.removeItem("grupo");
    sessionStorage.removeItem("codFuncionarios");
    sessionStorage.removeItem("codFuncoes");
    sessionStorage.removeItem("usuarioCryptado");
    sessionStorage.removeItem("sessionExpiration");
    onLogout();
  };

  return (
    <AppBar position="static" color="primary" sx={{ backgroundColor: "#D3D3D3" }}>
      <Toolbar>
        <Grid container alignItems="center">
          <Grid item xs={4} sx={{ display: "flex", alignItems: "center" }}>
            {!(Filial === "1 - MAT" || Filial === "7 - CES" || Filial === "2 - AMB" || Filial === "9 - LOG" || Filial === "5 - FMT") && (
              <img
                src={logo}
                alt="Logo"
                style={{
                  marginRight: "10px",
                  height: isSmallScreen ? "20px" : "32px",
                }}
              />
            )}
            {Filial === "1 - MAT" && (
              <img
                src={logo_sumatex}
                alt="Logo"
                style={{
                  marginRight: "10px",
                  height: isSmallScreen ? "20px" : "30px",
                }}
              />
            )}
            {Filial === "5 - FMT" && (
              <img
                src={logo_agro}
                alt="Logo"
                style={{
                  marginRight: "10px",
                  height: isSmallScreen ? "20px" : "30px",
                }}
              />
            )}
            {Filial === "7 - CES" && (
              <img
                src={logo_cesbra}
                alt="Logo"
                style={{
                  marginRight: "10px",
                  height: isSmallScreen ? "40px" : "50px",
                  width: isSmallScreen ? "40px" : "50px",
                }}
              />
            )}
            {Filial === "2 - AMB" && (
              <img
                src={logo_ambiental}
                alt="Logo"
                style={{
                  marginRight: "10px",
                  height: isSmallScreen ? "20px" : "32px",
                }}
              />
            )}
            {Filial === "9 - LOG" && (
              <img
                src={logo_lorenvel}
                alt="Logo"
                style={{
                  marginRight: "10px",
                  height: isSmallScreen ? "30px" : "50px",
                }}
              />
            )}
          </Grid>
          <Grid item xs={4} sx={{ display: "flex", justifyContent: "center" }}>
            <img
              src={logo_siga}
              alt="Logo"
              style={{
                height: isSmallScreen ? "25px" : "50px",
              }}
            />
          </Grid>
          <Grid item xs={4} sx={{ display: "flex", justifyContent: "flex-end" }}>
          {user && (
              <>
                {/* <div style={{marginRight: "20px",}}><Notifications notifications={[]} /></div> */}
                <div style={{width: "42px", marginRight: "20px",}}><ServiceDesk user={user} handleLogout={handleLogout} /></div>

              </>
            )}
            {user && (
              <>
                {/* <div style={{marginRight: "20px",}}><Notifications notifications={[]} /></div> */}
                <div style={{width: "42px",}}><ConfigUser user={user} handleLogout={handleLogout} /></div>

              </>
            )}
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
