import React, { useState, useEffect } from "react";
import { Box, useMediaQuery, useTheme, CircularProgress, Typography } from "@mui/material";
import { Axios } from "../config/axiosConf";
import { styled } from "@mui/material/styles";
import { DataGrid } from "@mui/x-data-grid";
import { ptBR } from '@mui/x-data-grid/locales';

const StyledGridOverlay = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  '& .no-rows-primary': {
    fill: theme.palette.mode === 'light' ? '#AEB8C2' : '#3D4751',
  },
  '& .no-rows-secondary': {
    fill: theme.palette.mode === 'light' ? '#E8EAED' : '#1D2126',
  },
}));

function CustomNoRowsOverlay() {
  return (
    <StyledGridOverlay>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        width={96}
        viewBox="0 0 452 257"
        aria-hidden
        focusable="false"
      >
        <path
          className="no-rows-primary"
          d="M348 69c-46.392 0-84 37.608-84 84s37.608 84 84 84 84-37.608 84-84-37.608-84-84-84Zm-104 84c0-57.438 46.562-104 104-104s104 46.562 104 104-46.562 104-104 104-104-46.562-104-104Z"
        />
        <path
          className="no-rows-primary"
          d="M308.929 113.929c3.905-3.905 10.237-3.905 14.142 0l63.64 63.64c3.905 3.905 3.905 10.236 0 14.142-3.906 3.905-10.237 3.905-14.142 0l-63.64-63.64c-3.905-3.905-3.905-10.237 0-14.142Z"
        />
        <path
          className="no-rows-primary"
          d="M308.929 191.711c-3.905-3.906-3.905-10.237 0-14.142l63.64-63.64c3.905-3.905 10.236-3.905 14.142 0 3.905 3.905 3.905 10.237 0 14.142l-63.64 63.64c-3.905 3.905-10.237 3.905-14.142 0Z"
        />
        <path
          className="no-rows-secondary"
          d="M0 10C0 4.477 4.477 0 10 0h380c5.523 0 10 4.477 10 10s-4.477 10-10 10H10C4.477 20 0 15.523 0 10ZM0 59c0-5.523 4.477-10 10-10h231c5.523 0 10 4.477 10 10s-4.477 10-10 10H10C4.477 69 0 64.523 0 59ZM0 106c0-5.523 4.477-10 10-10h203c5.523 0 10 4.477 10 10s-4.477 10-10 10H10c-5.523 0-10-4.477-10-10ZM0 153c0-5.523 4.477-10 10-10h195.5c5.523 0 10 4.477 10 10s-4.477 10-10 10H10c-5.523 0-10-4.477-10-10ZM0 200c0-5.523 4.477-10 10-10h203c5.523 0 10 4.477 10 10s-4.477 10-10 10H10c-5.523 0-10-4.477-10-10ZM0 247c0-5.523 4.477-10 10-10h231c5.523 0 10 4.477 10 10s-4.477 10-10 10H10c-5.523 0-10-4.477-10-10Z"
        />
      </svg>
      <div style={{ mt: 2, marginTop: "5px", }}>Você ainda não têm pendências</div>
    </StyledGridOverlay>
  );
}

// Estilos para o título
const StyledTitle = styled(Typography)`
  font-weight: bold;
  font-size: 1.5em;
  margin: 0em 0;
  background-color: #2957a4;
  color: #fff;
  padding: 10px;
  text-align: center;
  @media (max-width: 550px) {
    font-size: 1.2em;
  }
`;

const StyledOrange = styled(Typography)`
    background-color: #D66E20;
    padding-top: 3px;
    padding-bottom: 3px;
  @media (max-width: 550px) {
    font-size: 1.2em;
  }
`;

const StyledGreen = styled(Typography)`
    background-color: #7CA217;
    padding-top: 3px;
    padding-bottom: 3px;
  @media (max-width: 550px) {
    font-size: 1.2em;
  }
`;

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  '& .MuiDataGrid-columnHeader': {
    backgroundColor: '#D3D3D3',
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    fontWeight: 'bold',
  },
  '& .MuiDataGrid-scrollbarFiller': {
    backgroundColor: '#D3D3D3',
  },
  '& .MuiDataGrid-filler': {
    backgroundColor: '#D3D3D3',
  }
}));

const Home = () => {
  const [pendencias, setPendencias] = useState([]);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPendencias = async () => {
      const usuario = sessionStorage.getItem("user");
      try {
        const response = await Axios.post("/pendencias", { usuario: usuario });
        setPendencias(response.data);
      } catch (error) {
        console.error("Erro ao buscar pendências:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPendencias();
  }, []);

  useEffect(() => {
    if (pendencias.length > 0) {
      const rows = pendencias.map((pendencia, index) => {
        return {
          ...pendencia,
          id: index,
        };
      });

      const columns = [
        { field: "CODIGO", headerName: "Código", width: isSmallScreen ? 80 : 100 },
        { field: "ORIGEM", headerName: "Origem", width: isSmallScreen ? 120 : 250 },
        { field: "DESCRICAO", headerName: "Descrição", width: isSmallScreen ? 200 : 270 },
        { field: "DATA", headerName: "Data", width: isSmallScreen ? 80 : 100 },
        { field: "RESPONSAVEL", headerName: "Responsável", width: isSmallScreen ? 120 : 200 },
      ];

      setRows(rows);
      setColumns(columns);
    }
  }, [pendencias, isSmallScreen]);

  return (
    <div>
      <StyledOrange />
      <StyledTitle id="title"></StyledTitle>
      <StyledGreen />
      <div>
        <div>
          {loading ? (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }}>
              <CircularProgress size={60} />
            </div>
          ) : (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", marginTop: "10px" }}>
              <Box
                sx={{
                  height: '600px',
                  width: '95%',
                  maxWidth: 950,
                  backgroundColor: '#f7f7f7',
                  borderRadius: '8px',
                  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                }}>
                <StyledDataGrid
                  rows={rows}
                  columns={columns}
                  slots={{
                    noRowsOverlay: CustomNoRowsOverlay,
                  }}
                  pageSizeOptions={[5, 10, 20, 50]}
                  initialState={{
                    pagination: {
                      paginationModel: { page: 0, pageSize: 20 },
                    },
                    columns: {
                      columnVisibilityModel: {
                        id: false,
                      },
                    },
                  }}
                  localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                  disableSelectionOnClick
                />
              </Box>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Home;
