import React, { useState, useEffect } from "react";
import { TextField, Autocomplete } from "@mui/material/";
import { Axios } from "../../config/axiosConf";
import styled from "@emotion/styled";

const StyledH2 = styled.h2`
    display: block;
    font-size: 1em;
    font-weight: bold;
    padding-left: 15px;
`;

const StyledTextField = styled(TextField)`
  width: 380px;
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 500px;
`;

const StyledAutocomplete = styled(Autocomplete)`
    width: ${({ isSmallScreen }) => (isSmallScreen ? "100%" : "380px")};

    .MuiButtonBase-root {
        width: 40px;
    }
`;

const VeiculosReboquePortaria = ({ placaVeiculoReboque, setPlacaVeiculoReboque, numeroEixosVeiculoReboque, setNumeroEixosVeiculoReboque }) => {

    const [carros, setCarros] = useState([]);
    const [isSmallScreen, setIsSmallScreen] = useState(window.matchMedia("(max-width: 750px)").matches);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.matchMedia("(max-width: 750px)").matches);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        const getCarros = async () => {
            try {
                const response = await Axios.get("a60h7H-yK3gww2uXs3chAg");
                setCarros(response.data);
            } catch (error) {
                console.log(error);
            }
        };
        getCarros();
    }, []);

    useEffect(() => {
        const selectedPlacaReboque = carros.find(
            (carro) => carro.placa === placaVeiculoReboque
        );
        if (selectedPlacaReboque) {
            setNumeroEixosVeiculoReboque(selectedPlacaReboque.eixos);
        }
    }, [placaVeiculoReboque, setNumeroEixosVeiculoReboque, carros]);

    const enviarPlacaVeiculoReboque = (event, newValue) => {
        setPlacaVeiculoReboque(newValue);
    };

    const enviarNumeroDeEixosVeiculoReboque = (event) => {
        setNumeroEixosVeiculoReboque(event.target.value);
    };
    const handlePlacaChange = (event) => {
        const { value } = event.target;
        const sanitizedValue = value.replace(/[^a-zA-Z0-9]/g, "").substring(0, 7);
        setPlacaVeiculoReboque(sanitizedValue);
      };
    return (
        <>
            <StyledH2>1.6 - Equipamento/Reboque/Semirreboque:</StyledH2>
            <Label id="labelPlacaVeiculoReboque">
                <StyledAutocomplete
                    id="placaVeiculoReboque"
                    freeSolo
                    options={carros.map((carro) => carro.placa)}
                    value={placaVeiculoReboque}
                    onChange={(event, newValue) => enviarPlacaVeiculoReboque(event, newValue)}
                    onBlur={(event) => setPlacaVeiculoReboque(event.target.value)}
                    renderInput={(params) => (
                        <StyledTextField
                            {...params}
                            id="text-placaVeiculoReboque"
                            name="text-placaVeiculoReboque"
                            label="Digite a placa do veículo de reboque"
                            inputProps={{
                                ...params.inputProps,
                                maxLength: 7,
                                onChange: handlePlacaChange
                              }}
                            required
                        />
                    )}
                    isSmallScreen={isSmallScreen}
                    isOptionEqualToValue={(option, value) => option === value}

                />
            </Label>

            <Label id="labelEixosVeiculoReboque">
                <TextField
                    id="eixosVeiculoReboque"
                    name="eixosVeiculoReboque"
                    placeholder="Digite a quantidade de eixos do veículo de reboque..."
                    required
                    value={numeroEixosVeiculoReboque}
                    sx={{
                        width: 300,
                    }}
                    onChange={enviarNumeroDeEixosVeiculoReboque}
                    multiline
                />
            </Label>
        </>
    );
};

export default VeiculosReboquePortaria;