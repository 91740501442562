import {
    Box,
    Drawer,
    IconButton,
    List,
    ListItemIcon,
    ListItemText,
    Collapse,
    ListItemButton,
    useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import { FaHome, FaRegClipboard, FaClipboardList, FaChartLine } from "react-icons/fa";
import { TbCoin, TbCoins } from "react-icons/tb";
import { IoMdAnalytics } from "react-icons/io";
import { ImTable } from "react-icons/im"
import { GoGraph } from "react-icons/go";
import { FiMenu, FiX } from "react-icons/fi";
import { GiOpenGate, GiPoliceBadge, GiBrazil, GiReceiveMoney } from "react-icons/gi";
import { RiMoneyDollarCircleLine } from "react-icons/ri";
import { TbReportSearch, TbReportAnalytics, TbChartInfographic, TbBuildingWarehouse } from "react-icons/tb";
import { AiOutlineStock } from "react-icons/ai";
import { IoIosPeople } from "react-icons/io";
import { MdSecurity, MdOutlineLocalPolice } from "react-icons/md";
import { FaFileContract } from "react-icons/fa";
import { LiaCoinsSolid } from "react-icons/lia";
import { FaTruck } from "react-icons/fa";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { SummaryKpi } from '@carbon/icons-react';
import "../styles/Consulta.css";



const Sidebar = () => {
    const isSmallScreen = useMediaQuery("(max-width: 550px)");

    const drawerWidth = isSmallScreen ? 240 : 300;
    const iconMarginLeft = isSmallScreen ? (drawerWidth - 45) : (drawerWidth - 45);
    const menuButtonWidth = isSmallScreen ? 40 : 60;

    const theme = createTheme();

    const ToolbarStyled = styled('div')({
        margin: '16px 0',
    });

    const MenuButtonStyled = styled(IconButton)({
        borderRadius: '50%',
        width: 40,
    });

    const MenuButtonXStyled = styled(IconButton)({
        padding: 8,
        borderRadius: '50%',
        width: 40,
        marginLeft: 280,
    });

    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isPortariaDropdownOpen, setIsPortariaDropdownOpen] = useState(false);
    const [isConsultasDropdownOpen, setIsConsultasDropdownOpen] = useState(false);
    const [isConsultaGeralDropdownOpen, setIsConsultaGeralDropdownOpen] = useState(false);
    const [isProgDropdownOpen, setIsProgDropdownOpen] = useState(false);
    const [isFinanceirogDropdownOpen, setIsFinanceiroDropdownOpen] = useState(false);
    const [isContabilidadeDropdownOpen, setIsContabilidadeDropdownOpen] = useState(false);
    const [isQSMSDropdownOpen, setIsQSMSDropdownOpen] = useState(false);
    const [isContasaPagarDropdownOpen, setIsContasaPagarDropdownOpen] = useState(false);
    const [isContasaReceberDropdownOpen, setIsContasaReceberDropdownOpen] = useState(false);
    const [isModGerencialDropdownOpen, setIsModGerencialDropdownOpen] = useState(false);
    const [isConsultasContDropdownOpen, setIsConsultasContDropdownOpen] = useState(false);
    const [isConsultasQSMSDropdownOpen, setIsConsultasQSMSDropdownOpen] = useState(false);
    const [isEstoqueDropdownOpen, setIsEstoqueDropdownOpen] = useState(false);
    const [isAnaliseResultDropdownOpen, setIsAnaliseResultDropdownOpen] = useState(false);
    const [isRelatoriosDropdownOpen, setIsRelatoriosDropdownOpen] = useState(false);
    // const [isExibirDropdownOpen, setIsExibirDropdownOpen] = useState(false);
    const grupo = sessionStorage.getItem("grupo");
    const grupoGerente = ["8f498e6aabb107de59d0ad2177f6d882", "eb079e95b9e417370bdbb1e6171d466e", "96bb3ed33608f46604c7347013f90672"];// GE, C-GE, RC
    const grupoGerQSMS = ["9af77df26110dfd9ac70a1af85a413de"];// QU
    const grupoLabQSMS = ["f374586dc8f1e1b751acad96b8cfa564", "fc64917287fb54d821366203d5989b9f"]; //LA e C-LA
    const grupoLabQSMSCes = ["fc64917287fb54d821366203d5989b9f"]; //C-LA
    const grupoRH = ["739aa873c4e8cd26a48f65f630d8206b", "13b3595e583eb597544b54fe5856668b"];// RH, C-RH
    const grupoComercial = ["8afd0cfe8b54b9b1037e21d6dd23c4aa", "5ce485cbde9d581b03f410f13703202a"]; // CO, C-CO
    const grupoComprasCes = ["5ce485cbde9d581b03f410f13703202a"]; // C-CO - Liberações específicas para Cesbra
    const grupoVendas = ["3b1a6b04b05c76438fc41a29248a9c4d", "07e55f12cce0649558dfbc2a5f710b2b"]; // VE, C-VE
    const grupoContabilidade = ["feac8e8710626d0821a8c5396cf55567", "a9a354cac3fa0021718786abfe121e75"]; //CT, C-CT
    const grupoContabilidadeCES = ["a9a354cac3fa0021718786abfe121e75"]; //C-CT
    const grupoFinanceiro = ["96bb3ed33608f46604c7347013f90672", "d39c0c0a4a119fd4c288af6358718298", "a9a354cac3fa0021718786abfe121e75"]; //RC, PG, C-CT
    const grupoeEstoque = ["9fe1f49cfb19ec11cb98af5d16478e59"]; //ES
    const grupoPortaria = ["f4c8fdc8b4d6610c2bc3312dafeaa9bf"]; //PO


    const isUserInGroupGerente = grupoGerente.includes(grupo);
    const isUserInGroupGerQSMS = grupoGerQSMS.includes(grupo);
    const isUserInGroupLabQSMS = grupoLabQSMS.includes(grupo);
    const isUserInGroupLabQSMSCes = grupoLabQSMSCes.includes(grupo);
    const isUserInGroupRH = grupoRH.includes(grupo);
    const isUserInGroupComercial = grupoComercial.includes(grupo);
    const isUserInGroupComprasCes = grupoComprasCes.includes(grupo);
    const isUserInGroupVendas = grupoVendas.includes(grupo);
    const isUserInGroupContabilidade = grupoContabilidade.includes(grupo);
    const isUserInGroupContabilidadeCES = grupoContabilidadeCES.includes(grupo);
    const isUserInGroupFinanceiro = grupoFinanceiro.includes(grupo);
    const isUserInGroupEstoque = grupoeEstoque.includes(grupo);
    const isUserInGroupPortaria = grupoPortaria.includes(grupo);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const closeSidebar = () => {
        setIsSidebarOpen(false);
        setIsDropdownOpen(false);
        setIsPortariaDropdownOpen(false)
        setIsConsultasDropdownOpen(false);
        setIsConsultaGeralDropdownOpen(false);
        setIsProgDropdownOpen(false);
        setIsFinanceiroDropdownOpen(false);
        setIsContasaPagarDropdownOpen(false);
        setIsContasaReceberDropdownOpen(false);
        setIsModGerencialDropdownOpen(false);
        setIsConsultasContDropdownOpen(false);
        setIsContabilidadeDropdownOpen(false);
        setIsEstoqueDropdownOpen(false);
        setIsAnaliseResultDropdownOpen(false);
        setIsRelatoriosDropdownOpen(false);
        setIsQSMSDropdownOpen(false);
        setIsConsultasQSMSDropdownOpen(false);
    };

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const togglePortariaDropdown = () => {
        setIsPortariaDropdownOpen(!isPortariaDropdownOpen);
    };

    const toggleConsultasDropdown = () => {
        setIsConsultasDropdownOpen(!isConsultasDropdownOpen);
    };

    const toggleConsultaGeralDropdown = () => {
        setIsConsultaGeralDropdownOpen(!isConsultaGeralDropdownOpen);
    };

    const toggleModGerencialDropdown = () => {
        setIsModGerencialDropdownOpen(!isModGerencialDropdownOpen);
    };

    const toggleConsultasContDropdown = () => {
        setIsConsultasContDropdownOpen(!isConsultasContDropdownOpen);
    };

    const toggleConsultasQSMSDropdown = () => {
        setIsConsultasQSMSDropdownOpen(!isConsultasQSMSDropdownOpen);
    };


    const toggleContasaPagarDropdown = () => {
        setIsContasaPagarDropdownOpen(!isContasaPagarDropdownOpen);
    };

    const toggleContasaReceberDropdown = () => {
        setIsContasaReceberDropdownOpen(!isContasaReceberDropdownOpen);
    };

    const toggleProgDropdown = () => {
        setIsProgDropdownOpen(!isProgDropdownOpen);
    };

    const toggleFinanceiroDropdown = () => {
        setIsFinanceiroDropdownOpen(!isFinanceirogDropdownOpen);
    };

    const toggleContabilidadeDropdown = () => {
        setIsContabilidadeDropdownOpen(!isContabilidadeDropdownOpen);
    };

    const toggleQSMSDropdown = () => {
        setIsQSMSDropdownOpen(!isQSMSDropdownOpen);
    };

    const toggleEstoqueDropdown = () => {
        setIsEstoqueDropdownOpen(!isEstoqueDropdownOpen);
    };

    const toggleAnaliseResultDropdown = () => {
        setIsAnaliseResultDropdownOpen(!isAnaliseResultDropdownOpen);
    };

    const toggleRelatoriosDropdown = () => {
        setIsRelatoriosDropdownOpen(!isRelatoriosDropdownOpen);
    };

    if (!sessionStorage.getItem("user")) {
        return null;
    }
    return (
        <ThemeProvider theme={theme}>
            <Drawer
                variant="persistent"
                anchor="left"
                open={isSidebarOpen}
                style={{
                    width: drawerWidth,
                    '& .MuiDrawerPaper': {
                        width: drawerWidth,
                    },
                }}
            >
                <ToolbarStyled>
                    <MenuButtonXStyled
                        color="inherit"
                        aria-label="close sidebar"
                        onClick={closeSidebar}
                        edge="start"

                        style={{ marginLeft: iconMarginLeft }}
                    >
                        <FiX />
                    </MenuButtonXStyled>
                </ToolbarStyled>
                <List style={{ width: drawerWidth }}>
                    <ListItemButton component="a" href="/" onClick={closeSidebar}>
                        <ListItemIcon>
                            <FaHome />
                        </ListItemIcon>
                        <ListItemText
                            primary="Tela Inicial"
                        />
                    </ListItemButton>

                    {/* Item "Estoque" */}
                    <ListItemButton onClick={toggleEstoqueDropdown}>
                        <ListItemIcon>
                            <TbBuildingWarehouse />
                        </ListItemIcon>
                        <ListItemText primary="Estoque" />
                        {isEstoqueDropdownOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </ListItemButton>
                    {/* Subitens de "Estoque" */}
                    <Collapse style={{ transition: 'height 0.3s ease-out', }} in={isEstoqueDropdownOpen} timeout="auto" unmountOnExit>
                        <List style={{
                            fontSize: 14,
                            paddingLeft: '16px',
                        }} component="div" disablePadding >
                            {(isUserInGroupGerente || isUserInGroupComercial || isUserInGroupEstoque || isUserInGroupVendas || isUserInGroupGerQSMS) && (
                                <ListItemButton
                                    component={Link}
                                    to="/estoque/estoque-baixa-estoque"
                                    onClick={closeSidebar}
                                    sx={{
                                        paddingLeft: theme.spacing(4),
                                        '& .MuiTypography-root': {
                                            fontSize: '1.0em',
                                        },
                                    }}
                                >
                                    <ListItemIcon>
                                        <AiOutlineStock />
                                    </ListItemIcon>
                                    <ListItemText primary="Planilha - Baixa de Estoque" />
                                </ListItemButton>
                            )}
                            {(isUserInGroupGerente || isUserInGroupComercial || isUserInGroupEstoque || isUserInGroupVendas || isUserInGroupGerQSMS) && (
                                <ListItemButton
                                    component={Link}
                                    to="/estoque/estoque-planilha-geral"
                                    onClick={closeSidebar}
                                    sx={{
                                        paddingLeft: theme.spacing(4),
                                        '& .MuiTypography-root': {
                                            fontSize: '1.0em',
                                        },
                                    }}
                                >
                                    <ListItemIcon>
                                        <AiOutlineStock />
                                    </ListItemIcon>
                                    <ListItemText primary="Planilha de Estoque Geral" />
                                </ListItemButton>
                            )}
                            {(isUserInGroupGerente || isUserInGroupComercial || isUserInGroupEstoque || isUserInGroupGerQSMS) && (
                                <ListItemButton
                                    component={Link}
                                    to="/estoque/policia-federal"
                                    onClick={closeSidebar}
                                    sx={{
                                        paddingLeft: theme.spacing(4),
                                        '& .MuiTypography-root': {
                                            fontSize: '1.0em',
                                        },
                                    }}
                                >
                                    <ListItemIcon>
                                        <GiPoliceBadge />
                                    </ListItemIcon>
                                    <ListItemText primary="Polícia Federal" />
                                </ListItemButton>
                            )}
                            {(isUserInGroupGerente || isUserInGroupComercial || isUserInGroupEstoque || isUserInGroupGerQSMS) && (
                                <ListItemButton
                                    component={Link}
                                    to="/estoque/policia-civil"
                                    onClick={closeSidebar}
                                    sx={{
                                        paddingLeft: theme.spacing(4),
                                        '& .MuiTypography-root': {
                                            fontSize: '1.0em',
                                        },
                                    }}
                                >
                                    <ListItemIcon>
                                        <MdOutlineLocalPolice />
                                    </ListItemIcon>
                                    <ListItemText primary="Polícia Civíl" />
                                </ListItemButton>
                            )}
                            {(isUserInGroupGerente || isUserInGroupComercial || isUserInGroupEstoque || isUserInGroupGerQSMS) && (
                                <ListItemButton
                                    component={Link}
                                    to="/estoque/exercito"
                                    onClick={closeSidebar}
                                    sx={{
                                        paddingLeft: theme.spacing(4),
                                        '& .MuiTypography-root': {
                                            fontSize: '1.0em',
                                        },
                                    }}
                                >
                                    <ListItemIcon>
                                        <GiBrazil />
                                    </ListItemIcon>
                                    <ListItemText primary="Exército" />
                                </ListItemButton>
                            )}
                        </List >
                    </Collapse>

                    {/* Item "Análise de Resultados" */}
                    <ListItemButton onClick={toggleAnaliseResultDropdown}>
                        <ListItemIcon>
                            <FaChartLine />
                        </ListItemIcon>
                        <ListItemText primary="Análise de Resultados" />
                        {isAnaliseResultDropdownOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </ListItemButton>
                    {/* Subitens de "Análise de Resultados" */}
                    <Collapse style={{ transition: 'height 0.3s ease-out', }} in={isAnaliseResultDropdownOpen} timeout="auto" unmountOnExit>
                        <List style={{
                            fontSize: 14,
                            paddingLeft: '16px',
                        }} component="div" disablePadding >
                            {(isUserInGroupGerente || isUserInGroupContabilidadeCES || isUserInGroupVendas) && (
                                <ListItemButton
                                    component={Link}
                                    to="/consultas/pg-produtos"
                                    onClick={closeSidebar}
                                    sx={{
                                        paddingLeft: theme.spacing(4),
                                        '& .MuiTypography-root': {
                                            fontSize: '1.0em',
                                        },
                                    }}
                                >
                                    <ListItemIcon>
                                        <TbChartInfographic />
                                    </ListItemIcon>
                                    <ListItemText primary="Análise de Resultados de Produtos (Kg)" />
                                </ListItemButton>
                            )}
                            {(isUserInGroupGerente || isUserInGroupContabilidadeCES || isUserInGroupVendas) && (
                                <ListItemButton
                                    component={Link}
                                    to="/consultas/pg-fat-clientes"
                                    onClick={closeSidebar}
                                    sx={{
                                        paddingLeft: theme.spacing(4),
                                        '& .MuiTypography-root': {
                                            fontSize: '1.0em',
                                        },
                                    }}
                                >
                                    <ListItemIcon>
                                        <TbChartInfographic />
                                    </ListItemIcon>
                                    <ListItemText primary="Análise de Resultados de Clientes (R$)" />
                                </ListItemButton>
                            )}
                            {(isUserInGroupGerente || isUserInGroupContabilidadeCES || isUserInGroupVendas) && (
                                <ListItemButton
                                    component={Link}
                                    to="/consultas/pg-clientes"
                                    onClick={closeSidebar}
                                    sx={{
                                        paddingLeft: theme.spacing(4),
                                        '& .MuiTypography-root': {
                                            fontSize: '1.0em',
                                        },
                                    }}
                                >
                                    <ListItemIcon>
                                        <TbChartInfographic />
                                    </ListItemIcon>
                                    <ListItemText primary="Análise de Resultados de Clientes (Kg)" />
                                </ListItemButton>
                            )}
                        </List>
                    </Collapse >

                    {/* Item "Consultas" */}
                    <ListItemButton onClick={toggleConsultasDropdown}>
                        <ListItemIcon>
                            <FaRegClipboard />
                        </ListItemIcon>
                        <ListItemText primary="Consultas" />
                        {isConsultasDropdownOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </ListItemButton>
                    {/* Subitens de "Consultas" */}
                    <Collapse style={{ transition: 'height 0.3s ease-out', }} in={isConsultasDropdownOpen} timeout="auto" unmountOnExit>
                        <List style={{
                            fontSize: 14,
                            paddingLeft: '16px',
                        }} component="div" disablePadding >
                            {/* Item "Consulta Geral" */}
                            <ListItemButton onClick={toggleConsultaGeralDropdown}>
                                <ListItemIcon>
                                    <FaRegClipboard />
                                </ListItemIcon>
                                <ListItemText primary="Consulta Geral" />
                                {isConsultaGeralDropdownOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </ListItemButton>
                            {/* Subitens de "Consulta Geral" */}
                            <Collapse style={{ transition: 'height 0.3s ease-out', }} in={isConsultaGeralDropdownOpen} timeout="auto" unmountOnExit>
                                <List style={{
                                    fontSize: 14,
                                    paddingLeft: '16px',
                                }} component="div" disablePadding >
                                    {/* Item "Consulta Geral - Vendas" com o dropdown */}
                                    {(isUserInGroupGerente || isUserInGroupContabilidadeCES || isUserInGroupComprasCes || isUserInGroupVendas) && (
                                        <ListItemButton
                                            component={Link}
                                            to="/consultas/consulta-geral-vendas"
                                            onClick={closeSidebar}
                                            sx={{
                                                paddingLeft: theme.spacing(4),
                                                '& .MuiTypography-root': {
                                                    fontSize: '1.0em',
                                                },
                                            }}
                                        >
                                            <ListItemIcon>
                                                <GoGraph />
                                            </ListItemIcon>
                                            <ListItemText primary="Consulta Geral - Vendas" />
                                        </ListItemButton>
                                    )}
                                    {(isUserInGroupGerente || isUserInGroupComercial || isUserInGroupContabilidadeCES || isUserInGroupEstoque) && (
                                        <ListItemButton
                                            component={Link}
                                            to="/consultas/consulta-geral-compras"
                                            onClick={closeSidebar}
                                            sx={{
                                                paddingLeft: theme.spacing(4),
                                                '& .MuiTypography-root': {
                                                    fontSize: '1.0em',
                                                },
                                            }}
                                        >
                                            <ListItemIcon>
                                                <GoGraph />
                                            </ListItemIcon>
                                            <ListItemText primary="Consulta Geral - Compras" />
                                        </ListItemButton>
                                    )}
                                    {(isUserInGroupGerente || isUserInGroupContabilidadeCES || isUserInGroupComprasCes || isUserInGroupVendas) && (
                                        <ListItemButton
                                            component={Link}
                                            to="/consultas/consulta-venda-detail"
                                            onClick={closeSidebar}
                                            sx={{
                                                paddingLeft: theme.spacing(4),
                                                '& .MuiTypography-root': {
                                                    fontSize: '1.0em',
                                                },
                                            }}
                                        >
                                            <ListItemIcon>
                                                <GoGraph />
                                            </ListItemIcon>
                                            <ListItemText primary="Consulta Geral de Vendas - Detalhado" />
                                        </ListItemButton>
                                    )}
                                    {(isUserInGroupGerente || isUserInGroupComercial || isUserInGroupContabilidadeCES || isUserInGroupEstoque) && (
                                        <ListItemButton
                                            component={Link}
                                            to="/consultas/consulta-compra-detail"
                                            onClick={closeSidebar}
                                            sx={{
                                                paddingLeft: theme.spacing(4),
                                                '& .MuiTypography-root': {
                                                    fontSize: '1.0em',
                                                },
                                            }}
                                        >
                                            <ListItemIcon>
                                                <GoGraph />
                                            </ListItemIcon>
                                            <ListItemText primary="Consulta Geral de Compras - Detalhado" />
                                        </ListItemButton>
                                    )}
                                </List>
                            </Collapse>

                            {/* Item "Programação" */}
                            <ListItemButton onClick={toggleProgDropdown}>
                                <ListItemIcon>
                                    <TbChartInfographic />
                                </ListItemIcon>
                                <ListItemText primary="Programação" />
                                {isProgDropdownOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </ListItemButton>

                            {/* Subitens de Programação */}
                            <Collapse style={{ transition: 'height 0.3s ease-out', }} in={isProgDropdownOpen} timeout="auto" unmountOnExit>
                                <List style={{
                                    fontSize: 14,
                                    paddingLeft: '16px',
                                }} component="div" disablePadding >
                                    {(isUserInGroupGerente || isUserInGroupContabilidadeCES || isUserInGroupGerQSMS || isUserInGroupVendas) && (
                                        <ListItemButton
                                            component={Link}
                                            to="/consultas/programacao-venda"
                                            onClick={closeSidebar}
                                            sx={{
                                                paddingLeft: theme.spacing(4),
                                                '& .MuiTypography-root': {
                                                    fontSize: '1.0em',
                                                },
                                            }}
                                        >
                                            <ListItemIcon>
                                                <TbChartInfographic />
                                            </ListItemIcon>
                                            <ListItemText primary="Programação de Vendas" />
                                        </ListItemButton>
                                    )}
                                    {(isUserInGroupGerente || isUserInGroupContabilidadeCES || isUserInGroupGerQSMS || isUserInGroupVendas) && (
                                        <ListItemButton
                                            component={Link}
                                            to="/consultas/programacao-compra"
                                            onClick={closeSidebar}
                                            sx={{
                                                paddingLeft: theme.spacing(4),
                                                '& .MuiTypography-root': {
                                                    fontSize: '1.0em',
                                                },
                                            }}
                                        >
                                            <ListItemIcon>
                                                <TbChartInfographic />
                                            </ListItemIcon>
                                            <ListItemText primary="Programação de Compras" />
                                        </ListItemButton>
                                    )}
                                </List>
                            </Collapse>

                            {(isUserInGroupGerente || isUserInGroupContabilidadeCES || isUserInGroupVendas) && (
                                <ListItemButton
                                    component={Link}
                                    to="/consultas/analise-de-vendas"
                                    onClick={closeSidebar}
                                >
                                    <ListItemIcon>
                                        <ImTable />
                                    </ListItemIcon>
                                    <ListItemText primary="Cascata" />
                                </ListItemButton>
                            )}
                            {(isUserInGroupGerente) && (
                                <ListItemButton
                                    component={Link}
                                    to="/consultas/orcamento-comercial"
                                    onClick={closeSidebar}
                                >
                                    <ListItemIcon>
                                        <ImTable />
                                    </ListItemIcon>
                                    <ListItemText primary="Orçamento Comecial" />
                                </ListItemButton>
                            )}
                            {(isUserInGroupGerente) && (
                                <ListItemButton
                                    component={Link}
                                    to="/consultas/indicadores"
                                    onClick={closeSidebar}
                                >
                                    <ListItemIcon>
                                        <SummaryKpi size="20" />
                                    </ListItemIcon>
                                    <ListItemText primary="Indicadores" />
                                </ListItemButton>
                            )}

                        </List>
                    </Collapse>

                    {(isUserInGroupGerente || isUserInGroupFinanceiro || isUserInGroupContabilidadeCES) && (
                        <>
                            {/* Item "Financeiro" */}
                            <ListItemButton onClick={toggleFinanceiroDropdown}>
                                <ListItemIcon>
                                    <TbCoin />
                                </ListItemIcon>
                                <ListItemText primary="Financeiro" />
                                {isFinanceirogDropdownOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </ListItemButton>
                            {/* Subitens de "Financeiro" */}
                            <Collapse style={{ transition: 'height 0.3s ease-out', }} in={isFinanceirogDropdownOpen} timeout="auto" unmountOnExit>
                                <List style={{
                                    fontSize: 14,
                                    paddingLeft: '16px',
                                }} component="div" disablePadding >
                                    {/* Item "Módulo Gerencial" */}
                                    <ListItemButton onClick={toggleModGerencialDropdown}>
                                        <ListItemIcon>
                                            <RiMoneyDollarCircleLine />
                                        </ListItemIcon>
                                        <ListItemText primary="Módulo Gerencial" />
                                        {isModGerencialDropdownOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                    </ListItemButton>
                                    {/* Subitens de "Módulo Gerencial" */}
                                    <Collapse style={{ transition: 'height 0.3s ease-out', }} in={isModGerencialDropdownOpen} timeout="auto" unmountOnExit>
                                        <List style={{
                                            fontSize: 14,
                                            paddingLeft: '16px',
                                        }} component="div" disablePadding >
                                            {/* Item "Análise Gerencial" com o dropdown */}

                                            <ListItemButton
                                                component={Link}
                                                to="/financeiro/analise-gerencial"
                                                onClick={closeSidebar}
                                                sx={{
                                                    paddingLeft: theme.spacing(4),
                                                    '& .MuiTypography-root': {
                                                        fontSize: '1.0em',
                                                    },
                                                }}
                                            >
                                                <ListItemIcon>
                                                    <IoMdAnalytics />
                                                </ListItemIcon>
                                                <ListItemText primary="Análise Gerencial" />
                                            </ListItemButton>
                                        </List>
                                    </Collapse>
                                </List>
                                <List style={{
                                    fontSize: 14,
                                    paddingLeft: '16px',
                                }} component="div" disablePadding >
                                    {/* Item "Contas a Pagar" */}
                                    <ListItemButton onClick={toggleContasaPagarDropdown}>
                                        <ListItemIcon>
                                            <TbCoins />
                                        </ListItemIcon>
                                        <ListItemText primary="Contas a Pagar" />
                                        {isContasaPagarDropdownOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                    </ListItemButton>
                                    {/* Subitens de "Contas a Pagar" */}
                                    <Collapse style={{ transition: 'height 0.3s ease-out', }} in={isContasaPagarDropdownOpen} timeout="auto" unmountOnExit>
                                        <List style={{
                                            fontSize: 14,
                                            paddingLeft: '16px',
                                        }} component="div" disablePadding >
                                            {/* Item "Relatório - Contas a Pagar" com o dropdown */}

                                            <ListItemButton
                                                component={Link}
                                                to="/financeiro/relatorio-contas-a-pagar"
                                                onClick={closeSidebar}
                                                sx={{
                                                    paddingLeft: theme.spacing(4),
                                                    '& .MuiTypography-root': {
                                                        fontSize: '1.0em',
                                                    },
                                                }}
                                            >
                                                <ListItemIcon>
                                                    <IoMdAnalytics />
                                                </ListItemIcon>
                                                <ListItemText primary="Relatórios" />
                                            </ListItemButton>
                                        </List>
                                    </Collapse>
                                </List>
                                <List style={{
                                    fontSize: 14,
                                    paddingLeft: '16px',
                                }} component="div" disablePadding >
                                    {/* Item "Contas a Receber" */}
                                    <ListItemButton onClick={toggleContasaReceberDropdown}>
                                        <ListItemIcon>
                                            <GiReceiveMoney />
                                        </ListItemIcon>
                                        <ListItemText primary="Contas a Receber" />
                                        {isContasaReceberDropdownOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                    </ListItemButton>
                                    {/* Subitens de "Contas a Receber" */}
                                    <Collapse style={{ transition: 'height 0.3s ease-out', }} in={isContasaReceberDropdownOpen} timeout="auto" unmountOnExit>
                                        <List style={{
                                            fontSize: 14,
                                            paddingLeft: '16px',
                                        }} component="div" disablePadding >
                                            {/* Item "Relatório - Contas a Receber" com o dropdown */}

                                            <ListItemButton
                                                component={Link}
                                                to="/financeiro/relatorio-contas-a-receber"
                                                onClick={closeSidebar}
                                                sx={{
                                                    paddingLeft: theme.spacing(4),
                                                    '& .MuiTypography-root': {
                                                        fontSize: '1.0em',
                                                    },
                                                }}
                                            >
                                                <ListItemIcon>
                                                    <IoMdAnalytics />
                                                </ListItemIcon>
                                                <ListItemText primary="Relatórios" />
                                            </ListItemButton>
                                        </List>
                                    </Collapse >
                                </List>
                            </Collapse>
                        </>
                    )}

                    {(isUserInGroupGerente || isUserInGroupFinanceiro || isUserInGroupContabilidadeCES || isUserInGroupContabilidade) && (
                        <>
                            {/* Item "Contabilidade" */}
                            <ListItemButton onClick={toggleContabilidadeDropdown}>
                                <ListItemIcon>
                                    <LiaCoinsSolid />
                                </ListItemIcon>
                                <ListItemText primary="Contabilidade" />
                                {isFinanceirogDropdownOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </ListItemButton>
                            {/* Subitens de "Contabilidade" */}
                            <Collapse style={{ transition: 'height 0.3s ease-out', }} in={isContabilidadeDropdownOpen} timeout="auto" unmountOnExit>
                                <List style={{
                                    fontSize: 14,
                                    paddingLeft: '16px',
                                }} component="div" disablePadding >
                                    {/* Item "Consultas" */}
                                    <ListItemButton onClick={toggleConsultasContDropdown}>
                                        <ListItemIcon>
                                            <FaRegClipboard />
                                        </ListItemIcon>
                                        <ListItemText primary="Consultas" />
                                        {isConsultasContDropdownOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                    </ListItemButton>
                                    {/* Subitens de "Consultas" */}
                                    <Collapse style={{ transition: 'height 0.3s ease-out', }} in={isConsultasContDropdownOpen} timeout="auto" unmountOnExit>
                                        <List style={{
                                            fontSize: 14,
                                            paddingLeft: '16px',
                                        }} component="div" disablePadding >
                                            {/* Item "Entradas e Saídas" com o dropdown */}

                                            <ListItemButton
                                                component={Link}
                                                to="/contabilidade/relatorio-entradas-saidas"
                                                onClick={closeSidebar}
                                                sx={{
                                                    paddingLeft: theme.spacing(4),
                                                    '& .MuiTypography-root': {
                                                        fontSize: '1.0em',
                                                    },
                                                }}
                                            >
                                                <ListItemIcon>
                                                    <FaRegClipboard />
                                                </ListItemIcon>
                                                <ListItemText primary="Entradas e Saídas" />
                                            </ListItemButton>
                                        </List>
                                    </Collapse>
                                </List>
                            </Collapse>
                        </>
                    )}

                    {(isUserInGroupGerente || isUserInGroupGerQSMS || isUserInGroupLabQSMS || isUserInGroupLabQSMSCes) && (
                        <>
                            {/* Item "QSMS" */}
                            <ListItemButton onClick={toggleQSMSDropdown}>
                                <ListItemIcon>
                                    <MdSecurity />
                                </ListItemIcon>
                                <ListItemText primary="QSMS" />
                                {isQSMSDropdownOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </ListItemButton>
                            {/* Subitens de "QSMS" */}
                            <Collapse style={{ transition: 'height 0.3s ease-out', }} in={isQSMSDropdownOpen} timeout="auto" unmountOnExit>
                                <List style={{
                                    fontSize: 14,
                                    paddingLeft: '16px',
                                }} component="div" disablePadding >
                                    {/* Item "Relatórios" */}
                                    <ListItemButton onClick={toggleConsultasQSMSDropdown}>
                                        <ListItemIcon>
                                            <FaRegClipboard />
                                        </ListItemIcon>
                                        <ListItemText primary="Relatórios" />
                                        {isConsultasQSMSDropdownOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                    </ListItemButton>
                                    {/* Subitens de "Consultas" */}
                                    <Collapse style={{ transition: 'height 0.3s ease-out', }} in={isConsultasQSMSDropdownOpen} timeout="auto" unmountOnExit>
                                        <List style={{
                                            fontSize: 14,
                                            paddingLeft: '16px',
                                        }} component="div" disablePadding >
                                            {/* Item "Relatório Prodir"  */}

                                            <ListItemButton
                                                component={Link}
                                                to="/qsms/relatorios/relatorio-prodir"
                                                onClick={closeSidebar}
                                                sx={{
                                                    paddingLeft: theme.spacing(4),
                                                    '& .MuiTypography-root': {
                                                        fontSize: '1.0em',
                                                    },
                                                }}
                                            >
                                                <ListItemIcon>
                                                    <FaRegClipboard />
                                                </ListItemIcon>
                                                <ListItemText primary="Inventário de Produtos" />
                                            </ListItemButton>
                                        </List>
                                    </Collapse>
                                </List>
                            </Collapse>
                        </>
                    )}

                    {/* Item "Portaria" */}
                    {(isUserInGroupGerente || isUserInGroupPortaria || isUserInGroupGerQSMS) && (
                        <ListItemButton onClick={togglePortariaDropdown}>
                            <ListItemIcon>
                                <GiOpenGate />
                            </ListItemIcon>
                            <ListItemText primary="Portaria" />
                            {isPortariaDropdownOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </ListItemButton>
                    )}
                    {/* Subitens de "Portaria" */}
                    <Collapse style={{ transition: 'height 0.3s ease-out', }} in={isPortariaDropdownOpen} timeout="auto" unmountOnExit>
                        <List style={{
                            fontSize: 14,
                            paddingLeft: '16px',
                        }} component="div" disablePadding>
                            {/* Item "Análise de Transportadoras" com o dropdown */}

                            <ListItemButton
                                onClick={toggleDropdown}
                                sx={{
                                    paddingLeft: theme.spacing(4),

                                }}
                            >
                                <ListItemIcon>
                                    <FaRegClipboard />
                                </ListItemIcon>
                                <ListItemText primary="Análise de Transportadoras" />
                                {isDropdownOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </ListItemButton>
                            <Collapse style={{ transition: 'height 0.3s ease-out', }} in={isDropdownOpen} timeout="auto" unmountOnExit>
                                <List style={{
                                    fontSize: 14,
                                    paddingLeft: '16px',
                                }} component="div" disablePadding >
                                    {(isUserInGroupGerente || isUserInGroupPortaria || isUserInGroupGerQSMS) && (
                                        <ListItemButton
                                            component={Link}
                                            to="/portaria/analise-transp/cadastro"
                                            onClick={closeSidebar}
                                            sx={{
                                                paddingLeft: theme.spacing(4),

                                            }}
                                        >
                                            <ListItemIcon>
                                                <FaClipboardList />
                                            </ListItemIcon>
                                            <ListItemText primary="Formulário" />
                                        </ListItemButton>
                                    )}
                                    {(isUserInGroupGerente || isUserInGroupPortaria || isUserInGroupGerQSMS) && (
                                        <ListItemButton
                                            component={Link}
                                            to="/portaria/analise-transp/consulta"
                                            onClick={closeSidebar}
                                            sx={{
                                                paddingLeft: theme.spacing(4),

                                            }}
                                        >
                                            <ListItemIcon>
                                                <TbReportSearch />
                                            </ListItemIcon>
                                            <ListItemText primary="Consulta" />
                                        </ListItemButton>
                                    )}
                                </List>
                            </Collapse >

                        </List>
                    </Collapse>
                    {/* Item "Relatórios" */}
                    <ListItemButton onClick={toggleRelatoriosDropdown}>
                        <ListItemIcon>
                            <TbReportAnalytics />
                        </ListItemIcon>
                        <ListItemText primary="Relatórios XLS" />
                        {isRelatoriosDropdownOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </ListItemButton>
                    {/* Subitens de "Relatórios" */}
                    <Collapse style={{ transition: 'height 0.3s ease-out', }} in={isRelatoriosDropdownOpen} timeout="auto" unmountOnExit>
                        <List style={{
                            fontSize: 14,
                            paddingLeft: '16px',
                        }} component="div" disablePadding >
                            {/* Item "Relatorios" com o dropdown */}
                            {(isUserInGroupGerente || isUserInGroupComercial || isUserInGroupEstoque) && (
                                <ListItemButton
                                    component={Link}
                                    to="/relatorios/comercial"
                                    onClick={closeSidebar}

                                    sx={{
                                        paddingLeft: theme.spacing(4),
                                        '& .MuiTypography-root': {
                                            fontSize: '1.0em',
                                        },
                                    }}
                                >
                                    <ListItemIcon>
                                        <FaFileContract />
                                    </ListItemIcon>
                                    <ListItemText primary="Compras" />
                                </ListItemButton>
                            )}
                            {(isUserInGroupGerente) && (
                                <ListItemButton
                                    component={Link}
                                    to="/relatorios/vendas"
                                    onClick={closeSidebar}
                                    sx={{
                                        paddingLeft: theme.spacing(4),
                                        '& .MuiTypography-root': {
                                            fontSize: '1.0em',
                                        },
                                    }}
                                >
                                    <ListItemIcon>
                                        <FaFileContract />
                                    </ListItemIcon>
                                    <ListItemText primary="Vendas" />
                                </ListItemButton>
                            )}
                            {(isUserInGroupRH || isUserInGroupGerente) && (
                                <ListItemButton
                                    component={Link}
                                    to="/relatorios/rh"
                                    onClick={closeSidebar}

                                    sx={{
                                        paddingLeft: theme.spacing(4),
                                        '& .MuiTypography-root': {
                                            fontSize: '1.0em',
                                        },
                                    }}
                                >
                                    <ListItemIcon>
                                        <IoIosPeople />
                                    </ListItemIcon>
                                    <ListItemText primary="RH" />
                                </ListItemButton>
                            )}
                            {(isUserInGroupGerente || isUserInGroupGerQSMS || isUserInGroupLabQSMS) && (
                                <ListItemButton
                                    component={Link}
                                    to="/relatorios/qsms"
                                    onClick={closeSidebar}
                                    sx={{
                                        paddingLeft: theme.spacing(4),
                                        '& .MuiTypography-root': {
                                            fontSize: '1.0em',
                                        },
                                    }}
                                >
                                    <ListItemIcon>
                                        <MdSecurity />
                                    </ListItemIcon>
                                    <ListItemText primary="QSMS" />
                                </ListItemButton>
                            )}
                            {(isUserInGroupGerente || isUserInGroupContabilidade) && (
                                <ListItemButton
                                    component={Link}
                                    to="/relatorios/contabilidade"
                                    onClick={closeSidebar}
                                    sx={{
                                        paddingLeft: theme.spacing(4),
                                        '& .MuiTypography-root': {
                                            fontSize: '1.0em',
                                        },
                                    }}
                                >
                                    <ListItemIcon>
                                        <LiaCoinsSolid />
                                    </ListItemIcon>
                                    <ListItemText primary="Contabilidade" />
                                </ListItemButton>
                            )}
                            {(isUserInGroupGerente || isUserInGroupFinanceiro) && (
                                <ListItemButton
                                    component={Link}
                                    to="/relatorios/financeiro"
                                    onClick={closeSidebar}
                                    sx={{
                                        paddingLeft: theme.spacing(4),
                                        '& .MuiTypography-root': {
                                            fontSize: '1.0em',
                                        },
                                    }}
                                >
                                    <ListItemIcon>
                                        <LiaCoinsSolid />
                                    </ListItemIcon>
                                    <ListItemText primary="Financeiro" />
                                </ListItemButton>
                            )}
                            {(isUserInGroupGerente) && (
                                <ListItemButton
                                    component={Link}
                                    to="/relatorios/logistica"
                                    onClick={closeSidebar}
                                    sx={{
                                        paddingLeft: theme.spacing(4),
                                        '& .MuiTypography-root': {
                                            fontSize: '1.0em',
                                        },
                                    }}
                                >
                                    <ListItemIcon>
                                        <FaTruck />
                                    </ListItemIcon>
                                    <ListItemText primary="Logistica" />
                                </ListItemButton>
                            )}
                        </List>
                    </Collapse>
                </List>
            </Drawer>
            <Box>
                <MenuButtonStyled
                    color="inherit"
                    aria-label="open sidebar"
                    onClick={toggleSidebar}
                    edge="start"

                    style={{ width: menuButtonWidth }}
                >
                    {isSidebarOpen ? <FiX /> : <FiMenu />}
                </MenuButtonStyled>
            </Box>
        </ThemeProvider>
    );
};

export default Sidebar;