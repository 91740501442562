import React, { useState, useEffect, useCallback } from "react";
import { Axios } from "../config/axiosConf";
import { useNavigate } from "react-router-dom";
import {
  TextField,
  Button,
  Typography,
  Box,
  Autocomplete,
  useMediaQuery,
  InputAdornment,
  IconButton
} from "@mui/material";
import { RiUserLine, RiLockPasswordLine } from "react-icons/ri";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const Login = ({ onLogin }) => {
  const [nome, setNome] = useState("");
  const [senha, setSenha] = useState("");
  const [error, setError] = useState("");
  const [sigla, setSigla] = useState("");
  const [empresas, setEmpresas] = useState([]);
  const [showSenha, setShowSenha] = useState(false);
  const history = useNavigate();
  const isSmallScreen = useMediaQuery('(max-width:550px)');

  const generateToken = (codFuncionario, nome) => {

    const combined = codFuncionario + nome;
    const token = btoa(combined);
    return token;
  };

  useEffect(() => {
    const getEmpresas = async () => {
      try {
        const response = await Axios.get("Y83uIOjG6PnCLLj2WOoYnw");
        setEmpresas(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    getEmpresas();
  }, [nome]);

  useEffect(() => {
    const checkSessionExpiration = () => {
      const storedUser = sessionStorage.getItem("user");
      const storedEmpresa = sessionStorage.getItem("empresa");
      const sessionExpiration = sessionStorage.getItem("sessionExpiration");

      if (storedUser && storedEmpresa && sessionExpiration) {
        const now = new Date().getTime();
        if (now > parseInt(sessionExpiration, 10)) {
          sessionStorage.clear();
        } else {
          onLogin(storedUser);
          history("/home");
        }
      }
    };

    checkSessionExpiration();
  }, [history, onLogin]);

  const obterNomeUsuarioWindows = () => {
    const domainParts = document.domain.split('.');
    const nomeUsuario = domainParts[0];
    return nomeUsuario;
  };

  const handleLogin = useCallback(
    async (e) => {
      e.preventDefault();
      try {
        const response = await Axios.post("login", {
          nome,
          senha,
        });
  
        const responseEmpresas = await Axios.post(
          "empresa-selected",
          { sigla }
        );
  
        const empresa = responseEmpresas.data.message;
        const user = response.data;
        const { codFuncionarios } = user;
  
        const accessCheck = await Axios.post(
          "verificar-acesso-empresa",
          { codFuncionario: codFuncionarios, codEmpresa: parseInt(empresa.split(" - ")[0]) }
        );
  
        if (!accessCheck.data.hasAccess) {
          alert("Acesso negado: " + accessCheck.data.message);
          return;
        }
  
        setError("");
        const { usuario, usuarioCryptado, grupo, codFuncoes, codVendedor } = user;
  
        const expirationTime = new Date().getTime() + 6 * 60 * 60 * 1000;
        
        const token = generateToken(codFuncionarios, nome);
  
        sessionStorage.setItem("user", usuario);
        sessionStorage.setItem("usuarioCryptado", usuarioCryptado);
        sessionStorage.setItem("empresa", empresa);
        sessionStorage.setItem("grupo", grupo);
        sessionStorage.setItem("codFuncionarios", codFuncionarios);
        sessionStorage.setItem("codFuncoes", codFuncoes);
        sessionStorage.setItem("codVendedor", codVendedor)
        sessionStorage.setItem("sessionExpiration", expirationTime.toString());
        sessionStorage.setItem("authToken", token);
        onLogin(usuario);
        history("/home");
  
        await Axios.post("registrar-acesso", {
          nomeMaquina: obterNomeUsuarioWindows(),
          usuario: nome,
        });
      } catch (error) {
        if (error.response) {
          setError("Acesso negado: " + error.response.data.message);
        }
      }
    },
    [history, senha, sigla, onLogin, nome]
  );
  

  const handleNomeChange = useCallback((e) => {
    setNome(e.target.value.toUpperCase());
  }, []);

  const enviarEmpresa = (event, newValue) => {
    setSigla(newValue);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "80vh",
      }}
    >
      <Box
        component="form"
        onSubmit={handleLogin}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          width: isSmallScreen ? "100%" : "450px",
          height: isSmallScreen ? "100%" : "320px",
          padding: "16px",
          marginTop: isSmallScreen ? "0px" : "0px",
          border: "1px solid #ccc",
          borderRadius: "8px",
          background: "#fff",
          boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
        }}
      >
        <TextField
          type="text"
          label="Nome de usuário"
          value={nome}
          onChange={handleNomeChange}
          variant="outlined"
          InputProps={{
            startAdornment: (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  color: "rgba(0, 0, 0, 0.54)",
                  fontSize: isSmallScreen ? "1rem" : "1.5rem",
                  marginRight: "8px",
                }}
              >
                <RiUserLine />
              </Box>
            ),
          }}
        />

        <TextField
          type={showSenha ? "text" : "password"}
          label="Senha"
          value={senha}
          onChange={(e) => setSenha(e.target.value)}
          variant="outlined"
          onCopy={(e) => e.preventDefault()}
          onPaste={(e) => e.preventDefault()}
          InputProps={{
            startAdornment: (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  color: "rgba(0, 0, 0, 0.54)",
                  fontSize: isSmallScreen ? "1rem" : "1.5rem",
                  marginRight: "8px",
                }}
              >
                <RiLockPasswordLine />
              </Box>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowSenha(!showSenha)}
                >
                  {showSenha ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <Autocomplete
          id="combo-box-demo"
          freeSolo={false}
          options={empresas}
          value={sigla}
          onChange={enviarEmpresa}
          sx={{
            width: "100%",
            "& .MuiButtonBase-root": {
              width: isSmallScreen ? "100%" : "40px",
            },
          }}
          renderInput={(params) => (
            <TextField {...params} label="Escolha uma empresa" required />
          )}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            type="submit"
            variant="contained"
            sx={{
              backgroundColor: "#2957A4",
              color: "#fff",
              "&:hover": {
                backgroundColor: "#1F446A",
              },
              width: isSmallScreen ? "100%" : "auto",
            }}
          >
            Entrar
          </Button>
        </Box>

        {error && (
          <Typography variant="body1" color="error">
            {error}
          </Typography>
        )}
      </Box>
    </div>
  );
};

export default Login;
