import React, { useState, useEffect, useCallback } from "react";
import { RadioGroup, FormControlLabel, Radio } from "@mui/material/";
import "../DataPickers/Data.css";

const RadioTipoProduto = ({ produtoAtivo, setProdutoAtivo }) => {
    const [isSmallScreen, setIsSmallScreen] = useState(window.matchMedia("(max-width: 750px)").matches);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.matchMedia("(max-width: 750px)").matches);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const trocarAtivo = useCallback((event) => {
        setProdutoAtivo(event.target.value);
    }, [setProdutoAtivo]);

    return (
        <>
            <div className="date-input">
                    <label className="a">Produto Ativo ou Não-Ativo:</label>   
                    </div>
                    <RadioGroup
                        id="produtoAtivo"
                        required
                        aria-label="userType"
                        name="userType"
                        value={produtoAtivo}
                        onChange={trocarAtivo}
                        row={!isSmallScreen}
                    >
                        <FormControlLabel value="S" control={<Radio />} label="Somente Ativos" />
                        <FormControlLabel value="N" control={<Radio />} label="Todos" />
                    </RadioGroup>
             
        </>
    );

};

export default RadioTipoProduto;
