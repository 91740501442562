import React, { useState } from "react";
import { Axios } from "../../config/axiosConf";
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { DataGrid } from '@mui/x-data-grid';
import { ptBR } from '@mui/x-data-grid/locales';
import Produtos from "../../components/AutoCompletes/Produtos";
import Transportadoras from "../../components/AutoCompletes/Transportadoras";
import styled from "@emotion/styled";
import { styled as Estilo, darken, lighten } from "@mui/material/styles";
import {
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";
import { AiOutlineSearch } from "react-icons/ai";
import Data from "../../components/DataPickers/Data";
import logo from "../../assets/Logo-Grupo-Sumatex.png";
import logo_sumatex from "../../assets/logo.png";
import logo_ambiental from "../../assets/Logo-Sumatex-Ambiental.png";
import logo_agro from "../../assets/Logo-Sumatex-Agro.png";
import logo_lorenvel from "../../assets/Logo-Lorenvel.png";
import logo_cesbra from "../../assets/Logo-Cesbra.png";


  const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  width: 90%;
  gap: 16px;
  padding: 24px;
  background-color: #f5f5f5;
  border-radius: 8px;
  height: 100%;
  margin: 50px;

  @media (max-width: 550px) {
    width: 100%;
    margin: 0px;
  }
`;

const StyledTitle = styled(Typography)`
  font-weight: bold;
  font-size: 1.5em;
  margin: 0em 0;
  background-color: #2957a4;
  color: #fff;
  padding: 10px;
  text-align: center;
  @media (max-width: 550px) {
    font-size: 1.2em;
  }
`;

const StyledOrange = styled(Typography)`
    background-color: #D66E20;
    padding-top: 3px;
    padding-bottom: 3px;
  @media (max-width: 550px) {
    font-size: 1.2em;
  }
`;

const StyledGreen = styled(Typography)`
    background-color: #7CA217;
    padding-top: 3px;
    padding-bottom: 3px;
  @media (max-width: 550px) {
    font-size: 1.2em;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 16px;
  margin-left: 25px;
`;
const ResultadosTitle = styled.h2`
  margin: 0;
  font-size: 2em; /* Aumenta o tamanho da fonte */
  color: #1F437D; /* Define uma cor personalizada (laranja) */
  text-align: center; /* Centraliza o texto */
  text-transform: uppercase; /* Converte o texto em maiúsculas */
  padding: 10px; /* Adiciona espaçamento interno */
  border-radius: 10px; /* Adiciona bordas arredondadas */
  @media (max-width: 550px) {
    width: 80px;
    font-size: 1.5em;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-wrap: wrap; /* Permite que os botões quebrem para a próxima linha em telas estreitas */
  gap: 16px;
  justify-content: flex-end; /* Alinha os botões à direita */
  margin-top: 20px;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;

`;

const SaveButton = styled(Button)`
  height: 30px;
  width: 150px;
  color: #fff;
  background-color: #2957a4;
  border: 1px solid rgba(0, 0, 0, 0.2);
  &:hover {
    background-color: #1f437d;
  }
`;

const LimparButton= styled(Button)`
  height: 30px;
  width: 100px;
  color: #fff;
  background-color: #FF6347;
  border: 1px solid rgba(0, 0, 0, 0.2);
  &:hover {
    background-color: #ff0000;
  }
  @media (max-width: 550px) {
    width: 80px;
    font-size: 0.8em;
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const ImprimirButton = styled(Button)`
  height: 30px;
  padding-left: 50px;
  padding-right: 50px;
  width: 120px;
  color: #fff;
  background-color: #a0a0a0;
  border: 1px solid rgba(0, 0, 0, 0.2);
  &:hover {
    background-color: #808080;
  }
  
  @media (max-width: 550px) {
    width: 80px;
    font-size: 0.8em;
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const XlsButton = styled(Button)`
  height: 30px;
  width: 120px;
  padding-left: 50px;
  padding-right: 50px;
  color: #fff;
  background-color: #71bc78;
  border: 1px solid rgba(0, 0, 0, 0.2);
  &:hover {
    background-color: #569b5c;
  }
  @media (max-width: 550px) {
    width: 80px;
    font-size: 0.8em;
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const ResultadosContainer = styled.div`
  margin-top: 20px;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  @media (max-width: 550px) {
    // Estilo responsivo para largura de tela até 360px
    padding: 10px; // Ajuste o espaçamento conforme necessário
  }
`;

const MobileButton = styled(Button)`
  height: 30px;
  width: 150px;
  color: #fff;
  background-color: #2957a4;
  border: 1px solid rgba(0, 0, 0, 0.2);
  display: none; /* Inicialmente, oculte o botão em telas grandes */
  &:hover {
    background-color: #1f437d;
  }

  @media (max-width: 360px) {
    display: block; /* Mostrar o botão em telas menores */
  }
`;

const getBackgroundColor = (color, mode) =>
  mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7);

const getHoverBackgroundColor = (color, mode) =>
  mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

const getSelectedBackgroundColor = (color, mode) =>
  mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

const getSelectedHoverBackgroundColor = (color, mode) =>
  mode === 'dark' ? darken(color, 0.4) : lighten(color, 0.4);


const StyledDataGrid = Estilo(DataGrid)(({ theme }) => ({
  '& .grid-azul': {
    backgroundColor: getBackgroundColor(theme.palette.info.main, theme.palette.mode),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.info.main,
        theme.palette.mode,
      ),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.info.main,
        theme.palette.mode,
      ),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.info.main,
          theme.palette.mode,
        ),
      },
    },
  },
  '& .grid-vermelho': {
    backgroundColor: getBackgroundColor(
      theme.palette.error.main,
      theme.palette.mode,
    ),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.error.main,
        theme.palette.mode,
      ),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.error.main,
        theme.palette.mode,
      ),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.error.main,
          theme.palette.mode,
        ),
      },
    },
  },
}));

const PoliciaFederal = () => {
  const [formattedDataInicial, setFormattedDataInicial] = useState("");
  const [formattedDataFinal, setFormattedDataFinal] = useState("");
  const Filial = sessionStorage.getItem("empresa");
  const [produtoSelecionado, setProdutoSelecionado] = useState(null);
  const [transportadoraSelecionada, setTransportadoraSelecionada] = useState(null);
  const [resultados, setResultados] = useState([]);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [rows2, setRows2] = useState([]);
  const [columns2, setColumns2] = useState([]);
  // eslint-disable-next-line

  const [isLoading, setIsLoading] = useState(false);
    // eslint-disable-next-line
  const [consultaSelecionada, setConsultaSelecionada] = useState("relatorio");

  const grupo = sessionStorage.getItem("grupo");
  const grupoGerente = ["8f498e6aabb107de59d0ad2177f6d882"];// GE

  const isUserInGroupGerente = grupoGerente.includes(grupo);

  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({
    id: false,
    Estoque: false,
    Grupo: false,
    Produto: false,
    Pedido: false,
    Transportador: false,
    CNPJ_T: false,
  });

  const [filterModel, setFilterModel] = React.useState({
    items: [
    ],
  });

  const [columnVisibilityModel2, setColumnVisibilityModel2] = React.useState({
    id: false,
  });

  const [filterModel2, setFilterModel2] = React.useState({
    items: [
    ],
  });

  const applyFilters = (rows, filterModel) => {
    return rows.filter((row) => {
      return filterModel.items.every((filter) => {
        const { field, operator, value } = filter;
        const rowValue = row[field];
  
        // Convertendo rowValue e value para string para comparações de texto
        const rowValueStr = String(rowValue).toLowerCase();
        const valueStr = String(value).toLowerCase();
  
        switch (operator) {
          case '>':
            return rowValue > value;
          case '>=':
            return rowValue >= value;
          case '<':
            return rowValue < value;
          case '<=':
            return rowValue <= value;
          case 'equals':
          case '=': // Igualdade numérica ou textual
            return rowValueStr === valueStr;
          case '!=': // Diferença numérica ou textual
            return rowValue !== value;
          case 'contains': // Contém
            return rowValueStr.includes(valueStr);
          case 'startsWith': // Começa com
            return rowValueStr.startsWith(valueStr);
          case 'endsWith': // Termina com
            return rowValueStr.endsWith(valueStr);
          default:
            return true; // Se o operador não for suportado, não filtra.
        }
      });
    });
  };

  const handleLimparResultados = () => {
    setResultados([]);
    setRows([]);
    setColumns([]);
    setRows2([]);
    setColumns2([]);
  };

  const customComparator = (v1, v2) => {
    const value1 = parseFloat(v1.replace(',', '.'));
    const value2 = parseFloat(v2.replace(',', '.'));
  
    if (value1 < value2) {
      return -1;
    }
    if (value1 > value2) {
      return 1;
    }
    return 0;
  };

  const calcularValorTotalEntrada = () => {
    const valorTotal = applyFilters(rows, filterModel)
        .filter(item => item.Tp === 'E') 
        .reduce((total, item) => total + (item.Total || 0), 0); 
    return formatarValor(valorTotal);
  };

  const calcularValorTotalSaida = () => {
    const valorTotal = applyFilters(rows, filterModel)
        .filter(item => item.Tp === 'S') 
        .reduce((total, item) => total + (item.Total || 0), 0); 
    return formatarValor(valorTotal);
  };

  // Função para calcular a Quantidade Total
  const calcularQuantidadeTotalEntrada = () => {
    const quantidadeTotal = applyFilters(rows, filterModel)
    .filter(item => item.Tp === 'E')
    .filter(item => item.Tipo !== 'Produção')
    .reduce((total, item) => total + (item.Quantidade || 0), 0);
    return formatarValor(quantidadeTotal);
  };

  const calcularQuantidadeTotalSaida = () => {
    const quantidadeTotal = applyFilters(rows, filterModel)
    .filter(item => item.Tp === 'S')
    .filter(item => item.Tipo !== 'Insumo de Produção')
    .reduce((total, item) => total + (item.Quantidade || 0), 0);
    return formatarValor(quantidadeTotal);
  };

  const calcularQuantidadeTotalProd = () => {
    const quantidadeTotal = applyFilters(rows, filterModel)
    .filter(item => item.Tipo === 'Produção')
    .reduce((total, item) => total + (item.Quantidade || 0), 0);
    return formatarValor(quantidadeTotal);
  };

  const calcularQuantidadeTotalInsumo = () => {
    const quantidadeTotal = applyFilters(rows, filterModel)
    .filter(item => item.Tipo === 'Insumo de Produção')
    .reduce((total, item) => total + (item.Quantidade || 0), 0);
    return formatarValor(quantidadeTotal);
  };


  const customDateComparator = (dateStr1, dateStr2) => {

    const parts1 = dateStr1.split('/');
    const parts2 = dateStr2.split('/');
    const date1 = new Date(Number(parts1[2]), Number(parts1[1]) - 1, Number(parts1[0]));
    const date2 = new Date(Number(parts2[2]), Number(parts2[1]) - 1, Number(parts2[0]));
  
    // Comparando as datas
    if (date1 < date2) {
      return -1;
    }
    if (date1 > date2) {
      return 1;
    }
    return 0;
 };

  const realizarConsulta = async () => {
    let procedure = "";

    switch (consultaSelecionada) {
      case "relatorio":
        procedure = "consulta-policia-federal";
        break;
      default:
        break;
    }

    try {
      const data = {
        Data: {
          formattedDataInicial,
          formattedDataFinal,
        },
        Produtos: {
          produtoSelecionado: produtoSelecionado === null ? null : produtoSelecionado.substring(0, 6),
        },
        Transportadoras: {
          transportadoraSelecionada:
            transportadoraSelecionada === null
              ? null
              : transportadoraSelecionada.substring(0, 6),
        },
        Filial,
      };

      const config = {
        timeout: 45000,
      };

      setIsLoading(true);

      const response = await Axios.post(
        `estoque/${procedure}`,
        data,
        config
      );
      
      if (response.data.length > 0){
        
        const rows = response.data.map((item, index) => {
          const rowData = {};
          Object.keys(item).forEach(key => {
              rowData[key] = item[key];
          });
          // Adicionando manualmente o campo "ID" a cada linha
          rowData['id'] = index + 1; 
          return rowData;
        });

        function groupByProd(data) {
          return response.data.reduce((groups, item) => {
              const prod = item.Prod_Baixa;
              if (!groups[prod]) {
                  groups[prod] = [];
              }
              groups[prod].push(item);
              return groups;
          }, {});
        }


        function movimentacaoProdutos(data) {
          const groupedData = groupByProd(data);
          const result = [];


          for (let date in groupedData) {
              const items = groupedData[date];
              let totalEntrada = 0;
              let totalSaida = 0;

              for (let item of items) {
                if (item.Tp === "E") {
                  totalEntrada += item.Quantidade;
                } else if (item.Tp === "S") {
                  totalSaida += item.Quantidade;
                }
              }
            
              result.push({ NCM: items[0].NCM, Produto: items[0].Prod_Baixa, Entradas: totalEntrada, Saidas: totalSaida });
          }

        // Ordena o resultado pelo campo 'Produto'
        result.sort((a, b) => {
        if (a.Produto < b.Produto) {
          return -1;
        }
        if (a.Produto > b.Produto) {
          return 1;
        }
          return 0;
        });

          return result;
        }

        const rows2 = movimentacaoProdutos(response.data);

        const columns2 = Object.keys(rows2[0]).map(key => ({
            field: key,
            ...(key === 'NCM' ? {
              headerName: 'NCM',
              width: 150
            } : {...(key === 'Produto' ? {
              headerName: 'Produto',
              width: 400 
            } : {...(key === 'Entradas' ? {
              headerName: 'Entradas (Kg)',
              width: 200
            } : {...(key === 'Saidas' ? {
              headerName: 'Saídas (Kg)',
              width: 150 
            } : {headerName: key, width: 200})})})}),

            ...(key === 'Entradas' || key === 'Saidas' ? {
              valueFormatter: (params) => {
                if (params !== null) {
                  return params.toLocaleString('pt-BR', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  });
                }
              }
          } : {})
        }));

        columns2.unshift({ field: 'id', headerName: 'ID', width: 10 });

        setRows2(rows2)
        setColumns2(columns2)
        
        const columns = Object.keys(response.data[0]).map(key => ({
            field: key,
            ...(key === 'Emissão' ? {
                headerName: 'Emissão',
                width: 120
            } : {...(key === 'Estoque' ? {
                headerName: 'Estoque',
                width: 120
            } : {...(key === 'Nota' ? {
                headerName: 'Nota',
                width: 100
            } : {...(key === 'Pedido' ? {
                headerName: 'Pedido',
                width: 100 
            } : {...(key === 'Produto' ? {
              headerName: 'Produto',
              width: 400
            } : {...(key === 'Prod_Baixa' ? {
                headerName: 'Prod_Baixa',
                width: 400 
            } : {...(key === 'Grupo' ? {
                headerName: 'Grupo',
                width: 80 
            } : {...(key === 'Vl_Unitario' ? {
              headerName: 'Vl_Un (R$)',
              width: 100 
            } : {...(key === 'Quantidade' ? {
              headerName: 'Qtd (Kg)',
              width: 100 
            } : {...(key === 'Tipo' ? {
                headerName: 'Tipo',
                width: 150 
            } : {...(key === 'Total' ? {
              headerName: 'Total (R$)',
              width: 120 
            } : {...(key === 'Tipo' ? {
              headerName: 'Tipo',
              width: 180 
            } : {...(key === 'Tp' ? {
              headerName: 'Tp',
              width: 50
            } : {...(key === 'CFOP' ? {
              headerName: 'CFOP',
              width: 100
            } : {...(key === 'NCM' ? {
              headerName: 'NCM',
              width: 100
            } : {...(key === 'Empresa' ? {
              headerName: 'Empresa',
              width: 300
            } : {...(key === 'Transportador' ? {
              headerName: 'Transportador',
              width: 300
            } : {headerName: key, width: 200})})})})})})})})})})})})})})})})}),
  
            ...(key === 'Vl_Unitario' || key === 'Quantidade' || key  === 'Total' ? {
                valueFormatter: (params) => {
                  if (params === null) {
                    return 0
                  } else {
                    return params.toLocaleString("pt-BR", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 5,
                    })
                  }
                  //return params.value.toFixed(2);
                },
              } : {})
        }));
  
        columns.unshift({ field: 'id', headerName: 'ID', width: 10 });
  
        setRows(rows);
        setColumns(columns)
      
      }

      setResultados(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error("Erro ao realizar a consulta:", error);
      setIsLoading(false);
    }
  };

  function formatarValor(valor) {
    if (valor === null || valor === undefined) {
      return '';
    }

    if (typeof valor !== "number") {
      return valor;
    }

    return valor.toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }

  function desformatarData(data) {
    if (!data) return null;
  
    const ano = data.slice(0, 4);
    const mes = data.slice(4, 6);
    const dia = data.slice(6, 8);
  
    return `${dia}/${mes}/${ano}`;
  }

  const handlePrintResults = () => {
    const Filial = sessionStorage.getItem("empresa");
    const printContent = `
      <html>
        <head>
          <title>S.I.G.A - Grupo Sumatex</title>
          <style>
            /* Estilos para o relatório de impressão */
            body { font-family: Arial, sans-serif; margin: 0; padding: 0; }
            h1 {
              text-align: center;
              margin-bottom: 20px;
              font-size: 24px;
            }
            h2 img {
              height: 40px;
              margin-right: 10px;
            }
            table { 
              width: 100%; 
              border-collapse: 
              collapse; 
            }
            th, td { 
              border: 1px solid #000; 
              padding: 8px; 
              text-align: left; 
            }
          </style>
        </head>
        <body>
          <h2>
            ${
              Filial === "1 - MAT" ? `<img src="${logo_sumatex}" alt="Logo">` :
              Filial === "7 - CES" ? `<img src="${logo_cesbra}" alt="Logo">` :
              Filial === "2 - AMB" ? `<img src="${logo_ambiental}" alt="Logo">` :
              Filial === "9 - LOG" ? `<img src="${logo_lorenvel}" alt="Logo">` :
              Filial === "5 - FMT" ? `<img src="${logo_agro}" alt="Logo">` :
              `<img src="${logo}" alt="Logo">`
            } </h2>
           <h1>
             Movimentação Polícia Federal
           </h1>
           <div>
           <div class="filters">
           <p style="text-align: center;"><strong>Data:</strong> ${desformatarData(formattedDataInicial)} a ${desformatarData(formattedDataFinal)}</strong></p>
           
           ${produtoSelecionado ? `<div key="produto"><p><strong>Produto:</strong> ${produtoSelecionado}</p></div>` : ''}
           ${transportadoraSelecionada ? `<div key="transportadora"><p><strong>Transportadora:</strong> ${transportadoraSelecionada}</p></div>` : ''}
         </div>
        </div>
          <table>
            <thead>
              <tr>
                ${columns
                  .filter((coluna) => columnVisibilityModel[coluna.field] !== false)
                  .map((coluna) => `<th>${coluna.headerName}</th>`)
                  .join('')}
              </tr>
            </thead>
            <tbody>
              ${applyFilters(rows, filterModel)
                .map((item, index) => {
                  return `
                    <tr ${index % 2 === 0 ? 'style="background-color: #f9f9f9;"' : ''}>
                      ${columns
                        .filter((coluna) => columnVisibilityModel[coluna.field] !== false)
                        .map((coluna) => {
                          return `<td>${formatarValor(item[coluna.field])}</td>`
                        })
                        .join("")}
                    </tr>
                  `;
                })
                .join("")}
            </tbody>
          </table>
      <div style="margin-top: 20px; display: grid; grid-template-columns: 1fr 1fr;">
          <div>
            <h2>Dados</h2>
              <div class="consolidated-data">
                  <div><p><strong>Valor Total de Entrada:</strong> R$ ${calcularValorTotalEntrada()}</p></div>
              </div>
              <div class="consolidated-data">
                  <div><p><strong>Valor Total de Saída:</strong> R$ ${calcularValorTotalSaida()}</p></div>
              </div>
          </div>
          <div> 
              <h2>⠀</h2>
              <div class="consolidated-data"> 
                  <div><p><strong>Quantidade Total de Entrada (Kg):</strong> ${calcularQuantidadeTotalEntrada()}</p>
              </div>
              <div class="consolidated-data"> 
                  <div><p><strong>Quantidade Total de Saída (Kg):</strong> ${calcularQuantidadeTotalSaida()}</p>
              </div>
              <div class="consolidated-data"> 
                  <div><p><strong>Produção (Entrada) (Kg):</strong> ${calcularQuantidadeTotalProd()}</p>
              </div>
              <div class="consolidated-data"> 
                  <div><p><strong>Insumo de Produção (Saída) (Kg):</strong> ${calcularQuantidadeTotalInsumo()}</p>
              </div>
          </div>
      </div>
      </div>
    </body>
  </html>
    `;

    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;
    const printWindow = window.open("", "", `width=${screenWidth},height=${screenHeight}`);

    if (!printWindow) {
      alert(
        "Erro ao abrir a janela de impressão. Verifique as configurações do seu navegador."
      );
      return;
    }

    printWindow.document.open();
    printWindow.document.write(printContent);
    printWindow.document.close();

    printWindow.onload = () => {
      printWindow.print();

      setTimeout(() => {
      }, 1000); 
    };
  };

  const handlePrintResults2 = () => {
    const Filial = sessionStorage.getItem("empresa");
    const printContent = `
      <html>
        <head>
          <title>S.I.G.A - Grupo Sumatex</title>
          <style>
            /* Estilos para o relatório de impressão */
            body { font-family: Arial, sans-serif; margin: 0; padding: 0; }
            h1 {
              text-align: center;
              margin-bottom: 20px;
              font-size: 24px;
            }
            h2 img {
              height: 40px;
              margin-right: 10px;
            }
            table { 
              width: 100%; 
              border-collapse: 
              collapse; 
            }
            th, td { 
              border: 1px solid #000; 
              padding: 8px; 
              text-align: left; 
            }
          </style>
        </head>
        <body>
          <h2>
            ${
              Filial === "1 - MAT" ? `<img src="${logo_sumatex}" alt="Logo">` :
              Filial === "7 - CES" ? `<img src="${logo_cesbra}" alt="Logo">` :
              Filial === "2 - AMB" ? `<img src="${logo_ambiental}" alt="Logo">` :
              Filial === "9 - LOG" ? `<img src="${logo_lorenvel}" alt="Logo">` :
              Filial === "5 - FMT" ? `<img src="${logo_agro}" alt="Logo">` :
              `<img src="${logo}" alt="Logo">`
            } </h2>
           <h1>
             Movimentação Polícia Federal
           </h1>
           <div>
           <div class="filters">
           <p style="text-align: center;"><strong>Data:</strong> ${desformatarData(formattedDataInicial)} a ${desformatarData(formattedDataFinal)}</strong></p>
           
           ${produtoSelecionado ? `<div key="produto"><p><strong>Produto:</strong> ${produtoSelecionado}</p></div>` : ''}
           ${transportadoraSelecionada ? `<div key="transportadora"><p><strong>Transportadora:</strong> ${transportadoraSelecionada}</p></div>` : ''}
         </div>
        </div>
          <table>
            <thead>
              <tr>
                ${columns2
                  .filter((coluna) => columnVisibilityModel2[coluna.field] !== false)
                  .map((coluna) => `<th>${coluna.headerName}</th>`)
                  .join('')}
              </tr>
            </thead>
            <tbody>
              ${applyFilters(rows2, filterModel)
                .map((item, index) => {
                  return `
                    <tr ${index % 2 === 0 ? 'style="background-color: #f9f9f9;"' : ''}>
                      ${columns2
                        .filter((coluna) => columnVisibilityModel2[coluna.field] !== false)
                        .map((coluna) => {
                          return `<td>${formatarValor(item[coluna.field])}</td>`
                        })
                        .join("")}
                    </tr>
                  `;
                })
                .join("")}
            </tbody>
          </table>
    </body>
  </html>
    `;

    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;
    const printWindow = window.open("", "", `width=${screenWidth},height=${screenHeight}`);

    if (!printWindow) {
      alert(
        "Erro ao abrir a janela de impressão. Verifique as configurações do seu navegador."
      );
      return;
    }

    printWindow.document.open();
    printWindow.document.write(printContent);
    printWindow.document.close();

    printWindow.onload = () => {
      printWindow.print();

      setTimeout(() => {
      }, 1000); 
    };
  };
  

  const handleGenerateXLS = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Resultados');
  
    const colunas = columns
    .filter((coluna) => columnVisibilityModel[coluna.field] !== false)
    .map((coluna) => coluna.headerName);
  
    // Adiciona cabeçalhos
    const headerRow = worksheet.addRow(colunas);
    
    // Aplica estilo ao cabeçalho
    headerRow.eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'D3D3D3' },
      };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
      cell.font = { bold: true };
    });
  
    // Adiciona dados
    applyFilters(rows, filterModel).forEach((item) => {
      const rowData = columns
        .filter((coluna) => columnVisibilityModel[coluna.field] !== false)
        .map((coluna) => item[coluna.field]);
      const row = worksheet.addRow(rowData);
    });
  
    // Aplica bordas a todas as células, incluindo as vazias
    const range = worksheet.getSheetValues();
    for (let R = 1; R <= range.length; ++R) {
      const row = worksheet.getRow(R);
      for (let C = 1; C <= colunas.length; ++C) {
        const cell = row.getCell(C);
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      }
    }
  
    const buffer = await workbook.xlsx.writeBuffer();
    const nomeDoArquivo = `resultados_mov_policiafederal_${formattedDataInicial}-${formattedDataFinal}.xlsx`;
  
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, nomeDoArquivo);
  };

  const handleGenerateXLS2 = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Resultados');
  
    const colunas = columns2
    .filter((coluna) => columnVisibilityModel2[coluna.field] !== false)
    .map((coluna) => coluna.headerName);
  
    // Adiciona cabeçalhos
    const headerRow = worksheet.addRow(colunas);
    
    // Aplica estilo ao cabeçalho
    headerRow.eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'D3D3D3' },
      };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
      cell.font = { bold: true };
    });
  
    // Adiciona dados
    applyFilters(rows2, filterModel).forEach((item) => {
      const rowData = columns2
        .filter((coluna) => columnVisibilityModel2[coluna.field] !== false)
        .map((coluna) => item[coluna.field]);
      const row = worksheet.addRow(rowData);
    });
  
    // Aplica bordas a todas as células, incluindo as vazias
    const range = worksheet.getSheetValues();
    for (let R = 1; R <= range.length; ++R) {
      const row = worksheet.getRow(R);
      for (let C = 1; C <= colunas.length; ++C) {
        const cell = row.getCell(C);
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      }
    }
  
    const buffer = await workbook.xlsx.writeBuffer();
    const nomeDoArquivo = `resultados_mov_policiafederal_prod_${formattedDataInicial}-${formattedDataFinal}.xlsx`;
  
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, nomeDoArquivo);
  };

  return (
    <div>

      <StyledOrange />
      <StyledTitle>Movimentação - Polícia Federal</StyledTitle>
      <StyledGreen />

      <FormContainer>
        <div>
          {isLoading && (
            <div
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 9999,
              }}
            >
              <CircularProgress />
            </div>
          )}

            <Data
              formattedDataInicial={formattedDataInicial}
              formattedDataFinal={formattedDataFinal}
              setFormattedDataInicial={setFormattedDataInicial}
              setFormattedDataFinal={setFormattedDataFinal}
            />
            <Produtos
              produtoSelecionado={produtoSelecionado}
              setProdutoSelecionado={setProdutoSelecionado}
            />
            <Transportadoras
              transportadoraSelecionada={transportadoraSelecionada}
              setTransportadoraSelecionada={setTransportadoraSelecionada}
            />
            <br></br>
            <ButtonContainer>
              <SaveButton onClick={realizarConsulta}>
                <AiOutlineSearch />
                ⠀Calcular
              </SaveButton>
            </ButtonContainer>
          
        </div>
      </FormContainer>
      <MobileButton onClick={realizarConsulta}>
        <AiOutlineSearch />
        ⠀Calcular
      </MobileButton>
      
      {resultados.length > 0 && (
        <ResultadosContainer id="resultados-container">
            <ResultadosTitle>Resultados</ResultadosTitle>
            <ButtonWrapper>
            
            <LimparButton
              onClick={handleLimparResultados}
              variant="contained"
              color="secondary"
            >
              Limpar
            </LimparButton>
            <ImprimirButton
              onClick={handlePrintResults}
              variant="contained"
              color="primary"
            >
              Imprimir
            </ImprimirButton>
            <XlsButton
              onClick={handleGenerateXLS}
              variant="contained"
              color="primary"
            >
              Gerar⠀XLS
            </XlsButton>
            </ButtonWrapper>

            <div
            style={{
              border: "5px solid #D3D3D3", // Cor e largura da borda
              padding: "10px", // Espaçamento interno
            }}
          >
            <Typography component="div">
                <div className="tb-result">
                    <table>
                        <tr>
                          <td style={{color:"#2957A4"}}>Valor Total de Entrada (R$)</td>
                          <td>{calcularValorTotalEntrada()}</td>
                        </tr>
                        <tr>
                          <td style={{color:"#2957A4"}}>Valor Total de Saída (R$)</td>
                          <td>{calcularValorTotalSaida()}</td>
                        </tr>
                        <tr>
                          <td style={{color:"#2957A4"}}>Quantidade Total de Entrada (Kg)</td>
                          <td>{calcularQuantidadeTotalEntrada()}</td>
                        </tr>
                        <tr>
                          <td style={{color:"#2957A4"}}>Quantidade Total de Saída (Kg)</td>
                          <td>{calcularQuantidadeTotalSaida()}</td>
                        </tr>
                        <tr>
                          <td style={{color:"#2957A4"}}>Produção (Entrada) (Kg)</td>
                          <td>{calcularQuantidadeTotalProd()}</td>
                        </tr>
                        <tr>
                          <td style={{color:"#2957A4"}}>Insumo de Produção (Saída) (Kg)</td>
                          <td>{calcularQuantidadeTotalInsumo()}</td>
                        </tr>
                    </table>
                </div>                 
            </Typography>
          </div>
          <br></br>
          <StyledDataGrid
                  rows={rows}
                  columns={columns}
                  sortComparator={customComparator}
                  sx={{
                    boxShadow: 2,
                    border: 2,
                    borderColor: 'primary.light',
                    '& .MuiDataGrid-cell:hover': {
                      color: 'primary.main',
                    },
                  }}
                  initialState={{
                    pagination: {
                      paginationModel: { page: 0, pageSize: 20 },
                    },
                  }}
                  columnVisibilityModel={columnVisibilityModel}
                  onColumnVisibilityModelChange={(newModel) =>
                    setColumnVisibilityModel(newModel)
                  }
                  filterModel={filterModel}
                  onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
                  localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                  getRowClassName={(params) => (params.row.VL_REL >= 15 ? `grid-azul` : ``)}
                  pageSizeOptions={[5, 10, 15, 20]}
                  disableSelectionOnClick
                />
                <br></br>
                <ButtonWrapper>
                <LimparButton
                  onClick={handleLimparResultados}
                  variant="contained"
                  color="secondary"
                >
                  Limpar
                </LimparButton>
                <ImprimirButton
                  onClick={handlePrintResults2}
                  variant="contained"
                  color="primary"
                >
                  Imprimir
                </ImprimirButton>
                <XlsButton
                  onClick={handleGenerateXLS2}
                  variant="contained"
                  color="primary"
                >
                  Gerar⠀XLS
                </XlsButton>
                </ButtonWrapper>
                <StyledDataGrid
                  rows={rows2}
                  columns={columns2}
                  getRowId={(row) => row.NCM + row.Produto}
                  rowHeight={30}
                  sortComparator={customComparator}
                  sx={{
                    boxShadow: 2,
                    border: 2,
                    borderColor: 'primary.light',
                  }}
                  initialState={{
                    pagination: {
                      paginationModel: { page: 0, pageSize: 20 },
                    },
                  }}
                  columnVisibilityModel={columnVisibilityModel2}
                  onColumnVisibilityModelChange={(newModel) =>
                    setColumnVisibilityModel2(newModel)
                  }
                  filterModel={filterModel2}
                  onFilterModelChange={(newFilterModel) => setFilterModel2(newFilterModel)}
                  localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                  pageSizeOptions={[5, 10, 15, 20]}
                  disableSelectionOnClick
                />
        </ResultadosContainer>
      )}
    </div>
  );
};

export default PoliciaFederal;