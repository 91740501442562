import React, { useState  } from "react";
import { Axios } from "../../config/axiosConf";
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { DataGrid } from '@mui/x-data-grid';
import { ptBR } from '@mui/x-data-grid/locales';
import Grupo from "../../components/AutoCompletes/Grpds";
import CheckboxesEstBE from "../../components/Checkboxes/CheckboxesEstBE";
import styled from "@emotion/styled";
import { styled as Estilo, darken, lighten } from "@mui/material/styles";
import {
  Typography,
  Button,
  CircularProgress,
  
} from "@mui/material";
import { AiOutlineSearch } from "react-icons/ai";
import Data from "../../components/DataPickers/DataUnica";
import "../../styles/ConsultaGeralVendas.css";
import logo from "../../assets/Logo-Grupo-Sumatex.png";
import logo_sumatex from "../../assets/logo.png";
import logo_ambiental from "../../assets/Logo-Sumatex-Ambiental.png";
import logo_agro from "../../assets/Logo-Sumatex-Agro.png";
import logo_lorenvel from "../../assets/Logo-Lorenvel.png";
import logo_cesbra from "../../assets/Logo-Cesbra.png";

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  width: 90%;
  gap: 16px;
  padding: 24px;
  background-color: #f5f5f5;
  border-radius: 8px;
  height: 100%;
  margin: 50px;

  @media (max-width: 550px) {
    width: 100%;
    margin: 0px;
  }
`;

const StyledTitle = styled(Typography)`
  font-weight: bold;
  font-size: 1.5em;
  margin: 0em 0;
  background-color: #2957a4;
  color: #fff;
  padding: 10px;
  text-align: center;
  @media (max-width: 550px) {
    font-size: 1.2em;
  }
`;

const StyledOrange = styled(Typography)`
    background-color: #D66E20;
    padding-top: 3px;
    padding-bottom: 3px;
  @media (max-width: 550px) {
    font-size: 1.2em;
  }
`;

const StyledGreen = styled(Typography)`
    background-color: #7CA217;
    padding-top: 3px;
    padding-bottom: 3px;
  @media (max-width: 550px) {
    font-size: 1.2em;
  }
`;


const ButtonContainer = styled.div`
  display: flex;
  gap: 16px;
  margin-left: 25px;
`;
const ResultadosTitle = styled.h2`
  margin: 0;
  font-size: 2em;
  color: #1f437d;
  text-align: center;
  text-transform: uppercase;
  padding: 10px;
  border-radius: 10px;
  @media (max-width: 550px) {
    width: 80px;
    font-size: 1.5em;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: flex-end;
  margin-top: 20px;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  @media (max-width: 550px) {
    justify-content: space-between;
  }
`;

const SaveButton = styled(Button)`
  height: 30px;
  width: 150px;
  color: #fff;
  background-color: #2957a4;
  border: 1px solid rgba(0, 0, 0, 0.2);
  &:hover {
    background-color: #1f437d;
  }
`;

const LimparButton = styled(Button)`
  height: 30px;
  width: 100px;
  color: #fff;
  background-color: #ff6347;
  border: 1px solid rgba(0, 0, 0, 0.2);
  &:hover {
    background-color: #ff0000;
  }
  @media (max-width: 550px) {
    width: 80px;
    font-size: 0.8em;
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const ImprimirButton = styled(Button)`
  height: 30px;
  padding-left: 50px;
  padding-right: 50px;
  width: 120px;
  color: #fff;
  background-color: #a0a0a0;
  border: 1px solid rgba(0, 0, 0, 0.2);
  &:hover {
    background-color: #808080;
  }

  @media (max-width: 550px) {
    width: 80px;
    font-size: 0.8em;
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const XlsButton = styled(Button)`
  height: 30px;
  width: 120px;
  padding-left: 50px;
  padding-right: 50px;
  color: #fff;
  background-color: #71bc78;
  border: 1px solid rgba(0, 0, 0, 0.2);
  &:hover {
    background-color: #569b5c;
  }
  @media (max-width: 550px) {
    width: 80px;
    font-size: 0.8em;
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const ResultadosContainer = styled.div`
  margin-top: 20px;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  @media (max-width: 550px) {
    
    padding: 10px; 
  }
`;

const getBackgroundColor = (color, mode) =>
  mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7);

const getHoverBackgroundColor = (color, mode) =>
  mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

const getSelectedBackgroundColor = (color, mode) =>
  mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

const getSelectedHoverBackgroundColor = (color, mode) =>
  mode === 'dark' ? darken(color, 0.4) : lighten(color, 0.4);


const StyledDataGrid = Estilo(DataGrid)(({ theme }) => ({
  '& .grid-azul': {
    backgroundColor: getBackgroundColor(theme.palette.info.main, theme.palette.mode),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.info.main,
        theme.palette.mode,
      ),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.info.main,
        theme.palette.mode,
      ),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.info.main,
          theme.palette.mode,
        ),
      },
    },
  },
  '& .grid-vermelho': {
    backgroundColor: getBackgroundColor(
      theme.palette.error.main,
      theme.palette.mode,
    ),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.error.main,
        theme.palette.mode,
      ),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.error.main,
        theme.palette.mode,
      ),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.error.main,
          theme.palette.mode,
        ),
      },
    },
  },
  '& .grid-amarelo': {
    backgroundColor: getBackgroundColor(
      theme.palette.warning.main,
      theme.palette.mode,
    ),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.warning.main,
        theme.palette.mode,
      ),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.warning.main,
        theme.palette.mode,
      ),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.warning.main,
          theme.palette.mode,
        ),
      },
    },
  },
}));

const EstoqueBaixaEstoque = () => {
  const [formattedDataFinal, setFormattedDataFinal] = useState("");
  const Filial = sessionStorage.getItem("empresa");
  const [grupoSelecionado, setGrupoSelecionado] = useState(null);
  const [estoqueMinimo, setEstoqueMinimo] = useState(null);
  const [importados, setImportados] = useState(null);
  const [nacionais, setNacionais] = useState(null);
  const [controlePF, setControlePF] = useState(null);
  const [controlePC, setControlePC] = useState(null);
  const [controleEB, setControleEB] = useState(null);
  const [programado, setProgramado] = useState(null);
  const [trocaNF, setTrocaNF] = useState(null);
  const [resultados, setResultados] = useState([]);
    // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(false);
    // eslint-disable-next-line
  const [consultaSelecionada, setConsultaSelecionada] = useState("relatorio");
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);

  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({
    id: false,
    conversao: false,
    data_min: false,
    total: false,
    maximo: false,
    ultm_compra: false,
    ultm_compraval: false,
    est_medio: false,
    fornecedor: false,
  });

  const [filterModel, setFilterModel] = React.useState({
    items: [
    ],
  });

  const applyFilters = (rows, filterModel) => {
    return rows.filter((row) => {
      return filterModel.items.every((filter) => {
        const { field, operator, value } = filter;
        const rowValue = row[field];
  
        // Convertendo rowValue e value para string para comparações de texto
        const rowValueStr = String(rowValue).toLowerCase();
        const valueStr = String(value).toLowerCase();
  
        switch (operator) {
          case '>':
            return rowValue > value;
          case '>=':
            return rowValue >= value;
          case '<':
            return rowValue < value;
          case '<=':
            return rowValue <= value;
          case 'equals':
          case '=': // Igualdade numérica ou textual
            return rowValueStr === valueStr;
          case '!=': // Diferença numérica ou textual
            return rowValue !== value;
          case 'contains': // Contém
            return rowValueStr.includes(valueStr);
          case 'startsWith': // Começa com
            return rowValueStr.startsWith(valueStr);
          case 'endsWith': // Termina com
            return rowValueStr.endsWith(valueStr);
          default:
            return true; // Se o operador não for suportado, não filtra.
        }
      });
    });
  };

  const customComparator = (v1, v2) => {
    const value1 = parseFloat(v1.replace(',', '.'));
    const value2 = parseFloat(v2.replace(',', '.'));
  
    if (value1 < value2) {
      return -1;
    }
    if (value1 > value2) {
      return 1;
    }
    return 0;
  };

  const customDateComparator = (dateStr1, dateStr2) => {

    const parts1 = dateStr1.split('/');
    const parts2 = dateStr2.split('/');
    const date1 = new Date(Number(parts1[2]), Number(parts1[1]) - 1, Number(parts1[0]));
    const date2 = new Date(Number(parts2[2]), Number(parts2[1]) - 1, Number(parts2[0]));
  
    
    if (date1 < date2) {
      return -1;
    }
    if (date1 > date2) {
      return 1;
    }
    return 0;
};

const handleLimparResultados = () => {
    setResultados([]);
    setColumns([]);
    setRows([]);
  };

  const realizarConsulta = async () => {
    let procedure = "";

    switch (consultaSelecionada) {
      case "relatorio":
        procedure = "estoque-baixa-estoque";
        break;
      default:
        procedure = "relatorio";
        break;
    }

    try {
      const data = {
        Data: {
          formattedDataFinal,
        },

        Grpds: {
          grupoSelecionado:
            grupoSelecionado === null ? null : grupoSelecionado.substring(0, 6),
        },

        CheckboxesEstBE: {
          estoqueMinimo,
          importados,
          nacionais,
          controlePF,
          programado,
          trocaNF,
          controlePC,
          controleEB
        },
        Filial,
      };

      const config = {
        timeout: 100000,
      };

      setIsLoading(true);

      const response = await Axios.post(
        `estoque/${procedure}`,
        data,
        config
      );

      if (response.data.length > 0){

        const rows = response.data.map((item, index) => {
          const rowData = {};
          Object.keys(item).forEach(key => {
              rowData[key] = item[key];
          });
          
          rowData['id'] = index + 1; 
          return rowData;
        });
        
        const columns = Object.keys(response.data[0]).map(key => ({
            field: key,
            ...(key === 'codigo' ? {
              headerName: 'Código',
              width: 80,
              sortComparator: customDateComparator
            } : {...(key === 'nome' ? {
              headerName: 'Nome',
              width: 400 
            } : {...(key === 'unidade' ? {
              headerName: 'Un',
              width: 80
            } : {...(key === 'inicial' ? {
              headerName: 'Inicial (Kg)',
              width: 150 
            } : {...(key === 'entrada' ? {
              headerName: 'Entrada (Kg)',
              width: 150 
            } : {...(key === 'saida' ? {
              headerName: 'Saída (Kg)',
              width: 150
            } : {...(key === 'saldo' ? {
              headerName: 'Saldo (Kg)',
              width: 150 
            } : {...(key === 'preco_medio' ? {
              headerName: 'PM (R$)',
              width: 80 
            } : {...(key === 'reposicao' ? {
              headerName: 'Reposição',
              width: 120
            } : {...(key === 'vl_total' ? {
              headerName: 'Vl. Total (R$)',
              width: 120
            } : {...(key === 'fisico' ? {
              headerName: 'Físico',
              width: 120
            } : {...(key === 'data_saldo' ? {
              headerName: 'Fechamento',
              width: 120
            } : {headerName: key, width: 200})})})})})})})})})})})}),
  
            ...(key === 'inicial' || key === 'entrada' || key  === 'saida' || key  === 'preco_medio' || key  === 'reposicao' || key  === 'vl_total' || key  === 'fisico' || key  === 'saldo' ? {
              valueFormatter: (params) => {
                return params.toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                });
                
              }
          } : {})
        }));
  
        columns.unshift({ field: 'id', headerName: 'ID', width: 10 });

        setRows(rows);
        setColumns(columns)

      }

      setResultados(response.data);
      setIsLoading(false);
      
    } catch (error) {
      console.error("Erro ao realizar a consulta:", error);
      setIsLoading(false);
    }

  };

  function formatarValor(valor) {
    if (valor === null || valor === undefined) {
      return '';
    }
    
    if (typeof valor !== "number") {
      return valor;
    }

    return valor.toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }

  function desformatarData(data) {
    if (!data) return null;
  
    const ano = data.slice(0, 4);
    const mes = data.slice(4, 6);
    const dia = data.slice(6, 8);
  
    return `${dia}/${mes}/${ano}`;
  }

  const handlePrintResults = () => {
    const Filial = sessionStorage.getItem("empresa");
    const printContent = `
      <html>
        <head>
          <title>S.I.G.A - Grupo Sumatex</title>
          <style>
           
            body { font-family: Arial, sans-serif; margin: 0; padding: 0; }
            h1 {
              text-align: center;
              margin-bottom: 20px;
              font-size: 24px;
            }
            h2 img {
              height: 40px;
              margin-right: 10px;
            }
            table { 
              width: 100%; 
              border-collapse: 
              collapse; 
            }
            th, td { 
              border: 1px solid #000; 
              padding: 8px; 
              text-align: left; 
            }
          </style>
        </head>
        <body>
        <h2>
        ${
          Filial === "1 - MAT" ? `<img src="${logo_sumatex}" alt="Logo">` :
          Filial === "7 - CES" ? `<img src="${logo_cesbra}" alt="Logo">` :
          Filial === "2 - AMB" ? `<img src="${logo_ambiental}" alt="Logo">` :
          Filial === "9 - LOG" ? `<img src="${logo_lorenvel}" alt="Logo">` :
          Filial === "5 - FMT" ? `<img src="${logo_agro}" alt="Logo">` :
          `<img src="${logo}" alt="Logo">`
        } </h2>
       <h1>
         Registro de Inventário - Baixa de Estoque
       </h1>
       <div>
          <div class="filters">
           <p style="text-align: center;"><strong>Data: </strong>${desformatarData(formattedDataFinal)}</strong></p>
           
           ${grupoSelecionado ? `<div key="grupo"><p style="text-align: center;"><strong>Grupo:</strong> ${grupoSelecionado}</p></div>` : ''}
          </div>
        </div>
       <table>
       <thead>
         <tr>
           ${columns
            .filter((coluna) => columnVisibilityModel[coluna.field] !== false)
            .map((coluna) => `<th>${coluna.headerName}</th>`)
             .join('')}
         </tr>
       </thead>
       <tbody>
         ${applyFilters(rows, filterModel)
           .map((item, index) => {
             return `
               <tr ${index % 2 === 0 ? 'style="background-color: #f9f9f9;"' : ''}>
                 ${columns
                  .filter((coluna) => columnVisibilityModel[coluna.field] !== false)
                  .map((coluna) => {
                     return `<td>${formatarValor(item[coluna.field])}</td>`
                   })
                   .join("")}
               </tr>
             `;
           })
           .join("")}
       </tbody>
     </table>
      </body>
      </html>
      `;

      const screenWidth = window.screen.width;
      const screenHeight = window.screen.height;
      const printWindow = window.open(
        "",
        "",
        `width=${screenWidth},height=${screenHeight}`
      );
  
      if (!printWindow) {
        alert(
          "Erro ao abrir a janela de impressão. Verifique as configurações do seu navegador."
        );
        return;
      }
  
      printWindow.document.open();
      printWindow.document.write(printContent);
      printWindow.document.close();
  
      printWindow.onload = () => {
        printWindow.print();
  
        setTimeout(() => {}, 1000);
      };
  };

  const handleGenerateXLS = async () => {
  
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Resultados');
  
    
    const colunas = columns
    .filter((coluna) => columnVisibilityModel[coluna.field] !== false)
    .map((coluna) => coluna.headerName);
  
    
    const headerRow = worksheet.addRow(colunas);
  
    
    headerRow.eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'D3D3D3' }, 
      };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
      cell.font = { bold: true }; 
    });
  
    
    applyFilters(rows, filterModel).forEach((item) => {
      const rowData = columns
      .filter((coluna) => columnVisibilityModel[coluna.field] !== false)
      .map((coluna) => item[coluna.field]);
      worksheet.addRow(rowData);
    });
  
    
    const range = worksheet.getSheetValues();
    for (let R = 1; R <= range.length; ++R) {
      const row = worksheet.getRow(R);
      for (let C = 1; C <= colunas.length; ++C) {
        const cell = row.getCell(C);
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      }
    }
  
    
    const buffer = await workbook.xlsx.writeBuffer();
    const nomeDoArquivo = `resultados_estoque_${formattedDataFinal}.xlsx`;
  
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, nomeDoArquivo); 
  
    
    
  
  };

  return (
    <div className={`content`}>
      <StyledOrange />
      <StyledTitle>Planilha de Estoque - Baixa de Estoque</StyledTitle>
      <StyledGreen />

      <FormContainer>
        <div>
          {isLoading && (
            <div
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 9999,
              }}
            >
              <CircularProgress />
            </div>
          )}

          <Data
            formattedDataFinal={formattedDataFinal}
            setFormattedDataFinal={setFormattedDataFinal}
          />

          <Grupo
            grupoSelecionado={grupoSelecionado}
            setGrupoSelecionado={setGrupoSelecionado}
          />

          <label></label>
          <CheckboxesEstBE
            estoqueMinimo={estoqueMinimo}
            importados={importados}
            nacionais={nacionais}
            controlePF={controlePF}
            programado={programado}
            trocaNF={trocaNF}
            controlePC={controlePC}
            controleEB={controleEB}
            setEstoqueMinimo={setEstoqueMinimo}
            setImportados={setImportados}
            setNacionais={setNacionais}
            setControlePF={setControlePF}
            setProgramado={setProgramado}
            setTrocaNF={setTrocaNF}
            setControlePC={setControlePC}
            setControleEB={setControleEB}
          />
          <br></br>
          <ButtonContainer>
            <SaveButton onClick={realizarConsulta}>
              <AiOutlineSearch />
               Consultar
            </SaveButton>
          </ButtonContainer>
        </div>
      </FormContainer>

      {resultados.length > 0 ? (
        <ResultadosContainer id="resultados-container">
          <ResultadosTitle>Resultados</ResultadosTitle>
          <ButtonWrapper>
            <LimparButton
              onClick={handleLimparResultados}
              variant="contained"
              color="secondary"
            >
              Limpar
            </LimparButton>
            <ImprimirButton
              onClick={handlePrintResults}
              variant="contained"
              color="primary"
            >
              Imprimir
            </ImprimirButton>
            <XlsButton
              onClick={handleGenerateXLS}
              variant="contained"
              color="primary"
            >
              Gerar⠀XLS
            </XlsButton>
          </ButtonWrapper>
          <br></br>
          <StyledDataGrid
                rows={rows}
                columns={columns}
                rowHeight={30}
                sortComparator={customComparator}
                sx={{
                  boxShadow: 2,
                  border: 2,
                  borderColor: 'primary.light',
                }}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 20 },
                  },
                }}
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) =>
                  setColumnVisibilityModel(newModel)
                }
                filterModel={filterModel}
                onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                pageSizeOptions={[5, 10, 15, 20]}
                disableSelectionOnClick
              />

        </ResultadosContainer>
      ) : null}
    </div>
  );
};

export default EstoqueBaixaEstoque;