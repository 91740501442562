import React, { useState, useEffect, useRef } from "react";
import Data from "../../../components/DataPickers/Data";
import { Axios } from "../../../config/axiosConf";
import { Button, CircularProgress, Tab, Tabs } from "@mui/material";
import { AiOutlineSearch } from "react-icons/ai";
import styled from "@emotion/styled";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
} from "recharts";
import VendedoresAtivos from "../../../components/AutoCompletes/VendedoresAtivos";

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  width: 90%;
  gap: 16px;
  padding: 24px;
  background-color: #f5f5f5;
  border-radius: 8px;
  height: 100%;
  margin: 50px;

  @media (max-width: 550px) {
    width: 100%;
    margin: 0px;
  }
`;

const SaveButton = styled(Button)`
  height: 30px;
  width: 150px;
  color: #fff;
  background-color: #2957a4;
  border: 1px solid rgba(0, 0, 0, 0.2);
  &:hover {
    background-color: #1f437d;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 16px;
  margin-left: 25px;
`;

const ResultadosContainer = styled.div`
  margin-top: 20px;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  @media (max-width: 550px) {
    // Estilo responsivo para largura de tela até 360px
    padding: 10px; // Ajuste o espaçamento conforme necessário
  }
`;

const ResultadosTitle = styled.h2`
  margin: 0;
  font-size: 2em; /* Aumenta o tamanho da fonte */
  color: #1f437d; /* Define uma cor personalizada (laranja) */
  text-align: center; /* Centraliza o texto */
  text-transform: uppercase; /* Converte o texto em maiúsculas */
  padding: 10px; /* Adiciona espaçamento interno */
  border-radius: 10px; /* Adiciona bordas arredondadas */
  @media (max-width: 550px) {
    width: 80px;
    font-size: 1.5em;
  }
`;

const Indicadores = () => {
  const [formattedDataInicial, setFormattedDataInicial] = useState("");
  const [formattedDataFinal, setFormattedDataFinal] = useState("");
  const gridRef = useRef(null);
  const [vendedorAtivoSelecionado, setVendedorAtivoSelecionado] =
    useState(null);
  const [graficoSelecionado, setGraficoSelecionado] = useState(
    "indicador-kpi-cadastro-cliente"
  );
  const [isLoading, setIsLoading] = useState(false);
  const [resultados, setResultados] = useState([]);
  // eslint-disable-next-line
  const [mostrarResultados, setMostrarResultados] = useState(false);

  const handleGraficoChange = (event, newValue) => {
    setGraficoSelecionado(newValue);
  };
  const realizarConsulta = async () => {
    let procedure = "";

    switch (graficoSelecionado) {
      case "kpiCadastroClientes":
        procedure = "indicadores-kpi-cadastro-cliente";
        break;
      case "kpiClientesResgatados":
        procedure = "indicadores-kpi-clientes-resgatados";
        break;
      case "kpiContatos":
        procedure = "indicadores-kpi-contatos";
        break;
      case "kpiCotacoes":
        procedure = "indicadores-kpi-cotacoes";
        break;
      case "kpiLiberaPedidos":
        procedure = "indicadores-kpi-libera-pedidos";
        break;
      case "kpiMetas":
        procedure = "indicadores-kpi-metas";
        break;
      case "kpiRentabilidade":
        procedure = "indicadores-kpi-rentabilidade";
        break;
      default:
        procedure = "indicadores-kpi-cadastro-cliente";
        break;
    }

    try {
      const data = {
        Data: {
          formattedDataInicial,
          formattedDataFinal,
        },
        VendedoresAtivos: {
          vendedorAtivoSelecionado:
            vendedorAtivoSelecionado === null
              ? null
              : vendedorAtivoSelecionado.substring(0, 6),
        },
      };

      const config = {
        timeout: 45000,
      };

      setIsLoading(true);

      const response = await Axios.post(
        `consultas/${procedure}`,
        data,
        config
      );

      setTimeout(() => {
        gridRef.current.scrollIntoView({ behavior: 'smooth' });
        gridRef.current.focus();
      }, 100); 
    

      setResultados(response.data);
      setMostrarResultados(true);
      setIsLoading(false);
    } catch (error) {
      console.error("Erro ao realizar a consulta:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (
      formattedDataInicial &&
      formattedDataFinal &&
      vendedorAtivoSelecionado
    ) {
      realizarConsulta();
    }
    // eslint-disable-next-line
  }, [graficoSelecionado, formattedDataInicial, formattedDataFinal]);

  const gerarGrafico = () => {
    // Mapeia os resultados para os dados do gráfico
    const dadosGrafico = resultados.map((resultado) => {
      return {
        mes: resultado.mes,
        kpi: resultado.KPI,
      };
    });

    return (
      <BarChart width={1000} height={400} data={dadosGrafico}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="mes" interval="preserveStartEnd" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="kpi" fill="#8884d8" />
      </BarChart>
    );
  };
  const gerarGraficoMetaValor = () => {
    // Mapeia os resultados para os dados do gráfico
    const dadosGrafico = resultados.map((resultado) => {
      return {
        mes: resultado.mes,
        meta: resultado.META_VALOR,
        valor: resultado.VALOR,
      };
    });

    return (
      <BarChart width={1000} height={400} data={dadosGrafico}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="mes" interval="preserveStartEnd" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="valor" fill="#8884d8" />
        <ReferenceLine y= "meta" stroke="red" label="Meta Valor" />
      </BarChart>
    );
  };
  const gerarGraficoMetaKG = () => {
    // Mapeia os resultados para os dados do gráfico
    const dadosGrafico = resultados.map((resultado) => {
      return {
        mes: resultado.mes,
        meta: resultado.META_KG,
        quantidade: resultado.QUANTIDADE,
      };
    });

    return (
      <BarChart width={1000} height={400} data={dadosGrafico}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="mes" interval="preserveStartEnd" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="quantidade" fill="#8884d8" />
        <ReferenceLine y= "meta" stroke="red" label="Meta KG" />
      </BarChart>
    );
  };
  const gerarGraficoKPI_KG_VALOR = () => {
    // Mapeia os resultados para os dados do gráfico
    const dadosGrafico = resultados.map((resultado) => {
      return {
        mes: resultado.mes,
        kpiValor: resultado.KPI_VALOR,
        kpiKG: resultado.KPI_KG,
      };
    });

    return (
      <BarChart width={1000} height={400} data={dadosGrafico}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="mes" interval="preserveStartEnd" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="kpiValor" fill="yellow" />
        <Bar dataKey="kpiKG" fill="blue" />
      </BarChart>
    );
  };

  return (
    <div>
      <FormContainer>
        <div>
          {isLoading && (
            <div
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 9999,
              }}
            >
              <CircularProgress />
            </div>
          )}
          <Data
            formattedDataInicial={formattedDataInicial}
            formattedDataFinal={formattedDataFinal}
            setFormattedDataInicial={setFormattedDataInicial}
            setFormattedDataFinal={setFormattedDataFinal}
          />
          <VendedoresAtivos
            vendedorAtivoSelecionado={vendedorAtivoSelecionado}
            setVendedorAtivoSelecionado={setVendedorAtivoSelecionado}
          />
        </div>
        <Tabs
          value={graficoSelecionado}
          onChange={handleGraficoChange}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          aria-label="scrollable force tabs example"
        >
          <Tab
            label="KPI - Cadastro de Clientes"
            value="kpiCadastroClientes"
            defaultValue={"kpiCadastroClientes"}
          />
          <Tab
            label="KPI - Clientes Resgatados"
            value="kpiClientesResgatados"
          />
          <Tab label="KPI - Contatos" value="kpiContatos" />
          <Tab label="KPI - Cotações" value="kpiCotacoes" />
          <Tab label="KPI - Liberação de Pedidos " value="kpiLiberaPedidos" />
          <Tab label="KPI - Metas (R$ e KG)" value="kpiMetas" />
          <Tab label="KPI - Rentabilidade" value="kpiRentabilidade" />
        </Tabs>
        <ButtonContainer>
          <SaveButton onClick={realizarConsulta}>
            <AiOutlineSearch />
            ⠀Calcular
          </SaveButton>
        </ButtonContainer>
        {resultados.length > 0 && (
          <ResultadosContainer>
            <ResultadosTitle>Resultados</ResultadosTitle>
            {graficoSelecionado === "kpiMetas" ? (
              <>
                {gerarGraficoMetaKG()}
                {gerarGraficoMetaValor()}
                {gerarGraficoKPI_KG_VALOR()}
              </>
            ) : (
              gerarGrafico()
            )}
          </ResultadosContainer>
        )}
      </FormContainer>
    </div>
  );
};

export default Indicadores;
