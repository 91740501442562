import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import styled from "@emotion/styled";
import { useMediaQuery } from "@mui/material";

const MesAnoComplete = ({ mesSelecionado, setMesSelecionado, anoSelecionado, setAnoSelecionado }) => {
  const isSmallScreen = useMediaQuery("(max-width: 550px)");

  const StyledAutocomplete = styled(Autocomplete)`
  width: ${isSmallScreen ? "200px" : "250px"};
  padding-left: 25px;
  .MuiButtonBase-root {
    width: 35px;
  }
  .MuiInputBase-root {
    padding-top: 4px;
    padding-bottom: 4px;
  }
  .MuiInputBase-input {
    font-size: 15px;
  }
`;

const StyledTextField = styled(TextField)`
    width: ${isSmallScreen ? "100%" : "200px"};
  `;

  const optionsMes = [
    { label: 'Janeiro', id: '01' },
    { label: 'Fevereiro', id: '02' },
    { label: 'Março', id: '03' },
    { label: 'Abril', id: '04' },
    { label: 'Maio', id: '05' },
    { label: 'Junho', id: '06' },
    { label: 'Julho', id: '07' },
    { label: 'Agosto', id: '08' },
    { label: 'Setembro', id: '09' },
    { label: 'Outubro', id: '10' },
    { label: 'Novembro', id: '11' },
    { label: 'Dezembro', id: '12' },
  ];

  const defaultPropsMes = {
    options: optionsMes,
    isOptionEqualToValue:(option, value) => option.id === value.id,
    getOptionLabel: (option) => option.label,
  };

  const optionsAno = [
    { label: '2020', id: '2020' },
    { label: '2022', id: '2022' },
    { label: '2023', id: '2023' },
    { label: '2024', id: '2024' },
    { label: '2025', id: '2025' },
    { label: '2026', id: '2026' },
    { label: '2027', id: '2027' },
    { label: '2028', id: '2028' },
    { label: '2029', id: '2029' },
  ];

  const defaultPropsAno = {
    options: optionsAno,
    isOptionEqualToValue:(option, value) => option.id === value.id,
    getOptionLabel: (option) => option.label
  };

  const enviarAnoSelecionado = (event, newValue) => {
    if (!newValue) {
      setAnoSelecionado(null);
    } else {
      setAnoSelecionado(newValue);
    }
  };

  const enviarMesSelecionado = (event, newValue) => {
    if (!newValue) {
      setMesSelecionado(null);
    } else {
      setMesSelecionado(newValue);
    }
  };


  return (
    <div style={{ display: "flex", paddingTop: 15, fontWeight: "bold" }}>
        <StyledAutocomplete
          {...defaultPropsMes}
          id="controlled-demo"
          value={mesSelecionado}
          onChange={enviarMesSelecionado}
          renderInput={(params) => (
            <StyledTextField {...params} label="Mês" variant="standard" />
          )}
        />
        <StyledAutocomplete
          {...defaultPropsAno}
          id="controlled-demo"
          value={anoSelecionado}
          onChange={enviarAnoSelecionado}
          renderInput={(params) => (
            <StyledTextField {...params} label="Ano" variant="standard" />
          )}
        />
    </div>
  );
};

export default MesAnoComplete;
