import React, { useState, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Axios } from "../../config/axiosConf";
import styled from "@emotion/styled";
import { useMediaQuery } from "@mui/material";

const Vendedores = ({ vendedorSelecionado, setVendedorSelecionado }) => {
  const [vendedores, setVendedores] = useState([]);
  const isSmallScreen = useMediaQuery("(max-width: 550px)");
  const codVendedor = sessionStorage.getItem("codVendedor");

  const StyledAutocomplete = styled(Autocomplete)`
    width: ${isSmallScreen ? "270px" : "380px"};
    padding-left: 25px;
    .MuiButtonBase-root {
      width: 35px;
    }
    .MuiInputBase-root {
      padding-top: 4px;
      padding-bottom: 4px;
    }
    .MuiInputBase-input {
      font-size: 15px;
    }
  `;

  const StyledTextField = styled(TextField)`
    width: ${isSmallScreen ? "100%" : "380px"};
  `;

  useEffect(() => {
  
    const getVendedores = async () => {
      try {
        const response = await Axios.get("OSCtP4F8cdKrZhU9lYI86Q");
        setVendedores(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    getVendedores();
  }, []);

  useEffect(() => {
    if (vendedores.length > 0 && codVendedor && codVendedor !== "null") {
      const vendedorEncontrado = vendedores.find((vendedor) =>
        vendedor.startsWith(codVendedor)
      );
      if (vendedorEncontrado) {
        setVendedorSelecionado(vendedorEncontrado); 
      }
    }
  }, [vendedores, codVendedor, setVendedorSelecionado]);

  const enviarVendedorSelecionado = (event, newValue) => {
    if (!newValue) {
      setVendedorSelecionado(null);
    } else {
      setVendedorSelecionado(newValue);
    }
  };

  return (
    <div style={{ display: "flex" }}>
      <div style={{ paddingTop: 15, fontWeight: "bold" }}>
        <p>Vendedor:</p>
        {codVendedor && codVendedor !== "null" ? (
          <StyledAutocomplete
            freeSolo={false}
            options={vendedores}
            value={vendedorSelecionado}
            readOnly
            renderInput={(params) => (
              <StyledTextField
                {...params}
                label=""
                InputProps={{
                  ...params.InputProps,
                   
                }}
              />
            )}
          />
        ) : (
          <StyledAutocomplete
            freeSolo={false}
            options={vendedores}
            value={vendedorSelecionado}
            onChange={enviarVendedorSelecionado}
            renderInput={(params) => (
              <StyledTextField
                {...params}
                label=""
                InputProps={{
                  ...params.InputProps,
                  readOnly: false, 
                }}
              />
            )}
          />
        )}
      </div>
    </div>
  );
};

export default Vendedores;
