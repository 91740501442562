import React from "react";
import { Typography, Box, Button } from "@mui/material";
import { FaExclamationTriangle } from "react-icons/fa";
import { RiArrowLeftSLine } from "react-icons/ri";
import { styled } from "@mui/system";

const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100vh",
  backgroundSize: "cover",
  backgroundPosition: "center",
  paddingBottom: "150px",
  textAlign: "center",
}));

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "rgba(41, 87, 164, 0.85)",
  padding: "3rem",
  borderRadius: "1rem",
  boxShadow: "0 4px 15px rgba(0, 0, 0, 0.3)",
  backdropFilter: "blur(10px)",
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "60px",
  height: "60px",
  marginBottom: "1.5rem",
  borderRadius: "50%",
  backgroundColor: "#ff0000",
  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)",
}));

const StyledButton = styled(Button)(({ theme }) => ({
  color: "#fff",
  backgroundColor: "#f44336",
  fontWeight: 700,
  marginTop: theme.spacing(2),
  padding: theme.spacing(1, 4),
  borderRadius: "25px",
  transition: "all 0.3s ease",
  "&:hover": {
    backgroundColor: "#d32f2f",
    transform: "scale(1.05)",
    boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
  },
}));

const Erro = () => {
  return (
    <StyledBox>
      <Container sx={{backgroundColor: "blue", }}>
        <IconWrapper>
          <FaExclamationTriangle size={40} color="#fff" />
        </IconWrapper>
        <Typography
          variant="h4"
          component="h1"
          gutterBottom
          sx={{
            marginTop: "1rem",
            color: "#fff",
            textTransform: "uppercase",
            fontWeight: 700,
            textShadow: "2px 2px 5px rgba(0, 0, 0, 0.3)",
          }}
        >
          Página não encontrada
        </Typography>
        <Typography
          variant="body1"
          sx={{
            marginBottom: "1rem",
            color: "#fff",
            textShadow: "2px 2px 5px rgba(0, 0, 0, 0.3)",
          }}
        >
          A página que você está tentando acessar não existe.
        </Typography>
        <StyledButton
          variant="contained"
          href="/login"
        >
          <RiArrowLeftSLine style={{ marginRight: "0.5rem" }} /> Voltar à página inicial
        </StyledButton>
      </Container>
    </StyledBox>
  );
};

export default Erro;
