import React, { useState } from "react";
import { TextField } from "@mui/material";
import "./Data.css";

const DataUnica = ({ formattedDataFinal, setFormattedDataFinal }) => {

  const [dataFinal, setDataFinal] = useState(formattedDataFinal);

  const formatDate = (dateString) => {
    const date = new Date(dateString + "T00:00:00Z");
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, "0"); 
    const day = String(date.getUTCDate()).padStart(2, "0");
    return `${year}${month}${day}`;
  };


  const handleDataFinalChange = (event) => {
    const newData = event.target.value;
    setDataFinal(newData);
    setFormattedDataFinal(formatDate(newData));
  };

  return (
    <div className="date-picker-container">

      <div className="date-input">
        <label>Data:</label>
        <TextField
          type="date"
          value={dataFinal}
          onChange={handleDataFinalChange}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </div>
    </div>
  );
};

export default DataUnica;
