import React, { useState, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Axios } from "../../config/axiosConf";
import styled from "@emotion/styled";
import { useMediaQuery } from "@mui/material";

const ProdutosBC = ({ produtoBCSelecionado, setProdutoBCSelecionado }) => {
  const [produtos, setProdutos] = useState([]);
  const isSmallScreen = useMediaQuery("(max-width: 550px)");

  const StyledAutocomplete = styled(Autocomplete)`
  width: ${isSmallScreen ? "270px" : "380px"};
  padding-left: 25px;
  .MuiButtonBase-root {
    width: 35px;
  }
  .MuiInputBase-root {
    padding-top: 4px;
    padding-bottom: 4px;
  }
  .MuiInputBase-input {
    font-size: 15px;
  }
`;

  const StyledTextField = styled(TextField)`
    width: ${isSmallScreen ? "100%" : "380px"};
  `;

  useEffect(() => {
    const getProdutos = async () => {
      try {
        const response = await Axios.get(
          "6ByKyNb3AP5PS9RiI_fyZQ"
        );
        setProdutos(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    getProdutos();
  }, []);

  const enviarProdutoBCSelecionado = (event, newValue) => {
    if (!newValue) {
      setProdutoBCSelecionado(null);
    } else {
      setProdutoBCSelecionado(newValue);
    }
  };

  return (
    <div style={{ display: "flex" }}>
      <div style={{ paddingTop: 15, fontWeight: "bold" }}>
        <p>Baixa Comercial:</p>
        <StyledAutocomplete
          freeSolo={false}
          options={produtos}
          value={produtoBCSelecionado}
          onChange={enviarProdutoBCSelecionado}
          renderInput={(params) => <StyledTextField {...params} label="" />}
        />
      </div>
    </div>
  );
};

export default ProdutosBC;
