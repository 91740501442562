import React, { useState } from "react";
import { Axios } from "../../config/axiosConf";
import * as XLSX from "xlsx";
import styled from "@emotion/styled";
import {
  Typography,
  Button,
  CircularProgress,
  FormControl,
  Autocomplete,
  TextField,
  useMediaQuery,
} from "@mui/material";
import Data from "../../components/DataPickers/Data";
import Produtos from "../../components/AutoCompletes/Produtos";
import CheckboxTodas from "../../components/Checkboxes/CheckboxTodas";

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  width: 90%;
  gap: 16px;
  padding: 24px;
  background-color: #f5f5f5;
  border-radius: 8px;
  height: 100%;
  margin: 50px;

  @media (max-width: 1000px) {
    width: 100%;
    margin: 0px;
  }
`;

const StyledTitle = styled(Typography)`
  font-weight: bold;
  font-size: 1.5em;
  margin: 0em 0;
  background-color: #2957a4;
  color: #fff;
  padding: 10px;
  text-align: center;
  @media (max-width: 550px) {
    font-size: 1.2em;
  }
`;

const StyledOrange = styled(Typography)`
    background-color: #D66E20;
    padding-top: 3px;
    padding-bottom: 3px;
  @media (max-width: 550px) {
    font-size: 1.2em;
  }
`;

const StyledGreen = styled(Typography)`
    background-color: #7CA217;
    padding-top: 3px;
    padding-bottom: 3px;
  @media (max-width: 550px) {
    font-size: 1.2em;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-wrap: wrap; /* Permite que os botões quebrem para a próxima linha em telas estreitas */
  gap: 16px;
  justify-content: flex-end; /* Alinha os botões à direita */
  margin-top: 20px;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
`;

const XlsButton = styled(Button)`
  height: 30px;
  width: 150px;
  font-size: 0.8em;
  padding-left: 50px;
  padding-right: 50px;
  color: #fff;
  background-color: #71bc78;
  border: 1px solid rgba(0, 0, 0, 0.2);
  &:hover {
    background-color: #569b5c;
  }
  @media (max-width: 1000px) {
    width: 110px;
    font-size: 0.7em;
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const RelatoriosLogistica = () => {
  const [formattedDataInicial, setFormattedDataInicial] = useState("");
  const [formattedDataFinal, setFormattedDataFinal] = useState("");
  const [produtoSelecionado, setProdutoSelecionado] = useState(null);
  const [todas, setTodas] = useState("");
  const Filial = sessionStorage.getItem("empresa");
  const [isLoading, setIsLoading] = useState(false);
  const [relatorios] = useState (["Conferencia_Horario_Documentos_de_Saida", "NFs_Canceladas", "Cotacoes_Frete"])
  const [relatorioSelecionado, setRelatorioSelecionado] = useState(null);
  const isSmallScreen = useMediaQuery("(max-width: 550px)");

  const grupo = sessionStorage.getItem("grupo");
  const grupoGerente = ["8f498e6aabb107de59d0ad2177f6d882"]; // GE

  const isUserInGroupGerente = grupoGerente.includes(grupo);

  const StyledAutocomplete = styled(Autocomplete)`
  width: ${isSmallScreen ? "100%" : "350px"};
  padding-left: 22px;
    .MuiButtonBase-root {
      width: 35px;
    }
    .MuiInputBase-root {
      padding-top: 4px;
      padding-bottom: 4px;
    }
    .MuiInputBase-input {
      font-size: 15px;
    }
  `;

  const StyledTextField = styled(TextField)`
  width: ${isSmallScreen ? "100%" : "350px"};
`;

  const handleRelatorioSelecionado = (event, newValue) => {
    setRelatorioSelecionado(newValue);
  };

  const handleGenerateXLS = async () => {

    if (!formattedDataInicial) {
      alert("Data inicial não selecionada");
      return;
    }

    if (!formattedDataFinal) {
      alert("Data final não selecionada");
      return;
    }

    if (!relatorioSelecionado) {
      alert("Relatório não foi selecionado");
      return;
    }

    let procedure = "";

    switch (relatorioSelecionado) {
      case "Conferencia_Horario_Documentos_de_Saida":
        procedure = "rel-doc-horario-saida";
        break;
      case "NFs_Canceladas":
          procedure = "rel-nfs-canceladas";
          break;
      case "Cotacoes_Frete":
        procedure = "rel-cotacoes-frete";
        break;
      default:
        procedure = "";
        break;
    }

    try {
      const data = {
        Data: {
          formattedDataInicial,
          formattedDataFinal,
        },
        Produtos: {
          produtoSelecionado: produtoSelecionado === null ? null : produtoSelecionado.substring(0, 6),
        },
        Filial,
        CheckboxTodas: {
          todas,
        },
      };

      const config = {
        timeout: 45000,
      };

      setIsLoading(true);

      const response = await Axios.post(
        `relatorios/xls/${procedure}`,
        data,
        config
      );

      const datas = [];

      datas.push(Object.keys(response.data[0]));

      response.data.forEach((item) => {
      const rowData = Object.keys(response.data[0]).map((coluna) => {
          return item[coluna];
        }
      );
      datas.push(rowData);
      
      });

      const wb = XLSX.utils.book_new();

      const ws = XLSX.utils.aoa_to_sheet(datas);
      XLSX.utils.book_append_sheet(wb, ws, "Resultados");

      const nomeDoArquivo = `resultados_${relatorioSelecionado}_${formattedDataInicial}-${formattedDataFinal}.xlsx`;

      XLSX.writeFile(wb, nomeDoArquivo);

      setIsLoading(false);
      

    } catch (error) {
      console.error("Erro ao realizar a consulta:", error);
      setIsLoading(false);
    }


  };

  return (
    <div>
      <StyledOrange />
      <StyledTitle>Relatórios - Logística</StyledTitle>
      <StyledGreen />
      <FormContainer>
        <div>
          {isLoading && (
            <div
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 9999,
              }}
            >
              <CircularProgress />
            </div>
          )}

          <Data
            formattedDataInicial={formattedDataInicial}
            formattedDataFinal={formattedDataFinal}
            setFormattedDataInicial={setFormattedDataInicial}
            setFormattedDataFinal={setFormattedDataFinal}
          />
           <Produtos
              produtoSelecionado={produtoSelecionado}
              setProdutoSelecionado={setProdutoSelecionado}
            />
            <br></br>
            {isUserInGroupGerente && (
            <CheckboxTodas todas={todas} setTodas={setTodas} />
            )}
            <br></br>
          <FormControl fullWidth>
            <StyledAutocomplete
              required
              key={relatorioSelecionado}
              id="relatorio"
              value={relatorioSelecionado}
              onChange={handleRelatorioSelecionado}
              options={relatorios}
              getOptionLabel={(relatorio) => relatorio
                .replace(/Conferencia_Horario_Documentos_de_Saida/g, 'Documentos de Saída - Conferência de Horários')
                .replace(/NFs_Canceladas/g, 'Notas Fiscais Canceladas')
                .replace(/Cotacoes_Frete/g, 'Cotações de Frete')}
              renderInput={(params) => (
                <StyledTextField
                  {...params}
                  label="Selecione o Relatório"
                  variant="outlined"
                />
              )}
            />
            <ButtonWrapper>
                <XlsButton onClick={handleGenerateXLS}>
                  Baixar⠀XLS
                </XlsButton>
            </ButtonWrapper>
          </FormControl>
         
        </div>
      </FormContainer>
    </div>
  );
};

export default RelatoriosLogistica;

