import React, { useState, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Axios } from "../../config/axiosConf";
import styled from "@emotion/styled";
import { useMediaQuery } from "@mui/material";

const Cidades = ({ cidadeSelecionada, setCidadeSelecionada }) => {
  const [cidades, setcidades] = useState([]);
  const isSmallScreen = useMediaQuery("(max-width: 550px)");

  const StyledAutocomplete = styled(Autocomplete)`
  width: ${isSmallScreen ? "270px" : "380px"};
  padding-left: 25px;
  .MuiButtonBase-root {
    width: 35px;
  }
  .MuiInputBase-root {
    padding-top: 4px;
    padding-bottom: 4px;
  }
  .MuiInputBase-input {
    font-size: 15px;
  }
`;

  const StyledTextField = styled(TextField)`
    width: ${isSmallScreen ? "100%" : "380px"};
  `;

  useEffect(() => {
    const getCidades = async () => {
      try {
        const response = await Axios.get(
          "qbA5sB0fq8W37y-Bq5iK9A"
        );
        setcidades(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    getCidades();
  }, []);

  const enviarCidadeSelecionada = (event, newValue) => {
    if (!newValue) {
      setCidadeSelecionada(null);
    } else {
      setCidadeSelecionada(newValue);
    }
  };

  return (
    <div style={{ display: "flex" }}>
      <div style={{ paddingTop: 15, fontWeight: "bold" }}>
        <p>Cidade:</p>
        <StyledAutocomplete
          freeSolo={false}
          options={cidades}
          value={cidadeSelecionada}
          onChange={enviarCidadeSelecionada}
          renderInput={(params) => <StyledTextField {...params} label="" />}
        />
      </div>
    </div>
  );
};

export default Cidades;
