import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TextField, Autocomplete } from '@mui/material/';
import styled from '@emotion/styled';
import { fetchPessoas, setResponsavelTransp, setRGResponsavelTransp} from '../../slices/responsavelTranspSlice'

const StyledH2 = styled.h2`
    display: block;
    font-size: 1em;
    font-weight: bold;
    padding-left: 15px;
`;

const StyledTextField = styled(TextField)`
  width: 380px;
  
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 500px;
`;

const StyledAutocomplete = styled(Autocomplete)`
    width: ${({ isSmallScreen }) => (isSmallScreen ? '100%' : '380px')};

    .MuiButtonBase-root {
        width: 40px;
    }
`;

const ResponsavelTransportadoraPortaria = () => {
    const dispatch = useDispatch();
    const { responsavelTransp, rgResponsavelTransp, pessoas } = useSelector((state) => state.responsavelTransp);
    const [isSmallScreen, setIsSmallScreen] = useState(window.matchMedia('(max-width: 750px)').matches);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.matchMedia('(max-width: 750px)').matches);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        dispatch(fetchPessoas());
    }, [dispatch]);

    useEffect(() => {
        const selectedResponsavelTransp = pessoas.find((person) => person.nome === responsavelTransp);
        if (selectedResponsavelTransp) {
            dispatch(setRGResponsavelTransp(selectedResponsavelTransp.rg));
        }
    }, [responsavelTransp, pessoas, dispatch]);

    useEffect(() => {
        if (rgResponsavelTransp.length >= 8) {
        const selectedResponsavelTransp = pessoas.find((person) => person.rg === rgResponsavelTransp);
        if (selectedResponsavelTransp) {
            dispatch(setResponsavelTransp(selectedResponsavelTransp.nome));
        }
    }
    }, [rgResponsavelTransp, pessoas, dispatch]);


    const enviarResponsavelTransp = (event, newValue) => {
        dispatch(setResponsavelTransp(newValue));
    };

    const handleRGChange = (event) => {
        const value = event.target.value.replace(/\D/g, ''); 
        if (value.length <= 11) {
            dispatch(setRGResponsavelTransp(value));
        }
    };

    return (
        <>
            <StyledH2>1.4 - Responsável pela verificação (Transp):</StyledH2>
            
            <Label id="labelRGresponsavelTransp">
                <TextField
                    id="rgResponsavelTransp"
                    name="rgResponsavelTransp"
                    placeholder="Digite o RG do resp. pela transportadora..."
                    required
                    value={rgResponsavelTransp}
                    sx={{
                        width: 250,
                    }}
                    onChange={handleRGChange}
                    multiline
                />
            </Label>

            <Label id="labelResponsavelTransp">
                <StyledAutocomplete
                    id="responsavelTransp"
                    freeSolo
                    options={pessoas.map((person) => person.nome)}
                    value={responsavelTransp}
                    onChange={(event, newValue) => enviarResponsavelTransp(event, newValue)}
                    onBlur={(event) => dispatch(setResponsavelTransp(event.target.value))}
                    renderInput={(params) => (
                        <StyledTextField
                            {...params}
                            id="text-responsavel"
                            name="text-responsavel"
                            label="Digite o nome do Responsavel pela transportadora."
                            required
                        />
                    )}
                    isSmallScreen={isSmallScreen}
                    isOptionEqualToValue={(option, value) => option === value}
                />
            </Label>
        </>
    );
};

export default ResponsavelTransportadoraPortaria;