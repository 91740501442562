// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.box-usuario table {
    border-collapse: collapse;
    font-size: 0.9rem;
     @media (max-width: 599.95px) {
        font-size: 0.8rem;
    }
    
    text-align: center;
}

.box-usuario td {
    padding: 3px;
    border: 1px solid #2957A4;
    font-size: 0.9rem; 

     @media (max-width: 599.95px) {
        font-size: 0.8rem;
    }
}


.box-usuario th {
    padding: 3px;
    border: 1px solid #2957A4;
    color:#2957A4;
}


.box-usuario {
    box-shadow: 0px 1.5px 1.5px rgba(0, 0, 0, 0.5);
}`, "",{"version":3,"sources":["webpack://./src/styles/Header.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,iBAAiB;KAChB;QACG,iBAAiB;IACrB;;IAEA,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,yBAAyB;IACzB,iBAAiB;;KAEhB;QACG,iBAAiB;IACrB;AACJ;;;AAGA;IACI,YAAY;IACZ,yBAAyB;IACzB,aAAa;AACjB;;;AAGA;IACI,8CAA8C;AAClD","sourcesContent":[".box-usuario table {\r\n    border-collapse: collapse;\r\n    font-size: 0.9rem;\r\n     @media (max-width: 599.95px) {\r\n        font-size: 0.8rem;\r\n    }\r\n    \r\n    text-align: center;\r\n}\r\n\r\n.box-usuario td {\r\n    padding: 3px;\r\n    border: 1px solid #2957A4;\r\n    font-size: 0.9rem; \r\n\r\n     @media (max-width: 599.95px) {\r\n        font-size: 0.8rem;\r\n    }\r\n}\r\n\r\n\r\n.box-usuario th {\r\n    padding: 3px;\r\n    border: 1px solid #2957A4;\r\n    color:#2957A4;\r\n}\r\n\r\n\r\n.box-usuario {\r\n    box-shadow: 0px 1.5px 1.5px rgba(0, 0, 0, 0.5);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
