import React from 'react';
import { Modal, Box } from '@mui/material';
import { styled } from '@mui/system';

const StyledModal = styled(Modal)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const Paper = styled(Box)(({ theme }) => ({
  backgroundColor: "#D3D3D3",
  border: '2px solid #000',
  boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.3)',
  padding: theme.spacing(2, 4, 3),
  width: '500px',
}));

const ModalComp = ({ open, handleClose, content }) => {
  return (
    <StyledModal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Paper>
        {content}
      </Paper>
    </StyledModal>
  );
};

export default ModalComp;
