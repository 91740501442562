// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tb-result table {
  border-collapse: collapse;
  font-size: 14px;
  letter-spacing: 2px;
  text-align: center;
}

.tb-result td {
  padding: 3px;
  font-weight: bold;
  border: 1px solid #2957A4;
}

.tb-result th {
  padding: 3px;
  border: 1px solid #2957A4;
  color:#2957A4;
}`, "",{"version":3,"sources":["webpack://./src/pages/Consultas/Orcamento/Orcamento.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,eAAe;EACf,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,iBAAiB;EACjB,yBAAyB;AAC3B;;AAEA;EACE,YAAY;EACZ,yBAAyB;EACzB,aAAa;AACf","sourcesContent":[".tb-result table {\r\n  border-collapse: collapse;\r\n  font-size: 14px;\r\n  letter-spacing: 2px;\r\n  text-align: center;\r\n}\r\n\r\n.tb-result td {\r\n  padding: 3px;\r\n  font-weight: bold;\r\n  border: 1px solid #2957A4;\r\n}\r\n\r\n.tb-result th {\r\n  padding: 3px;\r\n  border: 1px solid #2957A4;\r\n  color:#2957A4;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
