import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Axios } from "../../../config/axiosConf";
import { styled } from "@mui/material/styles";
import { Typography, Button, Box, CircularProgress, Paper, Grid } from "@mui/material"; 
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";


const StyledTitle = styled(Typography)`
  font-weight: bold;
  font-size: 1.5em;
  margin: 0em 0;
  background-color: #2957a4;
  color: #fff;
  padding: 10px;
  text-align: center;
  @media (max-width: 550px) {
    font-size: 1.2em;
  }
`;

const StyledOrange = styled(Typography)`
    background-color: #D66E20;
    padding-top: 3px;
    padding-bottom: 3px;
  @media (max-width: 550px) {
    font-size: 1.2em;
  }
`;

const StyledGreen = styled(Typography)`
    background-color: #7CA217;
    padding-top: 3px;
    padding-bottom: 3px;
  @media (max-width: 550px) {
    font-size: 1.2em;
  }
`;

const Excluir = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [dados, setDados] = useState(null);
    const [loading, setLoading] = useState(true);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");

    const handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackbar(false);
    };

    const showSnackbar = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setOpenSnackbar(true);
    };

    useEffect(() => {
        const getViews = async () => {
            try {
                const response = await Axios.get(`portaria/analise-transp/cadastro/${id}`);
                setDados(response.data);
            } catch (error) {
                console.error(error);
                showSnackbar("Erro ao carregar registro!", "error");
                setLoading(false);
            }
        };
        getViews();
    }, [id]);

    const handleDelete = async () => {
        try {
            await Axios.delete(`portaria/analise-transp/cadastro/${id}`);
            showSnackbar("Cadastro excluído com sucesso!", "success");
            setTimeout(() => {
                navigate("/portaria/analise-transp/consulta");
            }, 2000);
        } catch (error) {
            console.error(error);
            showSnackbar("Erro ao excluir cadastro!", "error");
        }
    };

    const handleCancel = () => {
        navigate("/portaria/analise-transp/consulta");
    };

    useEffect(() => {
        setLoading(false);
    }, []);

    const StyledPaper = styled(Paper)(({ theme }) => ({
        padding: theme.spacing(4),
        backgroundColor: "#ffffff",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
        maxWidth: "600px",
        margin: "auto",
        marginTop: theme.spacing(0),
        textAlign: "center",
    }));

    const StyledButtonDelete = styled(Button)(({ theme }) => ({
        margin: theme.spacing(2),
        padding: theme.spacing(1, 4),
        fontWeight: "bold",
        fontSize: "1em",
        borderRadius: "8px",
        backgroundColor: "#f44336",
        color: "#fff",
        transition: "all 0.3s ease",
        '&:hover': {
            backgroundColor: "#d32f2f",
            transform: "scale(1.05)",
            boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
        },
    }));

    const StyledButtonCancel = styled(Button)(({ theme }) => ({
        margin: theme.spacing(2),
        padding: theme.spacing(1, 4),
        fontWeight: "bold",
        fontSize: "1em",
        borderRadius: "8px",
        borderColor: "#2957a4",
        color: "#2957a4",
        transition: "all 0.3s ease",
        '&:hover': {
            borderColor: "#1f437d",
            color: "#1f437d",
            transform: "scale(1.05)",
            boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
        },
    }));

    const BodyText = styled(Typography)(({ theme }) => ({
        margin: theme.spacing(1, 0),
        fontFamily: "'Roboto', sans-serif",
    }));

    const GridItem = styled(Grid)(({ theme }) => ({
        padding: theme.spacing(2),
        borderBottom: "1px solid #e0e0e0",
    }));

    return (
        <>
            {loading ? (
                <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                    <CircularProgress size={80} />
                </Box>
            ) : (
                <>
                <StyledOrange/>
                    <StyledTitle>Excluir Formulário</StyledTitle>
                    <StyledGreen/>
                    <StyledPaper>
                        <Typography variant="h5" align="center" gutterBottom>
                            Tem certeza que deseja excluir o seguinte registro?
                        </Typography>
                        {dados ? (
                            <Grid container>
                                <GridItem item xs={12}>
                                    <BodyText variant="body1"><strong>Data:</strong> {dados.data}</BodyText>
                                </GridItem>
                                <GridItem item xs={12}>
                                    <BodyText variant="body1"><strong>Fornecedor. (F) / Cliente (C):</strong> {dados.tipo}</BodyText>
                                </GridItem>
                                <GridItem item xs={12}>
                                    <BodyText variant="body1"><strong>Razão Social Forn.:</strong> {dados.empresa}</BodyText>
                                </GridItem>
                                <GridItem item xs={12}>
                                    <BodyText variant="body1"><strong>Respons. pela Vistoria:</strong> {dados.responsavel}</BodyText>
                                </GridItem>
                                <GridItem item xs={12}>
                                    <BodyText variant="body1"><strong>Condutor:</strong> {dados.condutor}</BodyText>
                                </GridItem>
                            </Grid>
                        ) : (
                            <Typography variant="body1" color="error">
                                Erro ao carregar os dados.
                            </Typography>
                        )}
                        <Box display="flex" justifyContent="center" marginTop={3}>
                            <StyledButtonDelete variant="contained" onClick={handleDelete}>
                                Confirmar Exclusão
                            </StyledButtonDelete>
                            <StyledButtonCancel variant="outlined" onClick={handleCancel}>
                                Cancelar
                            </StyledButtonCancel>
                        </Box>
                    </StyledPaper>
                </>
            )}
            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <MuiAlert elevation={6} variant="filled" onClose={handleCloseSnackbar} severity={snackbarSeverity}>
                    {snackbarMessage}
                </MuiAlert>
            </Snackbar>
        </>
    );
};

export default Excluir;
