import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import React from "react";

const CheckboxAmb = ({
  ambiental,
  setAmbiental
}) => {
  return (
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      <div style={{ width: "40%" }}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={ambiental === "S"}
                onChange={() => setAmbiental(ambiental === "S" ? null : "S")}
              />
            }
            label="Remover Ambiental"
          />
        </FormGroup>
        <label></label>
      </div>
    </div>
  );
};

export default CheckboxAmb;
