import React, { useState, useEffect, useCallback } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Navbar from "./components/Navbar";
import Header from "./components/Header";
import Login from "./components/Login";
import routes from "./routes";
import { StyledEngineProvider } from "@mui/material/styles";
import "./styles/Cadastro.css";
import "bootstrap/dist/css/bootstrap.min.css";
// import { NotificationsProvider } from "./context/NotificationsContext";

const App = () => {
    const [user, setUser] = useState('');
    const [empresa, setEmpresa] = useState('null');
    const [grupo, setGrupo] = useState('');

    const handleLogin = (usuario, empresa, grupo, token) => {
        setUser(usuario);
        setEmpresa(empresa);
        setGrupo(grupo);
    };

    const handleLogout = useCallback(() => {
        setUser('');
        setEmpresa('');
        setGrupo('');
        sessionStorage.clear();
    }, []);

    useEffect(() => {
        const checkSessionExpiration = () => {
            const sessionExpiration = sessionStorage.getItem("sessionExpiration");
            const now = new Date().getTime();
            if (sessionExpiration && now > parseInt(sessionExpiration, 10)) {
                handleLogout();
                return true;
            }
            return false;
        };

        const interval = setInterval(() => {
            if (checkSessionExpiration()) {
                window.location.href = "/login";
            }
        }, 60000); 

        return () => clearInterval(interval);
    }, [handleLogout]);

    return (
        <Router>
            {/* <NotificationsProvider> */}
            <div className="App">
                <StyledEngineProvider injectFirst>
                    <Navbar user={user} empresa={empresa} grupo={grupo} onLogout={handleLogout} />
                    <Header user={user} empresa={empresa} onLogout={handleLogout} />
                    <Routes>
                        <Route path="/" element={<Login onLogin={handleLogin} />} />
                        <Route path="/login" element={<Login onLogin={handleLogin} />} />
                        {routes.map((route, index) => {
                            if (route.protected) {
                                return (
                                    <Route key={index} path={route.path} element={user ? route.element : <Navigate to="/login" replace />} />
                                );
                            }
                            return <Route key={index} path={route.path} element={route.element} />;
                        })}
                    </Routes>
                </StyledEngineProvider>
                <div className="footer">
      © 2024 Sumatex Produtos Químicos LTDA. Todos os direitos reservados. Desenvolvido no Rio de Janeiro.
      </div>
            </div>
            {/* </NotificationsProvider> */}
        </Router>
    );
};

export default App;
