import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Axios } from '../config/axiosConf';

export const fetchPessoas = createAsyncThunk('condutor/fetchPessoas', async () => {
  const response = await Axios.get('sKC25mWjGC_VS3VeFBSJWqaxJnmfXbFJQd-B61htMsI');
  return response.data;
});

const condutorSlice = createSlice({
  name: 'condutor',
  initialState: {
    condutor: '',
    rgCondutor: '',
    pessoas: [],
  },
  reducers: {
    setCondutor: (state, action) => {
      state.condutor = action.payload;
    },
    setRGCondutor: (state, action) => {
      state.rgCondutor = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPessoas.fulfilled, (state, action) => {
      state.pessoas = action.payload;
    });
  },
});

export const { setCondutor, setRGCondutor } = condutorSlice.actions;

export default condutorSlice.reducer;
