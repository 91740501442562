import React, { useState } from 'react';
import { Button, Menu, MenuItem, Typography, useMediaQuery, useTheme } from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';
import AddIcon from '@mui/icons-material/Add';
import ListIcon from '@mui/icons-material/List';
import { useNavigate } from 'react-router-dom';
import { RiCustomerService2Line } from "react-icons/ri";



const ServiceDesk = ({ user }) => {
    const history = useNavigate();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleNovoChamado = () => {
        handleClose();
        history("/usuario/novo-chamado");
    };

    const handleMeusChamados = () => {
        handleClose();
        history("/usuario/meus-chamados");
    };

    const handleChatServiceDesk = () => {
        handleClose();
        history("/usuario/chat-service-desk");
    };

    return (
        <>
            <Button
                color="inherit"
                onClick={handleClick}
                sx={{
                    minWidth: isSmallScreen ? '31px' : '42px',
                    width: isSmallScreen ? '31px' : '42px',
                    height: isSmallScreen ? '31px' : '42px',
                    fontSize: isSmallScreen ? '0.7540em' : '1.0em',
                    padding: '6px',
                    borderRadius: 3800,
                    marginRight: '0px',
                    fontWeight: 'bold',
                    color: 'gray',

                }}
            >
                <RiCustomerService2Line style={{
                    width: "30px",
                    height: "30px"
                }} />
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                sx={{ mt: '5px' }}
            >
                <MenuItem onClick={handleNovoChamado}>
                    <AddIcon sx={{ marginRight: '0px' }} />
                    <Typography>Novo chamado</Typography>
                </MenuItem>
                <MenuItem onClick={handleMeusChamados}>
                    <ListIcon sx={{ marginRight: '0px' }} />
                    <Typography>Meus chamados</Typography>
                </MenuItem>
                <MenuItem onClick={handleChatServiceDesk}>
                    <ChatIcon sx={{ marginRight: '0px' }} />
                    <Typography>Chat</Typography>

                </MenuItem>
            </Menu>
        </>
    );
};

export default ServiceDesk;
