import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TextField, Autocomplete } from '@mui/material/';
import styled from '@emotion/styled';
import { fetchPessoas, setResponsavel, setRGResponsavel } from '../../slices/responsavelSlice';

const StyledH2 = styled.h2`
    display: block;
    font-size: 1em;
    font-weight: bold;
    padding-left: 15px;
`;

const StyledTextField = styled(TextField)`
  width: 380px;
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 500px;
`;

const StyledAutocomplete = styled(Autocomplete)`
    width: ${({ isSmallScreen }) => (isSmallScreen ? '100%' : '380px')};

    .MuiButtonBase-root {
        width: 40px;
    }
`;

const ResponsavelPortaria = () => {
    const dispatch = useDispatch();
    const { responsavel, rgResponsavel, pessoas } = useSelector((state) => state.responsavel);
    const [isSmallScreen, setIsSmallScreen] = useState(window.matchMedia('(max-width: 750px)').matches);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.matchMedia('(max-width: 750px)').matches);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        dispatch(fetchPessoas());
    }, [dispatch]);

    useEffect(() => {
        const selectedResponsavel = pessoas.find((person) => person.nome === responsavel);
        if (selectedResponsavel) {
            dispatch(setRGResponsavel(selectedResponsavel.rg));
        }
    }, [responsavel, pessoas, dispatch]);

    useEffect(() => {
        if (rgResponsavel.length >= 8) {
            const selectedResponsavel = pessoas.find((person) => person.rg === rgResponsavel);
            if (selectedResponsavel) {
                dispatch(setResponsavel(selectedResponsavel.nome));
            }
        }
    }, [rgResponsavel, pessoas, dispatch]);

    const handleResponsavelChange = (event, newValue) => {
        dispatch(setResponsavel(newValue));
    };

    const handleRGChange = (event) => {
        const value = event.target.value.replace(/\D/g, '');
        if (value.length <= 11) {
            dispatch(setRGResponsavel(value));
        }
    };

    return (
        <>
            <StyledH2>1.2 - Responsável pela verificação:</StyledH2>

            <Label id="labelRGresponsavel">
                <TextField
                    id="rgResponsavel"
                    name="rgResponsavel"
                    placeholder="Digite o RG do resp. pela verificação ..."
                    required
                    value={rgResponsavel}
                    sx={{
                        width: 250,
                    }}
                    onChange={handleRGChange}
                    multiline
                />
            </Label>

            <Label id="labelResponsavel">
                <StyledAutocomplete
                    id="responsavel"
                    freeSolo
                    options={pessoas.map((person) => person.nome)}
                    value={responsavel}
                    onChange={handleResponsavelChange}
                    onBlur={(event) => dispatch(setResponsavel(event.target.value))}
                    renderInput={(params) => (
                        <StyledTextField
                            {...params}
                            id="text-responsavel"
                            name="text-responsavel"
                            label="Digite o nome do Responsavel pela verif."
                            required
                        />
                    )}
                    isSmallScreen={isSmallScreen}
                    isOptionEqualToValue={(option, value) => option === value}
                />
            </Label>
        </>
    );
};

export default ResponsavelPortaria;
