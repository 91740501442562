import React, { useState, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Axios } from "../../config/axiosConf";
import styled from "@emotion/styled";
import { useMediaQuery } from "@mui/material";

const Grpds = ({ grupoSelecionado, setGrupoSelecionado }) => {
  const [grupo, setGrupo] = useState([]);
  const isSmallScreen = useMediaQuery("(max-width: 550px)");

  const StyledAutocomplete = styled(Autocomplete)`
  width: ${isSmallScreen ? "270px" : "380px"};
  padding-left: 25px;
  .MuiButtonBase-root {
    width: 35px;
  }
  .MuiInputBase-root {
    padding-top: 4px;
    padding-bottom: 4px;
  }
  .MuiInputBase-input {
    font-size: 15px;
  }
`;

  const StyledTextField = styled(TextField)`
  width: ${isSmallScreen ? "100%" : "380px"};
`;


  useEffect(() => {
    const getGrpds = async () => {
      try {
        const response = await Axios.get("aAP4jmKvrUaGvrxAVPUI61GnxY5dIYnzvZsoX4RdZHU");
        setGrupo(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    getGrpds();
  }, []);

  const enviarGrupoSelecionado = (event, newValue) => {
    if (!newValue) {
      // Se o novo valor for nulo (limpar seleção), defina como null
      setGrupoSelecionado(null);
    } else {
      setGrupoSelecionado(newValue);
    }
  };

  return (
    <div style={{ display: "flex" }}>
      <div style={{ paddingTop: 15, fontWeight: "bold" }}>
        <p>Grupo⠀de⠀Prod.:</p>
        <StyledAutocomplete
        freeSolo={false}
        options={grupo}
        value={grupoSelecionado}
        onChange={enviarGrupoSelecionado}
        renderInput={(params) => (
          <StyledTextField {...params} label=""  />
        )}
      />
      </div>
      
    </div>
  );
};

export default Grpds;
