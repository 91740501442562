import React from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useMediaQuery } from "@mui/material";
import styled from "@emotion/styled";

const Moeda = ({ moedaSelecionada, setMoedaSelecionada }) => {
  const isSmallScreen = useMediaQuery("(max-width: 550px)");
  const StyledSelect = styled(Select)`
    width: ${isSmallScreen ? "90px" : "90px"};
    margin-left: 25px;
    height: 40px;
    padding-right: 0px;
    padding-left: 5px;

  `;
  const handleChange = (event) => {
    setMoedaSelecionada(event.target.value);
  };

  return (
    <div style={{ display: "flex" }}>
      <div style={{ paddingTop: 15, fontWeight: "bold" }}>
        <p>Moeda:</p>
        <StyledSelect
          label="Moeda"
          value={moedaSelecionada}
          onChange={handleChange}
          defaultValue={"000001"}
        >
          <MenuItem value="000001">R$ </MenuItem>
          <MenuItem value="000002">US$ </MenuItem>
        </StyledSelect>
      </div>
    </div>
  );
};

export default Moeda;
