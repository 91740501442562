import React, { useState  } from "react";
import { Axios } from "../../../config/axiosConf";
import * as XLSX from "xlsx";
import { DataGrid } from '@mui/x-data-grid';
import { ptBR } from '@mui/x-data-grid/locales';
import Clientes from "../../../components/AutoCompletes/Clientes";
import Produtos from "../../../components/AutoCompletes/Produtos";
import ProdutosBC from "../../../components/AutoCompletes/ProdutosBC";
import Vendedores from "../../../components/AutoCompletes/Vendedores";
import Embalagens from "../../../components/AutoCompletes/Embalagens";
import CheckboxesProgVenda from "../../../components/Checkboxes/CheckboxesProgVenda";
import CheckboxTodas from "../../../components/Checkboxes/CheckboxTodas";
import styled from "@emotion/styled";
import { styled as Estilo, darken, lighten } from "@mui/material/styles";
import {
  Typography,
  Button,
  CircularProgress,
  
} from "@mui/material";
import { AiOutlineSearch } from "react-icons/ai";
import Data from "../../../components/DataPickers/Data";
import "../../../styles/ConsultaGeralVendas.css";
import logo from "../../../assets/Logo-Grupo-Sumatex.png";
import logo_sumatex from "../../../assets/logo.png";
import logo_ambiental from "../../../assets/Logo-Sumatex-Ambiental.png";
import logo_agro from "../../../assets/Logo-Sumatex-Agro.png";
import logo_lorenvel from "../../../assets/Logo-Lorenvel.png";
import logo_cesbra from "../../../assets/Logo-Cesbra.png";

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  width: 90%;
  gap: 16px;
  padding: 24px;
  background-color: #f5f5f5;
  border-radius: 8px;
  height: 100%;
  margin: 50px;

  @media (max-width: 550px) {
    width: 100%;
    margin: 0px;
  }
`;

const StyledTitle = styled(Typography)`
  font-weight: bold;
  font-size: 1.5em;
  margin: 0em 0;
  background-color: #2957a4;
  color: #fff;
  padding: 10px;
  text-align: center;
  @media (max-width: 550px) {
    font-size: 1.2em;
  }
`;

const StyledOrange = styled(Typography)`
    background-color: #D66E20;
    padding-top: 3px;
    padding-bottom: 3px;
  @media (max-width: 550px) {
    font-size: 1.2em;
  }
`;

const StyledGreen = styled(Typography)`
    background-color: #7CA217;
    padding-top: 3px;
    padding-bottom: 3px;
  @media (max-width: 550px) {
    font-size: 1.2em;
  }
`;


const ButtonContainer = styled.div`
  display: flex;
  gap: 16px;
  margin-left: 25px;
`;
const ResultadosTitle = styled.h2`
  margin: 0;
  font-size: 2em; /* Aumenta o tamanho da fonte */
  color: #1f437d; /* Define uma cor personalizada (laranja) */
  text-align: center; /* Centraliza o texto */
  text-transform: uppercase; /* Converte o texto em maiúsculas */
  padding: 10px; /* Adiciona espaçamento interno */
  border-radius: 10px; /* Adiciona bordas arredondadas */
  @media (max-width: 550px) {
    width: 80px;
    font-size: 1.5em;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-wrap: wrap; /* Permite que os botões quebrem para a próxima linha em telas estreitas */
  gap: 16px;
  justify-content: flex-end; /* Alinha os botões à direita */
  margin-top: 20px;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  @media (max-width: 550px) {
    justify-content: space-between;
  }
`;

const SaveButton = styled(Button)`
  height: 30px;
  width: 150px;
  color: #fff;
  background-color: #2957a4;
  border: 1px solid rgba(0, 0, 0, 0.2);
  &:hover {
    background-color: #1f437d;
  }
`;

const LimparButton = styled(Button)`
  height: 30px;
  width: 100px;
  color: #fff;
  background-color: #ff6347;
  border: 1px solid rgba(0, 0, 0, 0.2);
  &:hover {
    background-color: #ff0000;
  }
  @media (max-width: 550px) {
    width: 80px;
    font-size: 0.8em;
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const ImprimirButton = styled(Button)`
  height: 30px;
  padding-left: 50px;
  padding-right: 50px;
  width: 120px;
  color: #fff;
  background-color: #a0a0a0;
  border: 1px solid rgba(0, 0, 0, 0.2);
  &:hover {
    background-color: #808080;
  }

  @media (max-width: 550px) {
    width: 80px;
    font-size: 0.8em;
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const XlsButton = styled(Button)`
  height: 30px;
  width: 120px;
  padding-left: 50px;
  padding-right: 50px;
  color: #fff;
  background-color: #71bc78;
  border: 1px solid rgba(0, 0, 0, 0.2);
  &:hover {
    background-color: #569b5c;
  }
  @media (max-width: 550px) {
    width: 80px;
    font-size: 0.8em;
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const ResultadosContainer = styled.div`
  margin-top: 20px;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  @media (max-width: 550px) {
    // Estilo responsivo para largura de tela até 360px
    padding: 10px; // Ajuste o espaçamento conforme necessário
  }
`;

const MobileButton = styled(Button)`
  height: 30px;
  width: 150px;
  color: #fff;
  background-color: #2957a4;
  border: 1px solid rgba(0, 0, 0, 0.2);
  display: none; /* Inicialmente, oculte o botão em telas grandes */
  &:hover {
    background-color: #1f437d;
  }

  @media (max-width: 360px) {
    display: block; /* Mostrar o botão em telas menores */
  }
`;

const getBackgroundColor = (color, mode) =>
  mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7);

const getHoverBackgroundColor = (color, mode) =>
  mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

const getSelectedBackgroundColor = (color, mode) =>
  mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

const getSelectedHoverBackgroundColor = (color, mode) =>
  mode === 'dark' ? darken(color, 0.4) : lighten(color, 0.4);


const StyledDataGrid = Estilo(DataGrid)(({ theme }) => ({
  '& .grid-azul': {
    backgroundColor: getBackgroundColor(theme.palette.info.main, theme.palette.mode),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.info.main,
        theme.palette.mode,
      ),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.info.main,
        theme.palette.mode,
      ),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.info.main,
          theme.palette.mode,
        ),
      },
    },
  },
  '& .grid-vermelho': {
    backgroundColor: getBackgroundColor(
      theme.palette.error.main,
      theme.palette.mode,
    ),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.error.main,
        theme.palette.mode,
      ),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.error.main,
        theme.palette.mode,
      ),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.error.main,
          theme.palette.mode,
        ),
      },
    },
  },
  '& .grid-amarelo': {
    backgroundColor: getBackgroundColor(
      theme.palette.warning.main,
      theme.palette.mode,
    ),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.warning.main,
        theme.palette.mode,
      ),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.warning.main,
        theme.palette.mode,
      ),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.warning.main,
          theme.palette.mode,
        ),
      },
    },
  },
}));

const ProgramacaoVendas = () => {
  
  const [formattedDataInicial, setFormattedDataInicial] = useState("");
  const [formattedDataFinal, setFormattedDataFinal] = useState("");
  const Filial = sessionStorage.getItem("empresa");
  const [todas, setTodas] = useState("");
  const [clienteSelecionado, setClienteSelecionado] = useState(null);
  const [produtoSelecionado, setProdutoSelecionado] = useState(null);
  const [produtoBCSelecionado, setProdutoBCSelecionado] = useState(null);
  const [vendedorSelecionado, setVendedorSelecionado] = useState(null);
  const [embalagemSelecionado, setEmbalagemSelecionado] = useState(null);
  const [Faturamento, setFaturamento] = useState("S");
  const [NaoFaturadas, setNaoFaturadas] = useState(null);
  const [ordemCarregamento, setOrdemCarregamento] = useState(null);
  const [trocaNF, setTrocaNF] = useState(null);
  const [corantes, setCorantes] = useState("S");
  const [produtop, setProdutop] = useState("S");
  const [Farelo, setFarelo] = useState("S");
  const [Soja, setSoja] = useState("S");
  const [Milho, setMilho] = useState("S");
  const [Oleo, setOleo] = useState("S");
  const [Sumatex, setSumatex] = useState("S");
  const [FMT, setFMT] = useState("S");
  const [Rep, setRep] = useState("S");
  const [Cesbra, setCesbra] = useState("S");
  const [prod_Cesbra, setProd_Cesbra] = useState("S");
  const [resultados, setResultados] = useState([]);
  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line
  const [consultaSelecionada, setConsultaSelecionada] = useState("relatorio");
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);

  const grupo = sessionStorage.getItem("grupo");
  const grupoGerente = ["8f498e6aabb107de59d0ad2177f6d882"];// GE

  const isUserInGroupGerente = grupoGerente.includes(grupo);

  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({
    id: false,
    valor_total: false,
    qtd_total: false,
    valor_reais: false,
    qtd_convertida: false,
  });

  const [filterModel, setFilterModel] = React.useState({
    items: [
    ],
  });

  const applyFilters = (rows, filterModel) => {
    return rows.filter((row) => {
      return filterModel.items.every((filter) => {
        const { field, operator, value } = filter;
        const rowValue = row[field];
  
        // Convertendo rowValue e value para string para comparações de texto
        const rowValueStr = String(rowValue).toLowerCase();
        const valueStr = String(value).toLowerCase();
  
        switch (operator) {
          case '>':
            return rowValue > value;
          case '>=':
            return rowValue >= value;
          case '<':
            return rowValue < value;
          case '<=':
            return rowValue <= value;
          case 'equals':
          case '=': // Igualdade numérica ou textual
            return rowValueStr === valueStr;
          case '!=': // Diferença numérica ou textual
            return rowValue !== value;
          case 'contains': // Contém
            return rowValueStr.includes(valueStr);
          case 'startsWith': // Começa com
            return rowValueStr.startsWith(valueStr);
          case 'endsWith': // Termina com
            return rowValueStr.endsWith(valueStr);
          default:
            return true; // Se o operador não for suportado, não filtra.
        }
      });
    });
  };

  const customComparator = (v1, v2) => {
    const value1 = parseFloat(v1.replace(',', '.'));
    const value2 = parseFloat(v2.replace(',', '.'));
  
    if (value1 < value2) {
      return -1;
    }
    if (value1 > value2) {
      return 1;
    }
    return 0;
  };

  const customDateComparator = (dateStr1, dateStr2) => {

    const parts1 = dateStr1.split('/');
    const parts2 = dateStr2.split('/');
    const date1 = new Date(Number(parts1[2]), Number(parts1[1]) - 1, Number(parts1[0]));
    const date2 = new Date(Number(parts2[2]), Number(parts2[1]) - 1, Number(parts2[0]));
  
    // Comparando as datas
    if (date1 < date2) {
      return -1;
    }
    if (date1 > date2) {
      return 1;
    }
    return 0;
};

const handleLimparResultados = () => {
    setResultados([]);
    setColumns([]);
    setRows([]);
  };

  const calcularValorTotal = () => {
    const valorTotal = applyFilters(rows, filterModel).reduce((total, item) => total + (item.vl_total || 0), 0);
    return formatarValor(valorTotal);
  };

  // Função para calcular a Quantidade Total
  const calcularQuantidadeTotal = () => {
    const quantidadeTotal = applyFilters(rows, filterModel).reduce((total, item) => total + (item.qtd_convertida || 0), 0);
    return formatarValor(quantidadeTotal);
  };

  const realizarConsulta = async () => {
    let procedure = "";

    switch (consultaSelecionada) {
      case "relatorio":
        procedure = "programacao-venda";
        break;
      default:
        procedure = "relatorio";
        break;
    }

    try {
      const data = {
        Data: {
          formattedDataInicial,
          formattedDataFinal,
        },
        Clientes: {
          clienteSelecionado:
            clienteSelecionado === null
              ? null
              : clienteSelecionado.substring(0, 6),
        },

        Produtos: {
          produtoSelecionado:
            produtoSelecionado === null
              ? null
              : produtoSelecionado.substring(0, 6),
        },

        ProdutosBC: {
          produtoBCSelecionado:
            produtoBCSelecionado === null
              ? null
              : produtoBCSelecionado.substring(0, 6),
        },

        Vendedores: {
          vendedorSelecionado:
            vendedorSelecionado === null
              ? null
              : vendedorSelecionado.substring(0, 6),
        },

        Embalagens: {
          embalagemSelecionado:
            embalagemSelecionado === null
              ? null
              : embalagemSelecionado.substring(0, 6),
        },

        CheckboxesProgVenda: {
          Faturamento,
          NaoFaturadas,
          FMT,
          Cesbra,
          Farelo,
          Soja,
          Oleo,
          Milho,
          prod_Cesbra,
          Sumatex, 
          Rep,
          ordemCarregamento,
          trocaNF,
          corantes,
          produtop
        },
        Filial,
        CheckboxTodas: {
          todas
        },
      };

      const config = {
        timeout: 45000,
      };

      setIsLoading(true);

      const response = await Axios.post(
        `consultas/${procedure}`,
        data,
        config
      );

      if (response.data.length > 0){

        const rows = response.data.map((item, index) => {
          const rowData = {};
          Object.keys(item).forEach(key => {
              rowData[key] = item[key];
          });
          // Adicionando manualmente o campo "ID" a cada linha
          rowData['id'] = index + 1; 
          return rowData;
        });
        
        const columns = Object.keys(response.data[0]).map(key => ({
            field: key,
            ...(key === 'programado' ? {
              headerName: 'Programado',
              width: 100,
              sortComparator: customDateComparator
            } : {...(key === 'troca' ? {
              headerName: 'Troca',
              width: 80 
            } : {...(key === 'pedido' ? {
              headerName: 'Pedido',
              width: 80
            } : {...(key === 'empresa' ? {
              headerName: 'Empresa',
              width: 400 
            } : {...(key === 'produto' ? {
              headerName: 'Produto',
              width: 350 
            } : {...(key === 'quantidade' ? {
              headerName: 'Quantidade (Kg)',
              width: 100
            } : {...(key === 'unidade' ? {
              headerName: 'Un',
              width: 50 
            } : {...(key === 'moeda' ? {
              headerName: '$',
              width: 50 
            } : {...(key === 'vl_unitario' ? {
              headerName: 'Vl. Unitário (R$)',
              width: 120
            } : {...(key === 'vl_total' ? {
              headerName: 'Vl. Total (R$)',
              width: 120
            } : {...(key === 'embalagem' ? {
              headerName: 'Embalagem',
              width: 300
            } : {...(key === 'entrega' ? {
              headerName: 'Entrega',
              width: 120
            } : {...(key === 'transportadora' ? {
              headerName: 'Transportadora',
              width: 300
            } : {...(key === 'filial' ? {
              headerName: 'Filial',
              width: 80
            } : {...(key === 'carregamento' ? {
              headerName: 'OC',
              width: 80
            } : {headerName: key, width: 200})})})})})})})})})})})})})})}),
  
            ...(key === 'quantidade' || key === 'vl_unitario' || key  === 'vl_total' ? {
              valueFormatter: (params) => {
                return params.toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                });
                //return params.value.toFixed(2);
              }
          } : {})
        }));
  
        columns.unshift({ field: 'id', headerName: 'ID', width: 10 });

        setRows(rows);
        setColumns(columns)

      }

      setResultados(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error("Erro ao realizar a consulta:", error);
      setIsLoading(false);
    }

  };

  function formatarValor(valor) {
    if (valor === null || valor === undefined) {
      return '';
    }
    
    if (typeof valor !== "number") {
      return valor;
    }

    return valor.toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }

  function desformatarData(data) {
    if (!data) return null;
  
    const ano = data.slice(0, 4);
    const mes = data.slice(4, 6);
    const dia = data.slice(6, 8);
  
    return `${dia}/${mes}/${ano}`;
  }

  const handlePrintResults = () => {
    const Filial = sessionStorage.getItem("empresa");
    const printContent = `
      <html>
        <head>
          <title>S.I.G.A - Grupo Sumatex</title>
          <style>
            /* Estilos para o relatório de impressão */
            body { font-family: Arial, sans-serif; margin: 0; padding: 0; }
            h1 {
              text-align: center;
              margin-bottom: 20px;
              font-size: 24px;
            }
            h2 img {
              height: 40px;
              margin-right: 10px;
            }
            table { 
              width: 100%; 
              border-collapse: 
              collapse; 
            }
            th, td { 
              border: 1px solid #000; 
              padding: 8px; 
              text-align: left; 
            }
          </style>
        </head>
        <body>
        <h2>
        ${
          Filial === "1 - MAT" ? `<img src="${logo_sumatex}" alt="Logo">` :
          Filial === "7 - CES" ? `<img src="${logo_cesbra}" alt="Logo">` :
          Filial === "2 - AMB" ? `<img src="${logo_ambiental}" alt="Logo">` :
          Filial === "9 - LOG" ? `<img src="${logo_lorenvel}" alt="Logo">` :
          Filial === "5 - FMT" ? `<img src="${logo_agro}" alt="Logo">` :
          `<img src="${logo}" alt="Logo">`
        } </h2>
       <h1>
         Programação de Vendas
       </h1>

       <div>
       <div class="filters">
       <p style="text-align: center;"><strong>Data:</strong> ${desformatarData(formattedDataInicial)} a ${desformatarData(formattedDataFinal)}</strong></p>
       
       ${produtoSelecionado ? `<div key="produto"><p><strong>Produto:</strong> ${produtoSelecionado}</p></div>` : ''}
       ${produtoBCSelecionado ? `<div key="produtoBC"><p><strong>Baixa Comercial:</strong> ${produtoBCSelecionado}</p></div>` : ''}
       ${clienteSelecionado ? `<div key="cliente"><p><strong>Cliente:</strong> ${clienteSelecionado}</p></div>` : ''}
       ${vendedorSelecionado ? `<div key="vendedor"><p><strong>Vendedor:</strong> ${vendedorSelecionado}</p></div>` : ''}
       ${embalagemSelecionado ? `<div key="embalagem"><p><strong>Embalagem:</strong> ${embalagemSelecionado}</p></div>` : ''}
       </div>
    </div>

       <table>
       <thead>
         <tr>
           ${columns
             .filter((coluna) => columnVisibilityModel[coluna.field] !== false)
             .map((coluna) => `<th>${coluna.headerName}</th>`)
             .join('')}
         </tr>
       </thead>
       <tbody>
         ${applyFilters(rows, filterModel)
           .map((item, index) => {
             return `
               <tr ${index % 2 === 0 ? 'style="background-color: #f9f9f9;"' : ''}>
                 ${columns
                   .filter((coluna) => columnVisibilityModel[coluna.field] !== false)
                   .map((coluna) => {
                     return `<td>${formatarValor(item[coluna.field])}</td>`
                   })
                   .join("")}
               </tr>
             `;
           })
           .join("")}
       </tbody>
     </table>
     <div style="margin-top: 20px; display: grid; grid-template-columns: 1fr 1fr;">
          <div>
            <h2>Dados</h2>
              <div class="consolidated-data">
                  <div><p><strong>Valor Total:</strong> R$ ${calcularValorTotal()}</p></div>
              </div>
          </div>
          <div> 
            <h2>⠀</h2>
              <div class="consolidated-data"> 
                  <div><p><strong>Quantidade Total (Kg):</strong> ${calcularQuantidadeTotal()}</p></div>
              </div>
          </div>
      </div>
        </body>
      </html>
      `;

      const screenWidth = window.screen.width;
      const screenHeight = window.screen.height;
      const printWindow = window.open(
        "",
        "",
        `width=${screenWidth},height=${screenHeight}`
      );
  
      if (!printWindow) {
        alert(
          "Erro ao abrir a janela de impressão. Verifique as configurações do seu navegador."
        );
        return;
      }
  
      printWindow.document.open();
      printWindow.document.write(printContent);
      printWindow.document.close();
  
      printWindow.onload = () => {
        printWindow.print();
  
        setTimeout(() => {}, 1000);
      };
  };

  const handleGenerateXLS = () => {
    const datas = [];

    const colunas = columns
    .filter((coluna) => columnVisibilityModel[coluna.field] !== false)
    .map(
      (coluna) => coluna.headerName
    );
    datas.push(colunas);

    applyFilters(rows, filterModel).forEach((item) => {
      const rowData = columns
      .filter((coluna) => columnVisibilityModel[coluna.field] !== false)
      .map((coluna) => {
        return item[coluna.field];
      });
      datas.push(rowData);
    });

    const wb = XLSX.utils.book_new();

    const ws = XLSX.utils.aoa_to_sheet(datas);
    XLSX.utils.book_append_sheet(wb, ws, "Resultados");

    const nomeDoArquivo = `resultados_por_programacao_vendas_${formattedDataInicial}-${formattedDataFinal}.xlsx`;


    XLSX.writeFile(wb, nomeDoArquivo);
  };

  return (
    <div className={`content`}>
      <StyledOrange />
      <StyledTitle>Programação de Vendas</StyledTitle>
      <StyledGreen />

      <FormContainer>
        <div>
          {isLoading && (
            <div
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 9999,
              }}
            >
              <CircularProgress />
            </div>
          )}

          <Data
            formattedDataInicial={formattedDataInicial}
            formattedDataFinal={formattedDataFinal}
            setFormattedDataInicial={setFormattedDataInicial}
            setFormattedDataFinal={setFormattedDataFinal}
          />

          <Produtos
            produtoSelecionado={produtoSelecionado}
            setProdutoSelecionado={setProdutoSelecionado}
          />

         <ProdutosBC
            produtoBCSelecionado={produtoBCSelecionado}
            setProdutoBCSelecionado={setProdutoBCSelecionado}
          />

          <Clientes
            clienteSelecionado={clienteSelecionado}
            setClienteSelecionado={setClienteSelecionado}
          />

          <Vendedores
            vendedorSelecionado={vendedorSelecionado}
            setVendedorSelecionado={setVendedorSelecionado}
          />

          <Embalagens
            embalagemSelecionado={embalagemSelecionado}
            setEmbalagemSelecionado={setEmbalagemSelecionado}
          />

          <label></label>
          <CheckboxesProgVenda
            Faturamento={Faturamento}
            NaoFaturadas={NaoFaturadas}
            FMT={FMT}
            Cesbra={Cesbra}
            Farelo={Farelo}
            Soja={Soja}
            Oleo={Oleo}
            Milho={Milho}
            prod_Cesbra={prod_Cesbra}
            Sumatex={Sumatex}
            Rep={Rep}
            ordemCarregamento={ordemCarregamento}
            trocaNF={trocaNF}
            corantes={corantes}
            produtop={produtop}
            setFaturamento={setFaturamento}
            setNaoFaturadas={setNaoFaturadas}
            setFMT={setFMT}
            setCesbra={setCesbra}
            setFarelo={setFarelo}
            setSoja={setSoja}
            setOleo={setOleo}
            setMilho={setMilho}
            setProd_Cesbra={setProd_Cesbra}
            setSumatex={setSumatex}
            setRep={setRep}
            setOrdemCarregamento={setOrdemCarregamento}
            setTrocaNF={setTrocaNF}
            setCorantes={setCorantes}
            setProdutop={setProdutop}
          />

          <br></br>

          {(isUserInGroupGerente) && (
                  <CheckboxTodas
                    todas={todas}
                    setTodas={setTodas}
                  /> 
          )}

          <ButtonContainer>
            <SaveButton onClick={realizarConsulta}>
              <AiOutlineSearch />
               Buscar
            </SaveButton>
          </ButtonContainer>
        </div>
      </FormContainer>
      <MobileButton onClick={realizarConsulta}>
        <AiOutlineSearch />
         Buscar
      </MobileButton>

      {resultados.length > 0 ? (
        <ResultadosContainer id="resultados-container">
          <ResultadosTitle>Resultados</ResultadosTitle>
          <ButtonWrapper>
            <LimparButton
              onClick={handleLimparResultados}
              variant="contained"
              color="secondary"
            >
              Limpar
            </LimparButton>
            <ImprimirButton
              onClick={handlePrintResults}
              variant="contained"
              color="primary"
            >
              Imprimir
            </ImprimirButton>
            <XlsButton
              onClick={handleGenerateXLS}
              variant="contained"
              color="primary"
            >
              Gerar⠀XLS
            </XlsButton>
          </ButtonWrapper>
          <div
            style={{
              border: "5px solid #D3D3D3", // Cor e largura da borda
              padding: "10px", // Espaçamento interno
            }}
          >
            <Typography component="div">
              <div className="tb-result">
                  <table>
                      <tr>
                        <td style={{color:"#2957A4"}}>Valor Total (R$)</td>
                        <td>{calcularValorTotal()}</td>
                      </tr>
                      <tr>
                        <td style={{color:"#2957A4"}}>Quantidade Total (Kg)</td>
                        <td>{calcularQuantidadeTotal()}</td>
                      </tr>
                  </table>
              </div>              
            </Typography>
          </div>
          <br></br>
          <StyledDataGrid
                rows={rows}
                columns={columns}
                rowHeight={30}
                sortComparator={customComparator}
                sx={{
                  boxShadow: 2,
                  border: 2,
                  borderColor: 'primary.light',
                }}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 20 },
                  },
                }}
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) =>
                  setColumnVisibilityModel(newModel)
                }
                filterModel={filterModel}
                onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                pageSizeOptions={[5, 10, 15, 20]}
                disableSelectionOnClick
              />

        </ResultadosContainer>
      ) : null}
    </div>
  );
};

export default ProgramacaoVendas;