import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import styled from "@emotion/styled";
import { useMediaQuery } from "@mui/material";

const CheckboxesAnaliseTransp = ({
    prodPerigoso,
    resPerigoso,
    embalagemVazia,
    produtoAMais,
    setProdPerigoso,
    setResPerigoso,
    setEmbalagemVazia,
    setProdutoAMais,
}) => {
  const isSmallScreen = useMediaQuery("(max-width: 550px)");

  const StyledFormControlLabel = styled(FormControlLabel)`

  .MuiFormControlLabel-label {
    font-size: ${isSmallScreen ? "0.64em" : "auto"};
    font-weight: bold;
  }
`;
  return (
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      <div style={{ width: "50%" }}>

      <FormGroup>
          <StyledFormControlLabel
            control={
              <Checkbox
                checked={prodPerigoso === "S"}
                onChange={() => setProdPerigoso(prodPerigoso === "S" ? "N" : "S")}
              />
            }
            label="Produto perigoso"
          />
        </FormGroup>
      
        <label></label>
        <FormGroup>
          <StyledFormControlLabel
            control={
              <Checkbox
                checked={resPerigoso === "S"}
                onChange={() => setResPerigoso(resPerigoso === "S" ? "N" : "S")}
              />
            }
            label="Residuo perigoso"
          />
        </FormGroup>
        </div>
        <div style={{ width: "50%" }}>

        <FormGroup>
          <StyledFormControlLabel
            control={
              <Checkbox
                checked={embalagemVazia === "S"}
                onChange={() =>
                    setEmbalagemVazia(embalagemVazia === "S" ? "N" : "S")
                }
              />
            }
            label="Embalagem vazia"
          />
        </FormGroup>
        <label></label>
        <FormGroup>
          <StyledFormControlLabel
            control={
              <Checkbox
                checked={produtoAMais === "S"}
                onChange={() => setProdutoAMais(produtoAMais === "S" ? "N" : "S")}
              />
            }
            label="Mais de um produto"
          />
        </FormGroup>
        <label></label>

      </div>
    </div>
  );
};

export default CheckboxesAnaliseTransp;
