import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useMediaQuery, Paper } from "@mui/material";
import { styled } from "@mui/material";


const Container = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: "#F5F5F5",
  boxShadow: "0px 0px 0px 0px rgba(0,0,0,0.2),0px 0px 0px 0px rgba(0,0,0,0.14),0px 0px 0px 0px rgba(0,0,0,0.12)",
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
}));

const CheckboxesAnalise = ({
  baixa_Comercial,
  baixa_Estoque,
  Faturados,
  Colaboradores,
  Comercial,
  Comercial_Novo,
  Corporativos,
  Cesbra_Schwietzer,
  Farelo,
  Soja,
  Oleo,
  Milho,
  Corantes,
  RentB,
  prod_Cesbra,
  Sumatex, 
  Rep,
  Fat,
  Ent,
  setBaixaEstoque,
  setBaixaComercial,
  setFaturados,
  setColaboradores,
  setComercial,
  setComercialNovo,
  setCorporativos,
  setCesbraSchwietzer,
  setFarelo,
  setSoja,
  setOleo,
  setMilho,
  setProdCesbra,
  setSumatex,
  setRep,
  setCorantes,
  setRentB,
  setFat,
  setEnt,
}) => {
  const isSmallScreen = useMediaQuery("(max-width: 550px)");

  const StyledFormControlLabel = styled(FormControlLabel)`

  .MuiFormControlLabel-label {
    font-size: ${isSmallScreen ? "0.64em" : "auto"};
  }
`;
  return (
   <Container>
   <div style={{ display: "flex", flexWrap: "wrap" }}>
      <div style={{ width: "34%" }}>
      
        <FormGroup>
            <StyledFormControlLabel
              control={
                <Checkbox
                  checked={Faturados === "S"}
                  onChange={() => setFaturados(Faturados === "S" ? null : "S")}
                />
              }
              label="Pedidos Faturados"
            />
          </FormGroup>
          <FormGroup>
            <StyledFormControlLabel
              control={
                <Checkbox
                  checked={Cesbra_Schwietzer === "S"}
                  onChange={() => setCesbraSchwietzer(Cesbra_Schwietzer === "S" ? null : "S")}
                />
              }
              label="Desconsiderar Cliente - CESBRA"
            />
          </FormGroup>
          <FormGroup>
            <StyledFormControlLabel
              control={
                <Checkbox
                  checked={baixa_Comercial === "S"}
                  onChange={() =>
                    setBaixaComercial(baixa_Comercial === "S" ? null : "S")
                  }
                />
              }
              label="Considerar Baixa Comercial"
            />
          </FormGroup>
          <FormGroup>
            <StyledFormControlLabel
              control={
                <Checkbox
                  checked={Farelo === "S"}
                  onChange={() => setFarelo(Farelo === "S" ? null : "S")}
                />
              }
              label="Desconsiderar Farelo"
            />
          </FormGroup>   
          <FormGroup>
            <StyledFormControlLabel
              control={
                <Checkbox
                  checked={Milho === "S"}
                  onChange={() => setMilho(Milho === "S" ? null : "S")}
                />
              }
              label="Desconsiderar Milho"
            />
          </FormGroup>
          <FormGroup>
            <StyledFormControlLabel
              control={
                <Checkbox
                  checked={Comercial_Novo === "S"}
                  onChange={() => setComercialNovo(Comercial_Novo === "S" ? null : "S")}
                />
              }
              label="Considerar Somente Comercial Novo"
            />
          </FormGroup>
          <FormGroup>
            <StyledFormControlLabel
              control={
                <Checkbox
                  checked={Fat === "S"}
                  onChange={() => setFat(Fat === "S" ? null : "S")}
                />
              }
              label="Geram Faturamento"
            />
          </FormGroup>
          </div>
          <div style={{ width: "33%" }}>
          
          <FormGroup>
            <StyledFormControlLabel
              control={
                <Checkbox
                  checked={Colaboradores === "S"}
                  onChange={() => setColaboradores(Colaboradores === "S" ? null : "S")}
                />
              }
              label="Retirar Colaboradores"
            />
          </FormGroup>
          <FormGroup>
            <StyledFormControlLabel
              control={
                <Checkbox
                  checked={prod_Cesbra === "S"}
                  onChange={() => setProdCesbra(prod_Cesbra === "S" ? null : "S")}
                />
              }
              label="Desconsiderar Produtos CESBRA"
            />
          </FormGroup>
          <FormGroup>
            <StyledFormControlLabel
              control={
                <Checkbox
                  checked={baixa_Estoque === "S"}
                  onChange={() =>
                    setBaixaEstoque(baixa_Estoque === "S" ? null : "S")
                  }
                />
              }
              label="Considerar Baixa de Estoque"
            />
          </FormGroup>
          <FormGroup>
            <StyledFormControlLabel
              control={
                <Checkbox
                  checked={Soja === "S"}
                  onChange={() => setSoja(Soja === "S" ? null : "S")}
                />
              }
              label="Desconsiderar Soja"
            />
          </FormGroup>
          <FormGroup>
            <StyledFormControlLabel
              control={
                <Checkbox
                  checked={Corantes === "S"}
                  onChange={() => setCorantes(Corantes === "S" ? null : "S")}
                />
              }
              label="Desconsiderar Corantes"
            />
          </FormGroup>
          <FormGroup>
            <StyledFormControlLabel
              control={
                <Checkbox
                  checked={Corporativos === "S"}
                  onChange={() => setCorporativos(Corporativos === "S" ? null : "S")}
                />
              }
              label="Considerar Clientes Corporativos ⠀"
            />
          </FormGroup>
        </div>
        <div style={{ width: "33%" }}>
        <FormGroup>
            <StyledFormControlLabel
              control={
                <Checkbox
                  checked={RentB === "S"}
                  onChange={() => setRentB(RentB === "S" ? null : "S")}
                />
              }
              label="Rentabilidade Baixa"
            />
          </FormGroup>
          <FormGroup>
            <StyledFormControlLabel
              control={
                <Checkbox
                  checked={Sumatex === "S"}
                  onChange={() => setSumatex(Sumatex === "S" ? null : "S")}
                />
              }
              label="Desconsiderar Cliente Sumatex"
            />
          </FormGroup>
          <FormGroup>
            <StyledFormControlLabel
              control={
                <Checkbox
                  checked={Comercial === "S"}
                  onChange={() => setComercial(Comercial === "S" ? null : "S")}
                />
              }
              label="Considerar Somente Comercial"
            />
          </FormGroup>
          <FormGroup>
            <StyledFormControlLabel
              control={
                <Checkbox
                  checked={Oleo === "S"}
                  onChange={() => setOleo(Oleo === "S" ? null : "S")}
                />
              }
              label="Desconsiderar Óleo"
            />
          </FormGroup>
          <FormGroup>
            <StyledFormControlLabel
              control={
                <Checkbox
                  checked={Rep === "S"}
                  onChange={() => setRep(Rep === "S" ? null : "S")}
                />
              }
              label="Desconsiderar Cliente - Rep"
            />
          </FormGroup>
          <FormGroup>
            <StyledFormControlLabel
              control={
                <Checkbox
                  checked={Ent === "S"}
                  onChange={() => setEnt(Ent === "S" ? null : "S")}
                />
              }
              label="Desconsiderar Entradas"
            />
          </FormGroup>
        
      </div>
    </div>
    </Container>  
  );
};

export default CheckboxesAnalise;
