import React, { useState, useEffect, useRef, useCallback } from "react";
import { Axios } from "../../config/axiosConf";
import { 
         Typography, Box, Checkbox, FormControlLabel, Dialog, DialogTitle, DialogContent, DialogActions,
         Button, Grid, Paper, Toolbar, IconButton, Tabs, Tab, TextField, } from "@mui/material";
import { 
         Description, Event, CheckCircle, ErrorOutline, AssignmentTurnedIn, AccessTime, Refresh, Send,
       } from "@mui/icons-material";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { DataGrid } from "@mui/x-data-grid";
import { ptBR } from '@mui/x-data-grid/locales';
import { styled as estilo } from "@mui/material/styles";
import styled from "@emotion/styled";
import { styled as style} from "@mui/material/styles";

const StyledTitle = styled(Typography)`
  font-weight: bold;
  font-size: 1.5em;
  margin: 0em 0;
  background-color: #2957a4;
  color: #fff;
  padding: 10px;
  text-align: center;
  @media (max-width: 550px) {
    font-size: 1.2em;
  }
`;

const StyledOrange = styled(Typography)`
  background-color: #d66e20;
  padding-top: 3px;
  padding-bottom: 3px;
  @media (max-width: 550px) {
    font-size: 1.2em;
  }
`;

const StyledGreen = styled(Typography)`
  background-color: #7ca217;
  padding-top: 3px;
  padding-bottom: 3px;
  @media (max-width: 550px) {
    font-size: 1.2em;
  }
`;

const StyledDataGrid = estilo(DataGrid)(({ theme }) => ({
  "& .MuiDataGrid-root": {
    border: "none",
  },
  "& .MuiDataGrid-cell": {
    borderBottom: "1px solid #e0e0e0",
  },
  "& .MuiDataGrid-columnHeaders": {
    backgroundColor: "#f5f5f5",
    borderBottom: "2px solid #e0e0e0",
  },
  "& .MuiDataGrid-virtualScroller": {
    backgroundColor: "#ffffff",
  },
  "& .MuiDataGrid-footerContainer": {
    borderTop: "2px solid #e0e0e0",
    backgroundColor: "#f5f5f5",
  },
  "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
    color: "#2957a4",
  },
  '& .MuiDataGrid-filler': {
    backgroundColor: '#D3D3D3',
  },
  "& .MuiDataGrid-row:hover": {
    backgroundColor: "#f1f1f1",
  },
  '& .MuiDataGrid-columnHeader': {
    backgroundColor: '#D3D3D3',
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    fontWeight: 'bold',
  },
  '& .MuiDataGrid-scrollbarFiller': {
    backgroundColor: '#D3D3D3',
  },
}));

const LimitedDescription = styled(Typography)`
  display: -webkit-box;
  -webkit-line-clamp: ${({ expanded }) => (expanded ? "none" : 4)};
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 4px;
  cursor: pointer;
`;

const StyledCheckbox = styled(Checkbox)`
  color: #2957A4;
  &.Mui-checked {
    color: #2957A4;
  }
`;

const ToolbarContainer = styled(Toolbar)`
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  padding: 10px 20px;
  border-bottom: 1px solid #e0e0e0;
  border-radius: 4px;
`;

const ChatContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 400px;
  overflow-y: auto;
  padding: 10px;
`;

const MessageContainer = styled(Box)`
  display: flex;
  justify-content: ${({ isUser }) => (isUser ? "flex-end" : "flex-start")};
  margin-bottom: 10px;
`;

const MessageBubble = styled(Paper)`
  background-color: ${({ isUser }) => (isUser ? "#DCF8C6" : "#FFFFFF")};
  padding: 10px;
  max-width: 60%;
  word-wrap: break-word;
  border-radius: 10px;
`;

const ChatInputContainer = styled(Box)`
  display: flex;
  margin-top: 10px;
`;

const StyledCaption = styled(Typography)`
  font-size: 0.85rem;
  color: #2957a4;
  font-weight: 500;
  margin-bottom: 4px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  display: block
  text-align: ${({ isUser }) => (isUser ? "right" : "left")};
`;

const StyledDialog = styled(Dialog)`
  & .MuiDialog-paper {
    width: 100%;
    height: 100%;
    margin: 0;
    @media (min-width: 450px) {
      width: 80%;
      height: auto;
    }
  }
`;

const StyledDialogTitle = styled(DialogTitle)`
  font-size: 1.5rem;
  background-color: #2957a4;
  color: white;
  text-align: center;
  padding: 10px;
  @media (max-width: 450px) {
    font-size: 1.2rem;
    padding: 8px;
  }
`;

const StyledDialogContent = styled(DialogContent)`
  padding: 16px;
  @media (max-width: 450px) {
    padding: 8px;
  }
`;

const StyledDialogActions = styled(DialogActions)`
  padding: 16px;
  justify-content: center;
  @media (max-width: 450px) {
    padding: 8px;
  }
`;

const StyledButton = styled(Button)`
  font-size: 1rem;
  padding: 10px 20px;
  @media (max-width: 450px) {
    font-size: 0.9rem;
    padding: 8px 16px;
  }
`;

const ChamadosUser = () => {
  const usuario = sessionStorage.getItem("user");
  const grupo = sessionStorage.getItem("grupo");
  const [status, setStatus] = useState("Abertos");
  const [encerradosChecked, setEncerradosChecked] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [chamados, setChamados] = useState([]);
  const [selectedChamado, setSelectedChamado] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [descriptionExpanded, setDescriptionExpanded] = useState(false);
  const descriptionRef = useRef(null);
  const [isTruncated, setIsTruncated] = useState(false);
  const [value, setValue] = useState(0);
  const [chatMessages, setChatMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const chatEndRef = useRef(null);
  const [sending, setSending] = useState(false);
  const chatInputRef = useRef(null);


  useEffect(() => {
    if (chatInputRef.current) {
      chatInputRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [chatMessages]); // Depende de chatMessages

  

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const fetchChatMessages = useCallback(async () => {
    if (selectedChamado) {
      try {
        const response = await Axios.get("/usuario/chat", {
          params: {
            chamado: selectedChamado.id,
          },
        });
        setChatMessages(response.data);
      } catch (error) {
        showSnackbar("Erro ao buscar mensagens do chat", "error");
      }
    }
  }, [selectedChamado]);

  useEffect(() => {
    if (value === 1) {
      fetchChatMessages();
    }
  }, [value, selectedChamado, fetchChatMessages]);

  const handleSendMessage = async () => {
    if (!newMessage.trim()) return;

    setSending(true);
    try {
      await Axios.post("/usuario/chat", {

        chamadoID: selectedChamado.id,
        usuario: usuario,
        texto: newMessage,
      });
      setNewMessage("");
      fetchChatMessages();
    } catch (error) {
      showSnackbar("Erro ao enviar mensagem", "error");
    }
    setSending(false);
  };


  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") return;
    setOpenSnackbar(false);
  };

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setOpenSnackbar(true);
  };

  const fetchChamados = useCallback(async () => {
    try {
      const statusParam = encerradosChecked ? "Encerrado" : status;
      const response = await Axios.get("/usuario/meus-chamados", {
        params: {
          usuario: usuario,
          status: statusParam,
        },
      });
      setChamados(response.data);
    } catch (error) {
      showSnackbar("Erro ao buscar os chamados", "error");
    }
  }, [usuario, encerradosChecked, status]);

  useEffect(() => {
    fetchChamados();
  }, [encerradosChecked, fetchChamados]);

  useEffect(() => {
    if (descriptionRef.current) {
      const { scrollHeight, clientHeight } = descriptionRef.current;
      setIsTruncated(scrollHeight > clientHeight);
    }
  }, [selectedChamado?.descricao]);

  const handleCheckboxChange = (event) => {
    setEncerradosChecked(event.target.checked);
    fetchChamados();
  };

  const handleRowClick = (params) => {
    setSelectedChamado(params.row);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedChamado(null);
    setDescriptionExpanded(false);
    setChatMessages([]);
    setValue(0);
  };

  const handleDescriptionClick = () => {
    setDescriptionExpanded(!descriptionExpanded);
  };

  const handleRefresh = () => {
    fetchChamados();
    showSnackbar("Chamados atualizados com sucesso!", "success");
  };

  const columns = [
    { field: "id", headerName: "Chamado", width: 100 },
    ...(grupo === "8f498e6aabb107de59d0ad2177f6d882" ? [{ field: "usuario", headerName: "Nome", width: 250 }] : []),
    { field: "descricao", headerName: "Descrição", width: 350 },
    { field: "data", headerName: "Data de Abertura", width: 175, },
    { field: "status", headerName: "Status", width: 180 },
    { field: "dtatualiza", headerName: "Última Atualização", width: 175 },
    { field: "nome", headerName: "Atendimento", width: 250 },
    { field: "prazo", headerName: "Prazo", width: 100 },
  ];

  const StyledGridOverlay = style('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '& .no-rows-primary': {
      fill: theme.palette.mode === 'light' ? '#AEB8C2' : '#3D4751',
    },
    '& .no-rows-secondary': {
      fill: theme.palette.mode === 'light' ? '#E8EAED' : '#1D2126',
    },
  }));

  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          width={96}
          viewBox="0 0 452 257"
          aria-hidden
          focusable="false"
        >
          <path
            className="no-rows-primary"
            d="M348 69c-46.392 0-84 37.608-84 84s37.608 84 84 84 84-37.608 84-84-37.608-84-84-84Zm-104 84c0-57.438 46.562-104 104-104s104 46.562 104 104-46.562 104-104 104-104-46.562-104-104Z"
          />
          <path
            className="no-rows-primary"
            d="M308.929 113.929c3.905-3.905 10.237-3.905 14.142 0l63.64 63.64c3.905 3.905 3.905 10.236 0 14.142-3.906 3.905-10.237 3.905-14.142 0l-63.64-63.64c-3.905-3.905-3.905-10.237 0-14.142Z"
          />
          <path
            className="no-rows-primary"
            d="M308.929 191.711c-3.905-3.906-3.905-10.237 0-14.142l63.64-63.64c3.905-3.905 10.236-3.905 14.142 0 3.905 3.905 3.905 10.237 0 14.142l-63.64 63.64c-3.905 3.905-10.237 3.905-14.142 0Z"
          />
          <path
            className="no-rows-secondary"
            d="M0 10C0 4.477 4.477 0 10 0h380c5.523 0 10 4.477 10 10s-4.477 10-10 10H10C4.477 20 0 15.523 0 10ZM0 59c0-5.523 4.477-10 10-10h231c5.523 0 10 4.477 10 10s-4.477 10-10 10H10C4.477 69 0 64.523 0 59ZM0 106c0-5.523 4.477-10 10-10h203c5.523 0 10 4.477 10 10s-4.477 10-10 10H10c-5.523 0-10-4.477-10-10ZM0 153c0-5.523 4.477-10 10-10h195.5c5.523 0 10 4.477 10 10s-4.477 10-10 10H10c-5.523 0-10-4.477-10-10ZM0 200c0-5.523 4.477-10 10-10h203c5.523 0 10 4.477 10 10s-4.477 10-10 10H10c-5.523 0-10-4.477-10-10ZM0 247c0-5.523 4.477-10 10-10h231c5.523 0 10 4.477 10 10s-4.477 10-10 10H10c-5.523 0-10-4.477-10-10Z"
          />
        </svg>
        <div style={{ mt: 2, marginTop: "5px", }}>Você ainda não têm chamados</div>
      </StyledGridOverlay>
    );
  }
  

  return (
    <>
      <StyledOrange />
      <StyledTitle id="title">Service Desk - Meus Chamados</StyledTitle>
      <StyledGreen />
      <Box sx={{ backgroundColor: "#f0f2f5", minHeight: "100vh", padding: 4 }}>
        <Paper elevation={3} sx={{ padding: 2, borderRadius: 2 }}>
          <ToolbarContainer>
            <Typography variant="h6" component="div" color="#2957a4">
            </Typography>
            <div display="flex" alignItems="center" sx={{ backgroundColor: "#f0f2f5", }}>
              <FormControlLabel
                control={
                  <StyledCheckbox
                    checked={encerradosChecked}
                    onChange={handleCheckboxChange}
                  />
                }
                label="Encerrados"
              />
              <IconButton
                color="primary"
                onClick={handleRefresh}
                sx={{ marginLeft: 2 }}
                title="Atualizar"
              >
                <Refresh />
              </IconButton>
            </div>
          </ToolbarContainer>

          <Box
            sx={{
              height: 600,
              width: "98%",
              marginTop: 2,

            }}
          >
            <StyledDataGrid
              rows={chamados}
              columns={columns}
              pageSize={10}
              slots={{
                noRowsOverlay: CustomNoRowsOverlay,
              }}
              localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
              rowsPerPageOptions={[10, 20, 50]}
              onRowClick={handleRowClick}
              disableSelectionOnClick
              sx={{
                "& .MuiDataGrid-row": {
                  cursor: "pointer",
                },
              }}
            />
          </Box>
        </Paper>

        {selectedChamado && (
          <StyledDialog
            open={openDialog}
            onClose={handleCloseDialog}
            maxWidth="md"
            fullWidth
          >
            <StyledDialogTitle
              sx={{
                bgcolor: "#2957a4",
                color: "#fff",
                textAlign: "center",
                padding: "24px 16px",
                fontSize: "1.8em",
                fontWeight: "bold",
                letterSpacing: "0.5px",
                boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
              }}
            >
              Detalhes do Chamado #{selectedChamado.id}
            </StyledDialogTitle>
            <StyledDialogContent
              sx={{ padding: "24px 32px", backgroundColor: "#f4f6f9" }}
            >
              <Tabs value={value} onChange={handleChange} centered>
                <Tab label="Visão Geral" />
                <Tab label="Chat" />
              </Tabs>
              {value === 0 && (
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
                      <Description
                        sx={{ color: "#2957a4", fontSize: "1.2em", mr: 1 }}
                      />
                      <Typography variant="h6" sx={{ fontWeight: "500", mt: 1 }}>
                        Descrição:
                      </Typography>
                    </Box>
                    <LimitedDescription
                      expanded={descriptionExpanded}
                      onClick={handleDescriptionClick}
                      ref={descriptionRef}
                    >
                      {selectedChamado.descricao}
                    </LimitedDescription>
                    {isTruncated && !descriptionExpanded && (
                      <Typography
                        sx={{ color: "#2957a4", cursor: "pointer", ml: 4, mt: 1 }}
                        onClick={handleDescriptionClick}
                      >
                        Ver mais...
                      </Typography>
                    )}
                  </Grid>

                  <Grid item xs={12}>
                    <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                      <AccessTime
                        sx={{ color: "#7ca217", fontSize: "1.2em", mr: 1 }}
                      />
                      <Typography variant="h6" sx={{ fontWeight: "500", mt: 1 }}>
                        Prazo: {selectedChamado.prazo}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
                      <AssignmentTurnedIn
                        sx={{ color: "#7ca217", fontSize: "1.2em", mr: 1 }}
                      />
                      <Typography variant="h6" sx={{ fontWeight: "500", mt: 1 }}>
                        Atendimento:
                      </Typography>
                    </Box>
                    <Typography variant="body1" sx={{ marginLeft: 4 }}>
                      {selectedChamado.nome}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
                      <Event
                        sx={{ color: "#7ca217", fontSize: "1.2em", mr: 1 }}
                      />
                      <Typography variant="h6" sx={{ fontWeight: "500", mt: 1 }}>
                        Data de Abertura:
                      </Typography>
                    </Box>
                    <Typography variant="body1" sx={{ marginLeft: 4 }}>
                      {selectedChamado.data}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
                      <CheckCircle
                        sx={{ color: "#7ca217", fontSize: "1.2em", mr: 1 }}
                      />
                      <Typography variant="h6" sx={{ fontWeight: "500", mt: 1 }}>
                        Status:
                      </Typography>
                    </Box>
                    <Typography variant="body1" sx={{ marginLeft: 4 }}>
                      {selectedChamado.status}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
                      <ErrorOutline
                        sx={{ color: "#d66e20", fontSize: "1.2em", mr: 1 }}
                      />
                      <Typography variant="h6" sx={{ fontWeight: "500", mt: 1 }}>
                        Última Atualização:
                      </Typography>
                    </Box>
                    <Typography variant="body1" sx={{ marginLeft: 4 }}>
                      {selectedChamado.dtatualiza}
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
                      <ErrorOutline
                        sx={{ color: "#d66e20", fontSize: "1.2em", mr: 1 }}
                      />
                      <Typography variant="h6" sx={{ fontWeight: "500", mt: 1 }}>
                        Solução:
                      </Typography>
                    </Box>
                    <Typography variant="body1" sx={{ marginLeft: 4 }}>
                      {selectedChamado.solucao
                        ? selectedChamado.solucao
                        : "Ainda sem solução"}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {value === 1 && (
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <ChatContainer>
                      {chatMessages.map((msg, index) => {
                        const isUser = msg.nome === usuario;
                        
                        return (
                          <MessageContainer key={index} isUser={isUser}>
                            <MessageBubble isUser={isUser}>
                            <StyledCaption isUser={true}>
                                {msg.nome}
                              </StyledCaption>
                              <Typography variant="body1">{msg.texto}</Typography>
                            </MessageBubble>
                          </MessageContainer>
                        );
                      })}
                      <div ref={chatEndRef} />
                    </ChatContainer>
                    <ChatInputContainer>
                      <TextField
                        fullWidth
                        multiline
                        value={newMessage}
                        onChange={(e) => setNewMessage(e.target.value)}
                        placeholder="Digite sua mensagem..."
                        ref={chatInputRef}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") handleSendMessage();
                        }}
                      />
                      <IconButton color="primary" onClick={handleSendMessage}>
                        <Send />
                      </IconButton>
                    </ChatInputContainer>
                  </Grid>
                </Grid>
              )}
            </StyledDialogContent>
            <StyledDialogActions>
              <Button
                onClick={handleCloseDialog}
                sx={{
                  backgroundColor: "#2957a4",
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#1d468d",
                  },
                }}
              >
                Fechar
              </Button>
            </StyledDialogActions>
          </StyledDialog>
        )}

        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <MuiAlert
            onClose={handleCloseSnackbar}
            severity={snackbarSeverity}
            sx={{ width: "100%" }}
            elevation={6}
            variant="filled"
          >
            {snackbarMessage}
          </MuiAlert>
        </Snackbar>
      </Box>
    </>
  );
};

export default ChamadosUser;
