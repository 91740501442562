import React, { useState, useEffect } from "react";
import { TextField, Autocomplete } from "@mui/material/";
import { Axios } from "../../config/axiosConf";
import styled from "@emotion/styled";

const StyledH2 = styled.h2`
    display: block;
    font-size: 1em;
    font-weight: bold;
    padding-left: 15px;
`;

const StyledTextField = styled(TextField)`
  width: 380px;
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 500px;
`;

const StyledAutocomplete = styled(Autocomplete)`
    width: ${({ isSmallScreen }) => (isSmallScreen ? "100%" : "380px")};

    .MuiButtonBase-root {
        width: 40px;
    }
`;

const ProdutosPerigososPortaria = ({ cod_prodA, setProdutoA, cod_prodB, setProdutoB, cod_prodC, setProdutoC, cod_prodD, setProdutoD }) => {

    const [produtosBaixaComercial, setProdutosBaixaComercial] = useState([]);
    const [isSmallScreen, setIsSmallScreen] = useState(window.matchMedia("(max-width: 750px)").matches);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.matchMedia("(max-width: 750px)").matches);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        const getProdutosBaixaComercial = async () => {
            try {
                const response = await Axios.get("rP3o6m6E8jMfl7QxwCjJA8k8-qWztolpg7NUgVcs4Ds");
                setProdutosBaixaComercial(response.data);
            } catch (error) {
                console.log(error);
            }
        };
        getProdutosBaixaComercial();
    }, []);

    const enviarProdutoA = (event, newValue) => {
            setProdutoA(newValue);
    };

    const enviarProdutoB = (event, newValue) => {
        setProdutoB(newValue);
};

const enviarProdutoC = (event, newValue) => {
    setProdutoC(newValue);
};

const enviarProdutoD = (event, newValue) => {
    setProdutoD(newValue);
};


    return (
        <>
            <StyledH2>1.9 - Produto(s) perigoso(s) transportado(s):</StyledH2>
            <Label id = "labelProdutoA" >
                a)
                <StyledAutocomplete
                    id="produtoA"
                    freeSolo={false}
                    options={produtosBaixaComercial}
                    value={cod_prodA}
                    onChange={(event, newValue) => enviarProdutoA(event, newValue)}
                    renderInput={(params) => (
                        <StyledTextField id="text-produtoA" name="text-produtoA" {...params} label="Escolha um produto" />
                    )}
                    isSmallScreen={isSmallScreen}
                    isOptionEqualToValue={(option, value) => option === value}
                />
            </Label>

            <Label id="labelProdutoB">
                b)
                <StyledAutocomplete
                    id="produtoB"
                    freeSolo={false}
                    options={produtosBaixaComercial}
                    value={cod_prodB}
                    onChange={(event, newValue) => enviarProdutoB(event, newValue)}
                    renderInput={(params) => (
                        <StyledTextField id="text-produtoB" name="text-produtoB" {...params} label="Escolha um produto" />
                    )}
                    isSmallScreen={isSmallScreen}
                    isOptionEqualToValue={(option, value) => option === value}
                />
            </Label>

            <Label id="labelProdutoC">
                c)
                <StyledAutocomplete
                    id="produtoC"
                    freeSolo={false}
                    options={produtosBaixaComercial}
                    value={cod_prodC}
                    onChange={(event, newValue) => enviarProdutoC(event, newValue)}
                    renderInput={(params) => (
                        <StyledTextField id="text-produtoC" name="text-produtoC" {...params} label="Escolha um produto" />
                    )}
                    isSmallScreen={isSmallScreen}
                    isOptionEqualToValue={(option, value) => option === value}
                />
            </Label>

            <Label id="labelProdutoD">
                d)
                <StyledAutocomplete
                    id="produtoD"
                    freeSolo={false}
                    options={produtosBaixaComercial}
                    value={cod_prodD}
                    onChange={(event, newValue) => enviarProdutoD(event, newValue)}
                    renderInput={(params) => (
                        <StyledTextField id="text-produtoD" name="text-produtoD" {...params} label="Escolha um produto" />
                    )}
                    isSmallScreen={isSmallScreen}
                    isOptionEqualToValue={(option, value) => option === value}
                />
            </Label>
        </>
    );

};

export default ProdutosPerigososPortaria;