import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Axios } from '../config/axiosConf';

export const fetchPessoas = createAsyncThunk('responsavel/fetchPessoas', async () => {
  const response = await Axios.get('sKC25mWjGC_VS3VeFBSJWqaxJnmfXbFJQd-B61htMsI');
  return response.data;
});

const responsavelSlice = createSlice({
  name: 'responsavel',
  initialState: {
    responsavel: '',
    rgResponsavel: '',
    pessoas: [],
  },
  reducers: {
    setResponsavel: (state, action) => {
      state.responsavel = action.payload;
    },
    setRGResponsavel: (state, action) => {
      state.rgResponsavel = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPessoas.fulfilled, (state, action) => {
      state.pessoas = action.payload;
    });
  },
});

export const { setResponsavel, setRGResponsavel } = responsavelSlice.actions;

export default responsavelSlice.reducer;
