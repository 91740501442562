import {
    Cadastro,
    Visualizar,
    Consulta,
    Editar,
    Excluir,
    ConsultaGeral,
    ConsultaGeralCompra,
    ConsultaGeralDetail,
    ConsultaGeralVendasDetail,
    Cascata,
    Orcamento,
    ProgramacaoVendas,
    PerdasGanhosProdutos,
    PerdasGanhosFatClientes,
    PerdasGanhosClientes,
    ProgramacaoCompras,
    RelatoriosComercial,
    RelatoriosVendas,
    RelatoriosRH,
    EstoqueBaixaEstoque,
    PoliciaFederal,
    PoliciaCivil,
    Exercito,
    EstoquePlanilhaGeral,
    Erro,
    Home,
    Indicadores,
    RelatoriosContabilidade,
    RelatoriosFinanceiro,
    RelatoriosLogistica,
    AnaliseGerencial,
    RelatoriosContasaPagar,
    RelatoriosContasaReceber,
    RelatoriosContEntradasSaidas,
    User,
    TrocaEmpresa,
    // ChamadosUser
} from "./pages";

const routes = [
    { path: "/home", element: <Home />, protected: true },
    { path: "/usuario/alterar-senha", element: <User />, protected: true },
    { path: "/usuario/alterar-empresa", element: <TrocaEmpresa />, protected: true },
    // { path: "/usuario/meus-chamados", element: <ChamadosUser />, protected: true },
    { path: "/consultas/consulta-geral-vendas", element: <ConsultaGeral />, protected: true },
    { path: "/consultas/consulta-geral-compras", element: <ConsultaGeralCompra />, protected: true },
    { path: "/consultas/consulta-compra-detail", element: <ConsultaGeralDetail />, protected: true },
    { path: "/consultas/consulta-venda-detail", element: <ConsultaGeralVendasDetail />, protected: true },
    { path: "/consultas/analise-de-vendas", element: <Cascata />, protected: true },
    { path: "/consultas/orcamento-comercial", element: <Orcamento />, protected: true },
    { path: "/consultas/orcamento-comercial/atualiza", element: <Orcamento />, protected: true },
    { path: "/consultas/programacao-venda", element: <ProgramacaoVendas />, protected: true },
    { path: "/consultas/pg-produtos", element: <PerdasGanhosProdutos />, protected: true },
    { path: "/consultas/pg-fat-clientes", element: <PerdasGanhosFatClientes />, protected: true },
    { path: "/consultas/pg-clientes", element: <PerdasGanhosClientes />, protected: true },
    { path: "/consultas/programacao-compra", element: <ProgramacaoCompras />, protected: true },
    { path: "/consultas/indicadores", element: <Indicadores />, protected: true },
    { path: "/consultas/analise-de-vendas/:PEDIDO/:PRODUTO/:UNIDADE/:MOEDA", element: <Cascata />, protected: true },
    { path: "/consultas/orcamento-comercial/:COD_PRODUTO/:ANO_PREV/:MES_PREV", element: <Orcamento />, protected: true },
    { path: "/portaria/analise-transp/cadastro", element: <Cadastro />, protected: true },
    { path: "/portaria/analise-transp/consulta", element: <Consulta />, protected: true },
    { path: "/portaria/analise-transp/visualizar/:id", element: <Visualizar />, protected: true },
    { path: "/portaria/analise-transp/editar/:id", element: <Editar />, protected: true },
    { path: "/portaria/analise-transp/excluir/:id", element: <Excluir />, protected: true },
    { path: "/relatorios/comercial", element: <RelatoriosComercial />, protected: true },
    { path: "/relatorios/vendas", element: <RelatoriosVendas />, protected: true },
    { path: "/relatorios/rh", element: <RelatoriosRH />, protected: true },
    { path: "/relatorios/contabilidade", element: <RelatoriosContabilidade />, protected: true },
    { path: "/relatorios/financeiro", element: <RelatoriosFinanceiro />, protected: true },
    { path: "/relatorios/logistica", element: <RelatoriosLogistica />, protected: true },
    { path: "/estoque/estoque-baixa-estoque", element: <EstoqueBaixaEstoque />, protected: true },
    { path: "/estoque/estoque-planilha-geral", element: <EstoquePlanilhaGeral />, protected: true },
    { path: "/estoque/policia-federal", element: <PoliciaFederal />, protected: true },
    { path: "/estoque/exercito", element: <Exercito />, protected: true },
    { path: "/estoque/policia-civil", element: <PoliciaCivil />, protected: true },
    { path: "/financeiro/analise-gerencial", element: <AnaliseGerencial />, protected: true },
    { path: "/financeiro/relatorio-contas-a-pagar", element: <RelatoriosContasaPagar />, protected: true },
    { path: "/financeiro/relatorio-contas-a-receber", element: <RelatoriosContasaReceber />, protected: true },
    { path: "/contabilidade/relatorio-entradas-saidas", element: <RelatoriosContEntradasSaidas />, protected: true },
    { path: "*", element: <Erro /> },
];

export default routes;
