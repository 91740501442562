// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.charts-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.chart-container {
  flex: 1 1 45%; 
  max-width: 800px;
  max-height: 800px;
  margin: 10px;
  width: 100%;
  height: auto;
  padding-bottom: 70px;
  border: 1px solid #ccc;
  border-radius: 4px;
  display: flex;
  background-color: #f5f5f5;;
  justify-content: center;
  align-items: center;
}

@media (max-width: 700px) {
  .chart-container {
    flex: 1 1 100%; 
    margin-bottom: 50px;
    margin-left: 0px;
  }
}

.typographyChart {
  text-align: center;
  font-weight: bold;
  width: 100%;
  margin-top: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/styles/AnaliseGerencial.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;EACf,uBAAuB;EACvB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,gBAAgB;EAChB,iBAAiB;EACjB,YAAY;EACZ,WAAW;EACX,YAAY;EACZ,oBAAoB;EACpB,sBAAsB;EACtB,kBAAkB;EAClB,aAAa;EACb,yBAAyB;EACzB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE;IACE,cAAc;IACd,mBAAmB;IACnB,gBAAgB;EAClB;AACF;;AAEA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,WAAW;EACX,gBAAgB;AAClB","sourcesContent":[".charts-wrapper {\r\n  display: flex;\r\n  flex-wrap: wrap;\r\n  justify-content: center;\r\n  gap: 20px;\r\n}\r\n\r\n.chart-container {\r\n  flex: 1 1 45%; \r\n  max-width: 800px;\r\n  max-height: 800px;\r\n  margin: 10px;\r\n  width: 100%;\r\n  height: auto;\r\n  padding-bottom: 70px;\r\n  border: 1px solid #ccc;\r\n  border-radius: 4px;\r\n  display: flex;\r\n  background-color: #f5f5f5;;\r\n  justify-content: center;\r\n  align-items: center;\r\n}\r\n\r\n@media (max-width: 700px) {\r\n  .chart-container {\r\n    flex: 1 1 100%; \r\n    margin-bottom: 50px;\r\n    margin-left: 0px;\r\n  }\r\n}\r\n\r\n.typographyChart {\r\n  text-align: center;\r\n  font-weight: bold;\r\n  width: 100%;\r\n  margin-top: 10px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
