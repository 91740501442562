import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Axios } from "../../../config/axiosConf";
import "../../../styles/Cadastro.css";
import styled from "@emotion/styled";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import {
  FormControlLabel,
  Radio,
  TextField,
  Typography,
  Button
} from "@mui/material";
import { AiOutlineCalendar } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { MdPendingActions } from "react-icons/md";
import SignatureCanvas from "react-signature-canvas";
import CheckboxesAnaliseTransp from "../../../components/Checkboxes/CheckboxesAnaliseTransp";
import CondutoresPortaria from "../../../components/AutoCompletesPortaria/CondutoresPortaria";
import FornecedoresPortaria from "../../../components/AutoCompletesPortaria/FornecedoresPortaria";
import ResponsavelPortaria from "../../../components/AutoCompletesPortaria/ResponsavelPortaria";
import ResponsavelTransportadoraPortaria from "../../../components/AutoCompletesPortaria/ResponsavelTransportadoraPortaria";
import TransportadorasPortaria from "../../../components/AutoCompletesPortaria/TransportadorasPortaria";
import VeiculosPortaria from "../../../components/AutoCompletesPortaria/VeiculosPortaria";
import VeiculosReboquePortaria from "../../../components/AutoCompletesPortaria/VeiculosReboquePortaria";
import AuxiliaresPortaria from "../../../components/AutoCompletesPortaria/AuxiliaresPortaria";
import ProdutosPerigososPortaria from "../../../components/AutoCompletesPortaria/ProdutosPerigososPortaria";
import { setResponsavel, setRGResponsavel, } from "../../../slices/responsavelSlice";
import {
  setResponsavelTransp,
  setRGResponsavelTransp,
} from "../../../slices/responsavelTranspSlice";
import { setAuxiliar, setRGAuxiliar } from "../../../slices/auxiliarSlice";
import { setCondutor, setRGCondutor } from "../../../slices/condutorSlice";
import PergsPortaria from "../../../components/Radios/PergsPortaria";

const StyledH2 = styled.h2`
  display: block;
  font-size: 1em;
  font-weight: bold;
  padding-left: 15px;
`;
const StyledH1 = styled.h1`
  display: block;
  font-size: 1.1em;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
`;
const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  background-color: #f5f5f5;
  border-radius: 8px;
  height: 100%;
`;
const ButtonContainer = styled.div`
  display: flex;
  gap: 16px;
`;
const ButtonContainerSubmit = styled.div`
  display: flex;
  gap: 16px;
  justify-content: center;
`;
const ClearButton = styled(Button)`
  height: 30px;
  width: 60px;
  color: #fff;
  background-color: #f21f16;
  border: 1px solid rgba(0, 0, 0, 0.2);
  &:hover {
    background-color: #cf1810;
  }
`;
const SubmitButton = styled(Button)`
  height: 50px;
  width: 150px;
  background-color: #2957a4;
  border: 1px solid rgba(0, 0, 0, 0.2);
  color: #fff;
  font-size: 1.105rem;
  justify-content: center;
  &:hover {
    background-color: #1f437d;
  }
`;
const PendenteLabel = styled(Typography)`
  font-weight: bold;
  font-size: 18px;
`;
const StyledTitle = styled(Typography)`
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: bold;
  font-size: 1.5em;
  margin: 0em 0;
  background-color: #2957a4;
  color: #fff;
  padding: 10px;
  text-align: center;
  @media (max-width: 550px) {
    font-size: 1.2em;
  }
`;

const StyledOrange = styled(Typography)`
    background-color: #D66E20;
    padding-top: 3px;
    padding-bottom: 3px;
  @media (max-width: 550px) {
    font-size: 1.2em;
  }
`;

const StyledGreen = styled(Typography)`
    background-color: #7CA217;
    padding-top: 3px;
    padding-bottom: 3px;
  @media (max-width: 550px) {
    font-size: 1.2em;
  }
`;
const Label = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;
`;
const RadioWrapper = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;
const RadioButton = styled(Radio)`
  color: #2196f3;
`;
const DateIcon = styled(AiOutlineCalendar)`
  font-size: 24px;
  color: #2196f3;
`;
const PendingIcon = styled(MdPendingActions)`
  font-size: 24px;
  color: #2196f3;
`;

const CenteredSnackbar = styled(Snackbar)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LargeAlert = styled(MuiAlert)`
  font-size: 1.2rem;
  width: 400px;
  text-align: center;
`;

const SnackbarButton = styled(Button)`
  margin-top: 16px;
  background-color: #228b22;
  color: white;
  &:hover {
    background-color: #008000;
  }
`;
const Cadastro = () => {
  const user = sessionStorage.getItem("user");
  const [date, setDate] = useState(
    new Date().toLocaleDateString("pt-BR", { timeZone: "UTC" })
  );
  const [pendente, setPendente] = useState("N");
  const [tipo, setTipoSelecionado] = useState("C");
  const [tipoDeProduto, setTipoDeprodutosBaixaComercialelecionado] =
    useState("Granel");
  const [prodPerigoso, setProdutoPerigoso] = useState("N");
  const [resPerigoso, setResPerigoso] = useState("N");
  const [embalagemVazia, setEmbalagemVazia] = useState("N");
  const [produtoAMais, setProdutoAMais] = useState("N");
  const [perg1, setPerg1] = useState("S");
  const [perg2, setPerg2] = useState("S");
  const [perg3, setPerg3] = useState("S");
  const [perg4, setPerg4] = useState("S");
  const [perg5, setPerg5] = useState("S");
  const [perg6, setPerg6] = useState("S");
  const [perg7, setPerg7] = useState("S");
  const [perg8, setPerg8] = useState("S");
  const [perg9, setPerg9] = useState("S");
  const [perg10, setPerg10] = useState("S");
  const [perg11, setPerg11] = useState("S");
  const [perg12, setPerg12] = useState("S");
  const [perg13, setPerg13] = useState("S");
  const [perg14, setPerg14] = useState("S");
  const [perg15, setPerg15] = useState("S");
  const [perg16, setPerg16] = useState("S");
  const [perg17, setPerg17] = useState("S");
  const [perg18, setPerg18] = useState("S");
  const [perg19, setPerg19] = useState("S");
  const [perg20, setPerg20] = useState("S");
  const [perg21, setPerg21] = useState("S");
  const [perg22, setPerg22] = useState("S");
  const [perg23, setPerg23] = useState("S");
  const [perg24, setPerg24] = useState("S");
  const [perg25, setPerg25] = useState("S");
  const [perg26, setPerg26] = useState("S");
  const [perg27, setPerg27] = useState("S");
  const [perg28, setPerg28] = useState("S");
  const [perg29, setPerg29] = useState("S");
  const [perg30, setPerg30] = useState("S");
  const [perg31, setPerg31] = useState("S");
  const [perg32, setPerg32] = useState("S");
  const [perg33, setPerg33] = useState("S");
  const [perg34, setPerg34] = useState("S");
  const [perg35, setPerg35] = useState("S");
  const [perg36, setPerg36] = useState("S");
  const [perg37, setPerg37] = useState("S");
  const [perg38, setPerg38] = useState("S");
  const [perg39, setPerg39] = useState("S");
  const [perg40, setPerg40] = useState("S");
  const [perg41, setPerg41] = useState("S");
  const [perg42, setPerg42] = useState("S");
  const [perg43, setPerg43] = useState("S");
  const [perg44, setPerg44] = useState("S");
  const [perg45, setPerg45] = useState("S");
  const [perg46, setPerg46] = useState("S");
  const [perg47, setPerg47] = useState("S");
  const [perg48, setPerg48] = useState("S");
  const [perg49, setPerg49] = useState("S");
  const [perg50, setPerg50] = useState("S");
  const [perg51, setPerg51] = useState("S");
  const [perg52, setPerg52] = useState("S");
  const [perg53, setPerg53] = useState("S");
  const [perg54, setPerg54] = useState("S");
  const [perg55, setPerg55] = useState("S");
  const [perg56, setPerg56] = useState("S");
  const [perg57, setPerg57] = useState("S");
  const [perg58, setPerg58] = useState(" ");
  const [perg59, setPerg59] = useState(" ");
  const [perg60, setPerg60] = useState(" ");
  const [perg61, setPerg61] = useState(" ");
  const [perg62, setPerg62] = useState(" ");
  const [perg63, setPerg63] = useState(" ");
  const [perg64, setPerg64] = useState(" ");
  const [perg65, setPerg65] = useState(" ");
  const [perg66, setPerg66] = useState(" ");
  const [perg67, setPerg67] = useState(" ");
  const [perg68, setPerg68] = useState(" ");
  const [perg69, setPerg69] = useState(" ");
  const [perg70, setPerg70] = useState(" ");
  const [perg71, setPerg71] = useState(" ");
  const [perg72, setPerg72] = useState(" ");
  const [fornecedorSelecionado, setFornecedorSelecionado] = useState(null);
  const [cod_prodA, setProdutoA] = useState(null);
  const [cod_prodB, setProdutoB] = useState(null);
  const [cod_prodC, setProdutoC] = useState(null);
  const [cod_prodD, setProdutoD] = useState(null);
  const [transportadoraSelecionada, setTransportadoraSelecionada] =
    useState(null);
  const descricaoRef = useRef(null);
  const [descricao, setDescricao] = useState(null);
  const [numDocColeta, setNumDocColeta] = useState(null);
  const [observacoes, setObservacoes] = useState(null);
  const [signCondut, setSignCondut] = useState(null);
  const [urlCondut, setUrlCondut] = useState();
  const [signAux, setSignAux] = useState(null);
  const [urlAux, setUrlAux] = useState();
  const dispatch = useDispatch();
  const { responsavel, rgResponsavel } = useSelector(
    (state) => state.responsavel
  );
  const { responsavelTransp, rgResponsavelTransp } = useSelector(
    (state) => state.responsavelTransp
  );
  const { auxiliar, rgAuxiliar } = useSelector((state) => state.auxiliar);
  const { condutor, rgCondutor } = useSelector((state) => state.condutor);
  const [signResp, setSignResp] = useState(null);
  const [urlResp, setUrlResp] = useState();
  const [placaVeiculo, setPlacaVeiculo] = useState("");
  const [numeroEixosVeiculo, setNumeroEixosVeiculo] = useState("");
  const [placaVeiculoReboque, setPlacaVeiculoReboque] = useState("");
  const [numeroEixosVeiculoReboque, setNumeroEixosVeiculoReboque] =
    useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const history = useNavigate();
  useEffect(() => {
    const perguntas = [
      perg1,
      perg2,
      perg3,
      perg4,
      perg5,
      perg6,
      perg7,
      perg8,
      perg9,
      perg10,
      perg11,
      perg12,
      perg13,
      perg14,
      perg15,
      perg16,
      perg17,
      perg18,
      perg19,
      perg20,
      perg21,
      perg22,
      perg23,
      perg24,
      perg25,
      perg26,
      perg27,
      perg28,
      perg29,
      perg30,
      perg31,
      perg32,
      perg33,
      perg34,
      perg35,
      perg36,
      perg37,
      perg38,
      perg39,
      perg40,
      perg41,
      perg42,
      perg43,
      perg44,
      perg45,
      perg46,
      perg47,
      perg48,
      perg49,
      perg50,
      perg51,
      perg52,
      perg53,
      perg54,
      perg55,
      perg56,
      perg57,
      perg58,
      perg59,
      perg60,
      perg61,
      perg62,
      perg63,
      perg64,
      perg65,
      perg66,
      perg67,
      perg68,
      perg69,
      perg70,
      perg71,
      perg72,
    ];
    const hasPending = perguntas.some((pergunta) => pergunta === "N");
    if (hasPending) {
      setPendente("S");
    } else {
      setPendente("N");
    }
  }, [
    perg1,
    perg2,
    perg3,
    perg4,
    perg5,
    perg6,
    perg7,
    perg8,
    perg9,
    perg10,
    perg11,
    perg12,
    perg13,
    perg14,
    perg15,
    perg16,
    perg17,
    perg18,
    perg19,
    perg20,
    perg21,
    perg22,
    perg23,
    perg24,
    perg25,
    perg26,
    perg27,
    perg28,
    perg29,
    perg30,
    perg31,
    perg32,
    perg33,
    perg34,
    perg35,
    perg36,
    perg37,
    perg38,
    perg39,
    perg40,
    perg41,
    perg42,
    perg43,
    perg44,
    perg45,
    perg46,
    perg47,
    perg48,
    perg49,
    perg50,
    perg51,
    perg52,
    perg53,
    perg54,
    perg55,
    perg56,
    perg57,
    perg58,
    perg59,
    perg60,
    perg61,
    perg62,
    perg63,
    perg64,
    perg65,
    perg66,
    perg67,
    perg68,
    perg69,
    perg70,
    perg71,
    perg72,
  ]);
  const limparAssinatura = (canvasRef, urlSetter) => {
    canvasRef.clear();
    urlSetter("");
  };
  const gerarAssinaturaCondut = () => {
    setUrlCondut(signCondut.getTrimmedCanvas().toDataURL("image/png"));
    alert("Assinatura do condutor salva!");
  };
  const gerarAssinaturaAux = () => {
    setUrlAux(signAux.getTrimmedCanvas().toDataURL("image/png"));
    alert("Assinatura do auxiliar salva!");
  };
  const gerarAssinaturaResp = () => {
    setUrlResp(signResp.getTrimmedCanvas().toDataURL("image/png"));
    alert("Assinatura do responsavel pela vistoria salva!");
  };
  const trocarDeTipoDeProduto = (event) => {
    setTipoDeprodutosBaixaComercialelecionado(event.target.value);
  };
  const pendenteChecked = (event) => {
    setPendente(event.target.value);
  };
  const enviarData = (date) => {
    setDate(date);
  };
  const enviarDescricao = (event) => {
    setDescricao(event.target.value);
  };
  const enviarNumDocColeta = (event) => {
    setNumDocColeta(event.target.value);
  };
  const enviarObservacao = (event) => {
    setObservacoes(event.target.value);
  };
  const handleResponsavelChange = (responsavel) => {
    dispatch(setResponsavel(responsavel));
  };
  const handleRGResponsavelChange = (rgResponsavel) => {
    dispatch(setRGResponsavel(rgResponsavel));
  };
  const handleResponsavelTranspChange = (responsavelTransp) => {
    dispatch(setResponsavelTransp(responsavelTransp));
  };
  const handleRGResponsavelTranspChange = (rgResponsavelTransp) => {
    dispatch(setRGResponsavelTransp(rgResponsavelTransp));
  };
  const handleAuxiliarChange = (auxiliar) => {
    dispatch(setAuxiliar(auxiliar));
  };
  const handleRGAuxiliarChange = (rgAuxiliar) => {
    dispatch(setRGAuxiliar(rgAuxiliar));
  };
  const handleCondutorChange = (condutor) => {
    dispatch(setCondutor(condutor));
  };
  const handleRGCondutorChange = (rgCondutor) => {
    dispatch(setRGCondutor(rgCondutor));
  };
  const handleCadastro = async (event) => {
    event.preventDefault();
    if (signCondut.isEmpty()) {
      showSnackbar("Por favor, o condutor deve assinar antes de salvar!", "error");
      return;
    } else if (signResp.isEmpty()) {
      showSnackbar("Por favor, o responsavel pela vistoria deve assinar antes de salvar!", "error");
      return;
    } else if (urlCondut === undefined) {
      showSnackbar("Por favor, clique no botão 'SALVAR' para salvar a assinatura do condutor antes de cadastrar!", "error");
      return;
    } else if (urlResp === undefined) {
      showSnackbar("Por favor, clique no botão 'SALVAR' para salvar a assinatura do responsavel antes de cadastrar!", "error");
      return;
    } else if (urlCondut === "") {
      showSnackbar("Por favor, clique no botão 'SALVAR' para salvar a assinatura do condutor antes de cadastrar!", "error");
      return;
    } else if (urlAux === "") {
      showSnackbar("Por favor, clique no botão 'SALVAR' para salvar a assinatura do auxiliar do condutor antes de cadastrar!", "error");
      return;
    } else if (urlResp === "") {
      showSnackbar("Por favor, clique no botão 'SALVAR' para salvar a assinatura do responsavel antes de cadastrar!", "error");
      gerarAssinaturaCondut();
      gerarAssinaturaAux();
      gerarAssinaturaResp();
      return;
    } else if (transportadoraSelecionada === null || transportadoraSelecionada === "") {
      showSnackbar("Selecione uma transportadora antes de cadastrar!", "error");
      return;
    } else if (fornecedorSelecionado === null || fornecedorSelecionado === "") {
      showSnackbar("Selecione uma fornecedora ou um cliente antes de cadastrar!", "error");
      return;
    } else if (numDocColeta === null || numDocColeta === "") {
      showSnackbar("Escreva o número de coleta antes de cadastrar!", "error");
      return;
    } else if (responsavel === null || responsavel === "") {
      showSnackbar("Digite o nome do responsável pela verificação antes de cadastrar!", "error");
      return;
    } else if (rgResponsavel === null || rgResponsavel === "") {
      showSnackbar("Digite o RG do responsável pela verificação antes de cadastrar!", "error");
      return;
    } else if (condutor === null || condutor === "") {
      showSnackbar("Digite o nome do condutor do veículo antes de cadastrar!", "error");
      return;
    } else if (rgCondutor === null || rgCondutor === "") {
      showSnackbar("Digite o RG do condutor do veículo antes de cadastrar!", "error");
      return;
    } else if (responsavelTransp === null || responsavelTransp === "") {
      showSnackbar("Digite o nome do responsável pela transportadora antes de cadastrar!", "error");
      return;
    } else if (rgResponsavelTransp === null || rgResponsavelTransp === "") {
      showSnackbar("Digite o RG do responsável pela transportadora antes de cadastrar!", "error");
      return;
    } else if (auxiliar === null || auxiliar === "") {
      showSnackbar("Digite o nome do auxiliar do condutor antes de cadastrar!", "error");
      return;
    } else if (rgAuxiliar === null || rgAuxiliar === "") {
      showSnackbar("Digite o RG do auxiliar do condutor antes de cadastrar!", "error");
      return;
    } else if (placaVeiculo === null) {
      showSnackbar("Digite a placa do veículo antes de cadastrar!", "error");
      return;
    } else if (numeroEixosVeiculo === null) {
      showSnackbar("Digite o numero de eixos do veículo antes de cadastrar!", "error");
      return;
    } else if (placaVeiculoReboque === null) {
      showSnackbar("Digite a placa do veículo antes de cadastrar!", "error");
      return;
    } else if (numeroEixosVeiculoReboque === null) {
      showSnackbar("Digite o numero de eixos do veículo antes de cadastrar!", "error");
      return;
    } else if (descricao === null) {
      showSnackbar("Escreva uma descrição antes de cadastrar!", "error");
      return;
    } else if (cod_prodA === null && cod_prodB === null && cod_prodC === null && cod_prodD === null) {
      showSnackbar("Selecione pelo menos um produto antes de cadastrar!", "error");
      return;
    } else {
      console.log("Iniciando o processo de cadastro");
      try {
        const response = await Axios.post("portaria/analise-transp/cadastro", {
          user,
          pendente,
          transportadoraSelecionada,
          tipo,
          tipoDeProduto,
          prodPerigoso,
          resPerigoso,
          embalagemVazia,
          produtoAMais,
          fornecedorSelecionado,
          descricao,
          numDocColeta,
          perg1: perg1 === " " ? null : perg1,
          perg2: perg2 === " " ? null : perg2,
          perg3: perg3 === " " ? null : perg3,
          perg4: perg4 === " " ? null : perg4,
          perg5: perg5 === " " ? null : perg5,
          perg6: perg6 === " " ? null : perg6,
          perg7: perg7 === " " ? null : perg7,
          perg8: perg8 === " " ? null : perg8,
          perg9: perg9 === " " ? null : perg9,
          perg10: perg10 === " " ? null : perg10,
          perg11: perg11 === " " ? null : perg11,
          perg12: perg12 === " " ? null : perg12,
          perg13: perg13 === " " ? null : perg13,
          perg14: perg14 === " " ? null : perg14,
          perg15: perg15 === " " ? null : perg15,
          perg16: perg16 === " " ? null : perg16,
          perg17: perg17 === " " ? null : perg17,
          perg18: perg18 === " " ? null : perg18,
          perg19: perg19 === " " ? null : perg19,
          perg20: perg20 === " " ? null : perg20,
          perg21: perg21 === " " ? null : perg21,
          perg22: perg22 === " " ? null : perg22,
          perg23: perg23 === " " ? null : perg23,
          perg24: perg24 === " " ? null : perg24,
          perg25: perg25 === " " ? null : perg25,
          perg26: perg26 === " " ? null : perg26,
          perg27: perg27 === " " ? null : perg27,
          perg28: perg28 === " " ? null : perg28,
          perg29: perg29 === " " ? null : perg29,
          perg30: perg30 === " " ? null : perg30,
          perg31: perg31 === " " ? null : perg31,
          perg32: perg32 === " " ? null : perg32,
          perg33: perg33 === " " ? null : perg33,
          perg34: perg34 === " " ? null : perg34,
          perg35: perg35 === " " ? null : perg35,
          perg36: perg36 === " " ? null : perg36,
          perg37: perg37 === " " ? null : perg37,
          perg38: perg38 === " " ? null : perg38,
          perg39: perg39 === " " ? null : perg39,
          perg40: perg40 === " " ? null : perg40,
          perg41: perg41 === " " ? null : perg41,
          perg42: perg42 === " " ? null : perg42,
          perg43: perg43 === " " ? null : perg43,
          perg44: perg44 === " " ? null : perg44,
          perg45: perg45 === " " ? null : perg45,
          perg46: perg46 === " " ? null : perg46,
          perg47: perg47 === " " ? null : perg47,
          perg48: perg48 === " " ? null : perg48,
          perg49: perg49 === " " ? null : perg49,
          perg50: perg50 === " " ? null : perg50,
          perg51: perg51 === " " ? null : perg51,
          perg52: perg52 === " " ? null : perg52,
          perg53: perg53 === " " ? null : perg53,
          perg54: perg54 === " " ? null : perg54,
          perg55: perg55 === " " ? null : perg55,
          perg56: perg56 === " " ? null : perg56,
          perg57: perg57 === " " ? null : perg57,
          perg58: perg58 === " " ? null : perg58,
          perg59: perg59 === " " ? null : perg59,
          perg60: perg60 === " " ? null : perg60,
          perg61: perg61 === " " ? null : perg61,
          perg62: perg62 === " " ? null : perg62,
          perg63: perg63 === " " ? null : perg63,
          perg64: perg64 === " " ? null : perg64,
          perg65: perg65 === " " ? null : perg65,
          perg66: perg66 === " " ? null : perg66,
          perg67: perg67 === " " ? null : perg67,
          perg68: perg68 === " " ? null : perg68,
          perg69: perg69 === " " ? null : perg69,
          perg70: perg70 === " " ? null : perg70,
          perg71: perg71 === " " ? null : perg71,
          perg72: perg72 === " " ? null : perg72,
          observacoes,
          condutor,
          rgCondutor,
          urlCondut,
          responsavel,
          rgResponsavel,
          urlResp,
          auxiliar,
          rgAuxiliar,
          urlAux,
          responsavelTransp,
          rgResponsavelTransp,
          placaVeiculo,
          numeroEixosVeiculo,
          placaVeiculoReboque,
          numeroEixosVeiculoReboque,
          cod_prodA: cod_prodA === null ? "" : cod_prodA.substring(0, 6),
          cod_prodB: cod_prodB === null ? "" : cod_prodB.substring(0, 6),
          cod_prodC: cod_prodC === null ? "" : cod_prodC.substring(0, 6),
          cod_prodD: cod_prodD === null ? "" : cod_prodD.substring(0, 6),
        });
        console.log("Resposta do servidor:", response.data);
        showSnackbar("Cadastro realizado com sucesso!", "success");
        setTransportadoraSelecionada("");
        setFornecedorSelecionado("");
        setNumDocColeta("");
        setDescricao("");
        setObservacoes("");
        dispatch(setCondutor(""));
        dispatch(setRGCondutor(""));
        setUrlCondut("");
        dispatch(setResponsavel(""));
        dispatch(setRGResponsavel(""));
        dispatch(setResponsavelTransp(""));
        dispatch(setRGResponsavelTransp(""));
        setPlacaVeiculo("");
        setNumeroEixosVeiculo("");
        setPlacaVeiculoReboque("");
        setNumeroEixosVeiculoReboque("");
        setProdutoA("");
        setProdutoB("");
        setProdutoC("");
        setProdutoD("");
        setPerg1("S");
        setPerg2("S");
        setPerg3("S");
        setPerg4("S");
        setPerg5("S");
        setPerg6("S");
        setPerg7("S");
        setPerg8("S");
        setPerg9("S");
        setPerg10("S");
        setPerg11("S");
        setPerg12("S");
        setPerg13("S");
        setPerg14("S");
        setPerg15("S");
        setPerg16("S");
        setPerg17("S");
        setPerg18("S");
        setPerg19("S");
        setPerg20("S");
        setPerg21("S");
        setPerg22("S");
        setPerg23("S");
        setPerg24("S");
        setPerg25("S");
        setPerg26("S");
        setPerg27("S");
        setPerg28("S");
        setPerg29("S");
        setPerg30("S");
        setPerg31("S");
        setPerg32("S");
        setPerg33("S");
        setPerg34("S");
        setPerg35("S");
        setPerg36("S");
        setPerg37("S");
        setPerg38("S");
        setPerg39("S");
        setPerg40("S");
        setPerg41("S");
        setPerg42("S");
        setPerg43("S");
        setPerg44("S");
        setPerg45("S");
        setPerg46("S");
        setPerg47("S");
        setPerg48("S");
        setPerg49("S");
        setPerg50("S");
        setPerg51("S");
        setPerg52("S");
        setPerg53("S");
        setPerg54("S");
        setPerg55("S");
        setPerg56("S");
        setPerg57("S");
        setPerg58(" ");
        setPerg59(" ");
        setPerg60(" ");
        setPerg61(" ");
        setPerg62(" ");
        setPerg63(" ");
        setPerg64(" ");
        setPerg65(" ");
        setPerg66(" ");
        setPerg67(" ");
        setPerg68(" ");
        setPerg69(" ");
        setPerg70(" ");
        setPerg71(" ");
        setPerg72(" ");
        setUrlResp("");
        setProdutoPerigoso("N");
        setResPerigoso("N");
        setEmbalagemVazia("N");
        setProdutoAMais("N");
        setTipoDeprodutosBaixaComercialelecionado("Granel");
        setPendente("N")
        dispatch(setAuxiliar(""));
        dispatch(setRGAuxiliar(""));
        setUrlAux("");
        limparAssinatura(signCondut, setUrlCondut);
        limparAssinatura(signResp, setUrlResp);
        limparAssinatura(signAux, setUrlAux);
      } catch (error) {
        console.error("Erro ao cadastrar:", error);
        showSnackbar("Erro ao realizar cadastro!", "error");
      }
    }
  };
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };
  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setOpenSnackbar(true);
  };


  return (
    <div style={{fontFamily: "Inter"}}>

      <div>
        <StyledOrange/>
        <StyledTitle id="title">Cadastrar Formulário</StyledTitle>
      <StyledGreen />
      </div>
      <FormContainer id="meu-formulario" onSubmit={handleCadastro}>

        <StyledH1 id="styledh1" style={{ textAlign: "center" }}>

          Lista de verificação (checklist) para a expedição e o transporte
          rodoviário de produto perigoso
        </StyledH1>
        <br></br>
        <Label id="labelID1">

          <DateIcon />
          <TextField
            id="text-date"
            label="Data"
            value={date}
            InputProps={{ readOnly: true }}
            onChange={enviarData}
          />
        </Label>
        <br></br>
        <PendenteLabel id="labelID2">

          <PendingIcon /> ⠀Tipo de Produto
        </PendenteLabel>
        <RadioWrapper id="wrapperTipoProduto">

          <FormControlLabel
            id="produtoFracionado"
            value="Fracionado"
            control={<RadioButton required aria-label="Fracionado" />}
            label="Produto Fracionado"
            checked={tipoDeProduto === "Fracionado"}
            onChange={trocarDeTipoDeProduto}
          />
          <FormControlLabel
            id="produtoGranel"
            value="Granel"
            control={<RadioButton required aria-label="Granel" />}
            label="Produto à Granel"
            checked={tipoDeProduto === "Granel"}
            onChange={trocarDeTipoDeProduto}
          />
        </RadioWrapper>
        <br></br>
        <CheckboxesAnaliseTransp
          prodPerigoso={prodPerigoso}
          resPerigoso={resPerigoso}
          embalagemVazia={embalagemVazia}
          produtoAMais={produtoAMais}
          setProdPerigoso={setProdutoPerigoso}
          setResPerigoso={setResPerigoso}
          setEmbalagemVazia={setEmbalagemVazia}
          setProdutoAMais={setProdutoAMais}
        />
        <StyledH1 id="infoGeral">1 - INFORMAÇÕES GERAIS</StyledH1> <br></br>
        <PendenteLabel id="labelID3">

          <PendingIcon /> ⠀Pendente
        </PendenteLabel>
        <RadioWrapper id="wrapperPendente">

          <FormControlLabel
            id="pendente-sim"
            name="pendente-sim"
            value="S"
            control={<RadioButton required aria-label="pendente" />}
            label="Sim"
            checked={pendente === "S"}
            onChange={pendenteChecked}
          />
          <FormControlLabel
            id="pendente-nao"
            name="pendente-nao"
            value="N"
            control={<RadioButton required aria-label="pendente" />}
            label="Não"
            checked={pendente === "N"}
            onChange={pendenteChecked}
          />
        </RadioWrapper>
        <br></br>
        <StyledH2>

          Número de documento de coleta/Ordem de carregamento ou similar:
        </StyledH2>
        <Label id="labelID" style={{width: '500px'}}>

          <TextField
            id="num-doc-coleta"
            name="num-doc-coleta"
            placeholder="Insira núm. de doc. de coleta..."
            required
            sx={{ width: 300 }}
            value={numDocColeta}
            onChange={enviarNumDocColeta}
            onBlur={enviarNumDocColeta}
            multiline
          />
        </Label>
        <br></br>
        <FornecedoresPortaria
          fornecedorSelecionado={fornecedorSelecionado}
          setFornecedorSelecionado={setFornecedorSelecionado}
          tipo={tipo}
          setTipoSelecionado={setTipoSelecionado}
        />
        <ResponsavelPortaria
          responsavel={responsavel}
          setResponsavel={handleResponsavelChange}
          rgResponsavel={rgResponsavel}
          setRGResponsavel={handleRGResponsavelChange}
        />
        <TransportadorasPortaria
          transportadoraSelecionada={transportadoraSelecionada}
          setTransportadoraSelecionada={setTransportadoraSelecionada}
        />
        <ResponsavelTransportadoraPortaria
          responsavelTransp={responsavelTransp}
          setResponsavelTransp={handleResponsavelTranspChange}
          rgResponsavelTransp={rgResponsavelTransp}
          setRGResponsavelTransp={handleRGResponsavelTranspChange}
        />
        <VeiculosPortaria
          placaVeiculo={placaVeiculo}
          setPlacaVeiculo={setPlacaVeiculo}
          numeroEixosVeiculo={numeroEixosVeiculo}
          setNumeroEixosVeiculo={setNumeroEixosVeiculo}
        />
        <VeiculosReboquePortaria
          placaVeiculoReboque={placaVeiculoReboque}
          setPlacaVeiculoReboque={setPlacaVeiculoReboque}
          numeroEixosVeiculoReboque={numeroEixosVeiculoReboque}
          setNumeroEixosVeiculoReboque={setNumeroEixosVeiculoReboque}
        />
        <CondutoresPortaria
          condutor={condutor}
          setCondutor={handleCondutorChange}
          rgCondutor={rgCondutor}
          setRGCondutor={handleRGCondutorChange}
        />
        <AuxiliaresPortaria
          auxiliar={auxiliar}
          setAuxiliar={handleAuxiliarChange}
          rgAuxiliar={rgAuxiliar}
          setRGAuxiliar={handleRGAuxiliarChange}
        />
        <ProdutosPerigososPortaria
          cod_prodA={cod_prodA}
          cod_prodB={cod_prodB}
          cod_prodC={cod_prodC}
          cod_prodD={cod_prodD}
          setProdutoA={setProdutoA}
          setProdutoB={setProdutoB}
          setProdutoC={setProdutoC}
          setProdutoD={setProdutoD}
        />
        <StyledH2>Descrição:</StyledH2>
        <Label style={{width: '450px'}}>

          <TextField
            id="descricao"
            name="descricao"
            placeholder="Descrição"
            required
            value={descricao}
            sx={{ width: 400 }}
            onBlur={enviarDescricao}
            onChange={(event) => setDescricao(event.target.value)}
            multiline
            inputRef={descricaoRef}
          />
        </Label>
        <br></br>
        <PergsPortaria
          perg1={perg1}
          setPerg1={setPerg1}
          perg2={perg2}
          setPerg2={setPerg2}
          perg3={perg3}
          setPerg3={setPerg3}
          perg4={perg4}
          setPerg4={setPerg4}
          perg5={perg5}
          setPerg5={setPerg5}
          perg6={perg6}
          setPerg6={setPerg6}
          perg7={perg7}
          setPerg7={setPerg7}
          perg8={perg8}
          setPerg8={setPerg8}
          perg9={perg9}
          setPerg9={setPerg9}
          perg10={perg10}
          setPerg10={setPerg10}
          perg11={perg11}
          setPerg11={setPerg11}
          perg12={perg12}
          setPerg12={setPerg12}
          perg13={perg13}
          setPerg13={setPerg13}
          perg14={perg14}
          setPerg14={setPerg14}
          perg15={perg15}
          setPerg15={setPerg15}
          perg16={perg16}
          setPerg16={setPerg16}
          perg17={perg17}
          setPerg17={setPerg17}
          perg18={perg18}
          setPerg18={setPerg18}
          perg19={perg19}
          setPerg19={setPerg19}
          perg20={perg20}
          setPerg20={setPerg20}
          perg21={perg21}
          setPerg21={setPerg21}
          perg22={perg22}
          setPerg22={setPerg22}
          perg23={perg23}
          setPerg23={setPerg23}
          perg24={perg24}
          setPerg24={setPerg24}
          perg25={perg25}
          setPerg25={setPerg25}
          perg26={perg26}
          setPerg26={setPerg26}
          perg27={perg27}
          setPerg27={setPerg27}
          perg28={perg28}
          setPerg28={setPerg28}
          perg29={perg29}
          setPerg29={setPerg29}
          perg30={perg30}
          setPerg30={setPerg30}
          perg31={perg31}
          setPerg31={setPerg31}
          perg32={perg32}
          setPerg32={setPerg32}
          perg33={perg33}
          setPerg33={setPerg33}
          perg34={perg34}
          setPerg34={setPerg34}
          perg35={perg35}
          setPerg35={setPerg35}
          perg36={perg36}
          setPerg36={setPerg36}
          perg37={perg37}
          setPerg37={setPerg37}
          perg38={perg38}
          setPerg38={setPerg38}
          perg39={perg39}
          setPerg39={setPerg39}
          perg40={perg40}
          setPerg40={setPerg40}
          perg41={perg41}
          setPerg41={setPerg41}
          perg42={perg42}
          setPerg42={setPerg42}
          perg43={perg43}
          setPerg43={setPerg43}
          perg44={perg44}
          setPerg44={setPerg44}
          perg45={perg45}
          setPerg45={setPerg45}
          perg46={perg46}
          setPerg46={setPerg46}
          perg47={perg47}
          setPerg47={setPerg47}
          perg48={perg48}
          setPerg48={setPerg48}
          perg49={perg49}
          setPerg49={setPerg49}
          perg50={perg50}
          setPerg50={setPerg50}
          perg51={perg51}
          setPerg51={setPerg51}
          perg52={perg52}
          setPerg52={setPerg52}
          perg53={perg53}
          setPerg53={setPerg53}
          perg54={perg54}
          setPerg54={setPerg54}
          perg55={perg55}
          setPerg55={setPerg55}
          perg56={perg56}
          setPerg56={setPerg56}
          perg57={perg57}
          setPerg57={setPerg57}
          perg58={perg58}
          setPerg58={setPerg58}
          perg59={perg59}
          setPerg59={setPerg59}
          perg60={perg60}
          setPerg60={setPerg60}
          perg61={perg61}
          setPerg61={setPerg61}
          perg62={perg62}
          setPerg62={setPerg62}
          perg63={perg63}
          setPerg63={setPerg63}
          perg64={perg64}
          setPerg64={setPerg64}
          perg65={perg65}
          setPerg65={setPerg65}
          perg66={perg66}
          setPerg66={setPerg66}
          perg67={perg67}
          setPerg67={setPerg67}
          perg68={perg68}
          setPerg68={setPerg68}
          perg69={perg69}
          setPerg69={setPerg69}
          perg70={perg70}
          setPerg70={setPerg70}
          perg71={perg71}
          setPerg71={setPerg71}
          perg72={perg72}
          setPerg72={setPerg72}
        />
        <StyledH1>OBS:</StyledH1>
        <TextField
          id="outlined-textarea"
          placeholder="Observações..."
          value={observacoes}
          sx={{ width: 500 }}
          onChange={enviarObservacao}
          multiline
          inputRef={descricaoRef}
        />
        <br></br> <br></br>
        <div className="divTitulos">

          <Label>

            <StyledH2 style={{ alignSelf: "start" }}>

              EXPEDIDOR/TRANSPORTADOR:
            </StyledH2>
          </Label>
          <Label>

            <p style={{ fontStyle: "italic" }}>

              Declaramos para os devidos fins que o veículo acima caracterizado
              foi inspecionado e, neste momento, encontra-se em perfeito estado
              de conservação. A documentação exigida para a viagem foi entregue
              e foram informados os riscos e as características do(s) produto(s)
              a ser(em) transportado(s). As embalagens atendem às legislações; a
              carga está devidamente estivada/amarrada; o veículo e o(s)
              equipamento(s) estão aptos ao transporte; os rótulos de risco,
              painéis de segurança e símbolos estão colocados corretamente.
              Todos os documentos, a identificação e os equipamentos para
              situação de emergência (ABNT NBR 9735 e ABNT NBR 10271) exigidos
              para o transporte devem permanecer no veículo até o destino final
              da carga.
            </p>
            <br></br>
          </Label>
        </div>
        <div className="divTextos">

          <Label>

            <StyledH2 style={{ alignSelf: "start" }}>

              CONDUTOR/AUXILIAR:⠀⠀⠀⠀⠀⠀
            </StyledH2>
          </Label>
          <Label>

            <p style={{ fontStyle: "italic" }}>

              1 – Declaro para os devidos fins que o veículo acima caracterizado
              foi inspecionado e, neste momento, encontra-se em perfeito estado
              de conservação. A documentação exigida para a viagem foi entregue
              e foram informados os riscos e as características do(s) produto(s)
              a ser(em) transportado(s). As embalagens atendem às legislações; a
              carga está devidamente estivada/amarrada; o veículo e o(s)
              equipamento(s) estão aptos ao transporte; os rótulos de risco,
              painéis de segurança e símbolos estão colocados corretamente.
              Todos os documentos, a identificação e os equipamentos para
              situação de emergência (ABNT NBR 9735 e ABNT NBR 10271) exigidos
              para o transporte devem permanecer no veículo até o destino final
              da carga. <br></br>2 – Declaro para os devidos fins que cumpri o
              descanso previsto na legislação.<br></br>3 – Declaro que me
              responsabilizo por manter as condições do item 1, mesmo quando
              houver alterações na carga durante a viagem.
            </p>
            <br></br>
          </Label>
        </div>
        
        <StyledH1>Assinatura do condutor:</StyledH1>
        <div style={{ border: "2px solid black", width: 500, height: 200 }}>
          <SignatureCanvas
            ref={(ref) => setSignCondut(ref)}
            canvasProps={{ width: 500, height: 200, className: "sigCanvas" }}
            onEnd={() => {
              setUrlCondut(
                signCondut.getTrimmedCanvas().toDataURL("image/png")
              );
            }}
          />
        </div>
        <br></br>
        <ButtonContainer>

          <ClearButton
            onClick={() => limparAssinatura(signCondut, setUrlCondut)}
          >
            Limpar
          </ClearButton>
        </ButtonContainer>
        <br></br> <br></br> <StyledH1>Assinatura do auxiliar:</StyledH1>
        <div style={{ border: "2px solid black", width: 500, height: 200 }}>

          <SignatureCanvas
            ref={(ref) => setSignAux(ref)}
            canvasProps={{ width: 500, height: 200, className: "sigCanvas" }}
            onEnd={() => {
              setUrlAux(signAux.getTrimmedCanvas().toDataURL("image/png"));
            }}
          />
        </div>
        <br></br>
        <ButtonContainer>

          <ClearButton onClick={() => limparAssinatura(signAux, setUrlAux)}>
            Limpar
          </ClearButton>
        </ButtonContainer>
        <br></br> <br></br>
        <StyledH1>Assinatura do responsável pela vistoria:</StyledH1>
        <div style={{ border: "2px solid black", width: 500, height: 200 }}>

          <SignatureCanvas
            ref={(ref) => setSignResp(ref)}
            canvasProps={{ width: 500, height: 200, className: "sigCanvas" }}
            onEnd={() => {
              setUrlResp(signResp.getTrimmedCanvas().toDataURL("image/png"));
            }}
          />
        </div>
        <br></br>
        <ButtonContainer>

          <ClearButton onClick={() => limparAssinatura(signResp, setUrlResp)}>
            Limpar
          </ClearButton>
        </ButtonContainer>
        <label></label> <label></label> <label></label> <label></label>
        <label></label> <label></label>
        <ButtonContainerSubmit>

          <SubmitButton onClick={handleCadastro} type="submit">
            Cadastrar
          </SubmitButton>
        </ButtonContainerSubmit>
        <CenteredSnackbar
          open={openSnackbar}
          autoHideDuration={null}
          onClose={handleCloseSnackbar}
        >
          <LargeAlert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
            {snackbarMessage}
            <SnackbarButton onClick={() => history("/portaria/analise-transp/consulta")}>
              Ir para consultas
            </SnackbarButton>
          </LargeAlert>
        </CenteredSnackbar>
      </FormContainer>
    </div >
  );
};
export default Cadastro;
