import React, { useState, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Axios } from "../../config/axiosConf";
import styled from "@emotion/styled";
import { useMediaQuery } from "@mui/material";

const Vendedores = ({ vendedorSelecionado, setVendedorSelecionado }) => {
  const [vendedores, setVendedores] = useState([]);
  const isSmallScreen = useMediaQuery("(max-width: 550px)");

  const StyledAutocomplete = styled(Autocomplete)`
    width: ${isSmallScreen ? "270px" : "380px"};
    padding-left: 25px;
    .MuiButtonBase-root {
      width: 35px;
    }
    .MuiInputBase-root {
      padding-top: 4px;
      padding-bottom: 4px;
    }
    .MuiInputBase-input {
      font-size: 15px;
    }
  `;

  const StyledTextField = styled(TextField)`
    width: ${isSmallScreen ? "100%" : "380px"};
  `;

  useEffect(() => {
    const getVendedores = async () => {
      try {
        const response = await Axios.get(
          "OSCtP4F8cdKrZhU9lYI86Q"
        );
        setVendedores(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    getVendedores();
  }, []);

  const enviarVendedorSelecionado = (event, newValue) => {
    if (!newValue) {
      // Se o novo valor for nulo (limpar seleção), defina como null
      setVendedorSelecionado(null);
    } else {
      setVendedorSelecionado(newValue);
    }
  };

  return (
    <div style={{ display: "flex" }}>
      <div style={{ paddingTop: 15, fontWeight: "bold" }}>
        <p>Vendedor:</p>
        <StyledAutocomplete
          freeSolo={false}
          options={vendedores}
          value={vendedorSelecionado}
          onChange={enviarVendedorSelecionado}
          renderInput={(params) => <StyledTextField {...params} label="" />}
        />
      </div>
    </div>
  );
};

export default Vendedores;
