import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import React from "react";
import { styled, Paper } from "@mui/material";

const Container = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: "#F5F5F5",
  boxShadow: "0px 0px 0px 0px rgba(0,0,0,0.2),0px 0px 0px 0px rgba(0,0,0,0.14),0px 0px 0px 0px rgba(0,0,0,0.12)",
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
}));

const CheckboxesEstBE = ({
  estoqueMinimo,
  importados,
  nacionais,
  controlePF,
  programado,
  trocaNF,
  controlePC,
  controleEB,
  setEstoqueMinimo,
  setImportados,
  setNacionais,
  setControlePF,
  setProgramado,
  setTrocaNF,
  setControlePC,
  setControleEB
}) => {
  return (
    <Container>
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      <div style={{ width: "50%" }}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={estoqueMinimo === "S"}
                onChange={() => setEstoqueMinimo(estoqueMinimo === "S" ? null : "S")}
              />
            }
            label="Estoque Mínimo"
          />
        </FormGroup>

        <label></label>

        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={importados === "S"}
                onChange={() => setImportados(importados === "S" ? null : "S")}
              />
            }
            label="Somente Importados"
          />
        </FormGroup>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={nacionais === "S"}
                onChange={() => setNacionais(nacionais === "S" ? null : "S")}
              />
            }
            label="Somente Nacionais"
          />
        </FormGroup>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={controlePF === "S"}
                onChange={() => setControlePF(controlePF === "S" ? null : "S")}
              />
            }
            label="Controlados Pela PF"
          />
        </FormGroup>
      </div>
      <div style={{ width: "50%" }}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={programado === "S"}
                onChange={() => setProgramado(programado === "S" ? null : "S")}
              />
            }
            label="Considerar Programação"
          />
        </FormGroup>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={trocaNF === "S"}
                onChange={() => setTrocaNF(trocaNF === "S" ? null : "S")}
              />
            }
            label="Considerar Troca de NF (Programação)"
          />
        </FormGroup>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={controlePC === "S"}
                onChange={() => setControlePC(controlePC === "S" ? null : "S")}
              />
            }
            label="Controlados Pela PC"
          />
        </FormGroup>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={controleEB === "S"}
                onChange={() => setControleEB(controleEB === "S" ? null : "S")}
              />
            }
            label="Controlados Pelo EB"
          />
        </FormGroup>
      </div>
    </div>
    </Container>
  );
};

export default CheckboxesEstBE;