import React, { useState, useEffect, useCallback } from "react";
import { TextField, Autocomplete, RadioGroup, FormControlLabel, Radio, useMediaQuery } from "@mui/material/";
import { Axios } from "../../config/axiosConf";
import styled from "@emotion/styled";


const Empresas = ({ empresaSelecionada, setEmpresaSelecionada, tipo, setTipoSelecionado }) => {

    const [clientes, setClientes] = useState([]);
    const [fornecedores, setFornecedores] = useState([]);
    const [transportadoras, setTransportadoras] = useState([]);
    const isSmallScreen = useMediaQuery("(max-width: 550px)");

    const StyledAutocomplete = styled(Autocomplete)`
        width: ${isSmallScreen ? "270px" : "380px"};
        padding-left: 25px;
        .MuiButtonBase-root {
        width: 35px;
        }
        .MuiInputBase-input {
        font-size: 15px;
        }
    `;

    const StyledTextField = styled(TextField)`
        width: ${isSmallScreen ? "100%" : "380px"};
    `;

    useEffect(() => {
        const getClientes = async () => {
            try {
                const response = await Axios.get("3MNj5Y1x3oxTG5bxoK8ioA");
                setClientes(response.data);
            } catch (error) {
                console.log(error);
            }
        };
        getClientes();
    }, []);

    useEffect(() => {
        const getFornecedores = async () => {
            try {
                const response = await Axios.get("Q_vZ5fOesyjDckCVbrSlDA");
                setFornecedores(response.data);
            } catch (error) {
                console.log(error);
            }
        };
        getFornecedores();
    }, []);

    useEffect(() => {
        const getTransportadoras = async () => {
            try {
                const response = await Axios.get("ZvpzLdYsrU02Mwzc9obi_Q");
                setTransportadoras(response.data);
            } catch (error) {
                console.log(error);
            }
        };
        getTransportadoras();
    }, []);

    const enviarEmpresaEscolhida = useCallback((event, newValue) => {
        setEmpresaSelecionada(newValue);
    }, [setEmpresaSelecionada]);

    const trocarDeTipo = useCallback((event) => {
        setTipoSelecionado(event.target.value);
        setEmpresaSelecionada("");
    }, [setTipoSelecionado, setEmpresaSelecionada]);



    return (
        <div style={{ display: "flex" }}>
            <div style={{ paddingTop: 15, fontWeight: "bold" }}>
                <p>Empresa:</p>
                    <StyledAutocomplete
                        id={tipo === "F" ? "F" : tipo === "C" ? "C" : "T"}
                        freeSolo={false}
                        options={tipo === "F" ? fornecedores : tipo === "C" ? clientes : transportadoras}
                        value={empresaSelecionada}
                        onChange={enviarEmpresaEscolhida}
                        renderInput={(params) => (
                            <StyledTextField
                                {...params}
                                id={tipo === "F" ? "fornecedor" : tipo === "C" ? "cliente" : "transportador"}
                                name={tipo === "F" ? "fornecedor" : tipo === "C" ? "cliente" : "transpotador"}
                                label={`Escolha um ${tipo === "F" ? "fornecedor" : tipo === "C" ? "cliente" : "transportador"}`}
                                required
                            />
                        )}
                        isSmallScreen={isSmallScreen}
                        isOptionEqualToValue={(option, value) => option === value}
                    />
                    <br></br>
                    <RadioGroup
                        id="tipo"
                        required
                        aria-label="userType"
                        name="userType"
                        value={tipo}
                        onChange={trocarDeTipo}
                        style={{ display: "flex", flexDirection: "row" }}
                    >
                        <FormControlLabel value="C" control={<Radio />} label="Cliente" />
                        <FormControlLabel value="F" control={<Radio />} label="Fornecedor" />
                        <FormControlLabel value="T" control={<Radio />} label="Transportador" />
                    </RadioGroup>
                </div>
            </div>
    );

};

export default Empresas;