import React, { useState, useEffect } from "react";
import { TextField, Autocomplete } from "@mui/material/";
import { Axios } from "../../config/axiosConf";
import styled from "@emotion/styled";

const StyledH2 = styled.h2`
  display: block;
  font-size: 1em;
  font-weight: bold;
  padding-left: 15px;
`;

const StyledTextField = styled(TextField)`
  width: 380px;
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 500px;
`;

const StyledAutocomplete = styled(Autocomplete)`
  width: ${({ isSmallScreen }) => (isSmallScreen ? "100%" : "380px")};

  .MuiButtonBase-root {
    width: 40px;
  }
`;

const VeiculosPortaria = ({
  placaVeiculo,
  setPlacaVeiculo,
  numeroEixosVeiculo,
  setNumeroEixosVeiculo
}) => {
  const [carros, setCarros] = useState([]);
  const [isSmallScreen, setIsSmallScreen] = useState(
    window.matchMedia("(max-width: 750px)").matches
  );

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.matchMedia("(max-width: 750px)").matches);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const getCarros = async () => {
      try {
        const response = await Axios.get("a60h7H-yK3gww2uXs3chAg");
        setCarros(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    getCarros();
  }, []);

  useEffect(() => {
    const selectedPlaca = carros.find((carro) => carro.placa === placaVeiculo);
    if (selectedPlaca) {
      setNumeroEixosVeiculo(selectedPlaca.eixos);
    }
  }, [placaVeiculo, setNumeroEixosVeiculo, carros]);

  const enviarPlacaVeiculo = (event, newValue) => {
    setPlacaVeiculo(newValue);
  };

  const enviarNumeroDeEixosVeiculo = (event) => {
    setNumeroEixosVeiculo(event.target.value);
  };

  const handlePlacaChange = (event) => {
    const { value } = event.target;
    const sanitizedValue = value.replace(/[^a-zA-Z0-9]/g, "").substring(0, 7);
    setPlacaVeiculo(sanitizedValue);
  };

  return (
    <>
      <StyledH2>1.5 - Veículo (tração):</StyledH2>
      <Label id="labelPlacaVeiculo">
        <StyledAutocomplete
          id="placaVeiculo"
          freeSolo
          options={carros.map((carro) => carro.placa)}
          value={placaVeiculo}
          onChange={(event, newValue) => enviarPlacaVeiculo(event, newValue)}
          onBlur={(event) => setPlacaVeiculo(event.target.value)}
          renderInput={(params) => (
            <StyledTextField
              {...params}
              id="text-placaVeiculo"
              label="Digite a placa do veículo"
              required
              inputProps={{
                ...params.inputProps,
                maxLength: 7,
                onChange: handlePlacaChange
              }}
            />
          )}
          isSmallScreen={isSmallScreen}
          isOptionEqualToValue={(option, value) => option === value}
        />
      </Label>

      <Label id="labelEixosVeiculo">
        <TextField
          id="eixosVeiculo"
          name="eixosVeiculo"
          placeholder="Digite a quantidade de eixos do veículo..."
          required
          value={numeroEixosVeiculo}
          sx={{
            width: 300
          }}
          onChange={enviarNumeroDeEixosVeiculo}
          multiline
        />
      </Label>
    </>
  );
};

export default VeiculosPortaria;
