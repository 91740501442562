import React, { useState, useEffect, useCallback } from "react";
import { TextField, Autocomplete, RadioGroup, FormControlLabel, Radio } from "@mui/material/";
import { Axios } from "../../config/axiosConf";
import styled from "@emotion/styled";

const StyledH2 = styled.h2`
    display: block;
    font-size: 1em;
    font-weight: bold;
    padding-left: 15px;
`;

const StyledTextField = styled(TextField)`
  width: 380px;
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 500px;
`;

const FornecedoresPortaria = ({ fornecedorSelecionado, setFornecedorSelecionado, tipo, setTipoSelecionado }) => {

    const [clientes, setClientes] = useState([]);
    const [fornecedores, setFornecedores] = useState([]);
    const [isSmallScreen, setIsSmallScreen] = useState(window.matchMedia("(max-width: 750px)").matches);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.matchMedia("(max-width: 750px)").matches);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        const getClientes = async () => {
            try {
                const response = await Axios.get("3MNj5Y1x3oxTG5bxoK8ioA");
                setClientes(response.data);
            } catch (error) {
                console.log(error);
            }
        };
        getClientes();
    }, []);

    useEffect(() => {
        const getFornecedores = async () => {
            try {
                const response = await Axios.get("Q_vZ5fOesyjDckCVbrSlDA");
                setFornecedores(response.data);
            } catch (error) {
                console.log(error);
            }
        };
        getFornecedores();
    }, []);

    const enviarFornecedorEscolhido = useCallback((event, newValue) => {
        setFornecedorSelecionado(newValue);
    }, [setFornecedorSelecionado]);

    const trocarDeTipo = useCallback((event) => {
        setTipoSelecionado(event.target.value);
        setFornecedorSelecionado("");
    }, [setTipoSelecionado, setFornecedorSelecionado]);



    return (
        <>
            <StyledH2>1.1 - Cliente/Fornecedor:</StyledH2>
            <Label id="labelFornecedor">
                <Autocomplete
                    id={tipo === "F" ? "F" : "C"}
                    freeSolo={false}
                    options={tipo === "F" ? fornecedores : clientes}
                    value={fornecedorSelecionado}
                    onChange={enviarFornecedorEscolhido}
                    renderInput={(params) => (
                        <StyledTextField
                            {...params}
                            id={tipo === "F" ? "fornecedor" : "cliente"}
                            name={tipo === "F" ? "fornecedor" : "cliente"}
                            label={`Escolha um ${tipo === "F" ? "fornecedor" : "cliente"}`}
                            required
                        />
                    )}
                    isSmallScreen={isSmallScreen}
                    isOptionEqualToValue={(option, value) => option === value}
                />
                <RadioGroup
                    id="tipo"
                    required
                    aria-label="userType"
                    name="userType"
                    value={tipo}
                    onChange={trocarDeTipo}
                >
                    <FormControlLabel value="C" control={<Radio />} label="Cliente" />
                    <FormControlLabel value="F" control={<Radio />} label="Fornecedor" />
                </RadioGroup>
            </Label>
        </>
    );

};

export default FornecedoresPortaria;