import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import React from "react";
import { styled, Paper } from "@mui/material";


const Container = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: "#F5F5F5",
  boxShadow: "0px 0px 0px 0px rgba(0,0,0,0.2),0px 0px 0px 0px rgba(0,0,0,0.14),0px 0px 0px 0px rgba(0,0,0,0.12)",
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
}));

const CheckboxesAnalisResult = ({
  FMT,
  Cesbra,
  Farelo,
  Soja,
  Oleo,
  Milho,
  prod_Cesbra,
  Sumatex, 
  Rep,
  Cor,
  setFMT,
  setCesbra,
  setFarelo,
  setSoja,
  setOleo,
  setMilho,
  setProdCesbra,
  setSumatex,
  setRep,
  setCor,
}) => {
  return (
    <Container>
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      <div style={{ width: "40%" }}>
      
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={FMT === "S"}
                onChange={() => setFMT(FMT === "S" ? null : "S")}
              />
            }
            label="Retirar⠀FMT"
          />
        </FormGroup>

        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={Cesbra === "S"}
                onChange={() => setCesbra(Cesbra === "S" ? null : "S")}
              />
            }
            label="Retirar⠀Cesbra"
          />
        </FormGroup>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={Farelo === "S"}
                onChange={() => setFarelo(Farelo === "S" ? null : "S")}
              />
            }
            label="Retirar⠀Farelo"
          />
        </FormGroup>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={Soja === "S"}
                onChange={() => setSoja(Soja === "S" ? null : "S")}
              />
            }
            label="Retirar⠀Soja"
          />
        </FormGroup>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={Oleo === "S"}
                onChange={() => setOleo(Oleo === "S" ? null : "S")}
              />
            }
            label="Retirar⠀Óleo"
          />
        </FormGroup>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={Milho === "S"}
                onChange={() => setMilho(Milho === "S" ? null : "S")}
              />
            }
            label="Retirar⠀Milho"
          />
        </FormGroup>
        </div>
        <div style={{ width: "50%" }}>
        
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={prod_Cesbra === "S"}
                onChange={() => setProdCesbra(prod_Cesbra === "S" ? null : "S")}
              />
            }
            label="Retirar Produtos Cesbra"
          />
        </FormGroup>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={Sumatex === "S"}
                onChange={() => setSumatex(Sumatex === "S" ? null : "S")}
              />
            }
            label="Retirar Sumatex"
          />
        </FormGroup>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={Rep === "S"}
                onChange={() => setRep(Rep === "S" ? null : "S")}
              />
            }
            label="Retirar⠀Rep"
          />
        </FormGroup>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={Cor === "S"}
                onChange={() => setCor(Cor === "S" ? null : "S")}
              />
            }
            label="Retirar⠀Corantes"
          />
        </FormGroup>
      </div>
    </div>
    </Container>
  );
};

export default CheckboxesAnalisResult;
