import React, { useState } from 'react';
import { Axios } from '../../config/axiosConf';
import { TextField, Button, Typography, Box, useMediaQuery, InputAdornment, IconButton } from '@mui/material';
import styled from "@emotion/styled";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useNavigate } from 'react-router-dom';
// import { useNotifications } from '../../context/NotificationsContext';

const StyledTitle = styled(Typography)`
  font-weight: bold;
  font-size: 1.5em;
  margin: 0em 0;
  background-color: #2957a4;
  color: #fff;
  padding: 10px;
  text-align: center;
  @media (max-width: 550px) {
    font-size: 1.2em;
  }
`;

const StyledOrange = styled(Typography)`
  background-color: #d66e20;
  padding-top: 3px;
  padding-bottom: 3px;
  @media (max-width: 550px) {
    font-size: 1.2em;
  }
`;

const StyledGreen = styled(Typography)`
  background-color: #7ca217;
  padding-top: 3px;
  padding-bottom: 3px;
  @media (max-width: 550px) {
    font-size: 1.2em;
  }
`;

const User = () => {
    // const { addNotification } = useNotifications();
    const [senhaAntiga, setSenhaAntiga] = useState('');
    const [senhaNova, setSenhaNova] = useState('');
    const [senhaConfirmacao, setSenhaConfirmacao] = useState('');
    const [showSenhaAntiga, setShowSenhaAntiga] = useState(false);
    const [showSenhaNova, setShowSenhaNova] = useState(false);
    const [showSenhaConfirmacao, setShowSenhaConfirmacao] = useState(false);
    const [message, setMessage] = useState('');
    const usuario = sessionStorage.getItem("user");
    const isSmallScreen = useMediaQuery('(max-width:550px)');
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const history = useNavigate();

    const handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackbar(false);
    };

    const showSnackbar = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setOpenSnackbar(true);
    };

    const handleChangePassword = async () => {
        if (senhaNova !== senhaConfirmacao) {
            setMessage('A nova senha e a confirmação da senha não coincidem.');
            showSnackbar(message, "error");
            return;
        }

        try {
            const response = await Axios.post('zwJ5IZuQkP9EmUjQ4tZXrg', {
                prm_nome: usuario,
                prm_senha_antiga: senhaAntiga,
                prm_senha_nova: senhaNova,
            });

            const msgErro = response.data.msg_erro.trim();
            if (msgErro.toLowerCase() === 'senha antiga incorreta.') {
                showSnackbar(msgErro, "error");
            } else {
                setMessage(msgErro);
                showSnackbar(msgErro, "success");
                // addNotification({ message: msgErro, type: "success" });
                setTimeout(() => {
                    history("/home");
                }, 2000);

            }
        } catch (error) {
            setMessage('Erro ao alterar a senha. Tente novamente mais tarde.');
            showSnackbar(message, "error");
        }
    };

    return (
        <div>
            <div>
                <StyledOrange />
                <StyledTitle id="title">Alterar Senha</StyledTitle>
                <StyledGreen />
            </div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "75vh",
                }}
            >
                <Box
                    component="form"
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: isSmallScreen ? "95%" : "400px",
                        padding: "16px",
                        marginTop: isSmallScreen ? "20px" : "30px",
                        border: "1px solid #ccc",
                        borderRadius: "8px",
                        background: "#fff",
                        boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                    }}
                >
                    <TextField
                        label="Nome de Usuário"
                        fullWidth
                        value={usuario}
                        InputProps={{
                            readOnly: true,
                        }}
                        margin="none"
                    />
                    <TextField
                        label="Senha Atual"
                        type={showSenhaAntiga ? 'text' : 'password'}
                        fullWidth
                        value={senhaAntiga}
                        onChange={(e) => setSenhaAntiga(e.target.value)}
                        onCopy={(e) => e.preventDefault()}
                        onPaste={(e) => e.preventDefault()}
                        margin="normal"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowSenhaAntiga(!showSenhaAntiga)}
                                    >
                                        {showSenhaAntiga ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <TextField
                        label="Nova Senha"
                        type={showSenhaNova ? 'text' : 'password'}
                        fullWidth
                        value={senhaNova}
                        onChange={(e) => setSenhaNova(e.target.value)}
                        onCopy={(e) => e.preventDefault()}
                        onPaste={(e) => e.preventDefault()}
                        margin="normal"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowSenhaNova(!showSenhaNova)}
                                    >
                                        {showSenhaNova ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <TextField
                        label="Confirmação de Senha"
                        type={showSenhaConfirmacao ? 'text' : 'password'}
                        fullWidth
                        value={senhaConfirmacao}
                        onChange={(e) => setSenhaConfirmacao(e.target.value)}
                        onCopy={(e) => e.preventDefault()}
                        onPaste={(e) => e.preventDefault()}
                        margin="normal"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowSenhaConfirmacao(!showSenhaConfirmacao)}
                                    >
                                        {showSenhaConfirmacao ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={handleChangePassword}
                        sx={{ mt: 2, marginBottom: "0px" }}
                    >
                        Concluir
                    </Button>
                </Box>
                <Snackbar
                    open={openSnackbar}
                    autoHideDuration={2000}
                    onClose={handleCloseSnackbar}
                >
                    <MuiAlert
                        elevation={6}
                        variant="filled"
                        onClose={handleCloseSnackbar}
                        severity={snackbarSeverity}
                    >
                        {snackbarMessage}
                    </MuiAlert>
                </Snackbar>
            </div>
        </div>
    );
};

export default User;
