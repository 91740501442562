import React, { useState, useEffect, useCallback } from "react";
import { RadioGroup, FormControlLabel, Radio } from "@mui/material/";
import "../DataPickers/Data.css";

const RadioTipoProduto = ({ tipoProduto, setTipoProdutoSelecionado }) => {
    const [isSmallScreen, setIsSmallScreen] = useState(window.matchMedia("(max-width: 750px)").matches);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.matchMedia("(max-width: 750px)").matches);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const trocarDeTipo = useCallback((event) => {
        setTipoProdutoSelecionado(event.target.value);
    }, [setTipoProdutoSelecionado]);

    return (
        <>
            
                <div className="date-input">
                    <label className="a">Tipo de Produto:</label>   
                    </div>
                    <RadioGroup
                        id="tipoProduto"
                        required
                        aria-label="userType"
                        name="userType"
                        value={tipoProduto}
                        onChange={trocarDeTipo}
                        row={!isSmallScreen}
                    >
                        <FormControlLabel value="P" control={<Radio />} label="Código de Produto" />
                        <FormControlLabel value="E" control={<Radio />} label="Baixa de Estoque" />
                        <FormControlLabel value="C" control={<Radio />} label="Baixa Comercial" />
                    </RadioGroup>
        </>
    );

};

export default RadioTipoProduto;
