import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Axios } from "../../../config/axiosConf";
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { DataGrid } from "@mui/x-data-grid";
import { ptBR } from '@mui/x-data-grid/locales';
import Clientes from "../../../components/AutoCompletes/Clientes";
import Fornecedores from "../../../components/AutoCompletes/Fornecedores";
import Produtos from "../../../components/AutoCompletes/Produtos";
import Vendedores from "../../../components/AutoCompletes/Vendedores";
import Moeda from "../../../components/Selects/Moeda";
import Unidade from "../../../components/Selects/Unidade";
import CheckboxesAnalise from "../../../components/Checkboxes/CheckboxesAnalise";
import CheckboxTodas from "../../../components/Checkboxes/CheckboxTodas";
import styled from "@emotion/styled";
import { styled as Estilo, darken, lighten } from "@mui/material/styles";
import Modal from "react-modal";
import {
  Typography,
  Button,
  Table,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  Tab,
  Tabs,
  useMediaQuery,
} from "@mui/material";
import {
  AiOutlineSearch,
} from "react-icons/ai";
import { RiEyeLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import Data from "../../../components/DataPickers/Data";
import "../../../styles/ConsultaGeralVendas.css";
import "./Cascata.css";
import logo from "../../../assets/Logo-Grupo-Sumatex.png";
import logo_sumatex from "../../../assets/logo.png";
import logo_ambiental from "../../../assets/Logo-Sumatex-Ambiental.png";
import logo_agro from "../../../assets/Logo-Sumatex-Agro.png";
import logo_lorenvel from "../../../assets/Logo-Lorenvel.png";
import logo_cesbra from "../../../assets/Logo-Cesbra.png";


const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  width: 90%;
  gap: 16px;
  padding: 24px;
  background-color: #f5f5f5;
  border-radius: 8px;
  height: 100%;
  margin: 50px;

  @media (max-width: 550px) {
    width: 100%;
    margin: 0px;
  }
`;

const StyledTableCellModal = Estilo(TableCell)`
  font-weight: bold;
  background-color: #f2f2f2;
  font-size: 0.875em;
  padding-bottom: 5px;
`;

const StyledTableCellModal2 = Estilo(TableCell)`
  font-weight: bold;
  font-size: 0.775em;
  padding-bottom: 5px;
`;

const StyledTitle = styled(Typography)`
  font-weight: bold;
  font-size: 1.5em;
  margin: 0em 0;
  background-color: #2957a4;
  color: #fff;
  padding: 10px;
  text-align: center;
  @media (max-width: 550px) {
    font-size: 1.2em;
  }
`;

const StyledOrange = styled(Typography)`
    background-color: #D66E20;
    padding-top: 3px;
    padding-bottom: 3px;
  @media (max-width: 550px) {
    font-size: 1.2em;
  }
`;

const StyledGreen = styled(Typography)`
    background-color: #7CA217;
    padding-top: 3px;
    padding-bottom: 3px;
  @media (max-width: 550px) {
    font-size: 1.2em;
  }
`;

const StyledLink = styled(Link)`
  word-wrap: break-word;
  color: #2957a4;
  margin-right: 5px;
  &:hover {
    text-decoration: underline;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 16px;
  margin-left: 25px;
`;
const ResultadosTitle = styled.h2`
  margin: 0;
  font-size: 2em;
  color: #1f437d;
  text-align: center;
  text-transform: uppercase;
  padding: 10px;
  border-radius: 10px;
  @media (max-width: 550px) {
    width: 80px;
    font-size: 1.5em;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: flex-end;
  margin-top: 20px;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  @media (max-width: 550px) {
    justify-content: space-between;
  }
`;

const SaveButton = styled(Button)`
  height: 30px;
  width: 150px;
  color: #fff;
  background-color: #2957a4;
  border: 1px solid rgba(0, 0, 0, 0.2);
  &:hover {
    background-color: #1f437d;
  }
`;

const LimparButton = styled(Button)`
  height: 30px;
  width: 100px;
  color: #fff;
  background-color: #ff6347;
  border: 1px solid rgba(0, 0, 0, 0.2);
  &:hover {
    background-color: #ff0000;
  }
  @media (max-width: 550px) {
    width: 80px;
    font-size: 0.8em;
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const ImprimirButton = styled(Button)`
  height: 30px;
  padding-left: 50px;
  padding-right: 50px;
  width: 120px;
  color: #fff;
  background-color: #a0a0a0;
  border: 1px solid rgba(0, 0, 0, 0.2);
  &:hover {
    background-color: #808080;
  }

  @media (max-width: 550px) {
    width: 80px;
    font-size: 0.8em;
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const XlsButton = styled(Button)`
  height: 30px;
  width: 120px;
  padding-left: 50px;
  padding-right: 50px;
  color: #fff;
  background-color: #71bc78;
  border: 1px solid rgba(0, 0, 0, 0.2);
  &:hover {
    background-color: #569b5c;
  }
  @media (max-width: 550px) {
    width: 80px;
    font-size: 0.8em;
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const ResultadosContainer = styled.div`
  margin-top: 20px;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  @media (max-width: 550px) {
    
    padding: 10px; 
  }
`;

const MobileButton = styled(Button)`
  height: 30px;
  width: 150px;
  color: #fff;
  background-color: #2957a4;
  border: 1px solid rgba(0, 0, 0, 0.2);
  display: none;
  &:hover {
    background-color: #1f437d;
  }

  @media (max-width: 360px) {
    display: block;
  }
`;

const getBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.7) : lighten(color, 0.7);

const getHoverBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

const getSelectedBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.5) : lighten(color, 0.5);

const getSelectedHoverBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.4) : lighten(color, 0.4);

const StyledDataGrid = Estilo(DataGrid)(({ theme }) => ({
  "& .grid-azul": {
    backgroundColor: getBackgroundColor(
      theme.palette.info.main,
      theme.palette.mode
    ),
    "&:hover": {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.info.main,
        theme.palette.mode
      ),
    },
    "&.Mui-selected": {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.info.main,
        theme.palette.mode
      ),
      "&:hover": {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.info.main,
          theme.palette.mode
        ),
      },
    },
  },
  "& .grid-vermelho": {
    backgroundColor: getBackgroundColor(
      theme.palette.error.main,
      theme.palette.mode
    ),
    "&:hover": {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.error.main,
        theme.palette.mode
      ),
    },
    "&.Mui-selected": {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.error.main,
        theme.palette.mode
      ),
      "&:hover": {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.error.main,
          theme.palette.mode
        ),
      },
    },
  },
  "& .grid-amarelo": {
    backgroundColor: getBackgroundColor(
      theme.palette.warning.main,
      theme.palette.mode
    ),
    "&:hover": {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.warning.main,
        theme.palette.mode
      ),
    },
    "&.Mui-selected": {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.warning.main,
        theme.palette.mode
      ),
      "&:hover": {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.warning.main,
          theme.palette.mode
        ),
      },
    },
  },
}));

const Cascata = () => {
  const isSmallScreen = useMediaQuery("(max-width: 550px)");
  const [blurBackground, setBlurBackground] = useState(false);
  const [formattedDataInicial, setFormattedDataInicial] = useState("");
  const [formattedDataFinal, setFormattedDataFinal] = useState("");
  const Filial = sessionStorage.getItem("empresa");
  const [todas, setTodas] = useState("");
  const [clienteSelecionado, setClienteSelecionado] = useState(null);
  const [fornecedorSelecionado, setFornecedorSelecionado] = useState(null);
  const [produtoSelecionado, setProdutoSelecionado] = useState(null);
  const [vendedorSelecionado, setVendedorSelecionado] = useState(null);
  const [moedaSelecionada, setMoedaSelecionada] = useState("000001");
  const [unidadeSelecionada, setUnidadeSelecionada] = useState("KG");
  const [Comercial, setComercial] = useState(null);
  const [Comercial_Novo, setComercialNovo] = useState(null);
  const [Corantes, setCorantes] = useState("S");
  const [Colaboradores, setColaboradores] = useState(null);
  const [Corporativos, setCorporativos] = useState(null);
  const [Faturados, setFaturados] = useState("S");
  const [RentB, setRentB] = useState(null);
  const [Cesbra_Schwietzer, setCesbraSchwietzer] = useState("S");
  const [Farelo, setFarelo] = useState("S");
  const [Soja, setSoja] = useState("S");
  const [Milho, setMilho] = useState("S");
  const [Oleo, setOleo] = useState("S");
  const [prod_Cesbra, setProdCesbra] = useState("S");
  const [baixa_Comercial, setBaixaComercial] = useState("S");
  const [baixa_Estoque, setBaixaEstoque] = useState(null);
  const [Sumatex, setSumatex] = useState("S");
  const [Rep, setRep] = useState("S");
  const [Fat, setFat] = useState("S");
  const [Ent, setEnt] = useState("S");
  const [resultados, setResultados] = useState([]);
  // eslint-disable-next-line
  const [mostrarResultados, setMostrarResultados] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [consultaSelecionada, setConsultaSelecionada] = useState("relatorio");
  const [dados, setDados] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalKey, setModalKey] = useState(0);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);

  const grupo = sessionStorage.getItem("grupo");
  const grupoGerente = ["8f498e6aabb107de59d0ad2177f6d882"]; 

  const isUserInGroupGerente = grupoGerente.includes(grupo);

  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({
    id: false,
  });

  const [filterModel, setFilterModel] = React.useState({
    items: [
    ],
  });

  const applyFilters = (rows, filterModel) => {
    return rows.filter((row) => {
      return filterModel.items.every((filter) => {
        const { field, operator, value } = filter;
        const rowValue = row[field];
  
        // Convertendo rowValue e value para string para comparações de texto
        const rowValueStr = String(rowValue).toLowerCase();
        const valueStr = String(value).toLowerCase();
  
        switch (operator) {
          case '>':
            return rowValue > value;
          case '>=':
            return rowValue >= value;
          case '<':
            return rowValue < value;
          case '<=':
            return rowValue <= value;
          case 'equals':
          case '=': // Igualdade numérica ou textual
            return rowValueStr === valueStr;
          case '!=': // Diferença numérica ou textual
            return rowValue !== value;
          case 'contains': // Contém
            return rowValueStr.includes(valueStr);
          case 'startsWith': // Começa com
            return rowValueStr.startsWith(valueStr);
          case 'endsWith': // Termina com
            return rowValueStr.endsWith(valueStr);
          default:
            return true; // Se o operador não for suportado, não filtra.
        }
      });
    });
  };

  const navigate = useNavigate();

  const openModal = () => {
    if (!modalIsOpen) {
      
      setModalIsOpen(true);
      setBlurBackground(true);
      setModalKey((prevKey) => prevKey + 1);
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setBlurBackground(false);
    navigate("/consultas/analise-de-vendas/");
    
  };

  const customComparator = (v1, v2) => {
    const value1 = parseFloat(v1.replace(",", "."));
    const value2 = parseFloat(v2.replace(",", "."));

    if (value1 < value2) {
      return -1;
    }
    if (value1 > value2) {
      return 1;
    }
    return 0;
  };

  const customDateComparator = (dateStr1, dateStr2) => {
    const parts1 = dateStr1.split("/");
    const parts2 = dateStr2.split("/");
    const date1 = new Date(
      Number(parts1[2]),
      Number(parts1[1]) - 1,
      Number(parts1[0])
    );
    const date2 = new Date(
      Number(parts2[2]),
      Number(parts2[1]) - 1,
      Number(parts2[0])
    );

    
    if (date1 < date2) {
      return -1;
    }
    if (date1 > date2) {
      return 1;
    }
    return 0;
  };

  const handleLimparResultados = () => {
    setResultados([]);
    setColumns([]);
    setRows([]);
    setMostrarResultados(false);

  };

  const handleConsultaChange = (event, newValue) => {
    setConsultaSelecionada(newValue);
  };

  const calcularQuantidadeTotal = () => {
    const quantidadeTotal = applyFilters(rows, filterModel).reduce(
      (total, item) => total + (item.QUANTIDADE || 0),
      0
    );
    return formatarValor(quantidadeTotal);
  };

  const calcularBrutoTotal = () => {
    const brutoTotal = applyFilters(rows, filterModel).reduce((total, item) => {
      const valorUnitarioVenda = item.VALOR_UNITARIO_VENDA || 0;
      const quantidade = item.QUANTIDADE || 0;
      return total + valorUnitarioVenda * quantidade;
    }, 0);

    return brutoTotal;
  };
  const calcularLiquidoTotalDeCompra = () => {
    const liquidoTotalDeCompra = applyFilters(rows, filterModel).reduce((total, item) => {
      const valorDeCompra = item.VALOR_LIQUIDO_COMPRA || 0;
      const quantidade = item.QUANTIDADE || 0;
      return total + valorDeCompra * quantidade;
    }, 0);

    return liquidoTotalDeCompra;
  };

  const calcularLiquidoTotalDeVenda = () => {
    const liquidoTotalDeVenda = applyFilters(rows, filterModel).reduce((total, item) => {
      const valorDeVenda = item.VALOR_LIQUIDO_VENDA || 0;
      const quantidade = item.QUANTIDADE || 0;
      return total + valorDeVenda * quantidade;
    }, 0);

    return formatarValor(liquidoTotalDeVenda);
  };
  const calcularSaldoLiquido = () => {
    const liquidoTotalDeCompra = applyFilters(rows, filterModel).reduce((total, item) => {
      const valorDeCompra = parseFloat(item.VALOR_LIQUIDO_COMPRA) || 0;
      const quantidade = parseFloat(item.QUANTIDADE) || 0;
      return total + valorDeCompra * quantidade;
    }, 0);

    const liquidoTotalDeVenda = applyFilters(rows, filterModel).reduce((total, item) => {
      const valorDeVenda = parseFloat(item.VALOR_LIQUIDO_VENDA) || 0;
      const quantidade = parseFloat(item.QUANTIDADE) || 0;
      return total + valorDeVenda * quantidade;
    }, 0);

    const saldoLiquido = liquidoTotalDeVenda - liquidoTotalDeCompra;

    return saldoLiquido;
  };
  const calcularValorMedioDeCompra = () => {
    const totalValorCompra = applyFilters(rows, filterModel).reduce((total, item) => {
      const valorDeCompra = item.VALOR_LIQUIDO_COMPRA || 0;
      const quantidade = item.QUANTIDADE || 0;
      return total + valorDeCompra * quantidade;
    }, 0);

    const totalQuantidade = applyFilters(rows, filterModel).reduce((total, item) => {
      const quantidade = item.QUANTIDADE || 0;
      return total + quantidade;
    }, 0);

    if (totalQuantidade === 0) {
      return "N/A"; 
    }

    const valorMedioDeCompra = totalValorCompra / totalQuantidade;

    return valorMedioDeCompra;
  };

  const calcularValorMedioDeVenda = () => {
    const totalValorVenda = applyFilters(rows, filterModel).reduce((total, item) => {
      const valorDeVenda = item.VALOR_LIQUIDO_VENDA || 0;
      const quantidade = item.QUANTIDADE || 0;
      return total + valorDeVenda * quantidade;
    }, 0);

    const totalQuantidade = applyFilters(rows, filterModel).reduce((total, item) => {
      const quantidade = item.QUANTIDADE || 0;
      return total + quantidade;
    }, 0);

    if (totalQuantidade === 0) {
      return "N/A"; 
    }

    const valorMedioDeVenda = totalValorVenda / totalQuantidade;

    return valorMedioDeVenda;
  };

  const calcularValorMedioBrutoDeVenda = () => {
    const quantidadeTotal = applyFilters(rows, filterModel).reduce(
      (total, item) => total + (item.QUANTIDADE || 0),
      0
    );

    const brutoTotal = applyFilters(rows, filterModel).reduce((total, item) => {
      const valorUnitarioVenda = item.VALOR_UNITARIO_VENDA || 0;
      const quantidade = item.QUANTIDADE || 0;
      return total + valorUnitarioVenda * quantidade;
    }, 0);

    if (quantidadeTotal === 0) {
      return "N/A";
    }

    const valorMedioBrutoDeVenda = brutoTotal / quantidadeTotal;

    return valorMedioBrutoDeVenda;
  };

  const quantidadeTotal = calcularQuantidadeTotal();
  const brutoTotal = calcularBrutoTotal();
  const liquidoTotalDeCompra = calcularLiquidoTotalDeCompra();
  const liquidoTotalDeVenda = calcularLiquidoTotalDeVenda();
  const saldoLiquido = calcularSaldoLiquido();
  const valorMedioDeCompra = calcularValorMedioDeCompra();
  const valorMedioDeVenda = calcularValorMedioDeVenda();
  const valorMedioBrutoVenda = calcularValorMedioBrutoDeVenda();
  const rentabilidadeMediaLiquidoCompra = (
    (saldoLiquido / liquidoTotalDeCompra) *
    100
  ).toFixed(2);

  const realizarConsulta = async () => {
    let procedure = "";

    switch (consultaSelecionada) {
      case "relatorio":
        procedure = "analise-de-vendas";
        break;
      default:
        procedure = "relatorio";
        break;
    }

    try {
      const data = {
        Data: {
          formattedDataInicial,
          formattedDataFinal,
        },
        Clientes: {
          clienteSelecionado:
            clienteSelecionado === null
              ? null
              : clienteSelecionado.substring(0, 6),
        },

        Fornecedores: {
          fornecedorSelecionado:
            fornecedorSelecionado === null
              ? null
              : fornecedorSelecionado.substring(0, 6),
        },

        Produtos: {
          produtoSelecionado:
            produtoSelecionado === null
              ? null
              : produtoSelecionado.substring(0, 6),
        },

        Vendedores: {
          vendedorSelecionado:
            vendedorSelecionado === null
              ? null
              : vendedorSelecionado.substring(0, 6),
        },
        Moeda: {
          moedaSelecionada,
        },
        Unidade: {
          unidadeSelecionada,
        },
        CheckboxesAnalise: {
          baixa_Comercial,
          baixa_Estoque,
          Faturados,
          Colaboradores,
          Comercial,
          Comercial_Novo,
          Corporativos,
          Cesbra_Schwietzer,
          Farelo,
          Soja,
          Oleo,
          Milho,
          Corantes,
          RentB,
          prod_Cesbra,
          Sumatex,
          Rep,
          Fat,
          Ent,
        },
        Filial,
        CheckboxTodas: {
          todas,
        },
      };

      const config = {
        timeout: 45000,
      };

      setIsLoading(true);

      const response = await Axios.post(
        `consultas/${procedure}`,
        data,
        config
      );

      if (response.data.length > 0) {
        const rows = response.data.map((item, index) => {
          const rowData = {};
          Object.keys(item).forEach((key) => {
            rowData[key] = item[key];
          });
          
          rowData["id"] = index + 1;
          return rowData;
        });

        const columns = Object.keys(response.data[0]).map((key) => ({
          field: key,
          ...(key === "DATA"
            ? {
              headerName: "Data",
              width: 100,
              sortComparator: customDateComparator,
            }
            : {
              ...(key === "NRO_NOTA"
                ? {
                  headerName: "Nota",
                  width: 80,
                }
                : {
                  ...(key === "PEDIDO"
                    ? {
                      headerName: "Pedido",
                      width: 80,
                    }
                    : {
                      ...(key === "CLIENTE"
                        ? {
                          headerName: "Cliente",
                          width: 400,
                        }
                        : {
                          ...(key === "PRODUTO"
                            ? {
                              headerName: "Produto",
                              width: 350,
                            }
                            : {
                              ...(key === "QUANTIDADE"
                                ? {
                                  headerName: "Quantidade (Kg)",
                                  width: 100,
                                }
                                : {
                                  ...(key === "UNIDADE"
                                    ? {
                                      headerName: "Un",
                                      width: 50,
                                    }
                                    : {
                                      ...(key === "MOEDA"
                                        ? {
                                          headerName: "$",
                                          width: 50,
                                        }
                                        : {
                                          ...(key ===
                                            "VALOR_LIQUIDO_COMPRA"
                                            ? {
                                              headerName:
                                                "Liq. Compra (R$)",
                                              width: 120,
                                            }
                                            : {
                                              ...(key ===
                                                "VALOR_LIQUIDO_VENDA"
                                                ? {
                                                  headerName:
                                                    "Liq. Venda (R$)",
                                                  width: 120,
                                                }
                                                : {
                                                  ...(key ===
                                                    "PERCENTUAL"
                                                    ? {
                                                      headerName:
                                                        "Rent (%)",
                                                      width: 100,
                                                    }
                                                    : {
                                                      ...(key ===
                                                        "VALOR_UNITARIO_VENDA"
                                                        ? {
                                                          headerName:
                                                            "Vl. Unitário (R$)",
                                                          width: 120,
                                                        }
                                                        : {
                                                          headerName:
                                                            key,
                                                          width: 200,
                                                        }),
                                                    }),
                                                }),
                                            }),
                                        }),
                                    }),
                                }),
                            }),
                        }),
                    }),
                }),
            }),

          ...(key === "QUANTIDADE" ||
            key === "VALOR_LIQUIDO_COMPRA" ||
            key === "VALOR_LIQUIDO_VENDA" ||
            key === "PERCENTUAL" ||
            key === "VALOR_UNITARIO_VENDA"
            ? {
              valueFormatter: (params) => {
                if (params === null) {
                  return 0
                } else {
                  return params.toLocaleString("pt-BR", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 5,
                  })
                }
                
              },
            }
            : {}),
        }));

        columns.unshift({ field: "id", headerName: "ID", width: 10 });

        columns.unshift({
          field: "actions",
          headerName: "Ações",
          width: 90,
          renderCell: (params) => (
            <StyledLink
              onClick={() => handleLinkClick(params.row)} 
              to={`/consultas/analise-de-vendas/${params.row.PEDIDO
                }/${params.row.PRODUTO.substring(0, 6)}/${params.row.UNIDADE}/${params.row.MOEDA
                }`}
            >
              <RiEyeLine style={{ width: "15px", height: "15px" }} />
            </StyledLink>
          ),
        });

        setRows(rows);
        setColumns(columns);
      }

      setResultados(response.data);
      setMostrarResultados(true);
      setIsLoading(false);
    } catch (error) {
      console.error("Erro ao realizar a consulta:", error);
      setIsLoading(false);
    }
  };

  const handleClick = async (params) => {
    setIsLoading(true);
    try {
      const response = await Axios.get(
        `consultas/analise-de-vendas/${params.PEDIDO
        }/${params.PRODUTO.substring(0, 6)}/${params.UNIDADE}/${params.MOEDA}`
      );
      setDados(response.data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const handleLinkClick = (params) => {
    openModal(params);
    handleClick(params);
  };

  function formatarValor(valor) {
    if (valor === null || valor === undefined) {
      return '';
    }

    if (typeof valor !== "number") {
      return valor;
    }

    return valor.toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }

  function formatarValorMedio(valor) {
    if (typeof valor !== "number") {
      return valor;
    }

    return valor.toLocaleString("pt-BR", {
      minimumFractionDigits: 4,
      maximumFractionDigits: 4,
    });
  }

  function desformatarData(data) {
    if (!data) return null;

    const ano = data.slice(0, 4);
    const mes = data.slice(4, 6);
    const dia = data.slice(6, 8);

    return `${dia}/${mes}/${ano}`;
  }

  const handlePrintResults = () => {
    const Filial = sessionStorage.getItem("empresa");
    const printContent = `
      <html>
        <head>
          <title>S.I.G.A - Grupo Sumatex</title>
          <style>
           
            body { font-family: Arial, sans-serif; margin: 0; padding: 0; }
            h1 {
              text-align: center;
              margin-bottom: 20px;
              font-size: 24px;
            }
            h2 img {
              height: 40px;
              margin-right: 10px;
            }
            table { 
              width: 100%; 
              border-collapse: 
              collapse; 
            }
            th, td { 
              border: 1px solid #000; 
              padding: 8px; 
              text-align: left; 
            }
          </style>
        </head>
        <body>
        <h2>
        ${Filial === "1 - MAT"
        ? `<img src="${logo_sumatex}" alt="Logo">`
        : Filial === "7 - CES"
          ? `<img src="${logo_cesbra}" alt="Logo">`
          : Filial === "2 - AMB"
            ? `<img src="${logo_ambiental}" alt="Logo">`
            : Filial === "9 - LOG"
              ? `<img src="${logo_lorenvel}" alt="Logo">`
              : Filial === "5 - FMT"
                ? `<img src="${logo_agro}" alt="Logo">`
                : `<img src="${logo}" alt="Logo">`
      } </h2>
       <h1>
        Análise de Vendas - Cascata
       </h1> 
     <div>
      <div class="filters">
       <p style="text-align: center;"><strong>Data:</strong> ${desformatarData(
        formattedDataInicial
      )} a ${desformatarData(formattedDataFinal)}</strong></p>
       
       ${produtoSelecionado
        ? `<div key="produto"><p><strong>Produto:</strong> ${produtoSelecionado}</p></div>`
        : ""
      }
       ${fornecedorSelecionado
        ? `<div key="fornecedor"><p><strong>Fornecedor:</strong> ${fornecedorSelecionado}</p></div>`
        : ""
      }
       ${clienteSelecionado
        ? `<div key="cliente"><p><strong>Cliente:</strong> ${clienteSelecionado}</p></div>`
        : ""
      }
       ${vendedorSelecionado
        ? `<div key="vendedor"><p><strong>Vendedor:</strong> ${vendedorSelecionado}</p></div>`
        : ""
      }
       ${moedaSelecionada
        ? `<div key="moeda"><p><strong>Moeda:</strong> ${moedaSelecionada}</p></div>`
        : ""
      }
       ${unidadeSelecionada
        ? `<div key="unidade"><p><strong>Unidade:</strong> ${unidadeSelecionada}</p></div>`
        : ""
      }
      </div>
     </div>
       <table>
       <thead>
         <tr>
           ${columns
        .filter((coluna) => columnVisibilityModel[coluna.field] !== false) 
        .map((coluna) => `<th>${coluna.headerName}</th>`)
        .join("")}
         </tr>
       </thead>
       <tbody>
         ${applyFilters(rows, filterModel)
        .map((item, index) => {
          return `
               <tr ${index % 2 === 0 ? 'style="background-color: #f9f9f9;"' : ""
            }>
                 ${columns
              .filter((coluna) => columnVisibilityModel[coluna.field] !== false)
              .map((coluna) => {
                return `<td>${formatarValor(item[coluna.field])}</td>`;
              })
              .join("")}
               </tr>
             `;
        })
        .join("")}
       </tbody>
     </table>
     <div style="margin-top: 20px; display: grid; grid-template-columns: 1fr 1fr;">
       <div>
         <h2>Dados Consolidados</h2>
         <div class="consolidated-data">
           <div><p><strong>Volume Total Faturado:</strong> ${quantidadeTotal}</p></div>
           <div><p><strong>Valor Bruto Total Faturado:</strong> R$ ${formatarValor(
          brutoTotal
        )}</p></div>
           <div><p><strong>Valor Líq. Total de Compra:</strong> R$ ${formatarValor(
          liquidoTotalDeCompra
        )}</p></div>
           <div><p><strong>Valor Líq. Total de Venda:</strong> R$ ${liquidoTotalDeVenda}</p></div>
           <div><p><strong>Saldo Líquido:</strong> R$ ${formatarValor(
          saldoLiquido
        )}</p></div>
         </div>
       </div>
       <div>
         <h2>Médias e Rentabilidades</h2>
         <div class="consolidated-data">
           <div><p><strong>Valor Líq. Médio de Compra:</strong> R$ ${formatarValorMedio(
          valorMedioDeCompra
        )}</p></div>
           <div><p><strong>Valor Líq. Médio de Venda:</strong> R$ ${formatarValorMedio(
          valorMedioDeVenda
        )}</p></div>
           <div><p><strong>Valor Bruto Médio de Venda:</strong> R$ ${formatarValorMedio(
          valorMedioBrutoVenda
        )}</p></div>
           <div><p><strong>Rentabilidade Média Sobre o Valor Liquido de Compra:</strong> ${rentabilidadeMediaLiquidoCompra}%</p></div>
         </div>
       </div>
     </div>
        </body>
      </html>
      `;

    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;
    const printWindow = window.open(
      "",
      "",
      `width=${screenWidth},height=${screenHeight}`
    );

    if (!printWindow) {
      alert(
        "Erro ao abrir a janela de impressão. Verifique as configurações do seu navegador."
      );
      return;
    }

    printWindow.document.open();
    printWindow.document.write(printContent);
    printWindow.document.close();

    printWindow.onload = () => {
      printWindow.print();

      setTimeout(() => { }, 1000);
    };
  };

  const handleGenerateXLS = async () => {

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Resultados');

    const colunas = columns
      .filter((coluna) => columnVisibilityModel[coluna.field] !== false)
      .map((coluna) => coluna.headerName);

    const headerRow = worksheet.addRow(colunas);

    headerRow.eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'D3D3D3' },
      };
      applyThinBorder(cell); 
      cell.font = { bold: true };
    });

    applyFilters(rows, filterModel).forEach((item) => {
      const rowData = columns
        .filter((coluna) => columnVisibilityModel[coluna.field] !== false)
        .map((coluna) => item[coluna.field]);
      const row = worksheet.addRow(rowData);
      row.eachCell((cell) => {
        applyThinBorder(cell); 
      });
    });

    
    const range = worksheet.getSheetValues();
    for (let R = 1; R <= range.length; ++R) {
      const row = worksheet.getRow(R);
      for (let C = 1; C <= colunas.length; ++C) {
        const cell = row.getCell(C);
        applyThinBorder(cell); 
      }
    }

    const buffer = await workbook.xlsx.writeBuffer();
    const nomeDoArquivo = `resultados_por_analise_de_vendas_${formattedDataInicial}-${formattedDataFinal}.xlsx`;

    saveWorkbook(buffer, nomeDoArquivo);
  };

  
  const applyThinBorder = (cell) => {
    cell.border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };
  };

  
  const saveWorkbook = async (buffer, nomeDoArquivo) => {
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, nomeDoArquivo); 
  };



  return (
    <div className={`content ${blurBackground ? "blur-background" : ""}`}>
      <StyledOrange />
      <StyledTitle>Análise de Vendas</StyledTitle>
      <StyledGreen />

      <FormContainer>
        <div>
          {isLoading && (
            <div
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 9999,
              }}
            >
              <CircularProgress />
            </div>
          )}

          <Data
            formattedDataInicial={formattedDataInicial}
            formattedDataFinal={formattedDataFinal}
            setFormattedDataInicial={setFormattedDataInicial}
            setFormattedDataFinal={setFormattedDataFinal}
          />

          <Produtos
            produtoSelecionado={produtoSelecionado}
            setProdutoSelecionado={setProdutoSelecionado}
          />

          <Fornecedores
            fornecedorSelecionado={fornecedorSelecionado}
            setFornecedorSelecionado={setFornecedorSelecionado}
          />

          <Clientes
            clienteSelecionado={clienteSelecionado}
            setClienteSelecionado={setClienteSelecionado}
          />

          <Vendedores
            vendedorSelecionado={vendedorSelecionado}
            setVendedorSelecionado={setVendedorSelecionado}
          />
          <Moeda
            moedaSelecionada={moedaSelecionada}
            setMoedaSelecionada={setMoedaSelecionada}
          />
          <Unidade
            unidadeSelecionada={unidadeSelecionada}
            setUnidadeSelecionada={setUnidadeSelecionada}
          />
          <label></label>
          <CheckboxesAnalise
            baixa_Comercial={baixa_Comercial}
            baixa_Estoque={baixa_Estoque}
            Faturados={Faturados}
            Colaboradores={Colaboradores}
            Comercial={Comercial}
            Comercial_Novo={Comercial_Novo}
            Corporativos={Corporativos}
            Cesbra_Schwietzer={Cesbra_Schwietzer}
            Farelo={Farelo}
            Soja={Soja}
            Oleo={Oleo}
            Milho={Milho}
            Corantes={Corantes}
            RentB={RentB}
            prod_Cesbra={prod_Cesbra}
            Sumatex={Sumatex}
            Rep={Rep}
            Fat={Fat}
            Ent={Ent}
            setBaixaEstoque={setBaixaEstoque}
            setBaixaComercial={setBaixaComercial}
            setFaturados={setFaturados}
            setColaboradores={setColaboradores}
            setComercial={setComercial}
            setComercialNovo={setComercialNovo}
            setCorporativos={setCorporativos}
            setCesbraSchwietzer={setCesbraSchwietzer}
            setFarelo={setFarelo}
            setSoja={setSoja}
            setOleo={setOleo}
            setMilho={setMilho}
            setProdCesbra={setProdCesbra}
            setSumatex={setSumatex}
            setRep={setRep}
            setCorantes={setCorantes}
            setRentB={setRentB}
            setFat={setFat}
            setEnt={setEnt}
          />

          <br></br>

          {isUserInGroupGerente && (
            <CheckboxTodas todas={todas} setTodas={setTodas} />
          )}

          <ButtonContainer>
            <SaveButton onClick={realizarConsulta}>
              <AiOutlineSearch />
              ⠀Calcular
            </SaveButton>
          </ButtonContainer>
        </div>
        <Tabs
          value={consultaSelecionada}
          onChange={handleConsultaChange}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          aria-label="scrollable force tabs example"
        >
          <Tab label="Relatorio" value="relatorio" />
          <Tab label="Dados Consolidados" value="dados" />
        </Tabs>
      </FormContainer>
      <MobileButton onClick={realizarConsulta}>
        <AiOutlineSearch />
        ⠀Calcular
      </MobileButton>

      {resultados.length > 0 && consultaSelecionada === "relatorio" ? (
        <ResultadosContainer id="resultados-container">
          <ResultadosTitle>Resultados</ResultadosTitle>
          <ButtonWrapper>
            <LimparButton
              onClick={handleLimparResultados}
              variant="contained"
              color="secondary"
            >
              Limpar
            </LimparButton>
            <ImprimirButton
              onClick={handlePrintResults}
              variant="contained"
              color="primary"
            >
              Imprimir
            </ImprimirButton>
            <XlsButton
              onClick={handleGenerateXLS}
              variant="contained"
              color="primary"
            >
              Gerar⠀XLS
            </XlsButton>
          </ButtonWrapper>

          <StyledDataGrid
            rows={rows}
            columns={columns}
            rowHeight={30}
            sortComparator={customComparator}
            sx={{
              boxShadow: 2,
              border: 2,
              borderColor: "primary.light",
            }}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 20 },
              },
            }}
            getRowClassName={(params) =>
              params.row.PERCENTUAL < 0
                ? `grid-vermelho`
                : params.row.PERCENTUAL < 5
                  ? `grid-amarelo`
                  : ``
            }
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) =>
              setColumnVisibilityModel(newModel)
            }
            filterModel={filterModel}
            onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            pageSizeOptions={[5, 10, 15, 20]}
            disableSelectionOnClick
          />

          <Modal
            isOpen={modalIsOpen}
            key={modalKey}
            ariaHideApp={false}
            onRequestClose={closeModal}
            contentLabel="Detalhes do Pedido"
            className={`custom-modal ${isSmallScreen ? "isSmallScreen" : ""}`}
            overlayClassName="custom-overlay"
          >
            {dados ? (
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                <div
                  style={{
                    width: "100%",
                    border: "0.5px solid black",
                  }}
                >
                  <Table>
                    <TableBody>
                      <TableRow>
                        <StyledTableCellModal></StyledTableCellModal>
                        <StyledTableCellModal>Compra</StyledTableCellModal>
                        <StyledTableCellModal>Venda</StyledTableCellModal>
                      </TableRow>
                      <TableRow>
                        <StyledTableCellModal2>Valor:</StyledTableCellModal2>
                        <TableCell style={{ paddingBottom: "5px" }}>
                          {formatarValorMedio(dados.CUSTO_PRODUTO_COMPRA)}
                        </TableCell>
                        <TableCell style={{ paddingBottom: "5px" }}>
                          {formatarValorMedio(dados.VL_UNITARIO)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <StyledTableCellModal2>ICMS ($):</StyledTableCellModal2>
                        <TableCell style={{ paddingBottom: "5px" }}>
                          {formatarValorMedio(dados.ICMS_PRODUTO_COMPRA)}
                        </TableCell>
                        <TableCell style={{ paddingBottom: "5px" }}>
                          {formatarValorMedio(dados.icms_venda)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <StyledTableCellModal2>PIS ($):</StyledTableCellModal2>
                        <TableCell style={{ paddingBottom: "5px" }}>
                          {formatarValorMedio(dados.PIS_PRODUTO_COMPRA)}
                        </TableCell>
                        <TableCell style={{ paddingBottom: "5px" }}>
                          {formatarValorMedio(dados.pis_venda)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <StyledTableCellModal2>
                          COFINS ($):
                        </StyledTableCellModal2>
                        <TableCell style={{ paddingBottom: "5px" }}>
                          {formatarValorMedio(dados.COFINS_PRODUTO_COMPRA)}
                        </TableCell>
                        <TableCell style={{ paddingBottom: "5px" }}>
                          {formatarValorMedio(dados.cofins_venda)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <StyledTableCellModal2>
                          Encargos ($):
                        </StyledTableCellModal2>
                        <TableCell style={{ paddingBottom: "5px" }}>
                          {formatarValorMedio(dados.ENCARGOS_PRODUTO_COMPRA)}
                        </TableCell>
                        <TableCell style={{ paddingBottom: "5px" }}>
                          {formatarValorMedio(dados.encargos_venda)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <StyledTableCellModal2>
                          Embalagem ($):
                        </StyledTableCellModal2>
                        <TableCell style={{ paddingBottom: "5px" }}>
                          {formatarValorMedio(dados.EMB_COMPRA_LIQ)}
                        </TableCell>
                        <TableCell style={{ paddingBottom: "5px" }}>
                          {formatarValorMedio(dados.emb_venda_liq)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <StyledTableCellModal2>
                          Frete ($):
                        </StyledTableCellModal2>
                        <TableCell style={{ paddingBottom: "5px" }}>
                          {formatarValorMedio(dados.FRETE_COMPRA_LIQ)}
                        </TableCell>
                        <TableCell style={{ paddingBottom: "5px" }}>
                          {formatarValorMedio(dados.frete_venda_liq)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <StyledTableCellModal2>
                          Comissões ($):
                        </StyledTableCellModal2>
                        <TableCell style={{ paddingBottom: "5px" }}></TableCell>
                        <TableCell style={{ paddingBottom: "5px" }}>
                          {formatarValorMedio(dados.comissoes_venda)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <StyledTableCellModal2>
                          Part. Compra ($):
                        </StyledTableCellModal2>
                        <TableCell style={{ paddingBottom: "5px" }}>
                          {formatarValorMedio(dados.participacao_compra)}
                        </TableCell>
                        <TableCell style={{ paddingBottom: "5px" }}></TableCell>
                      </TableRow>
                      <TableRow>
                        <StyledTableCellModal2>
                          Part. Venda ($):
                        </StyledTableCellModal2>
                        <TableCell style={{ paddingBottom: "5px" }}>
                          {formatarValorMedio(dados.participacao_venda)}
                        </TableCell>
                        <TableCell style={{ paddingBottom: "5px" }}></TableCell>
                      </TableRow>
                      <TableRow>
                        <StyledTableCellModal2>
                          Vl. Líquido:
                        </StyledTableCellModal2>
                        <TableCell style={{ paddingBottom: "5px" }}>
                          {formatarValorMedio(dados.VALOR_LIQUIDO_COMPRA)}
                        </TableCell>
                        <TableCell style={{ paddingBottom: "5px" }}>
                          {formatarValorMedio(dados.VALOR_LIQUIDO_VENDA)}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>
              </div>
            ) : (
              <div
                style={{
                  marginLeft: "50%",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  zIndex: 9999,
                }}
              >
                <CircularProgress />
              </div>
            )}
            <br></br>
            <LimparButton onClick={closeModal}>Fechar</LimparButton>
          </Modal>
        </ResultadosContainer>
      ) : resultados.length > 0 && consultaSelecionada === "dados" ? (
        <div
          style={{
            border: "5px solid #D3D3D3", 
            padding: "10px", 
          }}
        >
          <Typography component="div">
            <div className="tb-result">
              <table>
                <tr>
                  <td style={{ color: "#2957A4" }}>Volume Total Faturado (Kg)</td>
                  <td>{quantidadeTotal}</td>
                </tr>
                <tr>
                  <td style={{ color: "#2957A4" }}>Valor Bruto Total Faturado (R$)</td>
                  <td>{formatarValor(brutoTotal)}</td>
                </tr>
                <tr>
                  <td style={{ color: "#2957A4" }}>Valor Líq. Total de Compra (R$)</td>
                  <td>{formatarValor(liquidoTotalDeCompra)}</td>
                </tr>
                <tr>
                  <td style={{ color: "#2957A4" }}>Valor Líq. Total de Venda (R$)</td>
                  <td>{liquidoTotalDeVenda}</td>
                </tr>
                <tr>
                  <td style={{ color: "#2957A4" }}>Saldo Líquido (R$)</td>
                  <td>{formatarValor(saldoLiquido)}</td>
                </tr>
                <tr>
                  <td style={{ color: "#2957A4" }}>Valor Líq. Médio de Compra (R$)</td>
                  <td>{formatarValorMedio(valorMedioDeCompra)}</td>
                </tr>
                <tr>
                  <td style={{ color: "#2957A4" }}>Valor Líq. Médio de Venda (R$)</td>
                  <td>{formatarValorMedio(valorMedioDeVenda)}</td>
                </tr>
                <tr>
                  <td style={{ color: "#2957A4" }}>Valor Bruto Médio de Venda (R$)</td>
                  <td>{formatarValorMedio(valorMedioBrutoVenda)}</td>
                </tr>
                <tr>
                  <td style={{ color: "#2957A4" }}>Rentabilidade Média Sobre o Vl. Liq. de Compra (%)</td>
                  <td style={{ backgroundColor: "#68d6a3" }}>{rentabilidadeMediaLiquidoCompra}</td>
                </tr>
              </table>
            </div>
          </Typography>
        </div>
      ) : null}
    </div>
  );
};

export default Cascata;
