import React, { useState, useEffect } from "react";
import { TextField, Autocomplete } from "@mui/material/";
import { Axios } from "../../config/axiosConf";
import styled from "@emotion/styled";

const StyledH2 = styled.h2`
    display: block;
    font-size: 1em;
    font-weight: bold;
    padding-left: 15px;
`;

const StyledTextField = styled(TextField)`
    width: 380px;
`;

const Label = styled.label`
    display: flex;
    align-items: center;
    gap: 8px;
    width: 500px;
`;

const StyledAutocomplete = styled(Autocomplete)`
    width: ${({ isSmallScreen }) => (isSmallScreen ? "100%" : "380px")};

    .MuiButtonBase-root {
        width: 40px;
    }
`;

const TransportadorasPortaria = ({ transportadoraSelecionada, setTransportadoraSelecionada }) => {
    const [transportadoras, setTransportadoras] = useState([]);
    const [isSmallScreen, setIsSmallScreen] = useState(window.matchMedia("(max-width: 750px)").matches);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.matchMedia("(max-width: 750px)").matches);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);



    useEffect(() => {
        const getTransportadoras = async () => {
            try {
                const response = await Axios.get("ZvpzLdYsrU02Mwzc9obi_Q");
                setTransportadoras(response.data);
            } catch (error) {
                console.log(error);
            }
        };

        getTransportadoras();
    }, []);

    const enviarTransportadoraSelecionada = (event, newValue) => {
        setTransportadoraSelecionada(newValue);
    };



    return (
        <>
            <StyledH2>1.3 - Transportador:</StyledH2>
            <Label id="labelTransportadora">
                <StyledAutocomplete
                    id="transportadoras"
                    freeSolo={false}
                    options={transportadoras}
                    value={transportadoraSelecionada}
                    onChange={(event, newValue) => enviarTransportadoraSelecionada(event, newValue)}
                    renderInput={(params) => (
                        <StyledTextField
                            {...params}
                            id="text-transportadoras"
                            label="Escolha uma transportadora"
                            required
                        />
                    )}
                    isSmallScreen={isSmallScreen}
                    isOptionEqualToValue={(option, value) => option === value}
                />
            </Label>
        </>
    );
};

export default TransportadorasPortaria;